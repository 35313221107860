// LICENSE_CODE TLM

/* eslint-disable quote-props,quotes,max-len */
let E = {
  "en": {
    "translation": {
      "Home": "Home",
      "Grouper": "Grouper",
      "GROUPER": "GROUPER",
      "Logout": "Logout",
      "Profile": "Profile",
      "Onboard": "Onboard",
      "Cutter": "Cutter",
      "Blog": "Blog",
      "Contact Us": "Contact Us",
      "Login": "Login",
      "Create group clips": "Create group clips",
      "made easy": "made easy",
      "Free Get started": "Free Get started",
      "14 days free. No risk.": "14 days free. No risk.",
      "Signup Free": "Signup Free",
      "Contact us with any question at": "Contact us with any question at",
      "Just one more step before you start Grouping": "Just one more step before you start Grouping",
      "First name": "First name",
      "What is your first name": "What is your first name",
      "Please input your first name!": "Please input your first name!",
      "Last/family name": "Last/family name",
      "What is your last/family name": "What is your last/family name",
      "Please input your last/family name!": "Please input your last/family name!",
      "Nickname": "Nickname",
      "What do you want others to call you?": "What do you want others to call you?",
      "Please input your nickname!": "Please input your nickname!",
      "Phone Number": "Phone Number",
      "What is your phone number?": "What is your phone number?",
      "Please input your phone number! Example +18777755232": "Please input your phone number! Example +18777755232",
      "I agree to the": "I agree to the",
      "privacy policy": "privacy policy",
      "terms of service": "terms of service",
      "disclaimer": "disclaimer",
      "Privacy policy": "Privacy policy",
      "Terms of service": "Terms of service",
      "Disclaimer": "Disclaimer",
      "Submit": "Submit",
      "Contact your Toolium representative to buy more credits": "Contact your Toolium representative to buy more credits",
      "See your credits in profile": "See your credits in profile",
      "Contact your Toolium representative to activate your account": "Contact your Toolium representative to activate your account",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Click to Upload or Drag and Drop an AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.",
      "Resend verification email": "Resend verification email",
      "Call us at +1-877-775-5232": "Call us at +1-877-775-5232",
      "Plan": "Plan",
      "PLANS": "PLANS",
      "Choose a plan that works for you": "Choose a plan that works for you",
      "Basic": "Basic",
      "credit": "credit",
      "year": "year",
      "Billed annually.": "Billed annually.",
      "Contact us": "Contact us",
      "Recommended": "Recommended",
      "Standard": "Standard",
      "credits": "credits",
      "Billed annually. Save": "Billed annually. Save",
      "Premium": "Premium",
      "Toolium Cutter": "Toolium Cutter",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process",
      "Automate uncreative tasks": "Automate uncreative tasks",
      "Save money processing media": "Save money processing media",
      "Save 4 hours every shoot day": "Save 4 hours every shoot day",
      "The next generation of post production workflow tools": "The next generation of post production workflow tools",
      "14 days free.": "14 days free.",
      "Plans": "Plans",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer",
      "Toolium Grouper": "Toolium Grouper",
      "Used in": "Used in",
      "Groups are created with the full master clips": "Groups are created with the full master clips",
      "Reference sub-clips for shortened clips": "Reference sub-clips for shortened clips",
      "New avid multigroup type groups": "New avid multigroup type groups",
      "Track renaming to group clip names": "Track renaming to group clip names",
      "Features": "Features",
      "AAF uploads": "AAF uploads",
      "AAF upload": "AAF upload",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: Classic AVID grouping",
      "Process type unknown": "Process type unknown",
      "No clips found on sequence": "No clips found on sequence",
      "No clips for writing sequence": "No clips for writing sequence",
      "No sequence found in AAF file": "No sequence found in AAF file",
      "File is to big reduce AAF file": "File is to big reduce AAF file",
      "Sequence has length of 0": "Sequence has length of 0",
      "Group clip found on sequence": "Group clip found on sequence",
      "Group clip found on sequence 2": "Group clip found on sequence 2",
      "Unknown selector type found in sequence": "Unknown selector type found in sequence",
      "Clip framerate does not match sequence framerate": "Clip framerate does not match sequence framerate",
      "Subclips with motion effects are not supported": "Subclips with motion effects are not supported",
      "Join the best": "Join the best",
      "You are in good hands.": "You are in good hands.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Assitent editors from these productions are already happy Tooliumers, more productive and saving time",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process",
      "Renames audio tracks within the group clips": "Renames audio tracks within the group clips",
      "Creates the new avid multigroup type groups": "Creates the new avid multigroup type groups",
      "Creates avid sub-clips for shortened clips": "Creates avid sub-clips for shortened clips",
      "Editor": "Editor",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Want to edit in your browser using our Avid Media Composer compatible editor?",
      "Yes, join pre launch": "Yes, join pre launch",
      "No I don't want": "No I don't want",
      "Click to Upload AAF": "Click to Upload AAF",
      "Bin panel": "Bin panel",
      "Go to Previous Event": "Go to Previous Event",
      "Go to Next Event": "Go to Next Event",
      "Step Backward 1 Fram": "Step Backward 1 Fram",
      "Step Forward 1 Fram": "Step Forward 1 Fram",
      "Mark IN": "Mark IN",
      "Play": "Play",
      "Mark OUT": "Mark OUT",
      "Mark Clip": "Mark Clip",
      "Clear Both Marks": "Clear Both Marks",
      "Add Marker": "Add Marker",
      "Swap Cam Bank": "Swap Cam Bank",
      "Find Bin": "Find Bin",
      "Gang": "Gang",
      "Step backward 10 frames": "Step backward 10 frames",
      "Step forward 10 frames": "Step forward 10 frames",
      "Go to IN": "Go to IN",
      "Play Length Toggle": "Play Length Toggle",
      "Go to OUT": "Go to OUT",
      "Match Frame": "Match Frame",
      "Reverse Match Frame": "Reverse Match Frame",
      "Quad Split": "Quad Split",
      "Nine Split": "Nine Split",
      "Step Backward 1 Frame": "Step Backward 1 Frame",
      "Step Forward 1 Frame": "Step Forward 1 Frame",
      "Add Edit": "Add Edit",
      "Lift": "Lift",
      "Extract": "Extract",
      "Quick Transition": "Quick Transition",
      "Remove Effect": "Remove Effect",
      "Collapse": "Collapse",
      "Video Quality Menu": "Video Quality Menu",
      "Monitor Volume": "Monitor Volume",
      "Meter Menu": "Meter Menu",
      "Effect Mode": "Effect Mode",
      "Trim Mode": "Trim Mode",
      "Step In": "Step In",
      "Step Out": "Step Out",
      "Render Effect": "Render Effect",
      "Tool Palette": "Tool Palette",
      "Head Fade": "Head Fade",
      "Tall Fade": "Tall Fade",
      "To the Left": "To the Left",
      "To the Right": "To the Right",
      "Select In/Out": "Select In/Out",
      "Link Selection Toggle": "Link Selection Toggle",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "Default Setup",
      "View Type": "View Type",
      "Track Panel": "Track Panel",
      "Effect Icons": "Effect Icons",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Dissolve Icons",
      "Clip Frames": "Clip Frames",
      "Clip Text": "Clip Text",
      "Sync Breaks": "Sync Breaks",
      "Dupe Detection": "Dupe Detection",
      "Color Correction": "Color Correction",
      "Audio Data": "Audio Data",
      "Clip Color...": "Clip Color...",
      "Segment Selection": "Segment Selection",
      "Mark I/O Selection": "Mark I/O Selection",
      "Track Color": "Track Color",
      "Show markers": "Show markers",
      "Show adapters": "Show adapters",
      "Show Track": "Show Track",
      "Track Control Panel": "Track Control Panel",
      "Zoom": "Zoom",
      "Open Comments": "Open Comments",
      "Call us at": "Call us at",
      "Office": "Office",
      "Tracks Info": "Tracks Info",
      "Edit Marker": "Edit Marker",
      "Add": "Add",
      "Marker Name": "Marker Name",
      "Color": "Color",
      "Frame": "Frame",
      "Track": "Track",
      "Clear Monitor": "Clear Monitor",
      "Clear Menu": "Clear Menu",
      "Show Markers": "Show Markers",
      "Show Adapters": "Show Adapters",
      "Toggle Fullscreen": "Toggle Fullscreen",
      "Highlighter": "Highlighter",
      "Logger": "Logger",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Invalid file DOC, use DOCX instead, you can try this 3rd party tool",
      "Open DOC to DOCX converter": "Open DOC to DOCX converter",
      "File is missing any cuts, try to click": "File is missing any cuts, try to click",
      "SET ALL ROWS": "SET ALL ROWS",
      "OFF: Timecodes have start and end": "OFF: Timecodes have start and end",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON: No end timecode and just add 30 seconds from start timecode",
      "NO END TIMECODE USE 30 SECONDS": "NO END TIMECODE USE 30 SECONDS",
      "HAS END TIMECODE": "HAS END TIMECODE",
      "OFF: Create cuts using highlighted text": "OFF: Create cuts using highlighted text",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON: Create cuts for all rows in the document. Ignoring the highlights",
      "ALL ROWS": "ALL ROWS",
      "ONLY HIGHLIGHTED": "ONLY HIGHLIGHTED",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "OFF: Ignore source camrolls. This will create cuts only according to source timecode.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll",
      "USE CAMROLL IN DOCX": "USE CAMROLL IN DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORE CAMROLL IN DOCX",
      "OFF: AAF downloaded will not be renamed": "OFF: AAF downloaded will not be renamed",
      "ON: Rename AAF to DOCX file name": "ON: Rename AAF to DOCX file name",
      "RENAME AAF": "RENAME AAF",
      "NO RENAME": "NO RENAME",
      "Click to Upload DOCX": "Click to Upload DOCX",
      "MULTI": "MULTI",
      "CLASSIC": "CLASSIC",
      "RENAME ON": "RENAME ON",
      "RENAME OFF": "RENAME OFF",
      "KEEP AUDIO ON": "KEEP AUDIO ON",
      "KEEP AUDIO OFF": "KEEP AUDIO OFF",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence",
      "GROUP AUDIO AS IS ON": "GROUP AUDIO AS IS ON",
      "GROUP AUDIO AS IS OFF": "GROUP AUDIO AS IS OFF",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot",
      "KEEP CAM ON TRACK ON": "KEEP CAM ON TRACK ON",
      "KEEP CAM ON TRACK OFF": "KEEP CAM ON TRACK OFF",
      "ROUTE AUDIO ON": "ROUTE AUDIO ON",
      "ROUTE AUDIO OFF": "ROUTE AUDIO OFF",
      "Toolium Editor": "Toolium Editor",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer",
      "Free forever": "Free forever",
      "Collaborate with ease with all the team": "Collaborate with ease with all the team",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Keep everyone on the same page by writing feedback right on a frame. Or draw it",
      "Look and feel you are use to in Avid Media Composer": "Look and feel you are use to in Avid Media Composer",
      "Keyboard shortcuts you are use to": "Keyboard shortcuts you are use to",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!",
      "A-Side Trim Counter": "A-Side Trim Counter",
      "B-Side Trim Counter": "B-Side Trim Counter",
      "Insert Track": "Insert Track",
      "OK": "OK",
      "Track Type": "Track Type",
      "Track Num": "Track Num",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Dur TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "Dur 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Abs 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Mas 25",
      "Dur 25": "Dur 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Abs 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Sequence",
      "Timecode": "Timecode",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "I/O",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Footage",
      "Frames": "Frames",
      "Source": "Source",
      "None": "None",
      "Master": "Master",
      "Duration": "Duration",
      "In/Out": "In/Out",
      "Absolute": "Absolute",
      "Remain": "Remain",
      "Audio Mono": "Audio Mono",
      "Audio Stereo": "Audio Stereo",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Data",
      "Video": "Video",
      "Leave your comment here...": "Leave your comment here...",
      "Send": "Send",
      "Workspace": "Workspace",
      "Project Settings": "Project Settings",
      "Leave Project": "Leave Project",
      "Delete Project": "Delete Project",
      "New Project": "New Project",
      "Name": "Name",
      "Please, input the name": "Please, input the name",
      "Edit Project": "Edit Project",
      "Save Settings": "Save Settings",
      "Jump to project of file...": "Jump to project of file...",
      "Status": "Status",
      "Comments": "Comments",
      "Size": "Size",
      "Date uploaded": "Date uploaded",
      "Uploader": "Uploader",
      "Action": "Action",
      "File upload": "File upload",
      "Folder upload": "Folder upload",
      "Folder upload as proxy": "Folder upload as proxy",
      "New folder": "New folder",
      "New private folder": "New private folder",
      "Download all": "Download all",
      "Recently deleted": "Recently deleted",
      "Project settings": "Project settings",
      "Rename file": "Rename file",
      "Move": "Move",
      "Download": "Download",
      "Make private": "Make private",
      "Reveal in project": "Reveal in project",
      "Delete": "Delete",
      "File Information": "File Information",
      "Copy Comments": "Copy Comments",
      "Paste Comments": "Paste Comments",
      "Print": "Print",
      "Download as File...": "Download as File...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Plain Text",
      "Download for Final Cut Pro X": "Download for Final Cut Pro X",
      "Download for Media Composer": "Download for Media Composer",
      "Download for Resolve": "Download for Resolve",
      "No comments": "No comments",
      "This is premium feature, contact us at support@toolium.org": "This is premium feature, contact us at support@toolium.org",
      "Automated test to speech": "Automated test to speech",
      "Import from uploads": "Import from uploads",
      "Upload your VO file": "Upload your VO file",
      "Record voice over": "Record voice over",
      "Crop": "Crop",
      "Voiceover": "Voiceover",
      "Adjust colors": "Adjust colors",
      "Trim": "Trim",
      "Speed": "Speed",
      "Loop": "Loop",
      "Flip": "Flip",
      "Effects": "Effects",
      "Animation": "Animation",
      "Add title": "Add title",
      "Export": "Export",
      "Grid": "Grid",
      "Split": "Split",
      "Split View": "Split View",
      "Zoom to fit": "Zoom to fit",
      "Audio meter": "Audio meter",
      "TC": "TC",
      "Comment": "Comment",
      "Jump to Marker": "Jump to Marker",
      "Change Color": "Change Color",
      "Change Track": "Change Track",
      "Import Markers": "Import Markers",
      "Export Markers": "Export Markers",
      "Choose Columns": "Choose Columns",
      "Cameras": "Cameras",
      "Desktop is required": "Desktop is required",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.",
      "Go back": "Go back",
      "OFF: Use 0 seconds for handles": "OFF: Use 0 seconds for handles",
      "ON: Use 5 seconds for handles": "ON: Use 5 seconds for handles",
      "HANDLES OFF": "HANDLES OFF",
      "HANDLES ON": "HANDLES ON",
      "OFF: word-per-second will be 0.37": "OFF: word-per-second will be 0.37",
      "ON: word-per-second will be detected": "ON: word-per-second will be detected",
      "DETECT WPS": "DETECT WPS",
      "WPS IS 0.37": "WPS IS 0.37",
      "OFF: don't change order or merge cuts": "OFF: don't change order or merge cuts",
      "ON: reduce cuts by merging and sorting may change order": "ON: reduce cuts by merging and sorting may change order",
      "USE REDUCE": "USE REDUCE",
      "KEEP ORDER AND CUTS": "KEEP ORDER AND CUTS",
      "Timecode Errors": "Timecode Errors",
      "Timecodes": "Timecodes",
      "Source ID": "Source ID",
      "Error": "Error",
      "Log Out": "Log Out",
      "Sign Up": "Sign Up",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Assistant editors from these productions are already happy Tooliumers, more productive and saving time",
      "Redo": "Redo",
      "Undo": "Undo",
      "You have no credits left": "You have no credits left",
      "You need to buy more credits in order to keep using our platform": "You need to buy more credits in order to keep using our platform",
      "Note": "Note",
      "You will still get free daily credits even if you don't make a purchase": "You will still get free daily credits even if you don't make a purchase",
      "Benefits of buying credits": "Benefits of buying credits",
      "Bulk discount (up to 50%)": "Bulk discount (up to 50%)",
      "Get to request features to fit your worksflow": "Get to request features to fit your worksflow",
      "You only pay for what you use": "You only pay for what you use",
      "No monthly membership and no automatic recurring payments": "No monthly membership and no automatic recurring payments",
      "You get to support our site": "You get to support our site",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits",
      "Buy credits": "Buy credits",
      "Ask for free credits, I don't have any money": "Ask for free credits, I don't have any money",
      "Signup with Google to get 500 free credits": "Signup with Google to get 500 free credits",
      "You will also get 150 additional free credits every day": "You will also get 150 additional free credits every day",
      "Select": "Select",
      "Credits": "Credits",
      "1 cut with Cutter = 20 credits": "1 cut with Cutter = 20 credits",
      "Discount": "Discount",
      "Diamond": "Diamond",
      "Enterprise": "Enterprise",
      "7 days money back guarantee": "7 days money back guarantee",
      "For any questions or issues": "For any questions or issues",
      "Free": "Free",
      "Gold": "Gold",
      "1 group with Grouper": "1 group with Grouper",
      "1 cut with Cutter": "1 cut with Cutter",
      "Expand": "Expand",
      "Markers": "Markers",
      "Search in project…": "Search in project…",
      "Camera List": "Camera List",
      "New Log": "New Log",
      "Framerate": "Framerate",
      "Open Log List": "Open Log List",
      "Hide scene / take": "Hide scene / take",
      "Automatically add members (premium feature)": "Automatically add members (premium feature)",
      "Logger default settings": "Logger default settings",
      "Please, select the fps": "Please, select the fps",
      "Log info": "Log info",
      "Export log": "Export log",
      "Delete log": "Delete log",
      "Mark Timecode In": "Mark Timecode In",
      "This is a premium feature, contact us at support@toolium.org": "This is a premium feature, contact us at support@toolium.org",
      "BLOGS": "BLOGS",
      "Published": "Published",
      "Author": "Author",
      "CONTACT US": "CONTACT US",
      "Phone number": "Phone number",
      "Payment": "Payment",
      "VAT": "VAT",
      "Last Modified": "Last Modified",
      "Something went wrong": "Something went wrong",
      "Download For Premiere": "Download For Premiere",
      "Move to...": "Move to...",
      "Copy to...": "Copy to...",
      "Duplicate": "Duplicate",
      "hours": "hours",
      "ago": "ago",
      "Share": "Share",
      "Open In Editor": "Open In Editor",
      "Rename": "Rename",
      "Open In Highlighter": "Open In Highlighter",
      "New": "New",
      "items": "items",
      "Center Duration": "Center Duration",
      "Focus": "Focus",
      "Toggle Source/Record In Timeline": "Toggle Source/Record In Timeline",
      "Toggle Client Monitor": "Toggle Client Monitor",
      "Segment Mode (Lift/Overwrite)": "Segment Mode (Lift/Overwrite)",
      "Segment Mode (Extract/Splice-In)": "Segment Mode (Extract/Splice-In)",
      "Display bin In Text View mode": "Display bin In Text View mode",
      "Display bin In Frame View mode": "Display bin In Frame View mode",
      "Add Track": "Add Track",
      "Creation Date": "Creation Date",
      "Start": "Start",
      "tbin": "tbin",
      "lbin": "lbin",
      "Audio SR": "Audio SR",
      "Drive": "Drive",
      "End": "End",
      "FPS": "FPS",
      "IN-OUT": "IN-OUT",
      "Tracks": "Tracks",
      "Tape": "Tape",
      "TapeID": "TapeID",
      "Source settings...": "Source settings...",
      "Get Info": "Get Info",
      "Bulk Edit...": "Bulk Edit...",
      "Find And Replace": "Find And Replace",
      "Choose columns...": "Choose columns...",
      "Add Custom Column": "Add Custom Column",
      "Rename Column": "Rename Column",
      "Hide Column": "Hide Column",
      "Sort on Column, Ascending": "Sort on Column, Ascending",
      "Sort on Column, Descending": "Sort on Column, Descending",
      "Sift Bin Contents...": "Sift Bin Contents...",
      "What' This?": "What' This?",
      "Media Tool": "Media Tool",
      "Bin Column Selection": "Bin Column Selection",
      "column are selected.": "column are selected.",
      "The project name must not exceed 255 characters": "The project name must not exceed 255 characters",
      "Rename folder": "Rename folder",
      "minutes": "minutes",
      "Lock Track": "Lock Track",
      "Unlock Track": "Unlock Track",
      "Delete Track": "Delete Track",
      "Rename Track...": "Rename Track...",
      "What's This?": "What's This?",
      "Match Frame Track": "Match Frame Track",
      "Reverse Match Frame Track": "Reverse Match Frame Track",
      "Add Filter": "Add Filter",
      "Create Sequence Template": "Create Sequence Template",
      "Mute Clips": "Mute Clips",
      "Unmute Clips": "Unmute Clips",
      "Change Source Track": "Change Source Track",
      "Waveform Sync": "Waveform Sync",
      "Add/Edit Title...": "Add/Edit Title...",
      "Promote Titles": "Promote Titles",
      "Render": "Render",
      "Mixdown": "Mixdown",
      "Audio Ducking": "Audio Ducking",
      "Show Sequence Map": "Show Sequence Map",
      "Strip Silence from Sequence": "Strip Silence from Sequence",
      "Remove Match Frame Edits": "Remove Match Frame Edits",
      "Create Sequence Based on Selection": "Create Sequence Based on Selection",
      "Find Black Hole": "Find Black Hole",
      "Find Flash Frame": "Find Flash Frame",
      "Lock Tracks": "Lock Tracks",
      "Unlock Tracks": "Unlock Tracks",
      "Move Clip Leaves Filter": "Move Clip Leaves Filter",
      "Select Filter with Segment Tools": "Select Filter with Segment Tools",
      "Add Timeline Clip Note...": "Add Timeline Clip Note...",
      "Get Position Info": "Get Position Info",
      "Set Local Clip Color": "Set Local Clip Color",
      "Timeline Settings...": "Timeline Settings...",
      "What's this?": "What's this?",
      "Open to Source Monitor": "Open to Source Monitor",
      "Open to Record Monitor": "Open to Record Monitor",
      "days": "days",
      "Disable popup": "Disable popup",
      "Single Cam": "Single Cam",
      "4 Split": "4 Split",
      "9 Split": "9 Split",
      "week": "week",
      "weeks": "weeks",
      "Zoom In": "Zoom In",
      "Zoom Out": "Zoom Out",
      "Move One Frame Left": "Move One Frame Left",
      "Move One Frame Right": "Move One Frame Right",
      "Move Ten Frames Left": "Move Ten Frames Left",
      "Move Ten Frames Right": "Move Ten Frames Right",
      "Space": "Space",
      "Play Backwards": "Play Backwards",
      "Stop": "Stop",
      "Go to Previous Cut": "Go to Previous Cut",
      "Go to Next Cut": "Go to Next Cut",
      "Go to Previous Marker": "Go to Previous Marker",
      "Go to Next Marker": "Go to Next Marker",
      "Go to Mark In": "Go to Mark In",
      "Go to Mark Out": "Go to Mark Out",
      "Delete Marker": "Delete Marker",
      "Toggle Selection Tool": "Toggle Selection Tool",
      "Toggle Insertion Tool": "Toggle Insertion Tool",
      "Open Info Modal": "Open Info Modal",
      "Mark In": "Mark In",
      "Mark Out": "Mark Out",
      "Cut": "Cut",
      "Add Green Marker": "Add Green Marker",
      "Add Red Marker": "Add Red Marker",
      "Play In to Out": "Play In to Out",
      "Toggle Trim Mode": "Toggle Trim Mode",
      "Move Trim One Frame Left": "Move Trim One Frame Left",
      "Move Trim One Frame Right": "Move Trim One Frame Right",
      "Move Trim Ten Frames Left": "Move Trim Ten Frames Left",
      "Move Trim Ten Frames Right": "Move Trim Ten Frames Right",
      "Add Video Track": "Add Video Track",
      "Add Mono Audio Track": "Add Mono Audio Track",
      "Add Stereo Audio Track": "Add Stereo Audio Track",
      "Mark All Tracks": "Mark All Tracks",
      "Unmark All Tracks": "Unmark All Tracks",
      "Insert": "Insert",
      "Shortcuts": "Shortcuts",
      "Search...": "Search...",
      "Play/Stop": "Play/Stop",
      "Search in project...": "Search in project...",
      "free with Google connect": "free with Google connect",
      "hour": "hour",
      "day": "day",
      "second": "second",
      "seconds": "seconds",
      "seconds left": "seconds left",
      "Uploading": "Uploading",
      "Preparing": "Preparing",
      "minute": "minute",
      "Credit cost": "Credit cost",
      "ws_file": "ws_file",
      "tcode": "tcode",
      "PROFILE": "PROFILE",
      "Your current credits": "Your current credits",
      "USAGE HISTORY": "USAGE HISTORY",
      "File already exists": "File already exists",
      "Settings": "Settings",
      "Add or remove files": "Add or remove files",
      "Copy link": "Copy link",
      "Public": "Public",
      "Private": "Private",
      "Link access": "Link access",
      "Access": "Access",
      "Please, select the access": "Please, select the access",
      "Date created": "Date created",
      "Created by": "Created by",
      "Visits": "Visits",
      "Active": "Active",
      "Please, input the password": "Please, input the password",
      "Password": "Password",
      "Cancel": "Cancel",
      "Create link": "Create link",
      "items selected": "items selected",
      "Enter password": "Enter password",
      "Shared by": "Shared by",
      "Camera name": "Camera name",
      "Add camera": "Add camera",
      "Increase shot": "Increase shot",
      "Increase take": "Increase take",
      "Attach": "Attach",
      "Type a pin": "Type a pin",
      "Search pins": "Search pins",
      "CSV file": "CSV file",
      "Avid Media Composer Markers": "Avid Media Composer Markers",
      "XLS file": "XLS file",
      "DaVinci Resolve EDL": "DaVinci Resolve EDL",
      "Export Log": "Export Log",
      "Export format": "Export format",
      "Please, select the export format": "Please, select the export format",
      "Edit": "Edit",
      "Are you sure to delete this pin?": "Are you sure to delete this pin?",
      "It's cannot be undone": "It's cannot be undone",
      "Yes": "Yes",
      "No": "No",
      "Pin It!": "Pin It!",
      "Wrong password": "Wrong password",
      "Move to": "Move to",
      "Copy to": "Copy to",
      "Delete this file?": "Delete this file?",
      "files?": "files?",
      "Delete this folder?": "Delete this folder?",
      "Folder": "Folder",
      "Type": "Type",
      "File": "File",
      "Delete this link?": "Delete this link?",
      "Save": "Save",
      "Please, enter the filename": "Please, enter the filename",
      "New filename": "New filename",
      "bins": "bins",
      "From story producer to editor in one minute": "From story producer to editor in one minute",
      "First of its kind - this instrument is required in the world of reality series where there are hundreds of hours of transcripts, and editing teams that sit to cut selects according to the choices of the story producers.": "First of its kind - this instrument is required in the world of reality series where there are hundreds of hours of transcripts, and editing teams that sit to cut selects according to the choices of the story producers.",
      "A simplified proffessional editing platform, with automized tools that makes work a  pleasure": "A simplified proffessional editing platform, with automized tools that makes work a  pleasure",
      "Fully customizable keyboard shortcuts": "Fully customizable keyboard shortcuts",
      "Toolium - The next generation of post production": "Toolium - The next generation of post production",
      "Toolium is a unique online video editing platform. It has a variety of automized tools for different aspects of the post production process. From transcoding to grouping, fast and simple, time is money.": "Toolium is a unique online video editing platform. It has a variety of automized tools for different aspects of the post production process. From transcoding to grouping, fast and simple, time is money.",
      "Automation tool for creating group clips and subclips": "Automation tool for creating group clips and subclips",
      "Upload an AAF file and we will provide you an Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Upload an AAF file and we will provide you an Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer",
      "These productions are already happy Tooliumers": "These productions are already happy Tooliumers",
      "Get your edited sequences by highlighting text in a transcription document": "Get your edited sequences by highlighting text in a transcription document",
      "Communicate directly in the system": "Communicate directly in the system",
      "Online all the time, access from any device": "Online all the time, access from any device",
      "New Bin": "New Bin",
      "Desktop is required for editor": "Desktop is required for editor",
      "Desktop is required for highlighter": "Desktop is required for highlighter",
      "Go to workspace": "Go to workspace",
      "Create new link": "Create new link",
      "Add to existing link": "Add to existing link",
      "Add new items": "Add new items",
      "Please, select the action": "Please, select the action",
      "Link": "Link",
      "Please, select a link": "Please, select a link",
      "Edit link": "Edit link",
      "links?": "links?",
      "New bin": "New bin",
      "File Name": "File Name",
      "Upload Date": "Upload Date",
      "RES": "RES",
      "uploaded on": "uploaded on",
      "Lock": "Lock",
      "Make a copy": "Make a copy",
      "Skip": "Skip",
      "Do this for all files": "Do this for all files",
      "Project": "Project",
      "Replace": "Replace",
      "The track already exists": "The track already exists",
      "Are you sure you want to delete the selected track(s)?": "Are you sure you want to delete the selected track(s)?",
      "Password is too long": "Password is too long",
      "Name is too long": "Name is too long",
      "View": "View",
      "Back to Workspace": "Back to Workspace",
    },
  },
  "fr": {
    "translation": {
      "Home": "Maison",
      "Grouper": "Groupeur",
      "GROUPER": "GROUPEUR",
      "Logout": "Se déconnecter",
      "Profile": "Profil",
      "Onboard": "À bord",
      "Cutter": "Coupeur",
      "Blog": "Blog",
      "Contact Us": "Contactez-nous",
      "Login": "Se connecter",
      "Create group clips": "Créer des clips de groupe",
      "made easy": "rendue facile",
      "Free Get started": "Gratuit Commencer",
      "14 days free. No risk.": "14 jours gratuits. Aucun risque.",
      "Signup Free": "Inscription gratuite",
      "Contact us with any question at": "Contactez-nous pour toute question à",
      "Just one more step before you start Grouping": "Encore une étape avant de commencer le regroupement",
      "First name": "Prénom",
      "What is your first name": "Quel est votre prénom",
      "Please input your first name!": "Veuillez saisir votre prénom !",
      "Last/family name": "Nom de famille",
      "What is your last/family name": "Quel est votre nom de famille",
      "Please input your last/family name!": "Veuillez saisir votre nom de famille !",
      "Nickname": "Surnom",
      "What do you want others to call you?": "Comment voulez-vous que les autres vous appellent ?",
      "Please input your nickname!": "Veuillez saisir votre pseudo !",
      "Phone Number": "Numéro de téléphone",
      "What is your phone number?": "Quel est votre numéro de téléphone?",
      "Please input your phone number! Example +18777755232": "Veuillez saisir votre numéro de téléphone ! Exemple +18777755232",
      "I agree to the": "je suis d'accord avec le",
      "privacy policy": "politique de confidentialité",
      "terms of service": "conditions d'utilisation",
      "disclaimer": "clause de non-responsabilité",
      "Privacy policy": "Politique de confidentialité",
      "Terms of service": "Conditions d'utilisation",
      "Disclaimer": "Clause de non-responsabilité",
      "Submit": "Soumettre",
      "Contact your Toolium representative to buy more credits": "Contactez votre représentant Toolium pour acheter plus de crédits",
      "See your credits in profile": "Voir vos crédits dans le profil",
      "Contact your Toolium representative to activate your account": "Contactez votre représentant Toolium pour activer votre compte",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "téléchargez un fichier AAF de votre séquence synchronisée et nous vous générerons un fichier AAF groupé pour Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Cliquez pour télécharger ou glisser-déposer un AFF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Veuillez vérifier votre adresse e-mail afin de pouvoir continuer à utiliser le site Web. Nous vous avons envoyé un e-mail avec un lien sur lequel cliquer.",
      "Resend verification email": "Renvoyer l'e-mail de vérification",
      "Call us at +1-877-775-5232": "Appelez-nous au +1-877-775-5232",
      "Plan": "Plan",
      "PLANS": "DES PLANS",
      "Choose a plan that works for you": "Choisissez un forfait qui vous convient",
      "Basic": "Basique",
      "credit": "crédit",
      "year": "année",
      "Billed annually.": "Facturé annuellement.",
      "Contact us": "Contactez-nous",
      "Recommended": "Recommandé",
      "Standard": "Standard",
      "credits": "crédits",
      "Billed annually. Save": "Facturé annuellement. Sauvegarder",
      "Premium": "Prime",
      "Toolium Cutter": "Coupeur d'outils",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Coupez et modifiez tous vos témoignages ou documentaires directement à partir de votre document Microsoft Word ou Google Docs en augmentant simplement votre texte et en téléchargeant votre fichier en utilisant notre processus.",
      "Automate uncreative tasks": "Automatisez les tâches non créatives",
      "Save money processing media": "Économisez de l'argent en traitant les médias",
      "Save 4 hours every shoot day": "Économisez 4 heures chaque jour de tournage",
      "The next generation of post production workflow tools": "La nouvelle génération d'outils de workflow de post-production",
      "14 days free.": "14 jours gratuits.",
      "Plans": "Des plans",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium est une plateforme centrale qui vous permet de regrouper, de réduire et de rationaliser efficacement votre flux de travail afin que votre équipe puisse accélérer votre travail et réduire les délais de livraison de 4 heures !",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Téléchargez un fichier AAF de votre séquence synchronisée et nous vous générerons un fichier AAF groupé pour Avid Media Composer",
      "Toolium Grouper": "Mérou Toolium",
      "Used in": "Utilisé dans",
      "Groups are created with the full master clips": "Les groupes sont créés avec les master clips complets",
      "Reference sub-clips for shortened clips": "Sous-clips de référence pour les clips raccourcis",
      "New avid multigroup type groups": "Nouveaux groupes de type multigroupe avide",
      "Track renaming to group clip names": "Renommer la piste en noms de clips de groupe",
      "Features": "Caractéristiques",
      "AAF uploads": "Téléchargements AAF",
      "AAF upload": "Téléchargement du AFF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI : Les groupes sont créés en tant que nouveaux groupes avides avec des espaces noirs entre les clips sur la même piste. Un nouveau groupe est créé après un espace sur la timeline.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON : Renomme les pistes audio à l'intérieur des groupes en fonction des noms des pistes de séquence. Vous pouvez ouvrir autant de pistes que nécessaire, les groupes seront créés en fonction de la piste audio source.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "GARDER L'AUDIO ON : Fusionne les groupes, y compris l'audio de fin, sur la séquence finale.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON : achemine toutes les pistes audio du groupe vers la séquence dans un ordre consécutif. Utilisez-le si vous avez de l'audio provenant de plusieurs appareils.",
      "CLASSIC: Classic AVID grouping": "CLASSIC : regroupement AVID classique",
      "Process type unknown": "Type de processus inconnu",
      "No clips found on sequence": "Aucun clip trouvé sur la séquence",
      "No clips for writing sequence": "Aucun clip pour l'écriture de la séquence",
      "No sequence found in AAF file": "Aucune séquence trouvée dans le fichier AAF",
      "File is to big reduce AAF file": "Le fichier est trop réduit pour le fichier AAF",
      "Sequence has length of 0": "La séquence a une longueur de 0",
      "Group clip found on sequence": "Clip de groupe trouvé sur la séquence",
      "Group clip found on sequence 2": "Clip de groupe trouvé sur la séquence 2",
      "Unknown selector type found in sequence": "Type de sélecteur inconnu trouvé dans la séquence",
      "Clip framerate does not match sequence framerate": "La fréquence d'images du clip ne correspond pas à la fréquence d'images de la séquence",
      "Subclips with motion effects are not supported": "Les sous-clips avec effets de mouvement ne sont pas pris en charge",
      "Join the best": "Rejoignez les meilleurs",
      "You are in good hands.": "Vous êtes entre de bonnes mains.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Les monteurs adjoints de ces productions sont déjà des Tooliumers satisfaits, plus productifs et gagnant du temps",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Coupez et modifiez tous vos témoignages ou documentaires directement à partir de votre document Microsoft Word ou Google Docs en mettant simplement en surbrillance votre texte et en téléchargeant votre fichier en utilisant notre processus.",
      "Renames audio tracks within the group clips": "Renomme les pistes audio dans les clips du groupe",
      "Creates the new avid multigroup type groups": "Crée les nouveaux groupes de type multigroupe avide",
      "Creates avid sub-clips for shortened clips": "Crée des sous-clips avides pour les clips raccourcis",
      "Editor": "Éditeur",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Vous souhaitez effectuer des modifications dans votre navigateur à l'aide de notre éditeur compatible Avid Media Composer ?",
      "Yes, join pre launch": "Oui, rejoignez le pré-lancement",
      "No I don't want": "Non, je ne veux pas",
      "Click to Upload AAF": "Cliquez pour télécharger l'AAF",
      "Bin panel": "Panneau de poubelle",
      "Go to Previous Event": "Aller à l'événement précédent",
      "Go to Next Event": "Aller à l'événement suivant",
      "Step Backward 1 Fram": "Reculer 1 image",
      "Step Forward 1 Fram": "Avancer d'une image",
      "Mark IN": "Marquer IN",
      "Play": "Jouer",
      "Mark OUT": "Jalonner",
      "Mark Clip": "Marquer le clip",
      "Clear Both Marks": "Effacer les deux marques",
      "Add Marker": "Ajouter un marqueur",
      "Swap Cam Bank": "Échanger la banque de caméras",
      "Find Bin": "Trouver un bac",
      "Gang": "Gang",
      "Step backward 10 frames": "Reculer de 10 images",
      "Step forward 10 frames": "Avancez de 10 images",
      "Go to IN": "Aller à IN",
      "Play Length Toggle": "Basculer la durée de lecture",
      "Go to OUT": "Aller à SORTIE",
      "Match Frame": "Cadre de correspondance",
      "Reverse Match Frame": "Cadre de correspondance inversé",
      "Quad Split": "Division en quatre",
      "Nine Split": "Neuf division",
      "Step Backward 1 Frame": "Reculer d'une image",
      "Step Forward 1 Frame": "Avancer d'une image",
      "Add Edit": "Ajouter Modifier",
      "Lift": "Ascenseur",
      "Extract": "Extrait",
      "Quick Transition": "Transition rapide",
      "Remove Effect": "Supprimer l'effet",
      "Collapse": "Effondrement",
      "Video Quality Menu": "Menu Qualité vidéo",
      "Monitor Volume": "Volume du moniteur",
      "Meter Menu": "Menu du compteur",
      "Effect Mode": "Mode effet",
      "Trim Mode": "Mode de coupe",
      "Step In": "Intervenir",
      "Step Out": "Sors",
      "Render Effect": "Effet de rendu",
      "Tool Palette": "Palette d'outils",
      "Head Fade": "Fondu de tête",
      "Tall Fade": "Fondu élevé",
      "To the Left": "À gauche",
      "To the Right": "À droite",
      "Select In/Out": "Sélectionnez Entrée/Sortie",
      "Link Selection Toggle": "Bascule de sélection de lien",
      "HW/SW": "Matériel/logiciel",
      "Titler Pro": "Titreur Pro",
      "Default Setup": "Configuration par défaut",
      "View Type": "Type de vue",
      "Track Panel": "Panneau de piste",
      "Effect Icons": "Icônes d'effet",
      "Render Rangers": "Rendu des Rangers",
      "Dissolve Icons": "Dissoudre les icônes",
      "Clip Frames": "Cadres de clips",
      "Clip Text": "Texte du clip",
      "Sync Breaks": "Pauses de synchronisation",
      "Dupe Detection": "Détection de duplication",
      "Color Correction": "Correction de couleur",
      "Audio Data": "Données audio",
      "Clip Color...": "Couleur du clip...",
      "Segment Selection": "Sélection des segments",
      "Mark I/O Selection": "Marquer la sélection d'E/S",
      "Track Color": "Couleur de la piste",
      "Show markers": "Afficher les marqueurs",
      "Show adapters": "Afficher les adaptateurs",
      "Show Track": "Afficher la piste",
      "Track Control Panel": "Panneau de contrôle des pistes",
      "Zoom": "Zoom",
      "Open Comments": "Commentaires ouverts",
      "Call us at": "Appelez-nous au",
      "Office": "Bureau",
      "Tracks Info": "Informations sur les pistes",
      "Edit Marker": "Modifier le marqueur",
      "Add": "Ajouter",
      "Marker Name": "Nom du marqueur",
      "Color": "Couleur",
      "Frame": "Cadre",
      "Track": "Piste",
      "Clear Monitor": "Effacer le moniteur",
      "Clear Menu": "Effacer le menu",
      "Show Markers": "Afficher les marqueurs",
      "Show Adapters": "Afficher les adaptateurs",
      "Toggle Fullscreen": "Basculer en plein écran",
      "Highlighter": "Surligneur",
      "Logger": "Enregistreur",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Fichier DOC non valide, utilisez plutôt DOCX, vous pouvez essayer cet outil tiers",
      "Open DOC to DOCX converter": "Ouvrir le convertisseur DOC en DOCX",
      "File is missing any cuts, try to click": "Il manque des coupures dans le fichier, essayez de cliquer",
      "SET ALL ROWS": "DÉFINIR TOUTES LES LIGNES",
      "OFF: Timecodes have start and end": "OFF : les timecodes ont un début et une fin",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON : Pas de timecode de fin et ajoutez simplement 30 secondes à partir du timecode de début.",
      "NO END TIMECODE USE 30 SECONDS": "PAS DE FIN D'UTILISATION DU TIMECODE 30 SECONDES",
      "HAS END TIMECODE": "A UN TIMECODE DE FIN",
      "OFF: Create cuts using highlighted text": "OFF : créer des coupes en utilisant le texte en surbrillance",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON : crée des coupes pour toutes les lignes du document. Ignorer les faits saillants",
      "ALL ROWS": "TOUTES LES RANGÉES",
      "ONLY HIGHLIGHTED": "SEULEMENT SOULIGNÉ",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "OFF : ignore les camrolls sources. Cela créera des coupes uniquement en fonction du timecode source.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON : Utiliser les camrolls sources. Cela créera des coupes en fonction du timecode source et du camroll du clip source.",
      "USE CAMROLL IN DOCX": "UTILISER CAMROLL DANS DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORER CAMROLL DANS DOCX",
      "OFF: AAF downloaded will not be renamed": "OFF : AAF téléchargé ne sera pas renommé",
      "ON: Rename AAF to DOCX file name": "ON : Renommer AAF en nom de fichier DOCX",
      "RENAME AAF": "RENOMMER AAF",
      "NO RENAME": "PAS DE RENOMMAGE",
      "Click to Upload DOCX": "Cliquez pour télécharger DOCX",
      "MULTI": "MULTI",
      "CLASSIC": "CLASSIQUE",
      "RENAME ON": "RENOMMER ACTIVÉ",
      "RENAME OFF": "RENOMMER DÉSACTIVÉ",
      "KEEP AUDIO ON": "GARDER L'AUDIO SUR",
      "KEEP AUDIO OFF": "GARDER L'AUDIO ÉTEINT",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO AS IS ON : crée des groupes avec les pistes audio acheminées exactement telles qu'elles sont placées sur votre séquence synchronisée. Utilisez-le si vous souhaitez que l'audio de votre groupe soit acheminé exactement comme votre séquence.",
      "GROUP AUDIO AS IS ON": "AUDIO DE GROUPE TEL QUEL",
      "GROUP AUDIO AS IS OFF": "AUDIO DE GROUPE TEL QUEL DÉSACTIVÉ",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON : Crée des groupes avec des angles de caméra sur la même piste tels qu'ils sont placés sur votre séquence synchronisée. Utilisez-le si vous souhaitez que vos caméras restent dans le même emplacement de groupe pendant toute la prise de vue.",
      "KEEP CAM ON TRACK ON": "GARDER LA CAM SUR LA PISTE",
      "KEEP CAM ON TRACK OFF": "GARDER LA CAM SUR LA PISTE DÉSACTIVÉE",
      "ROUTE AUDIO ON": "ITINÉRAIRE AUDIO ACTIVÉ",
      "ROUTE AUDIO OFF": "ITINÉRAIRE AUDIO DÉSACTIVÉ",
      "Toolium Editor": "Éditeur Toolium",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Téléchargez un fichier AAF et nous vous fournirons un éditeur distant n'importe où compatible Avid Media Composer et vous générerons ensuite un fichier AAF groupé pour Avid Media Composer.",
      "Free forever": "Libre pour toujours",
      "Collaborate with ease with all the team": "Collaborez en toute simplicité avec toute l’équipe",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Gardez tout le monde sur la même longueur d'onde en écrivant les commentaires directement sur un cadre. Ou dessine-le",
      "Look and feel you are use to in Avid Media Composer": "Look et sensation auxquels vous êtes habitué dans Avid Media Composer",
      "Keyboard shortcuts you are use to": "Raccourcis clavier que vous utilisez",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium est une plateforme centrale qui vous permet de regrouper, de réduire et de rationaliser efficacement votre flux de travail afin que votre équipe puisse accélérer votre travail et réduire les délais de livraison de 4 heures !",
      "A-Side Trim Counter": "Compteur de garniture côté A",
      "B-Side Trim Counter": "Compteur de garniture côté B",
      "Insert Track": "Insérer une piste",
      "OK": "D'ACCORD",
      "Track Type": "Type de piste",
      "Track Num": "Numéro de piste",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Durée TC1",
      "I/O TC1": "E/S TC1",
      "Abs TC1": "Abdos TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "Durée 24",
      "I/O 24": "E/S 24",
      "Abs 24": "Abdos 24",
      "Rem 24": "Rem 24",
      "Mas 25": "25 mars",
      "Dur 25": "Durée 25",
      "I/O 25": "E/S 25",
      "Abs 25": "Abdos 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "Carte E/S",
      "Abs Frm": "Abdos Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Séquence",
      "Timecode": "Code temporel",
      "TC1": "CT1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "E/S",
      "Abs": "Abdos",
      "Rem": "Rem",
      "Footage": "Images",
      "Frames": "Cadres",
      "Source": "Source",
      "None": "Aucun",
      "Master": "Maître",
      "Duration": "Durée",
      "In/Out": "Entrée/Sortie",
      "Absolute": "Absolu",
      "Remain": "Rester",
      "Audio Mono": "Audio mono",
      "Audio Stereo": "Audio Stéréo",
      "5.1 Surround": "Ambiance 5.1",
      "7.1 Surround": "7.1 Surround",
      "Data": "Données",
      "Video": "Vidéo",
      "Leave your comment here...": "Laissez votre commentaire ici...",
      "Send": "Envoyer",
      "Workspace": "Espace de travail",
      "Project Settings": "Paramètres du projet",
      "Leave Project": "Quitter le projet",
      "Delete Project": "Supprimer le projet",
      "New Project": "Nouveau projet",
      "Name": "Nom",
      "Please, input the name": "S'il vous plaît, entrez le nom",
      "Edit Project": "Modifier le projet",
      "Save Settings": "Enregistrer les paramètres",
      "Jump to project of file...": "Aller au projet du fichier...",
      "Status": "Statut",
      "Comments": "commentaires",
      "Size": "Taille",
      "Date uploaded": "Date de téléchargement",
      "Uploader": "Téléchargeur",
      "Action": "Action",
      "File upload": "Téléchargement de fichiers",
      "Folder upload": "Téléchargement de dossier",
      "Folder upload as proxy": "Téléchargement de dossier en tant que proxy",
      "New folder": "Nouveau dossier",
      "New private folder": "Nouveau dossier privé",
      "Download all": "Tout télécharger",
      "Recently deleted": "Récemment supprimé",
      "Project settings": "Paramètres du projet",
      "Rename file": "Renommer le fichier",
      "Move": "Se déplacer",
      "Download": "Télécharger",
      "Make private": "Rendre privé",
      "Reveal in project": "Révéler dans le projet",
      "Delete": "Supprimer",
      "File Information": "Informations sur le fichier",
      "Copy Comments": "Copier les commentaires",
      "Paste Comments": "Coller les commentaires",
      "Print": "Imprimer",
      "Download as File...": "Télécharger sous forme de fichier...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Texte brut",
      "Download for Final Cut Pro X": "Télécharger pour Final Cut Pro X",
      "Download for Media Composer": "Télécharger pour Media Composer",
      "Download for Resolve": "Télécharger pour résoudre",
      "No comments": "Sans commentaires",
      "This is premium feature, contact us at support@toolium.org": "Il s'agit d'une fonctionnalité premium, contactez-nous à support@toolium.org",
      "Automated test to speech": "Test automatisé de parole",
      "Import from uploads": "Importer à partir des téléchargements",
      "Upload your VO file": "Téléchargez votre fichier VO",
      "Record voice over": "Enregistrer la voix off",
      "Crop": "Recadrer",
      "Voiceover": "Voix off",
      "Adjust colors": "Ajuster les couleurs",
      "Trim": "Garniture",
      "Speed": "Vitesse",
      "Loop": "Boucle",
      "Flip": "Retourner",
      "Effects": "Effets",
      "Animation": "Animation",
      "Add title": "Ajouter un titre",
      "Export": "Exporter",
      "Grid": "Grille",
      "Split": "Diviser",
      "Split View": "Vue partagé",
      "Zoom to fit": "Zoomer pour s'adapter",
      "Audio meter": "Audiomètre",
      "TC": "TC",
      "Comment": "Commentaire",
      "Jump to Marker": "Aller au marqueur",
      "Change Color": "Changer de couleur",
      "Change Track": "Changer de piste",
      "Import Markers": "Importer des marqueurs",
      "Export Markers": "Exporter des marqueurs",
      "Choose Columns": "Choisir des colonnes",
      "Cameras": "Appareils photo",
      "Desktop is required": "Le bureau est requis",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Nous avons remarqué que vous utilisez un appareil à écran plus petit. Pour une expérience optimale, veuillez utiliser un ordinateur de bureau.",
      "Go back": "Retourner",
      "OFF: Use 0 seconds for handles": "OFF : utilisez 0 seconde pour les poignées",
      "ON: Use 5 seconds for handles": "ON : utilisez 5 secondes pour les poignées",
      "HANDLES OFF": "POIGNÉES",
      "HANDLES ON": "POIGNÉES SUR",
      "OFF: word-per-second will be 0.37": "OFF : le mot par seconde sera de 0,37",
      "ON: word-per-second will be detected": "ON : le mot par seconde sera détecté",
      "DETECT WPS": "DÉTECTER WPS",
      "WPS IS 0.37": "WPS EST 0,37",
      "OFF: don't change order or merge cuts": "OFF : ne modifiez pas l'ordre et ne fusionnez pas les coupes",
      "ON: reduce cuts by merging and sorting may change order": "ON : réduire les coupes en fusionnant et en triant peut changer l'ordre",
      "USE REDUCE": "UTILISER RÉDUIRE",
      "KEEP ORDER AND CUTS": "GARDER L'ORDRE ET LES COUPES",
      "Timecode Errors": "Erreurs de code temporel",
      "Timecodes": "Codes temporels",
      "Source ID": "Identifiant de la source",
      "Error": "Erreur",
      "Log Out": "Se déconnecter",
      "Sign Up": "S'inscrire",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Les assistants monteurs de ces productions sont déjà des Tooliumers satisfaits, plus productifs et gagnant du temps",
      "Redo": "Refaire",
      "Undo": "annuler",
      "You have no credits left": "Vous n'avez plus de crédits",
      "You need to buy more credits in order to keep using our platform": "Vous devez acheter plus de crédits pour continuer à utiliser notre plateforme",
      "Note": "Note",
      "You will still get free daily credits even if you don't make a purchase": "Vous recevrez toujours des crédits quotidiens gratuits même si vous n'effectuez aucun achat",
      "Benefits of buying credits": "Avantages de l'achat de crédits",
      "Bulk discount (up to 50%)": "Remise en gros (jusqu'à 50%)",
      "Get to request features to fit your worksflow": "Demandez des fonctionnalités adaptées à votre flux de travail",
      "You only pay for what you use": "Vous ne payez que ce que vous utilisez",
      "No monthly membership and no automatic recurring payments": "Pas d'abonnement mensuel et pas de paiements récurrents automatiques",
      "You get to support our site": "Vous pouvez soutenir notre site",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Si vous avez vraiment besoin de plus de crédits et que vous n'avez pas d'argent, envoyez-nous un essai expliquant pourquoi nous devrions vous offrir des crédits gratuits.",
      "Buy credits": "Acheter des crédits",
      "Ask for free credits, I don't have any money": "Demande des crédits gratuits, je n'ai pas d'argent",
      "Signup with Google to get 500 free credits": "Inscrivez-vous avec Google pour obtenir 500 crédits gratuits",
      "You will also get 150 additional free credits every day": "Vous obtiendrez également 150 crédits gratuits supplémentaires chaque jour",
      "Select": "Sélectionner",
      "Credits": "Crédits",
      "1 cut with Cutter = 20 credits": "1 coupe avec Cutter = 20 crédits",
      "Discount": "Rabais",
      "Diamond": "Diamant",
      "Enterprise": "Entreprise",
      "7 days money back guarantee": "Garantie de remboursement de 7 jours",
      "For any questions or issues": "Pour toute question ou problème",
      "Free": "Gratuit",
      "Gold": "Or",
      "1 group with Grouper": "1 groupe avec Mérou",
      "1 cut with Cutter": "1 coupe au Cutter"
    }
  },
  "bn": {
    "translation": {
      "Home": "বাড়ি",
      "Grouper": "গ্রুপার",
      "GROUPER": "GROUPER",
      "Logout": "প্রস্থান",
      "Profile": "প্রোফাইল",
      "Onboard": "বোর্ডে",
      "Cutter": "কাটার",
      "Blog": "ব্লগ",
      "Contact Us": "যোগাযোগ করুন",
      "Login": "প্রবেশ করুন",
      "Create group clips": "গ্রুপ ক্লিপ তৈরি করুন",
      "made easy": "সহজ করা",
      "Free Get started": "বিনামূল্যে শুরু করুন",
      "14 days free. No risk.": "14 দিন বিনামূল্যে। ঝুঁকিহীন।",
      "Signup Free": "আমার স্নাতকের",
      "Contact us with any question at": "এ যেকোন প্রশ্নে আমাদের সাথে যোগাযোগ করুন",
      "Just one more step before you start Grouping": "আপনি গ্রুপিং শুরু করার আগে আরও একটি ধাপ",
      "First name": "নামের প্রথম অংশ",
      "What is your first name": "তোমার প্রথম নাম কি",
      "Please input your first name!": "আপনার প্রথম নাম ইনপুট করুন!",
      "Last/family name": "শেষ / পারিবারিক নাম",
      "What is your last/family name": "আপনার শেষ/পরিবারের নাম কি",
      "Please input your last/family name!": "অনুগ্রহ করে আপনার শেষ/পরিবারের নাম ইনপুট করুন!",
      "Nickname": "ডাকনাম",
      "What do you want others to call you?": "আপনি কি চান অন্যরা আপনাকে ডাকুক?",
      "Please input your nickname!": "আপনার ডাকনাম ইনপুট করুন!",
      "Phone Number": "ফোন নম্বর",
      "What is your phone number?": "আপনার ফোন নম্বর কি?",
      "Please input your phone number! Example +18777755232": "আপনার ফোন নম্বর ইনপুট করুন! উদাহরণ +18777755232",
      "I agree to the": "আমি একমত",
      "privacy policy": "গোপনীয়তা নীতি",
      "terms of service": "সেবা পাবার শর্ত",
      "disclaimer": "দাবিত্যাগ",
      "Privacy policy": "গোপনীয়তা নীতি",
      "Terms of service": "সেবা পাবার শর্ত",
      "Disclaimer": "দাবিত্যাগ",
      "Submit": "জমা দিন",
      "Contact your Toolium representative to buy more credits": "আরও ক্রেডিট কিনতে আপনার টুলিয়াম প্রতিনিধির সাথে যোগাযোগ করুন",
      "See your credits in profile": "প্রোফাইলে আপনার ক্রেডিট দেখুন",
      "Contact your Toolium representative to activate your account": "আপনার অ্যাকাউন্ট সক্রিয় করতে আপনার টুলিয়াম প্রতিনিধির সাথে যোগাযোগ করুন",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "আপনার সিঙ্ক্রোনাইজড সিকোয়েন্সের একটি AAF ফাইল আপলোড করুন এবং আমরা আপনাকে Avid মিডিয়া কম্পোজারের জন্য একটি গ্রুপ করা AAF ফাইল আউটপুট করব",
      "Click to Upload or Drag and Drop an AAF": "একটি AAF আপলোড করতে বা টেনে আনতে ক্লিক করুন",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "দয়া করে যান এবং আপনার ইমেল যাচাই করুন যাতে আপনি ওয়েবসাইটটি ব্যবহার করা চালিয়ে যেতে পারেন। আমরা আপনাকে ক্লিক করার জন্য একটি লিঙ্ক সহ একটি ইমেল পাঠিয়েছি।",
      "Resend verification email": "যাচাইকরণ ইমেল পুনরায় পাঠান",
      "Call us at +1-877-775-5232": "+1-877-775-5232 এ আমাদের কল করুন",
      "Plan": "পরিকল্পনা",
      "PLANS": "পরিকল্পনা সমূহ",
      "Choose a plan that works for you": "আপনার জন্য কাজ করে এমন একটি পরিকল্পনা চয়ন করুন",
      "Basic": "মৌলিক",
      "credit": "ক্রেডিট",
      "year": "বছর",
      "Billed annually.": "বার্ষিক বিল।",
      "Contact us": "যোগাযোগ করুন",
      "Recommended": "প্রস্তাবিত",
      "Standard": "স্ট্যান্ডার্ড",
      "credits": "ক্রেডিট",
      "Billed annually. Save": "বার্ষিক বিল। সংরক্ষণ",
      "Premium": "প্রিমিয়াম",
      "Toolium Cutter": "টুলিয়াম কাটার",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "আপনার মাইক্রোসফ্ট ওয়ার্ড ডকুমেন্ট বা Google ডক্স থেকে আপনার সমস্ত প্রশংসাপত্র বা ডকুমেন্টারিগুলিকে কাটুন এবং সম্পাদনা করুন কেবলমাত্র আপনার পাঠ্য হাইট করে এবং আমাদের প্রক্রিয়া ব্যবহার করে আপনার ফাইল আপলোড করে",
      "Automate uncreative tasks": "সৃজনশীল কাজগুলি স্বয়ংক্রিয় করুন",
      "Save money processing media": "অর্থ প্রক্রিয়াকরণ মিডিয়া সংরক্ষণ করুন",
      "Save 4 hours every shoot day": "প্রতি শুটিং দিনে 4 ঘন্টা সংরক্ষণ করুন",
      "The next generation of post production workflow tools": "পরবর্তী প্রজন্মের পোস্ট প্রোডাকশন ওয়ার্কফ্লো টুল",
      "14 days free.": "14 দিন বিনামূল্যে।",
      "Plans": "পরিকল্পনা সমূহ",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "টুলিয়াম হল একটি কেন্দ্রীয় হাব যা আপনাকে দক্ষতার সাথে আপনার কর্মপ্রবাহকে গোষ্ঠীবদ্ধ করতে, কাটতে এবং প্রবাহিত করতে দেয় যাতে আপনার দল আপনার কাজকে ত্বরান্বিত করতে পারে এবং 4 ঘন্টা ডেলিভারি কমাতে পারে!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "আপনার সিঙ্ক্রোনাইজড সিকোয়েন্সের একটি AAF ফাইল আপলোড করুন এবং আমরা আপনাকে Avid মিডিয়া কম্পোজারের জন্য একটি গ্রুপ করা AAF ফাইল আউটপুট করব",
      "Toolium Grouper": "টুলিয়াম গ্রুপার",
      "Used in": "ব্যবহার করা হয়",
      "Groups are created with the full master clips": "সম্পূর্ণ মাস্টার ক্লিপ দিয়ে গ্রুপ তৈরি করা হয়",
      "Reference sub-clips for shortened clips": "সংক্ষিপ্ত ক্লিপগুলির জন্য রেফারেন্স সাব-ক্লিপ",
      "New avid multigroup type groups": "নতুন আগ্রহী মাল্টিগ্রুপ টাইপ গ্রুপ",
      "Track renaming to group clip names": "গ্রুপ ক্লিপ নাম পরিবর্তন ট্র্যাক",
      "Features": "বৈশিষ্ট্য",
      "AAF uploads": "AAF আপলোড",
      "AAF upload": "AAF আপলোড",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "মাল্টি: একই ট্র্যাকের ক্লিপগুলির মধ্যে কালো স্পেস সহ নতুন উত্সাহী গোষ্ঠী হিসাবে গোষ্ঠীগুলি তৈরি করা হয়েছে৷ টাইমলাইনে একটি স্থানের পরে একটি নতুন গ্রুপ তৈরি করা হয়।",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: সিকোয়েন্স ট্র্যাকের নাম অনুসারে গোষ্ঠীগুলির মধ্যে অডিও ট্র্যাকগুলির নাম পরিবর্তন করে৷ আপনি আপনার প্রয়োজন হিসাবে অনেক ট্র্যাক খুলতে পারেন, গ্রুপ অডিও ট্র্যাক উৎস অনুযায়ী তৈরি করা হবে.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "অডিও চালু রাখুন: চূড়ান্ত ক্রমানুসারে অনুগামী অডিও সহ স্প্লাইস গ্রুপ।",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "রুট অডিও চালু: গ্রুপের সমস্ত অডিও ট্র্যাককে পরপর ক্রমানুসারে রুট করে। আপনার যদি একাধিক ডিভাইস থেকে অডিও থাকে তবে এটি ব্যবহার করুন।",
      "CLASSIC: Classic AVID grouping": "ক্লাসিক: ক্লাসিক AVID গ্রুপিং",
      "Process type unknown": "প্রক্রিয়া প্রকার অজানা",
      "No clips found on sequence": "সিকোয়েন্সে কোন ক্লিপ পাওয়া যায়নি",
      "No clips for writing sequence": "ক্রম লেখার জন্য কোন ক্লিপ নেই",
      "No sequence found in AAF file": "AAF ফাইলে কোন ক্রম পাওয়া যায়নি",
      "File is to big reduce AAF file": "ফাইল AAF ফাইল বড় কমাতে হয়",
      "Sequence has length of 0": "সিকোয়েন্সের দৈর্ঘ্য 0",
      "Group clip found on sequence": "ক্রমানুসারে গ্রুপ ক্লিপ পাওয়া গেছে",
      "Group clip found on sequence 2": "গ্রুপ ক্লিপ অনুক্রম 2 পাওয়া গেছে",
      "Unknown selector type found in sequence": "ক্রমানুসারে অজানা নির্বাচক প্রকার পাওয়া গেছে",
      "Clip framerate does not match sequence framerate": "ক্লিপ ফ্রেমরেট সিকোয়েন্স ফ্রেমরেটের সাথে মেলে না",
      "Subclips with motion effects are not supported": "মোশন এফেক্ট সহ সাবক্লিপ সমর্থিত নয়",
      "Join the best": "সেরা যোগদান",
      "You are in good hands.": "আপনি ভাল হাত আছে।",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "এই প্রোডাকশনের অ্যাসিটেন্ট এডিটররা ইতিমধ্যেই খুশি Tooliumers, আরও বেশি উত্পাদনশীল এবং সময় সাশ্রয় করে৷",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "আপনার মাইক্রোসফ্ট ওয়ার্ড ডকুমেন্ট বা Google ডক্স থেকে আপনার সমস্ত প্রশংসাপত্র বা ডকুমেন্টারিগুলিকে কেবলমাত্র আপনার পাঠ্য হাইলাইট করে এবং আমাদের প্রক্রিয়া ব্যবহার করে আপনার ফাইল আপলোড করার মাধ্যমে কাটা এবং সম্পাদনা করুন",
      "Renames audio tracks within the group clips": "গ্রুপ ক্লিপগুলির মধ্যে অডিও ট্র্যাকগুলির নাম পরিবর্তন করে৷",
      "Creates the new avid multigroup type groups": "নতুন আগ্রহী মাল্টিগ্রুপ টাইপ গ্রুপ তৈরি করে",
      "Creates avid sub-clips for shortened clips": "সংক্ষিপ্ত ক্লিপগুলির জন্য উত্সাহী সাব-ক্লিপ তৈরি করে",
      "Editor": "সম্পাদক",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "আমাদের অ্যাভিড মিডিয়া কম্পোজার সামঞ্জস্যপূর্ণ সম্পাদক ব্যবহার করে আপনার ব্রাউজারে সম্পাদনা করতে চান?",
      "Yes, join pre launch": "হ্যাঁ, প্রাক লঞ্চে যোগ দিন",
      "No I don't want": "না আমি চাই না",
      "Click to Upload AAF": "AAF আপলোড করতে ক্লিক করুন",
      "Bin panel": "বিন প্যানেল",
      "Go to Previous Event": "আগের ইভেন্টে যান",
      "Go to Next Event": "পরবর্তী ইভেন্টে যান",
      "Step Backward 1 Fram": "ধাপ পিছিয়ে 1 ফ্রেম",
      "Step Forward 1 Fram": "ধাপ এগিয়ে 1 ফ্রেম",
      "Mark IN": "ছাপ দাও",
      "Play": "খেলা",
      "Mark OUT": "নির্দিষ্ট করা",
      "Mark Clip": "মার্ক ক্লিপ",
      "Clear Both Marks": "উভয় চিহ্ন পরিষ্কার করুন",
      "Add Marker": "মার্কার যোগ করুন",
      "Swap Cam Bank": "সোয়াপ ক্যাম ব্যাংক",
      "Find Bin": "বিন খুঁজুন",
      "Gang": "গ্যাং",
      "Step backward 10 frames": "10 ফ্রেম পিছনের দিকে যান",
      "Step forward 10 frames": "10 ফ্রেম এগিয়ে যান",
      "Go to IN": "IN-এ যান",
      "Play Length Toggle": "প্লে লেংথ টগল",
      "Go to OUT": "আউটে যান",
      "Match Frame": "ম্যাচ ফ্রেম",
      "Reverse Match Frame": "বিপরীত ম্যাচ ফ্রেম",
      "Quad Split": "কোয়াড স্প্লিট",
      "Nine Split": "নাইন স্প্লিট",
      "Step Backward 1 Frame": "ধাপ ব্যাকওয়ার্ড 1 ফ্রেম",
      "Step Forward 1 Frame": "ধাপ এগিয়ে 1 ফ্রেম",
      "Add Edit": "সম্পাদনা যোগ করুন",
      "Lift": "উত্তোলন",
      "Extract": "নির্যাস",
      "Quick Transition": "দ্রুত রূপান্তর",
      "Remove Effect": "প্রভাব সরান",
      "Collapse": "সঙ্কুচিত",
      "Video Quality Menu": "ভিডিও গুণমান মেনু",
      "Monitor Volume": "মনিটর ভলিউম",
      "Meter Menu": "মিটার মেনু",
      "Effect Mode": "প্রভাব মোড",
      "Trim Mode": "ট্রিম মোড",
      "Step In": "পদার্পণ করা",
      "Step Out": "পা বাড়ান",
      "Render Effect": "রেন্ডার ইফেক্ট",
      "Tool Palette": "টুল প্যালেট",
      "Head Fade": "মাথা বিবর্ণ",
      "Tall Fade": "লম্বা বিবর্ণ",
      "To the Left": "বামে",
      "To the Right": "ডানদিকে",
      "Select In/Out": "ইন/আউট নির্বাচন করুন",
      "Link Selection Toggle": "লিঙ্ক নির্বাচন টগল",
      "HW/SW": "HW/SW",
      "Titler Pro": "শিরোনাম প্রো",
      "Default Setup": "ডিফল্ট সেটআপ",
      "View Type": "দেখুন প্রকার",
      "Track Panel": "ট্র্যাক প্যানেল",
      "Effect Icons": "প্রভাব আইকন",
      "Render Rangers": "রেন্ডার রেন্ডার",
      "Dissolve Icons": "আইকন দ্রবীভূত করুন",
      "Clip Frames": "ক্লিপ ফ্রেম",
      "Clip Text": "ক্লিপ পাঠ্য",
      "Sync Breaks": "সিঙ্ক বিরতি",
      "Dupe Detection": "প্রতারণা সনাক্তকরণ",
      "Color Correction": "রং ঠিক করা",
      "Audio Data": "অডিও ডেটা",
      "Clip Color...": "ক্লিপের রঙ...",
      "Segment Selection": "সেগমেন্ট নির্বাচন",
      "Mark I/O Selection": "I/O নির্বাচন চিহ্নিত করুন",
      "Track Color": "ট্র্যাক রঙ",
      "Show markers": "মার্কার দেখান",
      "Show adapters": "অ্যাডাপ্টার দেখান",
      "Show Track": "ট্র্যাক দেখান",
      "Track Control Panel": "ট্র্যাক কন্ট্রোল প্যানেল",
      "Zoom": "জুম",
      "Open Comments": "মন্তব্য খুলুন",
      "Call us at": "আমাদের কল করুন",
      "Office": "দপ্তর",
      "Tracks Info": "ট্র্যাক তথ্য",
      "Edit Marker": "মার্কার সম্পাদনা করুন",
      "Add": "যোগ করুন",
      "Marker Name": "মার্কার নাম",
      "Color": "রঙ",
      "Frame": "ফ্রেম",
      "Track": "ট্র্যাক",
      "Clear Monitor": "ক্লিয়ার মনিটর",
      "Clear Menu": "মেনু সাফ করুন",
      "Show Markers": "মার্কার দেখান",
      "Show Adapters": "অ্যাডাপ্টার দেখান",
      "Toggle Fullscreen": "টগল পর্দা জুড়ে প্রদর্শন",
      "Highlighter": "হাইলাইটার",
      "Logger": "লগার",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "অবৈধ ফাইল DOC, পরিবর্তে DOCX ব্যবহার করুন, আপনি এই 3য় পক্ষের টুল ব্যবহার করে দেখতে পারেন",
      "Open DOC to DOCX converter": "DOC থেকে DOCX কনভার্টার খুলুন",
      "File is missing any cuts, try to click": "ফাইলে কোনো কাট অনুপস্থিত, ক্লিক করার চেষ্টা করুন",
      "SET ALL ROWS": "সমস্ত সারি সেট করুন",
      "OFF: Timecodes have start and end": "বন্ধ: টাইমকোডের শুরু এবং শেষ আছে",
      "ON: No end timecode and just add 30 seconds from start timecode": "চালু: শেষ টাইমকোড নেই এবং শুরুর টাইমকোড থেকে মাত্র 30 সেকেন্ড যোগ করুন",
      "NO END TIMECODE USE 30 SECONDS": "কোন শেষ টাইমকোড 30 সেকেন্ড ব্যবহার করুন",
      "HAS END TIMECODE": "শেষ টাইমকোড আছে",
      "OFF: Create cuts using highlighted text": "বন্ধ: হাইলাইট করা পাঠ্য ব্যবহার করে কাট তৈরি করুন",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "চালু: নথিতে সমস্ত সারির জন্য কাট তৈরি করুন। হাইলাইট উপেক্ষা",
      "ALL ROWS": "সমস্ত সারি",
      "ONLY HIGHLIGHTED": "শুধুমাত্র হাইলাইট করা হয়েছে",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "বন্ধ: উৎস ক্যামরোল উপেক্ষা করুন. এটি শুধুমাত্র উৎস টাইমকোড অনুযায়ী কাট তৈরি করবে।",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "চালু: সোর্স ক্যামরোল ব্যবহার করুন। এটি সোর্স টাইমকোড এবং সোর্স ক্লিপ ক্যামরোল অনুযায়ী কাট তৈরি করবে",
      "USE CAMROLL IN DOCX": "DOCX-এ ক্যামরোল ব্যবহার করুন",
      "IGNORE CAMROLL IN DOCX": "DOCX-এ ক্যামরোল উপেক্ষা করুন",
      "OFF: AAF downloaded will not be renamed": "বন্ধ: AAF ডাউনলোড করা নাম পরিবর্তন করা হবে না",
      "ON: Rename AAF to DOCX file name": "চালু: AAF এর DOCX ফাইলের নাম পরিবর্তন করুন",
      "RENAME AAF": "AAF এর নাম পরিবর্তন করুন",
      "NO RENAME": "কোন পুনঃনাম",
      "Click to Upload DOCX": "DOCX আপলোড করতে ক্লিক করুন",
      "MULTI": "মাল্টি",
      "CLASSIC": "ক্লাসিক",
      "RENAME ON": "পুনরায় নাম চালু করুন",
      "RENAME OFF": "পুনরায় নামকরণ বন্ধ করুন",
      "KEEP AUDIO ON": "অডিও চালু রাখুন",
      "KEEP AUDIO OFF": "অডিও বন্ধ রাখুন",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "গ্রুপ অডিও চালু আছে: অডিও ট্র্যাকগুলি আপনার সিঙ্ক্রোনাইজড সিকোয়েন্সে যেভাবে রাখা হয়েছে ঠিক সেভাবে রাউট করা সহ গ্রুপ তৈরি করে৷ আপনি যদি চান যে আপনার গোষ্ঠীর অডিও ঠিক আপনার ক্রমানুসারে রাউট করা হোক",
      "GROUP AUDIO AS IS ON": "গ্রুপ অডিও চালু আছে",
      "GROUP AUDIO AS IS OFF": "গ্রুপ অডিও বন্ধ আছে",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "ক্যাম অন ট্র্যাক চালু রাখুন: আপনার সিঙ্ক্রোনাইজড সিকোয়েন্সে যেভাবে স্থাপন করা হয়েছে একই ট্র্যাকে ক্যামেরা অ্যাঙ্গেল সহ গ্রুপগুলি তৈরি করে৷ পুরো শুটিং চলাকালীন আপনার ক্যামেরা একই গ্রুপ প্লেসমেন্টে থাকতে চাইলে এটি ব্যবহার করুন",
      "KEEP CAM ON TRACK ON": "ট্র্যাক অন ক্যামেরা রাখুন",
      "KEEP CAM ON TRACK OFF": "ট্র্যাক বন্ধ রাখুন",
      "ROUTE AUDIO ON": "রুট অডিও চালু",
      "ROUTE AUDIO OFF": "রুট অডিও বন্ধ",
      "Toolium Editor": "টুলিয়াম সম্পাদক",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "একটি AAF ফাইল আপলোড করুন এবং আমরা আপনাকে একটি অ্যাভিড মিডিয়া কম্পোজার সামঞ্জস্যপূর্ণ রিমোট এডিটর সরবরাহ করব এবং তারপরে অ্যাভিড মিডিয়া কম্পোজারের জন্য আপনাকে একটি গ্রুপ করা AAF ফাইল আউটপুট করব।",
      "Free forever": "চিরকালের জন্য স্বাধীন",
      "Collaborate with ease with all the team": "সব দলের সাথে সহজে সহযোগিতা করুন",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "একটি ফ্রেমে প্রতিক্রিয়া লিখে সবাইকে একই পৃষ্ঠায় রাখুন। অথবা আঁকুন",
      "Look and feel you are use to in Avid Media Composer": "দেখুন এবং অনুভব করুন আপনি অ্যাভিড মিডিয়া কম্পোজারে ব্যবহার করছেন",
      "Keyboard shortcuts you are use to": "কীবোর্ড শর্টকাট আপনি ব্যবহার করছেন",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "টুলিয়াম হল একটি কেন্দ্রীয় হাব যা আপনাকে দক্ষতার সাথে আপনার কর্মপ্রবাহকে গ্রুপ করতে, কাটতে এবং প্রবাহিত করতে দেয় যাতে আপনার দল আপনার কাজকে ত্বরান্বিত করতে পারে এবং ডেলিভারি 4 ঘন্টা কমাতে পারে!",
      "A-Side Trim Counter": "এ-সাইড ট্রিম কাউন্টার",
      "B-Side Trim Counter": "বি-সাইড ট্রিম কাউন্টার",
      "Insert Track": "ট্র্যাক সন্নিবেশ করান",
      "OK": "ঠিক আছে",
      "Track Type": "ট্র্যাক টাইপ",
      "Track Num": "ট্র্যাক নম্বর",
      "Mas TC1": "মাস TC1",
      "Dur TC1": "ডুর TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "রেম TC1",
      "Mas 24": "মাস 24",
      "Dur 24": "দুর 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Abs 24",
      "Rem 24": "রেম 24",
      "Mas 25": "মাস 25",
      "Dur 25": "ডুর 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Abs 25",
      "Rem 25": "রেম 25",
      "Mas Frm": "মাস ফ্রম",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "রেম ফ্রম",
      "Sequence": "ক্রম",
      "Timecode": "টাইমকোড",
      "TC1": "TC1",
      "Mas": "মাস",
      "Dur": "দূর",
      "I/O": "I/O",
      "Abs": "অ্যাবস",
      "Rem": "রেম",
      "Footage": "ফুটেজ",
      "Frames": "ফ্রেম",
      "Source": "উৎস",
      "None": "কোনোটিই নয়",
      "Master": "ওস্তাদ",
      "Duration": "সময়কাল",
      "In/Out": "ইন/আউট",
      "Absolute": "পরম",
      "Remain": "থাকে",
      "Audio Mono": "অডিও মনো",
      "Audio Stereo": "অডিও স্টেরিও",
      "5.1 Surround": "5.1 চারপাশ",
      "7.1 Surround": "7.1 চারপাশ",
      "Data": "ডেটা",
      "Video": "ভিডিও",
      "Leave your comment here...": "এখানে আপনার মন্তব্য দিন...",
      "Send": "পাঠান",
      "Workspace": "কর্মক্ষেত্র",
      "Project Settings": "প্রকল্প সেটিংস",
      "Leave Project": "প্রজেক্ট ত্যাগ করুন",
      "Delete Project": "প্রকল্প মুছুন",
      "New Project": "নতুন প্রকল্প",
      "Name": "নাম",
      "Please, input the name": "অনুগ্রহ করে নাম লিখুন",
      "Edit Project": "প্রকল্প সম্পাদনা করুন",
      "Save Settings": "সেটিংস সংরক্ষণ করুন",
      "Jump to project of file...": "ফাইলের প্রকল্পে যান...",
      "Status": "স্ট্যাটাস",
      "Comments": "মন্তব্য",
      "Size": "আকার",
      "Date uploaded": "আপলোডের তারিখ",
      "Uploader": "আপলোডার",
      "Action": "কর্ম",
      "File upload": "ফাইল আপলোড",
      "Folder upload": "ফোল্ডার আপলোড",
      "Folder upload as proxy": "প্রক্সি হিসাবে ফোল্ডার আপলোড",
      "New folder": "নতুন ফোল্ডার",
      "New private folder": "নতুন ব্যক্তিগত ফোল্ডার",
      "Download all": "সবগুলো ডাউনলোড",
      "Recently deleted": "সম্প্রতি মুছে ফেলা হয়েছে",
      "Project settings": "প্রকল্প সেটিংস",
      "Rename file": "ফাইলের নাম পরিবর্তন করুন",
      "Move": "সরান",
      "Download": "ডাউনলোড করুন",
      "Make private": "প্রাইভেট করা",
      "Reveal in project": "প্রকল্পে প্রকাশ করুন",
      "Delete": "মুছে ফেলা",
      "File Information": "ফাইল তথ্য",
      "Copy Comments": "কমেন্ট কপি করুন",
      "Paste Comments": "মন্তব্য পেস্ট করুন",
      "Print": "ছাপা",
      "Download as File...": "ফাইল হিসাবে ডাউনলোড করুন...",
      "CSV": "CSV",
      "XML": "এক্সএমএল",
      "Plain Text": "প্লেইন টেক্সট",
      "Download for Final Cut Pro X": "ফাইনাল কাট প্রো এক্স এর জন্য ডাউনলোড করুন",
      "Download for Media Composer": "মিডিয়া কম্পোজারের জন্য ডাউনলোড করুন",
      "Download for Resolve": "সমাধানের জন্য ডাউনলোড করুন",
      "No comments": "কোন মন্তব্য নেই",
      "This is premium feature, contact us at support@toolium.org": "এটি প্রিমিয়াম বৈশিষ্ট্য, support@toolium.org এ আমাদের সাথে যোগাযোগ করুন",
      "Automated test to speech": "বক্তৃতা স্বয়ংক্রিয় পরীক্ষা",
      "Import from uploads": "আপলোড থেকে আমদানি করুন",
      "Upload your VO file": "আপনার VO ফাইল আপলোড করুন",
      "Record voice over": "ভয়েস ওভার রেকর্ড করুন",
      "Crop": "ফসল",
      "Voiceover": "ভয়েসওভার",
      "Adjust colors": "রং সামঞ্জস্য করুন",
      "Trim": "ছাঁটা",
      "Speed": "গতি",
      "Loop": "লুপ",
      "Flip": "ফ্লিপ",
      "Effects": "প্রভাব",
      "Animation": "অ্যানিমেশন",
      "Add title": "শিরোনাম যোগ করুন",
      "Export": "রপ্তানি",
      "Grid": "গ্রিড",
      "Split": "বিভক্ত",
      "Split View": "স্প্লিট ভিউ",
      "Zoom to fit": "মানানসই জুম",
      "Audio meter": "অডিও মিটার",
      "TC": "টিসি",
      "Comment": "মন্তব্য করুন",
      "Jump to Marker": "মার্কারে যান",
      "Change Color": "রঙ পরিবর্তন করুন",
      "Change Track": "ট্র্যাক পরিবর্তন করুন",
      "Import Markers": "মার্কার আমদানি করুন",
      "Export Markers": "রপ্তানি মার্কার",
      "Choose Columns": "কলাম নির্বাচন করুন",
      "Cameras": "ক্যামেরা",
      "Desktop is required": "ডেস্কটপ প্রয়োজন",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "আমরা লক্ষ্য করেছি যে আপনি একটি ছোট পর্দার ডিভাইস ব্যবহার করছেন। সেরা অভিজ্ঞতার জন্য, একটি ডেস্কটপ ব্যবহার করুন.",
      "Go back": "ফিরে যাও",
      "OFF: Use 0 seconds for handles": "বন্ধ: হ্যান্ডেলের জন্য 0 সেকেন্ড ব্যবহার করুন",
      "ON: Use 5 seconds for handles": "চালু: হ্যান্ডলগুলির জন্য 5 সেকেন্ড ব্যবহার করুন",
      "HANDLES OFF": "হ্যান্ডেল বন্ধ",
      "HANDLES ON": "হ্যান্ডেল অন",
      "OFF: word-per-second will be 0.37": "বন্ধ: শব্দ-প্রতি-সেকেন্ড হবে 0.37",
      "ON: word-per-second will be detected": "চালু: শব্দ-প্রতি-সেকেন্ড সনাক্ত করা হবে",
      "DETECT WPS": "WPS সনাক্ত করুন",
      "WPS IS 0.37": "WPS IS 0.37",
      "OFF: don't change order or merge cuts": "বন্ধ: অর্ডার পরিবর্তন করবেন না বা কাটা একত্রিত করবেন না",
      "ON: reduce cuts by merging and sorting may change order": "চালু: মার্জ করে কাট কমাতে এবং সাজানোর ক্রম পরিবর্তন হতে পারে",
      "USE REDUCE": "কম ব্যবহার করুন",
      "KEEP ORDER AND CUTS": "অর্ডার এবং কাট রাখুন",
      "Timecode Errors": "টাইমকোড ত্রুটি",
      "Timecodes": "টাইমকোড",
      "Source ID": "উৎস আইডি",
      "Error": "ত্রুটি",
      "Log Out": "প্রস্থান",
      "Sign Up": "নিবন্ধন করুন",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "এই প্রোডাকশনের সহকারী সম্পাদকরা ইতিমধ্যেই খুশি টুলিয়ামার্স, আরও বেশি উত্পাদনশীল এবং সময় সাশ্রয় করে",
      "Redo": "আবার করুন",
      "Undo": "পূর্বাবস্থায় ফেরান",
      "You have no credits left": "আপনার কোন ক্রেডিট বাকি নেই",
      "You need to buy more credits in order to keep using our platform": "আমাদের প্ল্যাটফর্ম ব্যবহার চালিয়ে যাওয়ার জন্য আপনাকে আরও ক্রেডিট কিনতে হবে",
      "Note": "বিঃদ্রঃ",
      "You will still get free daily credits even if you don't make a purchase": "আপনি ক্রয় না করলেও আপনি বিনামূল্যে দৈনিক ক্রেডিট পাবেন",
      "Benefits of buying credits": "ক্রেডিট কেনার সুবিধা",
      "Bulk discount (up to 50%)": "বাল্ক ডিসকাউন্ট (50% পর্যন্ত)",
      "Get to request features to fit your worksflow": "আপনার ওয়ার্কফ্লো মাপসই বৈশিষ্ট্য অনুরোধ পান",
      "You only pay for what you use": "আপনি যা ব্যবহার করেন তার জন্য আপনি শুধুমাত্র অর্থ প্রদান করেন",
      "No monthly membership and no automatic recurring payments": "কোন মাসিক সদস্যতা এবং কোন স্বয়ংক্রিয় পুনরাবৃত্তি পেমেন্ট",
      "You get to support our site": "আপনি আমাদের সাইট সমর্থন পেতে",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "আপনার যদি সত্যিই আরও ক্রেডিট প্রয়োজন হয় এবং আপনার কাছে কোন টাকা না থাকে, তাহলে আমাদের কেন আপনাকে বিনামূল্যে ক্রেডিট দেওয়া উচিত তা ব্যাখ্যা করে একটি প্রবন্ধ পাঠান",
      "Buy credits": "ক্রেডিট কিনুন",
      "Ask for free credits, I don't have any money": "বিনামূল্যে ক্রেডিট জন্য জিজ্ঞাসা করুন, আমার কোন টাকা নেই",
      "Signup with Google to get 500 free credits": "500টি বিনামূল্যে ক্রেডিট পেতে Google এর সাথে সাইন আপ করুন৷",
      "You will also get 150 additional free credits every day": "এছাড়াও আপনি প্রতিদিন 150টি অতিরিক্ত ফ্রি ক্রেডিট পাবেন",
      "Select": "নির্বাচন করুন",
      "Credits": "ক্রেডিট",
      "1 cut with Cutter = 20 credits": "কাটার সহ 1 কাট = 20 ক্রেডিট",
      "Discount": "ছাড়",
      "Diamond": "হীরা",
      "Enterprise": "এন্টারপ্রাইজ",
      "7 days money back guarantee": "7 দিনের টাকা ফেরত গ্যারান্টি",
      "For any questions or issues": "কোন প্রশ্ন বা সমস্যার জন্য",
      "Free": "বিনামূল্যে",
      "Gold": "সোনা",
      "1 group with Grouper": "গ্রুপার সহ 1 টি গ্রুপ",
      "1 cut with Cutter": "1 কাটার দিয়ে কাটা"
    }
  },
  "de": {
    "translation": {
      "Home": "Heim",
      "Grouper": "Zackenbarsch",
      "GROUPER": "ZACKENBARSCH",
      "Logout": "Ausloggen",
      "Profile": "Profil",
      "Onboard": "An Bord",
      "Cutter": "Cutter",
      "Blog": "Blog",
      "Contact Us": "Kontaktiere uns",
      "Login": "Anmeldung",
      "Create group clips": "Erstellen Sie Gruppenclips",
      "made easy": "einfach gemacht",
      "Free Get started": "Kostenlos Jetzt loslegen",
      "14 days free. No risk.": "14 Tage kostenlos. Kein Risiko.",
      "Signup Free": "Melde dich kostenlos an",
      "Contact us with any question at": "Kontaktieren Sie uns bei Fragen unter",
      "Just one more step before you start Grouping": "Nur noch ein Schritt, bevor Sie mit der Gruppierung beginnen",
      "First name": "Vorname",
      "What is your first name": "Wie lautet dein Vorname",
      "Please input your first name!": "Bitte geben Sie Ihren Vornamen ein!",
      "Last/family name": "Nachname/Familienname",
      "What is your last/family name": "Wie lautet Ihr Nachname/Familienname?",
      "Please input your last/family name!": "Bitte geben Sie Ihren Nachnamen/Familiennamen ein!",
      "Nickname": "Spitzname",
      "What do you want others to call you?": "Wie möchten Sie, dass andere Sie nennen?",
      "Please input your nickname!": "Bitte geben Sie Ihren Spitznamen ein!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Was ist Ihre Telefonnummer?",
      "Please input your phone number! Example +18777755232": "Bitte geben Sie Ihre Telefonnummer ein! Beispiel +18777755232",
      "I agree to the": "Ich stimme dem zu",
      "privacy policy": "Datenschutzrichtlinie",
      "terms of service": "Nutzungsbedingungen",
      "disclaimer": "Haftungsausschluss",
      "Privacy policy": "Datenschutzrichtlinie",
      "Terms of service": "Nutzungsbedingungen",
      "Disclaimer": "Haftungsausschluss",
      "Submit": "Einreichen",
      "Contact your Toolium representative to buy more credits": "Wenden Sie sich an Ihren Toolium-Vertreter, um weitere Credits zu kaufen",
      "See your credits in profile": "Sehen Sie sich Ihre Credits im Profil an",
      "Contact your Toolium representative to activate your account": "Kontaktieren Sie Ihren Toolium-Vertreter, um Ihr Konto zu aktivieren",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Laden Sie eine AAF-Datei Ihrer synchronisierten Sequenz hoch und wir geben Ihnen eine gruppierte AAF-Datei für Avid Media Composer aus",
      "Click to Upload or Drag and Drop an AAF": "Klicken Sie, um eine AAF hochzuladen oder per Drag-and-Drop hochzuladen",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Bitte überprüfen Sie Ihre E-Mail-Adresse, damit Sie die Website weiterhin nutzen können. Wir haben Ihnen eine E-Mail mit einem Link zum Anklicken gesendet.",
      "Resend verification email": "Bestätigungsmail erneut senden",
      "Call us at +1-877-775-5232": "Rufen Sie uns unter +1-877-775-5232 an",
      "Plan": "Planen",
      "PLANS": "PLÄNE",
      "Choose a plan that works for you": "Wählen Sie einen Plan, der für Sie funktioniert",
      "Basic": "Basic",
      "credit": "Kredit",
      "year": "Jahr",
      "Billed annually.": "Jährlich abgerechnet.",
      "Contact us": "Kontaktiere uns",
      "Recommended": "Empfohlen",
      "Standard": "Standard",
      "credits": "Credits",
      "Billed annually. Save": "Jährlich abgerechnet. Speichern",
      "Premium": "Prämie",
      "Toolium Cutter": "Toolium-Fräser",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Schneiden und bearbeiten Sie alle Ihre Erfahrungsberichte oder Dokumentationen direkt aus Ihrem Microsoft Word-Dokument oder Google Docs, indem Sie einfach Ihren Text markieren und Ihre Datei mit unserem Verfahren hochladen",
      "Automate uncreative tasks": "Automatisieren Sie unkreative Aufgaben",
      "Save money processing media": "Sparen Sie Geld bei der Verarbeitung von Medien",
      "Save 4 hours every shoot day": "Sparen Sie an jedem Shooting-Tag 4 Stunden",
      "The next generation of post production workflow tools": "Die nächste Generation von Postproduktions-Workflow-Tools",
      "14 days free.": "14 Tage kostenlos.",
      "Plans": "Pläne",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium ist ein zentraler Hub, mit dem Sie Ihren Arbeitsablauf effizient gruppieren, kürzen und optimieren können, damit Ihr Team seine Arbeit beschleunigen und die Lieferzeit um 4 Stunden verkürzen kann!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Laden Sie eine AAF-Datei Ihrer synchronisierten Sequenz hoch und wir geben Ihnen eine gruppierte AAF-Datei für Avid Media Composer aus",
      "Toolium Grouper": "Toolium-Zackenbarsch",
      "Used in": "Benutzt in",
      "Groups are created with the full master clips": "Gruppen werden mit den vollständigen Masterclips erstellt",
      "Reference sub-clips for shortened clips": "Referenz-Unterclips für gekürzte Clips",
      "New avid multigroup type groups": "Neue Gruppen vom Typ Avid Multigroup",
      "Track renaming to group clip names": "Umbenennen von Spuren in Gruppenclipnamen",
      "Features": "Merkmale",
      "AAF uploads": "AAF-Uploads",
      "AAF upload": "AAF-Upload",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Gruppen werden als neue Avid-Gruppen mit schwarzen Leerzeichen zwischen Clips auf derselben Spur erstellt. Eine neue Gruppe wird nach einem Leerzeichen auf der Zeitleiste erstellt.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "UMBENENNEN EIN: Benennt Audiospuren innerhalb der Gruppen entsprechend den Sequenzspurnamen um. Sie können so viele Spuren öffnen, wie Sie benötigen. Die Gruppen werden entsprechend der Quellaudiospur erstellt.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: Fügt Gruppen einschließlich des abschließenden Audios in die endgültige Sequenz ein.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Leitet alle Audiospuren der Gruppe in aufeinanderfolgender Reihenfolge zur Sequenz weiter. Verwenden Sie diese Option, wenn Sie Audio von mehr als einem Gerät haben.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: Klassische AVID-Gruppierung",
      "Process type unknown": "Prozesstyp unbekannt",
      "No clips found on sequence": "In der Sequenz wurden keine Clips gefunden",
      "No clips for writing sequence": "Keine Clips für die Schreibsequenz",
      "No sequence found in AAF file": "Keine Sequenz in der AAF-Datei gefunden",
      "File is to big reduce AAF file": "Die Datei ist zu groß, um die AAF-Datei zu verkleinern",
      "Sequence has length of 0": "Die Sequenz hat die Länge 0",
      "Group clip found on sequence": "Gruppenclip in der Sequenz gefunden",
      "Group clip found on sequence 2": "Gruppenclip in Sequenz 2 gefunden",
      "Unknown selector type found in sequence": "Unbekannter Selektortyp in der Sequenz gefunden",
      "Clip framerate does not match sequence framerate": "Die Clip-Framerate stimmt nicht mit der Sequenz-Framerate überein",
      "Subclips with motion effects are not supported": "Subclips mit Bewegungseffekten werden nicht unterstützt",
      "Join the best": "Werden Sie Teil der Besten",
      "You are in good hands.": "Sie sind in guten Händen.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Assistenzredakteure dieser Produktionen sind bereits zufriedene Tooliumer, produktiver und zeitsparender",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Schneiden und bearbeiten Sie alle Ihre Erfahrungsberichte oder Dokumentationen direkt aus Ihrem Microsoft Word-Dokument oder Google Docs, indem Sie einfach Ihren Text markieren und Ihre Datei mit unserem Verfahren hochladen",
      "Renames audio tracks within the group clips": "Benennt Audiospuren innerhalb der Gruppenclips um",
      "Creates the new avid multigroup type groups": "Erstellt die neuen Gruppen vom Typ Avid Multigroup",
      "Creates avid sub-clips for shortened clips": "Erstellt Avid-Subclips für gekürzte Clips",
      "Editor": "Editor",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Möchten Sie in Ihrem Browser mit unserem Avid Media Composer-kompatiblen Editor bearbeiten?",
      "Yes, join pre launch": "Ja, treten Sie vor dem Start bei",
      "No I don't want": "Nein, ich will nicht",
      "Click to Upload AAF": "Klicken Sie hier, um AAF hochzuladen",
      "Bin panel": "Bin-Panel",
      "Go to Previous Event": "Gehen Sie zum vorherigen Ereignis",
      "Go to Next Event": "Gehen Sie zum nächsten Ereignis",
      "Step Backward 1 Fram": "Gehen Sie einen Frame zurück",
      "Step Forward 1 Fram": "Machen Sie einen Schritt vorwärts",
      "Mark IN": "Markieren Sie IN",
      "Play": "Spielen",
      "Mark OUT": "Abstecken",
      "Mark Clip": "Clip markieren",
      "Clear Both Marks": "Löschen Sie beide Markierungen",
      "Add Marker": "Markierung hinzufügen",
      "Swap Cam Bank": "Tauschen Sie die Cam-Bank aus",
      "Find Bin": "Finden Sie Bin",
      "Gang": "Gang",
      "Step backward 10 frames": "Gehen Sie 10 Frames zurück",
      "Step forward 10 frames": "Gehen Sie 10 Frames vorwärts",
      "Go to IN": "Gehen Sie zu IN",
      "Play Length Toggle": "Wiedergabelänge umschalten",
      "Go to OUT": "Gehen Sie zu OUT",
      "Match Frame": "Match-Rahmen",
      "Reverse Match Frame": "Reverse-Match-Frame",
      "Quad Split": "Quad-Split",
      "Nine Split": "Neun Split",
      "Step Backward 1 Frame": "Gehen Sie einen Frame zurück",
      "Step Forward 1 Frame": "Machen Sie einen Schritt vorwärts",
      "Add Edit": "Bearbeiten hinzufügen",
      "Lift": "Aufzug",
      "Extract": "Extrakt",
      "Quick Transition": "Schneller Übergang",
      "Remove Effect": "Effekt entfernen",
      "Collapse": "Zusammenbruch",
      "Video Quality Menu": "Menü „Videoqualität“.",
      "Monitor Volume": "Überwachen Sie die Lautstärke",
      "Meter Menu": "Messgerät-Menü",
      "Effect Mode": "Effektmodus",
      "Trim Mode": "Trimmmodus",
      "Step In": "Eintreten",
      "Step Out": "Steigen Sie aus",
      "Render Effect": "Rendereffekt",
      "Tool Palette": "Werkzeugpalette",
      "Head Fade": "Kopf verblassen",
      "Tall Fade": "Hohes Fade",
      "To the Left": "Nach links",
      "To the Right": "Nach rechts",
      "Select In/Out": "Wählen Sie Ein/Aus",
      "Link Selection Toggle": "Linkauswahl umschalten",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "Standard Einstellung",
      "View Type": "Ansichtstyp",
      "Track Panel": "Track-Panel",
      "Effect Icons": "Effektsymbole",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Symbole auflösen",
      "Clip Frames": "Clip-Frames",
      "Clip Text": "Text ausschneiden",
      "Sync Breaks": "Synchronisierungspausen",
      "Dupe Detection": "Dupe-Erkennung",
      "Color Correction": "Farbkorrektur",
      "Audio Data": "Audiodaten",
      "Clip Color...": "Clipfarbe...",
      "Segment Selection": "Segmentauswahl",
      "Mark I/O Selection": "I/O-Auswahl markieren",
      "Track Color": "Spurfarbe",
      "Show markers": "Markierungen anzeigen",
      "Show adapters": "Adapter anzeigen",
      "Show Track": "Spur anzeigen",
      "Track Control Panel": "Track-Bedienfeld",
      "Zoom": "Zoomen",
      "Open Comments": "Kommentare öffnen",
      "Call us at": "Ruf uns an um",
      "Office": "Büro",
      "Tracks Info": "Tracks-Info",
      "Edit Marker": "Markierung bearbeiten",
      "Add": "Hinzufügen",
      "Marker Name": "Markierungsname",
      "Color": "Farbe",
      "Frame": "Rahmen",
      "Track": "Schiene",
      "Clear Monitor": "Klarer Monitor",
      "Clear Menu": "Menü löschen",
      "Show Markers": "Markierungen anzeigen",
      "Show Adapters": "Adapter anzeigen",
      "Toggle Fullscreen": "Vollbild umschalten",
      "Highlighter": "Textmarker",
      "Logger": "Logger",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Ungültige DOC-Datei. Verwenden Sie stattdessen DOCX. Sie können dieses Drittanbieter-Tool ausprobieren",
      "Open DOC to DOCX converter": "Öffnen Sie den DOC-zu-DOCX-Konverter",
      "File is missing any cuts, try to click": "In der Datei fehlen Schnitte. Versuchen Sie, darauf zu klicken",
      "SET ALL ROWS": "ALLE REIHEN EINSTELLEN",
      "OFF: Timecodes have start and end": "AUS: Timecodes haben Start und Ende",
      "ON: No end timecode and just add 30 seconds from start timecode": "EIN: Kein End-Timecode und nur 30 Sekunden ab dem Start-Timecode hinzufügen",
      "NO END TIMECODE USE 30 SECONDS": "KEIN ENDTIMECODE, 30 SEKUNDEN VERWENDEN",
      "HAS END TIMECODE": "HAT ENDE TIMECODE",
      "OFF: Create cuts using highlighted text": "AUS: Schnitte mit hervorgehobenem Text erstellen",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "EIN: Schnitte für alle Zeilen im Dokument erstellen. Ignorieren der Highlights",
      "ALL ROWS": "ALLE REIHEN",
      "ONLY HIGHLIGHTED": "NUR HERVORRAGEND",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "AUS: Quell-Camrolls ignorieren. Dadurch werden Schnitte nur entsprechend dem Quell-Timecode erstellt.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "EIN: Quell-Camrolls verwenden. Dadurch werden Schnitte entsprechend dem Quell-Timecode und der Quellclip-Camroll erstellt",
      "USE CAMROLL IN DOCX": "VERWENDEN SIE CAMROLL IN DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORIEREN SIE CAMROLL IN DOCX",
      "OFF: AAF downloaded will not be renamed": "AUS: Das heruntergeladene AAF wird nicht umbenannt",
      "ON: Rename AAF to DOCX file name": "EIN: AAF in DOCX-Dateinamen umbenennen",
      "RENAME AAF": "AAF UMBENENNEN",
      "NO RENAME": "KEINE UMBENENNUNG",
      "Click to Upload DOCX": "Klicken Sie, um DOCX hochzuladen",
      "MULTI": "MULTI",
      "CLASSIC": "KLASSISCH",
      "RENAME ON": "UMBENENNEN EIN",
      "RENAME OFF": "UMBENENNEN AUS",
      "KEEP AUDIO ON": "LASSEN SIE AUDIO AN",
      "KEEP AUDIO OFF": "HALTEN SIE AUDIO AUS",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "AUDIO GRUPPE WIE EINGESCHALTET: Erstellt Gruppen, wobei die Audiospuren genau so weitergeleitet werden, wie sie in Ihrer synchronisierten Sequenz platziert sind. Verwenden Sie diese Option, wenn Sie möchten, dass das Audio Ihrer Gruppe genau wie Ihre Sequenz weitergeleitet wird",
      "GROUP AUDIO AS IS ON": "GRUPPE AUDIO WIE IST EIN",
      "GROUP AUDIO AS IS OFF": "GROUP AUDIO AS IST AUS",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Erstellt Gruppen mit Kamerawinkeln auf derselben Spur, auf der sie in Ihrer synchronisierten Sequenz platziert sind. Verwenden Sie diese Option, wenn Sie möchten, dass Ihre Kameras während der gesamten Aufnahme in derselben Gruppenposition bleiben",
      "KEEP CAM ON TRACK ON": "Halten Sie die Kamera auf der Spur",
      "KEEP CAM ON TRACK OFF": "CAM AUF RICHTUNG AUS HALTEN",
      "ROUTE AUDIO ON": "ROUTE AUDIO EIN",
      "ROUTE AUDIO OFF": "ROUTE AUDIO AUS",
      "Toolium Editor": "Toolium-Editor",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Laden Sie eine AAF-Datei hoch und wir stellen Ihnen einen mit Avid Media Composer kompatiblen Remote-Editor zur Verfügung und geben Ihnen anschließend eine gruppierte AAF-Datei für Avid Media Composer aus",
      "Free forever": "Für immer frei",
      "Collaborate with ease with all the team": "Arbeiten Sie problemlos mit dem gesamten Team zusammen",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Halten Sie alle auf dem gleichen Stand, indem Sie Feedback direkt in einen Rahmen schreiben. Oder zeichne es",
      "Look and feel you are use to in Avid Media Composer": "Aussehen und Verhalten, wie Sie es von Avid Media Composer gewohnt sind",
      "Keyboard shortcuts you are use to": "Tastaturkürzel, die Sie gewohnt sind",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium ist ein zentraler Knotenpunkt, mit dem Sie Ihren Arbeitsablauf effizient gruppieren, kürzen und optimieren können, damit Ihr Team seine Arbeit beschleunigen und die Lieferzeit um 4 Stunden verkürzen kann!",
      "A-Side Trim Counter": "A-Seiten-Trimmzähler",
      "B-Side Trim Counter": "B-Seiten-Trimmzähler",
      "Insert Track": "Spur einfügen",
      "OK": "OK",
      "Track Type": "Spurtyp",
      "Track Num": "Titelnr",
      "Mas TC1": "Mehr TC1",
      "Dur TC1": "Während TC1",
      "I/O TC1": "E/A TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "24. Mai",
      "Dur 24": "Am 24",
      "I/O 24": "E/A 24",
      "Abs 24": "Bauchmuskeln 24",
      "Rem 24": "Rem 24",
      "Mas 25": "25. Mai",
      "Dur 25": "Am 25",
      "I/O 25": "E/A 25",
      "Abs 25": "Bauchmuskeln 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "E/A-Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Reihenfolge",
      "Timecode": "Timecode",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dauer",
      "I/O": "E/A",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Filmmaterial",
      "Frames": "Rahmen",
      "Source": "Quelle",
      "None": "Keiner",
      "Master": "Meister",
      "Duration": "Dauer",
      "In/Out": "Rein/Raus",
      "Absolute": "Absolut",
      "Remain": "Bleiben",
      "Audio Mono": "Audio-Mono",
      "Audio Stereo": "Audio-Stereo",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Daten",
      "Video": "Video",
      "Leave your comment here...": "Hinterlassen Sie hier Ihren Kommentar...",
      "Send": "Schicken",
      "Workspace": "Arbeitsplatz",
      "Project Settings": "Projekt Einstellungen",
      "Leave Project": "Projekt verlassen",
      "Delete Project": "Projekt löschen",
      "New Project": "Neues Projekt",
      "Name": "Name",
      "Please, input the name": "Bitte geben Sie den Namen ein",
      "Edit Project": "Projekt bearbeiten",
      "Save Settings": "Einstellungen speichern",
      "Jump to project of file...": "Zum Projekt der Datei springen...",
      "Status": "Status",
      "Comments": "Kommentare",
      "Size": "Größe",
      "Date uploaded": "Datum des Hochladens",
      "Uploader": "Uploader",
      "Action": "Aktion",
      "File upload": "Datei-Upload",
      "Folder upload": "Ordner hochladen",
      "Folder upload as proxy": "Ordner-Upload als Proxy",
      "New folder": "Neuer Ordner",
      "New private folder": "Neuer privater Ordner",
      "Download all": "Alles herunterladen",
      "Recently deleted": "Kürzlich gelöscht",
      "Project settings": "Projekt Einstellungen",
      "Rename file": "Datei umbenennen",
      "Move": "Bewegen",
      "Download": "Herunterladen",
      "Make private": "Privatisieren",
      "Reveal in project": "Im Projekt offenbaren",
      "Delete": "Löschen",
      "File Information": "Dateiinformationen",
      "Copy Comments": "Kommentare kopieren",
      "Paste Comments": "Kommentare einfügen",
      "Print": "Drucken",
      "Download as File...": "Als Datei herunterladen...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Klartext",
      "Download for Final Cut Pro X": "Für Final Cut Pro X herunterladen",
      "Download for Media Composer": "Für Media Composer herunterladen",
      "Download for Resolve": "Für Resolve herunterladen",
      "No comments": "Keine Kommentare",
      "This is premium feature, contact us at support@toolium.org": "Dies ist eine Premium-Funktion. Kontaktieren Sie uns unter support@toolium.org",
      "Automated test to speech": "Automatisierter Sprachtest",
      "Import from uploads": "Aus Uploads importieren",
      "Upload your VO file": "Laden Sie Ihre VO-Datei hoch",
      "Record voice over": "Voice-Over aufnehmen",
      "Crop": "Ernte",
      "Voiceover": "Voice-over",
      "Adjust colors": "Farben anpassen",
      "Trim": "Trimmen",
      "Speed": "Geschwindigkeit",
      "Loop": "Schleife",
      "Flip": "Umdrehen",
      "Effects": "Auswirkungen",
      "Animation": "Animation",
      "Add title": "Titel hinzufügen",
      "Export": "Export",
      "Grid": "Netz",
      "Split": "Teilt",
      "Split View": "Geteilte Sicht",
      "Zoom to fit": "Zoomen Sie, um es anzupassen",
      "Audio meter": "Audiometer",
      "TC": "TC",
      "Comment": "Kommentar",
      "Jump to Marker": "Zur Markierung springen",
      "Change Color": "Farbe ändern",
      "Change Track": "Spur wechseln",
      "Import Markers": "Markierungen importieren",
      "Export Markers": "Markierungen exportieren",
      "Choose Columns": "Spalten auswählen",
      "Cameras": "Kameras",
      "Desktop is required": "Desktop ist erforderlich",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Wir haben festgestellt, dass Sie ein Gerät mit kleinerem Bildschirm verwenden. Für das beste Erlebnis verwenden Sie bitte einen Desktop.",
      "Go back": "Geh zurück",
      "OFF: Use 0 seconds for handles": "AUS: 0 Sekunden für Griffe verwenden",
      "ON: Use 5 seconds for handles": "EIN: 5 Sekunden für Griffe verwenden",
      "HANDLES OFF": "GRIFFE AUS",
      "HANDLES ON": "GRIFFE AN",
      "OFF: word-per-second will be 0.37": "AUS: Wort pro Sekunde beträgt 0,37",
      "ON: word-per-second will be detected": "EIN: Wort pro Sekunde wird erkannt",
      "DETECT WPS": "WPS ERKENNEN",
      "WPS IS 0.37": "WPS beträgt 0,37",
      "OFF: don't change order or merge cuts": "AUS: Reihenfolge nicht ändern und Schnitte nicht zusammenführen",
      "ON: reduce cuts by merging and sorting may change order": "EIN: Reduziert Schnitte durch Zusammenführen und Sortieren kann die Reihenfolge ändern",
      "USE REDUCE": "VERWENDUNG REDUZIEREN",
      "KEEP ORDER AND CUTS": "HALTEN SIE ORDNUNG UND SCHNITTE",
      "Timecode Errors": "Timecode-Fehler",
      "Timecodes": "Zeitcodes",
      "Source ID": "Quell-ID",
      "Error": "Fehler",
      "Log Out": "Ausloggen",
      "Sign Up": "Melden Sie sich an",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Redaktionsassistenten dieser Produktionen sind bereits zufriedene Tooliumer, die produktiver sind und Zeit sparen",
      "Redo": "Wiederholen",
      "Undo": "Rückgängig machen",
      "You have no credits left": "Sie haben kein Guthaben mehr",
      "You need to buy more credits in order to keep using our platform": "Sie müssen mehr Credits kaufen, um unsere Plattform weiterhin nutzen zu können",
      "Note": "Notiz",
      "You will still get free daily credits even if you don't make a purchase": "Sie erhalten weiterhin kostenlose Tagesguthaben, auch wenn Sie keinen Kauf tätigen",
      "Benefits of buying credits": "Vorteile des Kaufs von Credits",
      "Bulk discount (up to 50%)": "Mengenrabatt (bis zu 50%)",
      "Get to request features to fit your worksflow": "Fordern Sie Funktionen an, die zu Ihrem Arbeitsablauf passen",
      "You only pay for what you use": "Sie zahlen nur für das, was Sie nutzen",
      "No monthly membership and no automatic recurring payments": "Keine monatliche Mitgliedschaft und keine automatisch wiederkehrenden Zahlungen",
      "You get to support our site": "Sie können unsere Seite unterstützen",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Wenn Sie wirklich mehr Credits benötigen und kein Geld haben, senden Sie uns einen Aufsatz, in dem Sie erklären, warum wir Ihnen kostenlose Credits geben sollten",
      "Buy credits": "Guthaben kaufen",
      "Ask for free credits, I don't have any money": "Fordern Sie kostenlose Credits an, ich habe kein Geld",
      "Signup with Google to get 500 free credits": "Melden Sie sich bei Google an, um 500 kostenlose Credits zu erhalten",
      "You will also get 150 additional free credits every day": "Außerdem erhalten Sie täglich 150 zusätzliche Gratis-Credits",
      "Select": "Wählen",
      "Credits": "Credits",
      "1 cut with Cutter = 20 credits": "1 Schnitt mit Cutter = 20 Credits",
      "Discount": "Rabatt",
      "Diamond": "Diamant",
      "Enterprise": "Unternehmen",
      "7 days money back guarantee": "7 Tage Geld-zurück-Garantie",
      "For any questions or issues": "Bei Fragen oder Problemen",
      "Free": "Frei",
      "Gold": "Gold",
      "1 group with Grouper": "1 Gruppe mit Zackenbarsch",
      "1 cut with Cutter": "1 Schnitt mit Cutter"
    }
  },
  "hi": {
    "translation": {
      "Home": "घर",
      "Grouper": "ग्रूपर",
      "GROUPER": "ग्रूपर",
      "Logout": "लॉग आउट",
      "Profile": "प्रोफ़ाइल",
      "Onboard": "सवार",
      "Cutter": "काटने वाला",
      "Blog": "ब्लॉग",
      "Contact Us": "संपर्क करें",
      "Login": "लॉग इन करें",
      "Create group clips": "समूह क्लिप बनाएं",
      "made easy": "आसान बना दिया",
      "Free Get started": "निःशुल्क आरंभ करें",
      "14 days free. No risk.": "14 दिन मुफ़्त. कोई खतरा नहीं।",
      "Signup Free": "निशुल्क साइन अप करें",
      "Contact us with any question at": "किसी भी प्रश्न के लिए हमसे संपर्क करें",
      "Just one more step before you start Grouping": "ग्रुपिंग शुरू करने से पहले बस एक और कदम",
      "First name": "पहला नाम",
      "What is your first name": "आपका पहला नाम क्या है",
      "Please input your first name!": "कृपया अपना पहला नाम दर्ज करें!",
      "Last/family name": "अंतिम / पारिवारिक नाम",
      "What is your last/family name": "आपका उपनाम/परिवार का नाम क्या है?",
      "Please input your last/family name!": "कृपया अपना अंतिम/परिवार का नाम दर्ज करें!",
      "Nickname": "उपनाम",
      "What do you want others to call you?": "आप क्या चाहते हैं कि दूसरे आपको क्या कहें?",
      "Please input your nickname!": "कृपया अपना उपनाम दर्ज करें!",
      "Phone Number": "फ़ोन नंबर",
      "What is your phone number?": "आपका फ़ोन नंबर क्या है?",
      "Please input your phone number! Example +18777755232": "कृपया अपना फ़ोन नंबर दर्ज करें! उदाहरण +18777755232",
      "I agree to the": "मैं करने के लिए सहमत हूं",
      "privacy policy": "गोपनीयता नीति",
      "terms of service": "सेवा की शर्तें",
      "disclaimer": "अस्वीकरण",
      "Privacy policy": "गोपनीयता नीति",
      "Terms of service": "सेवा की शर्तें",
      "Disclaimer": "अस्वीकरण",
      "Submit": "जमा करना",
      "Contact your Toolium representative to buy more credits": "अधिक क्रेडिट खरीदने के लिए अपने टूलियम प्रतिनिधि से संपर्क करें",
      "See your credits in profile": "प्रोफ़ाइल में अपना क्रेडिट देखें",
      "Contact your Toolium representative to activate your account": "अपना खाता सक्रिय करने के लिए अपने टूलियम प्रतिनिधि से संपर्क करें",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "अपने सिंक्रनाइज़ अनुक्रम की एक AAF फ़ाइल अपलोड करें और हम आपको Avid Media Composer के लिए एक समूहीकृत AAF फ़ाइल आउटपुट देंगे",
      "Click to Upload or Drag and Drop an AAF": "AAF अपलोड करने या खींचने और छोड़ने के लिए क्लिक करें",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "कृपया जाएं और अपना ईमेल सत्यापित करें ताकि आप वेबसाइट का उपयोग जारी रख सकें। हमने आपको क्लिक करने के लिए एक लिंक के साथ एक ईमेल भेजा है।",
      "Resend verification email": "सत्यापन ईमेल पुनः भेजे",
      "Call us at +1-877-775-5232": "हमें +1-877-775-5232 पर कॉल करें",
      "Plan": "योजना",
      "PLANS": "योजना",
      "Choose a plan that works for you": "ऐसी योजना चुनें जो आपके लिए कारगर हो",
      "Basic": "बुनियादी",
      "credit": "श्रेय",
      "year": "वर्ष",
      "Billed annually.": "सालाना बिल किया।",
      "Contact us": "संपर्क करें",
      "Recommended": "अनुशंसित",
      "Standard": "मानक",
      "credits": "क्रेडिट",
      "Billed annually. Save": "सालाना बिल किया। बचाना",
      "Premium": "अधिमूल्य",
      "Toolium Cutter": "टूलियम कटर",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "बस अपने टेक्स्ट को हाईट करके और हमारी प्रक्रिया का उपयोग करके अपनी फ़ाइल अपलोड करके अपने सभी प्रशंसापत्र या डॉक्यूमेंट्री को सीधे अपने Microsoft Word दस्तावेज़ या Google डॉक्स से काटें और संपादित करें।",
      "Automate uncreative tasks": "अरचनात्मक कार्यों को स्वचालित करें",
      "Save money processing media": "मनी प्रोसेसिंग मीडिया बचाएं",
      "Save 4 hours every shoot day": "प्रत्येक शूट दिवस पर 4 घंटे बचाएं",
      "The next generation of post production workflow tools": "पोस्ट प्रोडक्शन वर्कफ़्लो टूल की अगली पीढ़ी",
      "14 days free.": "14 दिन मुफ़्त.",
      "Plans": "योजनाओं",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "टूलियम एक केंद्रीय केंद्र है जो आपको अपने वर्कफ़्लो को कुशलतापूर्वक समूहित करने, काटने और सुव्यवस्थित करने की सुविधा देता है ताकि आपकी टीम आपके काम में तेजी ला सके और डिलीवरी को 4 घंटे कम कर सके!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "अपने सिंक्रनाइज़ अनुक्रम की एक AAF फ़ाइल अपलोड करें और हम आपको Avid Media Composer के लिए एक समूहीकृत AAF फ़ाइल आउटपुट देंगे",
      "Toolium Grouper": "टूलियम ग्रॉपर",
      "Used in": "में इस्तेमाल किया",
      "Groups are created with the full master clips": "समूह पूर्ण मास्टर क्लिप के साथ बनाए जाते हैं",
      "Reference sub-clips for shortened clips": "छोटी क्लिप के लिए संदर्भ उप-क्लिप",
      "New avid multigroup type groups": "नए शौकीन बहुसमूह प्रकार के समूह",
      "Track renaming to group clip names": "समूह क्लिप नामों का नाम बदलना ट्रैक करें",
      "Features": "विशेषताएँ",
      "AAF uploads": "एएएफ अपलोड करता है",
      "AAF upload": "एएएफ अपलोड",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "मल्टी: समूह एक ही ट्रैक पर क्लिप के बीच काले रिक्त स्थान वाले नए शौकीन समूहों के रूप में बनाए जाते हैं। टाइमलाइन पर एक स्थान के बाद एक नया समूह बनाया जाता है।",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "नाम बदलें: अनुक्रम ट्रैक नामों के अनुसार समूहों के अंदर ऑडियो ट्रैक का नाम बदलें। आप जितने चाहें उतने ट्रैक खोल सकते हैं, ग्रुप सोर्स ऑडियो ट्रैक के अनुसार बनाए जाएंगे।",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "ऑडियो चालू रखें: पिछले ऑडियो सहित समूहों को अंतिम अनुक्रम में विभाजित करें।",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "रूट ऑडियो ऑन: समूह के सभी ऑडियो ट्रैक को लगातार क्रम में रूट करता है। यदि आपके पास एक से अधिक डिवाइस से ऑडियो है तो इसका उपयोग करें।",
      "CLASSIC: Classic AVID grouping": "क्लासिक: क्लासिक AVID ग्रुपिंग",
      "Process type unknown": "प्रक्रिया प्रकार अज्ञात",
      "No clips found on sequence": "अनुक्रम पर कोई क्लिप नहीं मिली",
      "No clips for writing sequence": "लेखन क्रम के लिए कोई क्लिप नहीं",
      "No sequence found in AAF file": "AAF फ़ाइल में कोई अनुक्रम नहीं मिला",
      "File is to big reduce AAF file": "फ़ाइल AAF फ़ाइल को बड़ा करने के लिए है",
      "Sequence has length of 0": "अनुक्रम की लंबाई 0 है",
      "Group clip found on sequence": "अनुक्रम पर समूह क्लिप मिली",
      "Group clip found on sequence 2": "अनुक्रम 2 पर समूह क्लिप मिली",
      "Unknown selector type found in sequence": "क्रम में अज्ञात चयनकर्ता प्रकार मिला",
      "Clip framerate does not match sequence framerate": "क्लिप फ़्रेमरेट अनुक्रम फ़्रेमरेट से मेल नहीं खाता",
      "Subclips with motion effects are not supported": "गति प्रभाव वाले उपक्लिप समर्थित नहीं हैं",
      "Join the best": "सर्वश्रेष्ठ से जुड़ें",
      "You are in good hands.": "आप अच्छे हाथों में हैं.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "इन प्रस्तुतियों के सहायक संपादक पहले से ही खुश टूलियमर्स हैं, अधिक उत्पादक और समय बचाने वाले हैं",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "अपने टेक्स्ट को हाइलाइट करके और हमारी प्रक्रिया का उपयोग करके अपनी फ़ाइल अपलोड करके अपने सभी प्रशंसापत्र या दस्तावेज़ों को सीधे अपने Microsoft Word दस्तावेज़ या Google डॉक्स से काटें और संपादित करें।",
      "Renames audio tracks within the group clips": "समूह क्लिप के भीतर ऑडियो ट्रैक का नाम बदलता है",
      "Creates the new avid multigroup type groups": "नए शौकीन बहुसमूह प्रकार के समूह बनाता है",
      "Creates avid sub-clips for shortened clips": "छोटी क्लिपों के लिए उत्साही उप-क्लिप बनाता है",
      "Editor": "संपादक",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "क्या आप हमारे एविड मीडिया कंपोज़र संगत संपादक का उपयोग करके अपने ब्राउज़र में संपादन करना चाहते हैं?",
      "Yes, join pre launch": "हाँ, लॉन्च से पहले शामिल हों",
      "No I don't want": "नहीं, मैं नहीं चाहता",
      "Click to Upload AAF": "एएएफ अपलोड करने के लिए क्लिक करें",
      "Bin panel": "बिन पैनल",
      "Go to Previous Event": "पिछले इवेंट पर जाएँ",
      "Go to Next Event": "अगले इवेंट पर जाएँ",
      "Step Backward 1 Fram": "कदम पीछे 1 फ्रैम",
      "Step Forward 1 Fram": "कदम आगे 1 फ्रैम",
      "Mark IN": "में निशान लगाये",
      "Play": "खेल",
      "Mark OUT": "चिह्नित करना",
      "Mark Clip": "मार्क क्लिप",
      "Clear Both Marks": "दोनों निशान साफ़ करें",
      "Add Marker": "मार्कर जोड़ें",
      "Swap Cam Bank": "कैम बैंक स्वैप करें",
      "Find Bin": "बिन खोजें",
      "Gang": "गिरोह",
      "Step backward 10 frames": "10 फ्रेम पीछे हटें",
      "Step forward 10 frames": "10 फ़्रेम आगे बढ़ाएँ",
      "Go to IN": "आईएन पर जाएं",
      "Play Length Toggle": "खेल की लंबाई टॉगल करें",
      "Go to OUT": "आउट पर जाएं",
      "Match Frame": "मिलान फ़्रेम",
      "Reverse Match Frame": "रिवर्स मैच फ्रेम",
      "Quad Split": "क्वाड स्प्लिट",
      "Nine Split": "नौ विभाजन",
      "Step Backward 1 Frame": "पीछे की ओर कदम 1 फ़्रेम",
      "Step Forward 1 Frame": "कदम आगे 1 फ़्रेम",
      "Add Edit": "संपादित करें जोड़ें",
      "Lift": "उठाना",
      "Extract": "निकालना",
      "Quick Transition": "त्वरित संक्रमण",
      "Remove Effect": "प्रभाव हटाएँ",
      "Collapse": "गिर जाना",
      "Video Quality Menu": "वीडियो गुणवत्ता मेनू",
      "Monitor Volume": "मॉनिटर वॉल्यूम",
      "Meter Menu": "मीटर मेनू",
      "Effect Mode": "प्रभाव मोड",
      "Trim Mode": "ट्रिम मोड",
      "Step In": "अंदर आएं",
      "Step Out": "बाहर कदम",
      "Render Effect": "प्रभाव प्रस्तुत करें",
      "Tool Palette": "टूल पैलेट",
      "Head Fade": "सिर फीका पड़ना",
      "Tall Fade": "लंबा फीका",
      "To the Left": "बांई ओर",
      "To the Right": "दांई ओर",
      "Select In/Out": "अंदर/बाहर का चयन करें",
      "Link Selection Toggle": "लिंक चयन टॉगल करें",
      "HW/SW": "एचडब्ल्यू/एसडब्ल्यू",
      "Titler Pro": "टाइटलर प्रो",
      "Default Setup": "डिफ़ॉल्ट सेटअप",
      "View Type": "प्रकार देखें",
      "Track Panel": "ट्रैक पैनल",
      "Effect Icons": "प्रभाव चिह्न",
      "Render Rangers": "रेंडर रेंजर्स",
      "Dissolve Icons": "चिह्न विघटित करें",
      "Clip Frames": "क्लिप फ़्रेम",
      "Clip Text": "क्लिप टेक्स्ट",
      "Sync Breaks": "सिंक टूट जाता है",
      "Dupe Detection": "नकल का पता लगाना",
      "Color Correction": "रंग सुधार",
      "Audio Data": "ऑडियो डेटा",
      "Clip Color...": "क्लिप का रंग...",
      "Segment Selection": "खंड चयन",
      "Mark I/O Selection": "मार्क I/O चयन",
      "Track Color": "ट्रैक का रंग",
      "Show markers": "मार्कर दिखाएँ",
      "Show adapters": "एडाप्टर दिखाएँ",
      "Show Track": "ट्रैक दिखाएँ",
      "Track Control Panel": "ट्रैक नियंत्रण कक्ष",
      "Zoom": "ज़ूम",
      "Open Comments": "टिप्पणियाँ खोलें",
      "Call us at": "हमें पर फोन करो",
      "Office": "कार्यालय",
      "Tracks Info": "ट्रैक जानकारी",
      "Edit Marker": "मार्कर संपादित करें",
      "Add": "जोड़ना",
      "Marker Name": "मार्कर का नाम",
      "Color": "रंग",
      "Frame": "चौखटा",
      "Track": "रास्ता",
      "Clear Monitor": "मॉनिटर साफ़ करें",
      "Clear Menu": "मेनू साफ़ करें",
      "Show Markers": "मार्कर दिखाएँ",
      "Show Adapters": "एडाप्टर दिखाएँ",
      "Toggle Fullscreen": "संपूर्ण स्क्रीन टॉगल करें",
      "Highlighter": "हाइलाइटर",
      "Logger": "लकड़हारा",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "अमान्य फ़ाइल DOC, इसके बजाय DOCX का उपयोग करें, आप इस तृतीय पक्ष टूल को आज़मा सकते हैं",
      "Open DOC to DOCX converter": "DOC से DOCX कनवर्टर खोलें",
      "File is missing any cuts, try to click": "फ़ाइल में कोई कट नहीं है, क्लिक करने का प्रयास करें",
      "SET ALL ROWS": "सभी पंक्तियाँ सेट करें",
      "OFF: Timecodes have start and end": "बंद: टाइमकोड का प्रारंभ और अंत होता है",
      "ON: No end timecode and just add 30 seconds from start timecode": "चालू: कोई समाप्ति समयकोड नहीं और प्रारंभ समयकोड से केवल 30 सेकंड जोड़ें",
      "NO END TIMECODE USE 30 SECONDS": "कोई समाप्ति समय नहीं, कोड 30 सेकंड का उपयोग करें",
      "HAS END TIMECODE": "समाप्ति समयकोड है",
      "OFF: Create cuts using highlighted text": "बंद: हाइलाइट किए गए टेक्स्ट का उपयोग करके कट बनाएं",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "चालू: दस्तावेज़ में सभी पंक्तियों के लिए कट बनाएं। हाइलाइट्स को नजरअंदाज करना",
      "ALL ROWS": "सभी पंक्तियाँ",
      "ONLY HIGHLIGHTED": "केवल हाइलाइट किया गया",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "बंद: स्रोत कैमरोल पर ध्यान न दें। यह केवल स्रोत टाइमकोड के अनुसार कटौती करेगा।",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "चालू: स्रोत कैमरोल का उपयोग करें। यह सोर्स टाइमकोड और सोर्स क्लिप कैमरोल के अनुसार कटौती करेगा",
      "USE CAMROLL IN DOCX": "DOCX में कैमरोल का उपयोग करें",
      "IGNORE CAMROLL IN DOCX": "DOCX में कैमरोल पर ध्यान न दें",
      "OFF: AAF downloaded will not be renamed": "बंद: डाउनलोड किए गए एएएफ का नाम नहीं बदला जाएगा",
      "ON: Rename AAF to DOCX file name": "चालू: AAF का नाम बदलकर DOCX फ़ाइल नाम रखें",
      "RENAME AAF": "AAF का नाम बदलें",
      "NO RENAME": "कोई नाम परिवर्तन नहीं",
      "Click to Upload DOCX": "DOCX अपलोड करने के लिए क्लिक करें",
      "MULTI": "बहु",
      "CLASSIC": "क्लासिक",
      "RENAME ON": "पर नाम बदलें",
      "RENAME OFF": "नाम बदलें बंद",
      "KEEP AUDIO ON": "ऑडियो चालू रखें",
      "KEEP AUDIO OFF": "ऑडियो बंद रखें",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "समूह ऑडियो जैसा चालू है: रूट किए गए ऑडियो ट्रैक के साथ समूह बनाता है जैसे वे आपके सिंक्रनाइज़ अनुक्रम पर रखे गए हैं। यदि आप चाहते हैं कि आपके समूह का ऑडियो बिल्कुल आपके अनुक्रम की तरह रूट किया जाए तो इसका उपयोग करें",
      "GROUP AUDIO AS IS ON": "ग्रुप ऑडियो यथावत चालू है",
      "GROUP AUDIO AS IS OFF": "समूह ऑडियो यथा बंद है",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "कैम को ट्रैक पर रखें: उसी ट्रैक पर कैमरा कोणों के साथ समूह बनाता है जैसे वे आपके सिंक्रनाइज़ अनुक्रम पर रखे गए हैं। यदि आप चाहते हैं कि आपके कैमरे पूरे शूट के दौरान एक ही ग्रुप प्लेसमेंट में रहें तो इसका उपयोग करें",
      "KEEP CAM ON TRACK ON": "कैम को ट्रैक पर रखें",
      "KEEP CAM ON TRACK OFF": "कैम ऑन ट्रैक ऑफ रखें",
      "ROUTE AUDIO ON": "रूट ऑडियो चालू",
      "ROUTE AUDIO OFF": "रूट ऑडियो बंद",
      "Toolium Editor": "टूलियम संपादक",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "एक एएएफ फ़ाइल अपलोड करें और हम आपको एक एविड मीडिया कंपोज़र संगत रिमोट कहीं भी संपादक प्रदान करेंगे और उसके बाद आपको एविड मीडिया कंपोज़र के लिए एक समूहीकृत एएएफ फ़ाइल आउटपुट करेंगे।",
      "Free forever": "हमेशा के लिए आज़ाद",
      "Collaborate with ease with all the team": "पूरी टीम के साथ सहजता से सहयोग करें",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "सीधे एक फ्रेम पर फीडबैक लिखकर सभी को एक ही पेज पर रखें। या इसे ड्रा करें",
      "Look and feel you are use to in Avid Media Composer": "देखें और महसूस करें कि आप एविड मीडिया कंपोज़र के आदी हैं",
      "Keyboard shortcuts you are use to": "कीबोर्ड शॉर्टकट जिनका आप उपयोग करते हैं",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "टूलियम एक केंद्रीय केंद्र है जो आपको अपने वर्कफ़्लो को कुशलतापूर्वक समूहित करने, काटने और सुव्यवस्थित करने की सुविधा देता है ताकि आपकी टीम आपके काम में तेजी ला सके और डिलीवरी को 4 घंटे कम कर सके!",
      "A-Side Trim Counter": "ए-साइड ट्रिम काउंटर",
      "B-Side Trim Counter": "बी-साइड ट्रिम काउंटर",
      "Insert Track": "ट्रैक डालें",
      "OK": "ठीक है",
      "Track Type": "ट्रैक प्रकार",
      "Track Num": "ट्रैक संख्या",
      "Mas TC1": "मास TC1",
      "Dur TC1": "ड्यूर टीसी1",
      "I/O TC1": "आई/ओ टीसी1",
      "Abs TC1": "एब्स टीसी1",
      "Rem TC1": "रेम TC1",
      "Mas 24": "मास 24",
      "Dur 24": "ड्यूर 24",
      "I/O 24": "आई/ओ 24",
      "Abs 24": "एब्स 24",
      "Rem 24": "रेम 24",
      "Mas 25": "मास 25",
      "Dur 25": "ड्यूर 25",
      "I/O 25": "आई/ओ 25",
      "Abs 25": "एब्स 25",
      "Rem 25": "रेम 25",
      "Mas Frm": "मास फ्रम",
      "Dur Frm": "ड्यूर फ्रम",
      "I/O Frm": "मैं/ओ फ़ार्म",
      "Abs Frm": "एब्स फ्रम",
      "Rem Frm": "रेम फ्रॉम",
      "Sequence": "अनुक्रम",
      "Timecode": "टाइमकोड",
      "TC1": "टीसी1",
      "Mas": "मैस",
      "Dur": "दुर",
      "I/O": "मैं/ओ",
      "Abs": "पेट",
      "Rem": "रेम",
      "Footage": "फुटेज",
      "Frames": "फ्रेम्स",
      "Source": "स्रोत",
      "None": "कोई नहीं",
      "Master": "मालिक",
      "Duration": "अवधि",
      "In/Out": "बाहर में",
      "Absolute": "निरपेक्ष",
      "Remain": "अवशेष",
      "Audio Mono": "ऑडियो मोनो",
      "Audio Stereo": "ऑडियो स्टीरियो",
      "5.1 Surround": "5.1 चारों ओर",
      "7.1 Surround": "7.1 चारों ओर",
      "Data": "डेटा",
      "Video": "वीडियो",
      "Leave your comment here...": "अपनी टिप्पणी यहां छोड़ें...",
      "Send": "भेजना",
      "Workspace": "कार्यस्थान",
      "Project Settings": "प्रोजेक्ट सेटिंग्स",
      "Leave Project": "प्रोजेक्ट छोड़ें",
      "Delete Project": "प्रोजेक्ट हटाएँ",
      "New Project": "नया काम",
      "Name": "नाम",
      "Please, input the name": "कृपया, नाम दर्ज करें",
      "Edit Project": "प्रोजेक्ट संपादित करें",
      "Save Settings": "सेटिंग्स सेव करें",
      "Jump to project of file...": "फ़ाइल के प्रोजेक्ट पर जाएं...",
      "Status": "स्थिति",
      "Comments": "टिप्पणियाँ",
      "Size": "आकार",
      "Date uploaded": "तारीख अपलोड की गई",
      "Uploader": "अपलोडर",
      "Action": "कार्रवाई",
      "File upload": "फाइल अपलोड",
      "Folder upload": "फ़ोल्डर अपलोड करें",
      "Folder upload as proxy": "फ़ोल्डर को प्रॉक्सी के रूप में अपलोड करें",
      "New folder": "नया फ़ोल्डर",
      "New private folder": "नया निजी फ़ोल्डर",
      "Download all": "सभी डाउनलोड",
      "Recently deleted": "हाल ही में हटाया गया",
      "Project settings": "प्रोजेक्ट सेटिंग्स",
      "Rename file": "फ़ाइल का नाम बदलें",
      "Move": "कदम",
      "Download": "डाउनलोड करना",
      "Make private": "निजी बनाना",
      "Reveal in project": "प्रोजेक्ट में खुलासा",
      "Delete": "मिटाना",
      "File Information": "फ़ाइल जानकारी",
      "Copy Comments": "टिप्पणियाँ कॉपी करें",
      "Paste Comments": "टिप्पणियाँ चिपकाएँ",
      "Print": "छाप",
      "Download as File...": "फ़ाइल के रूप में डाउनलोड करें...",
      "CSV": "सीएसवी",
      "XML": "एक्सएमएल",
      "Plain Text": "सादे पाठ",
      "Download for Final Cut Pro X": "फाइनल कट प्रो एक्स के लिए डाउनलोड करें",
      "Download for Media Composer": "मीडिया संगीतकार के लिए डाउनलोड करें",
      "Download for Resolve": "समाधान के लिए डाउनलोड करें",
      "No comments": "कोई टिप्पणी नहीं",
      "This is premium feature, contact us at support@toolium.org": "यह प्रीमियम सुविधा है, support@toolium.org पर हमसे संपर्क करें",
      "Automated test to speech": "बोलने का स्वचालित परीक्षण",
      "Import from uploads": "अपलोड से आयात करें",
      "Upload your VO file": "अपनी VO फ़ाइल अपलोड करें",
      "Record voice over": "वॉइसओवर रिकॉर्ड करें",
      "Crop": "काटना",
      "Voiceover": "पार्श्व स्वर",
      "Adjust colors": "रंग समायोजित करें",
      "Trim": "काट-छांट करना",
      "Speed": "रफ़्तार",
      "Loop": "कुंडली",
      "Flip": "पलटना",
      "Effects": "प्रभाव",
      "Animation": "एनिमेशन",
      "Add title": "शीर्षक जोडें",
      "Export": "निर्यात",
      "Grid": "ग्रिड",
      "Split": "विभाजित करना",
      "Split View": "भाजित दृश्य",
      "Zoom to fit": "फ़िट करने के लिए ज़ूम करें",
      "Audio meter": "ऑडियो मीटर",
      "TC": "टीसी",
      "Comment": "टिप्पणी",
      "Jump to Marker": "मार्कर पर जाएं",
      "Change Color": "रंग बदलना",
      "Change Track": "ट्रैक बदलें",
      "Import Markers": "मार्कर आयात करें",
      "Export Markers": "निर्यात मार्कर",
      "Choose Columns": "कॉलम चुनें",
      "Cameras": "कैमरा",
      "Desktop is required": "डेस्कटॉप आवश्यक है",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "हमने देखा है कि आप छोटी स्क्रीन वाले उपकरण का उपयोग कर रहे हैं। सर्वोत्तम अनुभव के लिए, कृपया डेस्कटॉप का उपयोग करें।",
      "Go back": "वापस जाओ",
      "OFF: Use 0 seconds for handles": "बंद: हैंडल के लिए 0 सेकंड का उपयोग करें",
      "ON: Use 5 seconds for handles": "चालू: हैंडल के लिए 5 सेकंड का उपयोग करें",
      "HANDLES OFF": "हैंडल बंद",
      "HANDLES ON": "हैंडल चालू",
      "OFF: word-per-second will be 0.37": "बंद: शब्द-प्रति-सेकंड 0.37 होगा",
      "ON: word-per-second will be detected": "चालू: शब्द-प्रति-सेकंड का पता लगाया जाएगा",
      "DETECT WPS": "डब्ल्यूपीएस का पता लगाएं",
      "WPS IS 0.37": "डब्ल्यूपीएस 0.37 है",
      "OFF: don't change order or merge cuts": "बंद: ऑर्डर न बदलें या कट मर्ज न करें",
      "ON: reduce cuts by merging and sorting may change order": "चालू: विलय और छँटाई द्वारा कटौती कम करें, क्रम बदल सकता है",
      "USE REDUCE": "उपयोग कम करें",
      "KEEP ORDER AND CUTS": "ऑर्डर और कटौती रखें",
      "Timecode Errors": "टाइमकोड त्रुटियाँ",
      "Timecodes": "टाइमकोड",
      "Source ID": "स्रोत आईडी",
      "Error": "गलती",
      "Log Out": "लॉग आउट",
      "Sign Up": "साइन अप करें",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "इन प्रस्तुतियों के सहायक संपादक पहले से ही खुश टूलियमर हैं, अधिक उत्पादक और समय बचाने वाले हैं",
      "Redo": "फिर से करना",
      "Undo": "पूर्ववत",
      "You have no credits left": "आपके पास कोई क्रेडिट नहीं बचा है",
      "You need to buy more credits in order to keep using our platform": "हमारे प्लेटफ़ॉर्म का उपयोग जारी रखने के लिए आपको अधिक क्रेडिट खरीदने की आवश्यकता है",
      "Note": "टिप्पणी",
      "You will still get free daily credits even if you don't make a purchase": "यदि आप खरीदारी नहीं करते हैं तब भी आपको निःशुल्क दैनिक क्रेडिट प्राप्त होंगे",
      "Benefits of buying credits": "क्रेडिट खरीदने के लाभ",
      "Bulk discount (up to 50%)": "थोक छूट (50% तक)",
      "Get to request features to fit your worksflow": "अपने वर्कफ़्लो में फिट होने के लिए सुविधाओं का अनुरोध करें",
      "You only pay for what you use": "आप केवल उसी के लिए भुगतान करते हैं जिसका आप उपयोग करते हैं",
      "No monthly membership and no automatic recurring payments": "कोई मासिक सदस्यता नहीं और कोई स्वचालित आवर्ती भुगतान नहीं",
      "You get to support our site": "आपको हमारी साइट का समर्थन करना होगा",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "यदि आपको वास्तव में अधिक क्रेडिट की आवश्यकता है और आपके पास कोई पैसा नहीं है, तो हमें यह बताते हुए एक निबंध भेजें कि हमें आपको मुफ्त क्रेडिट क्यों देना चाहिए",
      "Buy credits": "क्रेडिट्स खरीदें",
      "Ask for free credits, I don't have any money": "निःशुल्क क्रेडिट मांगें, मेरे पास कोई पैसा नहीं है",
      "Signup with Google to get 500 free credits": "500 निःशुल्क क्रेडिट प्राप्त करने के लिए Google के साथ साइन अप करें",
      "You will also get 150 additional free credits every day": "आपको हर दिन 150 अतिरिक्त मुफ्त क्रेडिट भी मिलेंगे",
      "Select": "चुनना",
      "Credits": "क्रेडिट",
      "1 cut with Cutter = 20 credits": "कटर से 1 कट = 20 क्रेडिट",
      "Discount": "छूट",
      "Diamond": "डायमंड",
      "Enterprise": "उद्यम",
      "7 days money back guarantee": "7 दिन की मनी बैक गारंटी",
      "For any questions or issues": "किसी भी प्रश्न या समस्या के लिए",
      "Free": "मुक्त",
      "Gold": "सोना",
      "1 group with Grouper": "ग्रूपर के साथ 1 समूह",
      "1 cut with Cutter": "1 कटर से काटें"
    }
  },
  "zh": {
    "translation": {
      "Home": "家",
      "Grouper": "石斑鱼",
      "GROUPER": "石斑鱼",
      "Logout": "登出",
      "Profile": "轮廓",
      "Onboard": "在船上",
      "Cutter": "刀具",
      "Blog": "博客",
      "Contact Us": "联系我们",
      "Login": "登录",
      "Create group clips": "创建组剪辑",
      "made easy": "变得容易",
      "Free Get started": "免费开始使用",
      "14 days free. No risk.": "14 天免费。没有风险。",
      "Signup Free": "免费注册",
      "Contact us with any question at": "如有任何问题请联系我们",
      "Just one more step before you start Grouping": "开始分组前只需再执行一步",
      "First name": "名",
      "What is your first name": "你的名字是什么",
      "Please input your first name!": "请输入您的名字！",
      "Last/family name": "姓氏/姓氏",
      "What is your last/family name": "您的姓氏是什么",
      "Please input your last/family name!": "请输入您的姓氏！",
      "Nickname": "昵称",
      "What do you want others to call you?": "你希望别人怎么称呼你？",
      "Please input your nickname!": "请输入您的昵称！",
      "Phone Number": "电话号码",
      "What is your phone number?": "你的电话号码是什么？",
      "Please input your phone number! Example +18777755232": "请输入您的电话号码！例如+18777755232",
      "I agree to the": "我同意",
      "privacy policy": "隐私政策",
      "terms of service": "服务条款",
      "disclaimer": "免责声明",
      "Privacy policy": "隐私政策",
      "Terms of service": "服务条款",
      "Disclaimer": "免责声明",
      "Submit": "提交",
      "Contact your Toolium representative to buy more credits": "请联系您的 Toolium 代表购买更多积分",
      "See your credits in profile": "在个人资料中查看您的学分",
      "Contact your Toolium representative to activate your account": "请联系您的 Toolium 代表以激活您的帐户",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "上传您的同步序列的 AAF 文件，我们将为您输出 Avid Media Composer 的分组 AAF 文件",
      "Click to Upload or Drag and Drop an AAF": "单击上传或拖放 AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "请去验证您的电子邮件，以便您可以继续使用该网站。我们向您发送了一封电子邮件，其中包含可点击的链接。",
      "Resend verification email": "重新发送验证邮件",
      "Call us at +1-877-775-5232": "请致电 +1-877-775-5232 联系我们",
      "Plan": "计划",
      "PLANS": "计划",
      "Choose a plan that works for you": "选择适合您的计划",
      "Basic": "基本的",
      "credit": "信用",
      "year": "年",
      "Billed annually.": "按年计费。",
      "Contact us": "联系我们",
      "Recommended": "受到推崇的",
      "Standard": "标准",
      "credits": "学分",
      "Billed annually. Save": "按年计费。节省",
      "Premium": "优质的",
      "Toolium Cutter": "工具刀具",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "只需使用我们的流程简单地突出文本并上传文件，即可直接从 Microsoft Word 文档或 Google 文档剪切和编辑所有推荐或文档",
      "Automate uncreative tasks": "自动执行无创意的任务",
      "Save money processing media": "节省处理介质的费用",
      "Save 4 hours every shoot day": "每个拍摄日节省 4 小时",
      "The next generation of post production workflow tools": "下一代后期制作工作流程工具",
      "14 days free.": "14 天免费。",
      "Plans": "计划",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium 是一个中心枢纽，可让您有效地分组、剪切和简化工作流程，以便您的团队可以加快工作速度并将交付时间缩短 4 小时！",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "上传同步序列的 AAF 文件，我们将为您输出 Avid Media Composer 的分组 AAF 文件",
      "Toolium Grouper": "工具石斑鱼",
      "Used in": "用于",
      "Groups are created with the full master clips": "使用完整的主剪辑创建组",
      "Reference sub-clips for shortened clips": "缩短剪辑的参考子剪辑",
      "New avid multigroup type groups": "新的狂热多组类型组",
      "Track renaming to group clip names": "轨道重命名为组剪辑名称",
      "Features": "特征",
      "AAF uploads": "AAF 上传",
      "AAF upload": "AAF上传",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI：将组创建为新的 avid 组，同一轨道上的剪辑之间有黑色空间。在时间轴上的空格后创建一个新组。",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "重命名打开：根据序列轨道名称重命名组内的音轨。您可以根据需要打开任意数量的曲目，将根据源音轨创建组。",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "保持音频打开：将包括尾随音频的组拼接到最终序列上。",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON：将组中的所有音轨按连续顺序路由至序列。如果您有来自多个设备的音频，请使用此选项。",
      "CLASSIC: Classic AVID grouping": "CLASSIC：经典 AVID 分组",
      "Process type unknown": "进程类型未知",
      "No clips found on sequence": "序列中未找到剪辑",
      "No clips for writing sequence": "没有用于编写序列的剪辑",
      "No sequence found in AAF file": "AAF 文件中未找到序列",
      "File is to big reduce AAF file": "文件为大减AAF文件",
      "Sequence has length of 0": "序列长度为 0",
      "Group clip found on sequence": "在序列中找到组剪辑",
      "Group clip found on sequence 2": "在序列 2 上找到的组剪辑",
      "Unknown selector type found in sequence": "序列中发现未知的选择器类型",
      "Clip framerate does not match sequence framerate": "剪辑帧速率与序列帧速率不匹配",
      "Subclips with motion effects are not supported": "不支持具有运动效果的子剪辑",
      "Join the best": "加入最优秀的",
      "You are in good hands.": "你得到了很好的照顾。",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "这些作品的助理编辑已经成为快乐的 Tooliumers，提高了工作效率并节省了时间",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "只需突出显示您的文本并使用我们的流程上传文件，即可直接从 Microsoft Word 文档或 Google 文档剪切和编辑您的所有推荐或文档",
      "Renames audio tracks within the group clips": "重命名组剪辑中的音轨",
      "Creates the new avid multigroup type groups": "创建新的 avid multigroup 类型组",
      "Creates avid sub-clips for shortened clips": "为缩短的剪辑创建狂热的子剪辑",
      "Editor": "编辑",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "想要使用我们的 Avid Media Composer 兼容编辑器在浏览器中进行编辑吗？",
      "Yes, join pre launch": "是的，加入预发布",
      "No I don't want": "不，我不想",
      "Click to Upload AAF": "点击上传AAF",
      "Bin panel": "仓面板",
      "Go to Previous Event": "前往上一个活动",
      "Go to Next Event": "前往下一个活动",
      "Step Backward 1 Fram": "后退 1 帧",
      "Step Forward 1 Fram": "前进 1 帧",
      "Mark IN": "标记输入",
      "Play": "玩",
      "Mark OUT": "注明",
      "Mark Clip": "标记剪辑",
      "Clear Both Marks": "清除两个标记",
      "Add Marker": "添加标记",
      "Swap Cam Bank": "交换凸轮银行",
      "Find Bin": "查找垃圾箱",
      "Gang": "刚",
      "Step backward 10 frames": "后退 10 帧",
      "Step forward 10 frames": "前进10帧",
      "Go to IN": "前往印度",
      "Play Length Toggle": "播放长度切换",
      "Go to OUT": "转到输出",
      "Match Frame": "比赛框架",
      "Reverse Match Frame": "反向匹配帧",
      "Quad Split": "四分体",
      "Nine Split": "九分",
      "Step Backward 1 Frame": "后退 1 帧",
      "Step Forward 1 Frame": "前进 1 帧",
      "Add Edit": "添加编辑",
      "Lift": "举起",
      "Extract": "提炼",
      "Quick Transition": "快速过渡",
      "Remove Effect": "移除效果",
      "Collapse": "坍塌",
      "Video Quality Menu": "视频质量菜单",
      "Monitor Volume": "监听音量",
      "Meter Menu": "仪表菜单",
      "Effect Mode": "效果模式",
      "Trim Mode": "修剪模式",
      "Step In": "介入;涉足",
      "Step Out": "走出去",
      "Render Effect": "渲染效果",
      "Tool Palette": "工具调色板",
      "Head Fade": "头部褪色",
      "Tall Fade": "高褪色",
      "To the Left": "向左转",
      "To the Right": "向右",
      "Select In/Out": "选择输入/输出",
      "Link Selection Toggle": "链接选择切换",
      "HW/SW": "硬件/软件",
      "Titler Pro": "标题专业版",
      "Default Setup": "默认设置",
      "View Type": "视图类型",
      "Track Panel": "轨道面板",
      "Effect Icons": "效果图标",
      "Render Rangers": "渲染游骑兵",
      "Dissolve Icons": "溶解图标",
      "Clip Frames": "剪辑框架",
      "Clip Text": "剪辑文本",
      "Sync Breaks": "同步中断",
      "Dupe Detection": "欺骗检测",
      "Color Correction": "色彩校正",
      "Audio Data": "音频数据",
      "Clip Color...": "剪辑颜色...",
      "Segment Selection": "细分选择",
      "Mark I/O Selection": "标记 I/O 选择",
      "Track Color": "轨道颜色",
      "Show markers": "显示标记",
      "Show adapters": "显示适配器",
      "Show Track": "显示曲目",
      "Track Control Panel": "轨道控制面板",
      "Zoom": "飞涨",
      "Open Comments": "开放评论",
      "Call us at": "请致电我们",
      "Office": "办公室",
      "Tracks Info": "曲目信息",
      "Edit Marker": "编辑标记",
      "Add": "添加",
      "Marker Name": "标记名称",
      "Color": "颜色",
      "Frame": "框架",
      "Track": "追踪",
      "Clear Monitor": "清晰监控",
      "Clear Menu": "清除菜单",
      "Show Markers": "显示标记",
      "Show Adapters": "显示适配器",
      "Toggle Fullscreen": "切换全屏",
      "Highlighter": "荧光笔",
      "Logger": "记录器",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "文件 DOC 无效，请改用 DOCX，您可以尝试此第 3 方工具",
      "Open DOC to DOCX converter": "打开 DOC 到 DOCX 转换器",
      "File is missing any cuts, try to click": "文件缺少任何剪切，请尝试单击",
      "SET ALL ROWS": "设置所有行",
      "OFF: Timecodes have start and end": "OFF：时间码有开始和结束",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON：没有结束时间码，仅从开始时间码添加 30 秒",
      "NO END TIMECODE USE 30 SECONDS": "无结束时间码使用 30 秒",
      "HAS END TIMECODE": "有结束时间码",
      "OFF: Create cuts using highlighted text": "关闭：使用突出显示的文本创建剪切",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON：为文档中的所有行创建剪切。忽略亮点",
      "ALL ROWS": "所有行",
      "ONLY HIGHLIGHTED": "仅突出显示",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "OFF：忽略源凸轮。这将仅根据源时间码创建剪辑。",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON：使用源凸轮。这将根据源时间码和源剪辑凸轮卷创建剪辑",
      "USE CAMROLL IN DOCX": "在 DOCX 中使用 CAMROLL",
      "IGNORE CAMROLL IN DOCX": "忽略 DOCX 中的 CAMROLL",
      "OFF: AAF downloaded will not be renamed": "OFF：下载的 AAF 不会被重命名",
      "ON: Rename AAF to DOCX file name": "ON：将 AAF 重命名为 DOCX 文件名",
      "RENAME AAF": "重命名AAF",
      "NO RENAME": "无需重命名",
      "Click to Upload DOCX": "点击上传DOCX",
      "MULTI": "多",
      "CLASSIC": "经典的",
      "RENAME ON": "重命名于",
      "RENAME OFF": "重命名关闭",
      "KEEP AUDIO ON": "保持音频打开",
      "KEEP AUDIO OFF": "关闭音频",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO AS IS ON（按原样分组音频）：创建组，其中音轨的路由完全按照同步序列上的位置进行。如果您希望组音频完全按照您的序列进行路由，请使用此选项",
      "GROUP AUDIO AS IS ON": "群组音频按原样打开",
      "GROUP AUDIO AS IS OFF": "群组音频处于关闭状态",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "保持摄像机在轨道上：创建组，摄像机角度与放置在同步序列上的轨道相同。如果您希望相机在整个拍摄过程中保持在同一组位置，请使用此选项",
      "KEEP CAM ON TRACK ON": "让摄像机保持在轨道上",
      "KEEP CAM ON TRACK OFF": "让摄像机保持在轨道上",
      "ROUTE AUDIO ON": "路由音频打开",
      "ROUTE AUDIO OFF": "路由音频关闭",
      "Toolium Editor": "工具库编辑器",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "上传 AAF 文件，我们将为您提供兼容 Avid Media Composer 的远程任意编辑器，然后为您输出 Avid Media Composer 的分组 AAF 文件",
      "Free forever": "永远免费",
      "Collaborate with ease with all the team": "与所有团队轻松协作",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "将反馈直接写在框架上，让每个人都保持一致。或者画出来",
      "Look and feel you are use to in Avid Media Composer": "您在 Avid Media Composer 中所习惯的外观和感觉",
      "Keyboard shortcuts you are use to": "您常用的键盘快捷键",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium 是一个中心枢纽，可让您有效地分组、剪切和简化工作流程，以便您的团队可以加快工作速度并将交付时间缩短 4 小时！",
      "A-Side Trim Counter": "A 侧修剪计数器",
      "B-Side Trim Counter": "B 侧修剪计数器",
      "Insert Track": "插入轨道",
      "OK": "好的",
      "Track Type": "轨道类型",
      "Track Num": "曲目编号",
      "Mas TC1": "马斯TC1",
      "Dur TC1": "杜尔TC1",
      "I/O TC1": "输入/输出TC1",
      "Abs TC1": "绝对值TC1",
      "Rem TC1": "雷姆TC1",
      "Mas 24": "马斯 24",
      "Dur 24": "持续时间 24",
      "I/O 24": "输入/输出24",
      "Abs 24": "腹肌 24",
      "Rem 24": "雷姆 24",
      "Mas 25": "马斯25",
      "Dur 25": "持续时间 25",
      "I/O 25": "输入/输出25",
      "Abs 25": "腹肌 25",
      "Rem 25": "雷姆 25",
      "Mas Frm": "马斯弗姆",
      "Dur Frm": "杜弗姆",
      "I/O Frm": "I/O 框架",
      "Abs Frm": "腹肌Frm",
      "Rem Frm": "雷姆·弗姆",
      "Sequence": "顺序",
      "Timecode": "时间码",
      "TC1": "TC1",
      "Mas": "马斯",
      "Dur": "杜尔",
      "I/O": "输入/输出",
      "Abs": "腹肌",
      "Rem": "雷姆",
      "Footage": "镜头",
      "Frames": "镜框",
      "Source": "来源",
      "None": "没有任何",
      "Master": "掌握",
      "Duration": "期间",
      "In/Out": "进出",
      "Absolute": "绝对",
      "Remain": "保持",
      "Audio Mono": "音频单声道",
      "Audio Stereo": "音频立体声",
      "5.1 Surround": "5.1环绕声",
      "7.1 Surround": "7.1 环绕声",
      "Data": "数据",
      "Video": "视频",
      "Leave your comment here...": "在这里留下您的评论...",
      "Send": "发送",
      "Workspace": "工作空间",
      "Project Settings": "项目设置",
      "Leave Project": "离开项目",
      "Delete Project": "删除项目",
      "New Project": "新项目",
      "Name": "姓名",
      "Please, input the name": "请输入姓名",
      "Edit Project": "编辑项目",
      "Save Settings": "保存设置",
      "Jump to project of file...": "跳转到文件项目...",
      "Status": "地位",
      "Comments": "评论",
      "Size": "尺寸",
      "Date uploaded": "上传日期",
      "Uploader": "上传者",
      "Action": "行动",
      "File upload": "上传文件",
      "Folder upload": "文件夹上传",
      "Folder upload as proxy": "作为代理上传文件夹",
      "New folder": "新建文件夹",
      "New private folder": "新建私人文件夹",
      "Download all": "全部下载",
      "Recently deleted": "最近删除",
      "Project settings": "项目设置",
      "Rename file": "重新命名文件",
      "Move": "移动",
      "Download": "下载",
      "Make private": "设为私有",
      "Reveal in project": "在项目中展现",
      "Delete": "删除",
      "File Information": "文件信息",
      "Copy Comments": "复制评论",
      "Paste Comments": "粘贴评论",
      "Print": "打印",
      "Download as File...": "下载为文件...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "纯文本",
      "Download for Final Cut Pro X": "下载 Final Cut Pro X",
      "Download for Media Composer": "下载媒体作曲家",
      "Download for Resolve": "下载解决",
      "No comments": "暂无评论",
      "This is premium feature, contact us at support@toolium.org": "这是高级功能，请通过 support@toolium.org 联系我们",
      "Automated test to speech": "自动测试语音",
      "Import from uploads": "从上传导入",
      "Upload your VO file": "上传你的VO文件",
      "Record voice over": "录制旁白",
      "Crop": "庄稼",
      "Voiceover": "画外音",
      "Adjust colors": "调整颜色",
      "Trim": "修剪",
      "Speed": "速度",
      "Loop": "环形",
      "Flip": "翻动",
      "Effects": "效果",
      "Animation": "动画片",
      "Add title": "添加标题",
      "Export": "出口",
      "Grid": "网格",
      "Split": "分裂",
      "Split View": "拆分视图",
      "Zoom to fit": "缩放以适合",
      "Audio meter": "音频表",
      "TC": "TC",
      "Comment": "评论",
      "Jump to Marker": "跳转到标记",
      "Change Color": "换颜色",
      "Change Track": "改变轨迹",
      "Import Markers": "导入标记",
      "Export Markers": "导出标记",
      "Choose Columns": "选择列",
      "Cameras": "相机",
      "Desktop is required": "需要桌面",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "我们注意到您使用的是屏幕较小的设备。为了获得最佳体验，请使用桌面。",
      "Go back": "回去",
      "OFF: Use 0 seconds for handles": "OFF：手柄使用 0 秒",
      "ON: Use 5 seconds for handles": "ON：手柄使用 5 秒",
      "HANDLES OFF": "把手关闭",
      "HANDLES ON": "处理",
      "OFF: word-per-second will be 0.37": "关闭：每秒字数将为 0.37",
      "ON: word-per-second will be detected": "ON：将检测每秒字数",
      "DETECT WPS": "检测WPS",
      "WPS IS 0.37": "WPS 为 0.37",
      "OFF: don't change order or merge cuts": "OFF：不更改顺序或合并剪辑",
      "ON: reduce cuts by merging and sorting may change order": "ON：通过合并和排序减少削减可能会改变顺序",
      "USE REDUCE": "使用减少",
      "KEEP ORDER AND CUTS": "保持秩序并削减",
      "Timecode Errors": "时间码错误",
      "Timecodes": "时间码",
      "Source ID": "源ID",
      "Error": "错误",
      "Log Out": "登出",
      "Sign Up": "报名",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "这些作品的助理编辑已经成为快乐的 Tooliumers，提高了工作效率并节省了时间",
      "Redo": "重做",
      "Undo": "撤消",
      "You have no credits left": "您没有剩余积分",
      "You need to buy more credits in order to keep using our platform": "您需要购买更多积分才能继续使用我们的平台",
      "Note": "笔记",
      "You will still get free daily credits even if you don't make a purchase": "即使您不进行购买，您仍然可以获得免费的每日积分",
      "Benefits of buying credits": "购买积分的好处",
      "Bulk discount (up to 50%)": "批量折扣（高达 50%）",
      "Get to request features to fit your worksflow": "请求适合您工作流程的功能",
      "You only pay for what you use": "您只需为您使用的内容付费",
      "No monthly membership and no automatic recurring payments": "没有月费会员资格，也没有自动定期付款",
      "You get to support our site": "您可以支持我们的网站",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "如果您确实需要更多学分但又没有钱，请向我们发送一篇文章，解释为什么我们应该为您提供免费学分",
      "Buy credits": "购买积分",
      "Ask for free credits, I don't have any money": "要求免费积分，我没有钱",
      "Signup with Google to get 500 free credits": "注册 Google 即可获得 500 个免费积分",
      "You will also get 150 additional free credits every day": "您每天还将获得 150 个额外的免费积分",
      "Select": "选择",
      "Credits": "制作人员",
      "1 cut with Cutter = 20 credits": "使用 Cutter 进行 1 次切割 = 20 学分",
      "Discount": "折扣",
      "Diamond": "钻石",
      "Enterprise": "企业",
      "7 days money back guarantee": "7 天退款保证",
      "For any questions or issues": "如有任何疑问或问题",
      "Free": "自由的",
      "Gold": "金子",
      "1 group with Grouper": "1组与石斑鱼",
      "1 cut with Cutter": "用切割机切割 1 次"
    }
  },
  "es": {
    "translation": {
      "Home": "Hogar",
      "Grouper": "Agrupador",
      "GROUPER": "AGRUPADOR",
      "Logout": "Cerrar sesión",
      "Profile": "Perfil",
      "Onboard": "A bordo",
      "Cutter": "Cortador",
      "Blog": "Blog",
      "Contact Us": "Contáctenos",
      "Login": "Acceso",
      "Create group clips": "Crear clips de grupo",
      "made easy": "hecho facil",
      "Free Get started": "Gratis Empezar",
      "14 days free. No risk.": "14 días gratis. Sin riesgo.",
      "Signup Free": "Regístrate gratis",
      "Contact us with any question at": "Contáctanos con cualquier pregunta en",
      "Just one more step before you start Grouping": "Sólo un paso más antes de empezar a agrupar",
      "First name": "Nombre de pila",
      "What is your first name": "Cuál es tu primer nombre",
      "Please input your first name!": "¡Por favor ingrese su nombre!",
      "Last/family name": "Apellido/apellido",
      "What is your last/family name": "¿Cuál es tu apellido/apellido?",
      "Please input your last/family name!": "¡Por favor ingrese su apellido/apellido!",
      "Nickname": "Apodo",
      "What do you want others to call you?": "¿Cómo quieres que te llamen los demás?",
      "Please input your nickname!": "¡Por favor ingresa tu apodo!",
      "Phone Number": "Número de teléfono",
      "What is your phone number?": "¿Cuál es tu número de teléfono?",
      "Please input your phone number! Example +18777755232": "¡Por favor ingrese su número de teléfono! Ejemplo +18777755232",
      "I agree to the": "Estoy de acuerdo con la",
      "privacy policy": "política de privacidad",
      "terms of service": "términos de servicio",
      "disclaimer": "descargo de responsabilidad",
      "Privacy policy": "Política de privacidad",
      "Terms of service": "Términos de servicio",
      "Disclaimer": "Descargo de responsabilidad",
      "Submit": "Entregar",
      "Contact your Toolium representative to buy more credits": "Contacta con tu representante de Toolium para comprar más créditos",
      "See your credits in profile": "Ver tus créditos en el perfil",
      "Contact your Toolium representative to activate your account": "Contacta con tu representante de Toolium para activar tu cuenta",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "cargue un archivo AAF de su secuencia sincronizada y le enviaremos un archivo AAF agrupado para Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Haga clic para cargar o arrastrar y soltar un AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Vaya y verifique su correo electrónico para poder continuar usando el sitio web. Le enviamos un correo electrónico con un enlace para hacer clic.",
      "Resend verification email": "Reenviar correo electrónico de verificación",
      "Call us at +1-877-775-5232": "Llámanos al +1-877-775-5232",
      "Plan": "Plan",
      "PLANS": "PLANES",
      "Choose a plan that works for you": "Elija un plan que funcione para usted",
      "Basic": "Básico",
      "credit": "crédito",
      "year": "año",
      "Billed annually.": "Facturado anualmente.",
      "Contact us": "Contáctenos",
      "Recommended": "Recomendado",
      "Standard": "Estándar",
      "credits": "créditos",
      "Billed annually. Save": "Facturado anualmente. Ahorrar",
      "Premium": "De primera calidad",
      "Toolium Cutter": "Cortador de herramientas",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Corte y edite todos sus testimonios o documentos directamente desde su documento de Microsoft Word o Google Docs simplemente resaltando su texto y cargando su archivo usando nuestro proceso.",
      "Automate uncreative tasks": "Automatizar tareas no creativas",
      "Save money processing media": "Ahorre dinero en medios de procesamiento",
      "Save 4 hours every shoot day": "Ahorra 4 horas cada día de rodaje",
      "The next generation of post production workflow tools": "La próxima generación de herramientas de flujo de trabajo de posproducción",
      "14 days free.": "14 días gratis.",
      "Plans": "Planes",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium es un centro central que le permite agrupar, recortar y optimizar su flujo de trabajo de manera eficiente para que su equipo pueda acelerar su trabajo y reducir la entrega en 4 horas.",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Cargue un archivo AAF de su secuencia sincronizada y le enviaremos un archivo AAF agrupado para Avid Media Composer",
      "Toolium Grouper": "Mero Toolium",
      "Used in": "Utilizada en",
      "Groups are created with the full master clips": "Los grupos se crean con los clips maestros completos.",
      "Reference sub-clips for shortened clips": "Subclips de referencia para clips acortados",
      "New avid multigroup type groups": "Nuevos grupos tipo ávidos multigrupo",
      "Track renaming to group clip names": "Cambiar el nombre de la pista a nombres de clips de grupo",
      "Features": "Características",
      "AAF uploads": "Cargas AAF",
      "AAF upload": "subir AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Los grupos se crean como nuevos grupos de Avid con espacios negros entre clips en la misma pista. Se crea un nuevo grupo después de un espacio en la línea de tiempo.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: cambia el nombre de las pistas de audio dentro de los grupos según los nombres de las pistas de secuencia. Puedes abrir tantas pistas como necesites, los grupos se crearán según la pista de audio fuente.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "MANTENER AUDIO ENCENDIDO: une grupos, incluido el audio final, en la secuencia final.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "RUTAR AUDIO ACTIVADO: Enruta todas las pistas de audio del grupo a la secuencia en orden consecutivo. Úselo si tiene audio de más de un dispositivo.",
      "CLASSIC: Classic AVID grouping": "CLÁSICO: Agrupación AVID clásica",
      "Process type unknown": "Tipo de proceso desconocido",
      "No clips found on sequence": "No se encontraron clips en la secuencia",
      "No clips for writing sequence": "Sin clips para secuencia de escritura.",
      "No sequence found in AAF file": "No se encontró ninguna secuencia en el archivo AAF",
      "File is to big reduce AAF file": "El archivo tiene una reducción grande en el archivo AAF",
      "Sequence has length of 0": "La secuencia tiene una longitud de 0.",
      "Group clip found on sequence": "Clip de grupo encontrado en secuencia",
      "Group clip found on sequence 2": "Clip grupal encontrado en la secuencia 2",
      "Unknown selector type found in sequence": "Tipo de selector desconocido encontrado en secuencia",
      "Clip framerate does not match sequence framerate": "La velocidad de fotogramas del clip no coincide con la velocidad de fotogramas de la secuencia",
      "Subclips with motion effects are not supported": "No se admiten subclips con efectos de movimiento.",
      "Join the best": "Únete a los mejores",
      "You are in good hands.": "Estás en buenas manos.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Los editores asistentes de estas producciones ya son Tooliumers felices, más productivos y ahorran tiempo.",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Corte y edite todos sus testimonios o documentos directamente desde su documento de Microsoft Word o Google Docs simplemente resaltando su texto y cargando su archivo usando nuestro proceso.",
      "Renames audio tracks within the group clips": "Cambia el nombre de las pistas de audio dentro de los clips del grupo.",
      "Creates the new avid multigroup type groups": "Crea los nuevos grupos de tipo multigrupo Avid.",
      "Creates avid sub-clips for shortened clips": "Crea subclips ávidos para clips acortados",
      "Editor": "Editor",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "¿Quieres editar en tu navegador usando nuestro editor compatible con Avid Media Composer?",
      "Yes, join pre launch": "Sí, únete antes del lanzamiento",
      "No I don't want": "no, no quiero",
      "Click to Upload AAF": "Haga clic para cargar AAF",
      "Bin panel": "Panel de contenedores",
      "Go to Previous Event": "Ir al evento anterior",
      "Go to Next Event": "Ir al próximo evento",
      "Step Backward 1 Fram": "Paso atrás 1 cuadro",
      "Step Forward 1 Fram": "Paso adelante 1 cuadro",
      "Mark IN": "Marcar en",
      "Play": "Jugar",
      "Mark OUT": "Señalar",
      "Mark Clip": "Clip de marca",
      "Clear Both Marks": "Borrar ambas marcas",
      "Add Marker": "Agregar marcador",
      "Swap Cam Bank": "Intercambiar banco de cámaras",
      "Find Bin": "encontrar papelera",
      "Gang": "Pandilla",
      "Step backward 10 frames": "Retroceder 10 fotogramas",
      "Step forward 10 frames": "Avanza 10 fotogramas",
      "Go to IN": "Ir a EN",
      "Play Length Toggle": "Alternar duración de reproducción",
      "Go to OUT": "Ir a FUERA",
      "Match Frame": "Marco de partido",
      "Reverse Match Frame": "Cuadro de coincidencia inversa",
      "Quad Split": "División cuádruple",
      "Nine Split": "Nueve divisiones",
      "Step Backward 1 Frame": "Paso atrás 1 fotograma",
      "Step Forward 1 Frame": "Paso adelante 1 fotograma",
      "Add Edit": "Agregar Editar",
      "Lift": "Elevar",
      "Extract": "Extracto",
      "Quick Transition": "Transición rápida",
      "Remove Effect": "Quitar efecto",
      "Collapse": "Colapsar",
      "Video Quality Menu": "Menú de calidad de vídeo",
      "Monitor Volume": "Monitorear volumen",
      "Meter Menu": "Menú del medidor",
      "Effect Mode": "Modo de efecto",
      "Trim Mode": "Modo de recorte",
      "Step In": "Paso",
      "Step Out": "Salir",
      "Render Effect": "Efecto de renderizado",
      "Tool Palette": "Paleta de herramientas",
      "Head Fade": "Desvanecimiento de la cabeza",
      "Tall Fade": "Desvanecimiento alto",
      "To the Left": "A la izquierda",
      "To the Right": "A la derecha",
      "Select In/Out": "Seleccionar entrada/salida",
      "Link Selection Toggle": "Alternar selección de enlace",
      "HW/SW": "HW/SO",
      "Titler Pro": "Titulador Pro",
      "Default Setup": "Configuración por defecto",
      "View Type": "Tipo de vista",
      "Track Panel": "Panel de seguimiento",
      "Effect Icons": "Iconos de efectos",
      "Render Rangers": "renderizar guardabosques",
      "Dissolve Icons": "Disolver iconos",
      "Clip Frames": "Marcos de clip",
      "Clip Text": "Recortar texto",
      "Sync Breaks": "pausas de sincronización",
      "Dupe Detection": "Detección de duplicados",
      "Color Correction": "Corrección de color",
      "Audio Data": "Datos de audio",
      "Clip Color...": "Color del clip...",
      "Segment Selection": "Selección de segmento",
      "Mark I/O Selection": "Marcar selección de E/S",
      "Track Color": "Color de pista",
      "Show markers": "Mostrar marcadores",
      "Show adapters": "Mostrar adaptadores",
      "Show Track": "Mostrar pista",
      "Track Control Panel": "Panel de control de seguimiento",
      "Zoom": "Zoom",
      "Open Comments": "Comentarios abiertos",
      "Call us at": "Llamenos al",
      "Office": "Oficina",
      "Tracks Info": "Información de pistas",
      "Edit Marker": "Editar marcador",
      "Add": "Agregar",
      "Marker Name": "Nombre del marcador",
      "Color": "Color",
      "Frame": "Marco",
      "Track": "Pista",
      "Clear Monitor": "Limpiar monitor",
      "Clear Menu": "Borrar menú",
      "Show Markers": "Mostrar marcadores",
      "Show Adapters": "Mostrar adaptadores",
      "Toggle Fullscreen": "Alternar pantalla completa",
      "Highlighter": "resaltador",
      "Logger": "registrador",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Archivo DOC no válido; utilice DOCX en su lugar; puede probar esta herramienta de terceros",
      "Open DOC to DOCX converter": "Abrir convertidor de DOC a DOCX",
      "File is missing any cuts, try to click": "Al archivo le faltan cortes, intente hacer clic",
      "SET ALL ROWS": "ESTABLECER TODAS LAS FILAS",
      "OFF: Timecodes have start and end": "APAGADO: Los códigos de tiempo tienen inicio y fin.",
      "ON: No end timecode and just add 30 seconds from start timecode": "ENCENDIDO: Sin código de tiempo de finalización y solo agregue 30 segundos desde el código de tiempo de inicio",
      "NO END TIMECODE USE 30 SECONDS": "SIN CÓDIGO DE TIEMPO FINAL USO 30 SEGUNDOS",
      "HAS END TIMECODE": "TIENE CÓDIGO DE TIEMPO FINAL",
      "OFF: Create cuts using highlighted text": "APAGADO: crea cortes usando texto resaltado",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ACTIVADO: crea cortes para todas las filas del documento. Ignorando los aspectos más destacados",
      "ALL ROWS": "TODAS LAS FILAS",
      "ONLY HIGHLIGHTED": "SOLO DESTACADO",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "APAGADO: Ignora los camrolls de origen. Esto creará cortes sólo según el código de tiempo fuente.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ENCENDIDO: Utilice camrolls de origen. Esto creará cortes según el código de tiempo fuente y el camroll del clip fuente.",
      "USE CAMROLL IN DOCX": "USAR CAMROLL EN DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORAR A CAMROLL EN DOCX",
      "OFF: AAF downloaded will not be renamed": "APAGADO: No se cambiará el nombre del AAF descargado",
      "ON: Rename AAF to DOCX file name": "ACTIVADO: Cambiar el nombre de AAF al nombre de archivo DOCX",
      "RENAME AAF": "RENOMBRAR AAF",
      "NO RENAME": "SIN RENOMBRAR",
      "Click to Upload DOCX": "Haga clic para cargar DOCX",
      "MULTI": "MULTI",
      "CLASSIC": "CLÁSICO",
      "RENAME ON": "RENOMBRAR EN",
      "RENAME OFF": "RENOMBRAR DESACTIVADO",
      "KEEP AUDIO ON": "MANTENER EL AUDIO ENCENDIDO",
      "KEEP AUDIO OFF": "MANTÉN EL AUDIO APAGADO",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GRUPO DE AUDIO COMO ESTÁ ACTIVADO: Crea grupos con las pistas de audio enrutadas exactamente como están colocadas en su secuencia sincronizada. Utilízalo si quieres que el audio de tu grupo se enrute exactamente como tu secuencia.",
      "GROUP AUDIO AS IS ON": "AUDIO DE GRUPO TAL CUAL ESTÁ ENCENDIDO",
      "GROUP AUDIO AS IS OFF": "AUDIO DE GRUPO TAL CUAL ESTÁ APAGADO",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "MANTENER CÁMARA EN PISTA ENCENDIDA: crea grupos con ángulos de cámara en la misma pista en la que se ubican en su secuencia sincronizada. Utilízalo si quieres que tus cámaras permanezcan en la misma ubicación de grupo durante toda la sesión.",
      "KEEP CAM ON TRACK ON": "MANTENER LA CÁMARA EN PISTA ENCENDIDA",
      "KEEP CAM ON TRACK OFF": "MANTENER LA CÁMARA EN PISTA APAGADA",
      "ROUTE AUDIO ON": "RUTA AUDIO ENCENDIDO",
      "ROUTE AUDIO OFF": "RUTA AUDIO APAGADO",
      "Toolium Editor": "Editor de herramientas",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Cargue un archivo AAF y le proporcionaremos un editor remoto en cualquier lugar compatible con Avid Media Composer y luego le generaremos un archivo AAF agrupado para Avid Media Composer.",
      "Free forever": "Siempre libre",
      "Collaborate with ease with all the team": "Colabora fácilmente con todo el equipo.",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Mantenga a todos en sintonía escribiendo comentarios directamente en un marco. O dibujarlo",
      "Look and feel you are use to in Avid Media Composer": "Aspecto y sensación al que estás acostumbrado en Avid Media Composer",
      "Keyboard shortcuts you are use to": "Atajos de teclado que estás acostumbrado",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium es un centro central que le permite agrupar, cortar y optimizar su flujo de trabajo de manera eficiente para que su equipo pueda acelerar su trabajo y reducir la entrega en 4 horas.",
      "A-Side Trim Counter": "Contador de recorte del lado A",
      "B-Side Trim Counter": "Contador de recorte del lado B",
      "Insert Track": "Insertar pista",
      "OK": "DE ACUERDO",
      "Track Type": "Tipo de pista",
      "Track Num": "Número de pista",
      "Mas TC1": "Más TC1",
      "Dur TC1": "Durante TC1",
      "I/O TC1": "E/S TC1",
      "Abs TC1": "Abdominales TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Más 24",
      "Dur 24": "Durante 24",
      "I/O 24": "E/S 24",
      "Abs 24": "Abdominales 24",
      "Rem 24": "rem 24",
      "Mas 25": "Más 25",
      "Dur 25": "Día 25",
      "I/O 25": "E/S 25",
      "Abs 25": "Abdominales 25",
      "Rem 25": "rem 25",
      "Mas Frm": "Mas Derm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "E/S desde",
      "Abs Frm": "abdominales",
      "Rem Frm": "Rem de",
      "Sequence": "Secuencia",
      "Timecode": "Código de tiempo",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "E/S",
      "Abs": "Abdominales",
      "Rem": "Movimiento rápido del ojo",
      "Footage": "Imágenes",
      "Frames": "Marcos",
      "Source": "Fuente",
      "None": "Ninguno",
      "Master": "Maestro",
      "Duration": "Duración",
      "In/Out": "En fuera",
      "Absolute": "Absoluto",
      "Remain": "Permanecer",
      "Audio Mono": "Audio mono",
      "Audio Stereo": "audio estéreo",
      "5.1 Surround": "5.1 envolvente",
      "7.1 Surround": "7.1 envolvente",
      "Data": "Datos",
      "Video": "Video",
      "Leave your comment here...": "Deja tu comentario aquí...",
      "Send": "Enviar",
      "Workspace": "Espacio de trabajo",
      "Project Settings": "Configuración del proyecto",
      "Leave Project": "Dejar proyecto",
      "Delete Project": "Eliminar proyecto",
      "New Project": "Nuevo proyecto",
      "Name": "Nombre",
      "Please, input the name": "Por favor, introduce el nombre.",
      "Edit Project": "Editar proyecto",
      "Save Settings": "Guardar ajustes",
      "Jump to project of file...": "Saltar al proyecto del archivo...",
      "Status": "Estado",
      "Comments": "Comentarios",
      "Size": "Tamaño",
      "Date uploaded": "Fecha de carga",
      "Uploader": "Cargador",
      "Action": "Acción",
      "File upload": "Subir archivo",
      "Folder upload": "Subir carpeta",
      "Folder upload as proxy": "Carga de carpeta como proxy",
      "New folder": "Nueva carpeta",
      "New private folder": "Nueva carpeta privada",
      "Download all": "Descargar todo",
      "Recently deleted": "Eliminado recientemente",
      "Project settings": "Configuración del proyecto",
      "Rename file": "Renombrar archivo",
      "Move": "Mover",
      "Download": "Descargar",
      "Make private": "Hazlo privado",
      "Reveal in project": "Revelar en proyecto",
      "Delete": "Borrar",
      "File Information": "Informacion del archivo",
      "Copy Comments": "Copiar comentarios",
      "Paste Comments": "Pegar comentarios",
      "Print": "Imprimir",
      "Download as File...": "Descargar como archivo...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Texto sin formato",
      "Download for Final Cut Pro X": "Descargar para Final Cut Pro X",
      "Download for Media Composer": "Descargar para Media Composer",
      "Download for Resolve": "Descargar para resolver",
      "No comments": "Sin comentarios",
      "This is premium feature, contact us at support@toolium.org": "Esta es una característica premium, contáctenos en support@toolium.org",
      "Automated test to speech": "Prueba automatizada de habla",
      "Import from uploads": "Importar desde cargas",
      "Upload your VO file": "Sube tu archivo VO",
      "Record voice over": "Grabar voz en off",
      "Crop": "Cultivo",
      "Voiceover": "Narración",
      "Adjust colors": "Ajustar colores",
      "Trim": "Recortar",
      "Speed": "Velocidad",
      "Loop": "Bucle",
      "Flip": "Voltear",
      "Effects": "Efectos",
      "Animation": "Animación",
      "Add title": "Añadir título",
      "Export": "Exportar",
      "Grid": "Red",
      "Split": "Dividir",
      "Split View": "Vista dividida",
      "Zoom to fit": "Ampliar para ajustar",
      "Audio meter": "medidor de audio",
      "TC": "TC",
      "Comment": "Comentario",
      "Jump to Marker": "Saltar al marcador",
      "Change Color": "Cambiar el color",
      "Change Track": "Cambiar pista",
      "Import Markers": "Importar marcadores",
      "Export Markers": "Exportar marcadores",
      "Choose Columns": "Elija columnas",
      "Cameras": "Cámaras",
      "Desktop is required": "Se requiere escritorio",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Hemos notado que estás usando un dispositivo de pantalla más pequeña. Para obtener la mejor experiencia, utilice una computadora de escritorio.",
      "Go back": "Regresa",
      "OFF: Use 0 seconds for handles": "APAGADO: Use 0 segundos para manijas",
      "ON: Use 5 seconds for handles": "ENCENDIDO: Use 5 segundos para manijas",
      "HANDLES OFF": "MANIJAS APAGADAS",
      "HANDLES ON": "MANIJAS ENCENDIDAS",
      "OFF: word-per-second will be 0.37": "APAGADO: palabra por segundo será 0,37",
      "ON: word-per-second will be detected": "ENCENDIDO: se detectará palabra por segundo",
      "DETECT WPS": "DETECTAR WPS",
      "WPS IS 0.37": "WPS ES 0,37",
      "OFF: don't change order or merge cuts": "APAGADO: no cambiar el orden ni fusionar cortes",
      "ON: reduce cuts by merging and sorting may change order": "ENCENDIDO: reducir los cortes fusionando y clasificando puede cambiar el orden",
      "USE REDUCE": "USAR REDUCIR",
      "KEEP ORDER AND CUTS": "MANTENGA EL ORDEN Y LOS CORTES",
      "Timecode Errors": "Errores de código de tiempo",
      "Timecodes": "Códigos de tiempo",
      "Source ID": "ID de fuente",
      "Error": "Error",
      "Log Out": "Cerrar sesión",
      "Sign Up": "Inscribirse",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Los asistentes de edición de estas producciones ya son Tooliumers felices, más productivos y ahorrando tiempo.",
      "Redo": "Rehacer",
      "Undo": "Deshacer",
      "You have no credits left": "No te quedan créditos",
      "You need to buy more credits in order to keep using our platform": "Necesitas comprar más créditos para seguir usando nuestra plataforma.",
      "Note": "Nota",
      "You will still get free daily credits even if you don't make a purchase": "Seguirás recibiendo créditos diarios gratuitos incluso si no realizas una compra",
      "Benefits of buying credits": "Beneficios de comprar créditos",
      "Bulk discount (up to 50%)": "Descuento por volumen (hasta 50%)",
      "Get to request features to fit your worksflow": "Solicite funciones que se adapten a su flujo de trabajo",
      "You only pay for what you use": "Solo paga por lo que usas",
      "No monthly membership and no automatic recurring payments": "Sin membresía mensual ni pagos recurrentes automáticos",
      "You get to support our site": "Puedes apoyar nuestro sitio",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Si realmente necesitas más créditos y no tienes dinero, envíanos un ensayo explicando por qué deberíamos darte créditos gratis.",
      "Buy credits": "Compra creditos",
      "Ask for free credits, I don't have any money": "Pide créditos gratis, no tengo dinero",
      "Signup with Google to get 500 free credits": "Regístrese en Google para obtener 500 créditos gratis",
      "You will also get 150 additional free credits every day": "También obtendrás 150 créditos gratuitos adicionales cada día.",
      "Select": "Seleccionar",
      "Credits": "Créditos",
      "1 cut with Cutter = 20 credits": "1 corte con Cutter = 20 créditos",
      "Discount": "Descuento",
      "Diamond": "Diamante",
      "Enterprise": "Empresa",
      "7 days money back guarantee": "Garantía de devolución de dinero de 7 días",
      "For any questions or issues": "Para cualquier duda o problema",
      "Free": "Gratis",
      "Gold": "Oro",
      "1 group with Grouper": "1 grupo con Mero",
      "1 cut with Cutter": "1 corte con Cutter"
    }
  },
  "pl": {
    "translation": {
      "Home": "Dom",
      "Grouper": "Grupnik",
      "GROUPER": "GRUPARKA",
      "Logout": "Wyloguj",
      "Profile": "Profil",
      "Onboard": "Na pokładzie",
      "Cutter": "Nóż",
      "Blog": "Bloga",
      "Contact Us": "Skontaktuj się z nami",
      "Login": "Zaloguj sie",
      "Create group clips": "Twórz klipy grupowe",
      "made easy": "uczynione prostym",
      "Free Get started": "Bezpłatne Rozpocznij",
      "14 days free. No risk.": "14 dni za darmo. Bez ryzyka.",
      "Signup Free": "Zarejestruj się za darmo",
      "Contact us with any question at": "Skontaktuj się z nami w przypadku jakichkolwiek pytań pod adresem",
      "Just one more step before you start Grouping": "Jeszcze tylko jeden krok, zanim zaczniesz grupować",
      "First name": "Imię",
      "What is your first name": "Jakie jest Twoje pierwsze imię",
      "Please input your first name!": "Proszę wpisać swoje imię!",
      "Last/family name": "Nazwisko/nazwisko",
      "What is your last/family name": "Jakie jest Twoje nazwisko/nazwisko",
      "Please input your last/family name!": "Proszę wpisać swoje nazwisko/nazwisko!",
      "Nickname": "Przezwisko",
      "What do you want others to call you?": "Jak chcesz, żeby inni Cię nazywali?",
      "Please input your nickname!": "Proszę wpisać swój nick!",
      "Phone Number": "Numer telefonu",
      "What is your phone number?": "Jaki jest Twój numer telefonu?",
      "Please input your phone number! Example +18777755232": "Proszę podać swój numer telefonu! Przykład +18777755232",
      "I agree to the": "zgadzam się z",
      "privacy policy": "Polityka prywatności",
      "terms of service": "warunki usługi",
      "disclaimer": "zastrzeżenie",
      "Privacy policy": "Polityka prywatności",
      "Terms of service": "Warunki usługi",
      "Disclaimer": "Zastrzeżenie",
      "Submit": "Składać",
      "Contact your Toolium representative to buy more credits": "Skontaktuj się z przedstawicielem Toolium, aby kupić więcej kredytów",
      "See your credits in profile": "Zobacz swoje kredyty w profilu",
      "Contact your Toolium representative to activate your account": "Skontaktuj się z przedstawicielem Toolium w celu aktywacji konta",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "prześlij plik AAF z zsynchronizowaną sekwencją, a my wyślemy Ci pogrupowany plik AAF dla Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Kliknij, aby przesłać lub przeciągnij i upuść plik AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Przejdź i zweryfikuj swój adres e-mail, aby móc nadal korzystać z witryny. Wysłaliśmy do Ciebie e-mail z linkiem, w który możesz kliknąć.",
      "Resend verification email": "Ponownie wysłać e-mail weryfikacyjny",
      "Call us at +1-877-775-5232": "Zadzwoń do nas pod numer +1-877-775-5232",
      "Plan": "Plan",
      "PLANS": "PLANY",
      "Choose a plan that works for you": "Wybierz plan, który Ci odpowiada",
      "Basic": "Podstawowy",
      "credit": "kredyt",
      "year": "rok",
      "Billed annually.": "Rozliczane corocznie.",
      "Contact us": "Skontaktuj się z nami",
      "Recommended": "Zalecana",
      "Standard": "Standard",
      "credits": "kredyty",
      "Billed annually. Save": "Rozliczane corocznie. Ratować",
      "Premium": "Premia",
      "Toolium Cutter": "Przecinarka narzędziowa",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Wytnij i edytuj wszystkie swoje referencje lub dokumenty bezpośrednio z dokumentu Microsoft Word lub Dokumentów Google, po prostu podnosząc tekst i przesyłając plik, korzystając z naszego procesu",
      "Automate uncreative tasks": "Automatyzuj niekreatywne zadania",
      "Save money processing media": "Oszczędzaj media do przetwarzania pieniędzy",
      "Save 4 hours every shoot day": "Zaoszczędź 4 godziny każdego dnia zdjęciowego",
      "The next generation of post production workflow tools": "Następna generacja narzędzi do obsługi postprodukcji",
      "14 days free.": "14 dni za darmo.",
      "Plans": "Plany",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium to centralne centrum, które pozwala efektywnie grupować, dzielić i usprawniać przepływ pracy, dzięki czemu Twój zespół może przyspieszyć Twoją pracę i skrócić czas dostawy o 4 godziny!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Prześlij plik AAF z zsynchronizowaną sekwencją, a my wyślemy Ci pogrupowany plik AAF dla Avid Media Composer",
      "Toolium Grouper": "Grouper Toolium",
      "Used in": "Stosuje się w",
      "Groups are created with the full master clips": "Grupy tworzone są z pełnych klipów głównych",
      "Reference sub-clips for shortened clips": "Podklipy referencyjne dla skróconych klipów",
      "New avid multigroup type groups": "Nowe, zapalone grupy typu wielogrupowego",
      "Track renaming to group clip names": "Zmiana nazwy ścieżki w celu grupowania nazw klipów",
      "Features": "Cechy",
      "AAF uploads": "Przesyłanie plików AAF",
      "AAF upload": "Przesyłanie AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Grupy są tworzone jako nowe grupy Avid z czarnymi odstępami pomiędzy klipami na tej samej ścieżce. Nowa grupa jest tworzona po spacji na osi czasu.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Zmienia nazwy ścieżek audio w grupach zgodnie z nazwami ścieżek w sekwencji. Możesz otworzyć dowolną liczbę ścieżek, grupy zostaną utworzone zgodnie ze źródłową ścieżką audio.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "ZACHOWAJ AUDIO WŁĄCZONE: Łączy grupy zawierające końcowy dźwięk w końcową sekwencję.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Kieruje wszystkie ścieżki audio w grupie do sekwencji w kolejności. Użyj tej opcji, jeśli masz dźwięk z więcej niż jednego urządzenia.",
      "CLASSIC: Classic AVID grouping": "KLASYCZNY: Klasyczne grupowanie AVID",
      "Process type unknown": "Typ procesu nieznany",
      "No clips found on sequence": "W sekwencji nie znaleziono żadnych klipów",
      "No clips for writing sequence": "Brak klipów do zapisu sekwencji",
      "No sequence found in AAF file": "W pliku AAF nie znaleziono sekwencji",
      "File is to big reduce AAF file": "Plik jest zbyt duży, aby zmniejszyć plik AAF",
      "Sequence has length of 0": "Sekwencja ma długość 0",
      "Group clip found on sequence": "W sekwencji znaleziono klip grupowy",
      "Group clip found on sequence 2": "Znaleziono klip grupowy w sekwencji 2",
      "Unknown selector type found in sequence": "Znaleziono nieznany typ selektora w sekwencji",
      "Clip framerate does not match sequence framerate": "Liczba klatek na sekundę klipu nie jest zgodna z liczbą klatek na sekundę w sekwencji",
      "Subclips with motion effects are not supported": "Podklipy z efektami ruchu nie są obsługiwane",
      "Join the best": "Dołącz do najlepszych",
      "You are in good hands.": "Jesteś w dobrych rękach.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Asystentni montażyści tych produkcji są już szczęśliwymi Tooliumerami, bardziej produktywnymi i oszczędzającymi czas",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Wytnij i edytuj wszystkie swoje referencje lub dokumenty bezpośrednio z dokumentu Microsoft Word lub Dokumentów Google, po prostu zaznaczając tekst i przesyłając plik, korzystając z naszego procesu",
      "Renames audio tracks within the group clips": "Zmienia nazwy ścieżek audio w klipach grupowych",
      "Creates the new avid multigroup type groups": "Tworzy nowe grupy typu Avid Multigroup",
      "Creates avid sub-clips for shortened clips": "Tworzy ciekawe podklipy dla skróconych klipów",
      "Editor": "Redaktor",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Chcesz edytować w przeglądarce, korzystając z naszego edytora kompatybilnego z Avid Media Composer?",
      "Yes, join pre launch": "Tak, dołącz przed uruchomieniem",
      "No I don't want": "Nie, nie chcę",
      "Click to Upload AAF": "Kliknij, aby przesłać plik AAF",
      "Bin panel": "Panel pojemnika",
      "Go to Previous Event": "Przejdź do poprzedniego wydarzenia",
      "Go to Next Event": "Przejdź do następnego wydarzenia",
      "Step Backward 1 Fram": "Krok wstecz o 1 klatkę",
      "Step Forward 1 Fram": "Krok do przodu o 1 klatkę",
      "Mark IN": "Zaznacz",
      "Play": "Grać",
      "Mark OUT": "Wyróżniać",
      "Mark Clip": "Marka Klip",
      "Clear Both Marks": "Usuń oba znaki",
      "Add Marker": "Dodaj znacznik",
      "Swap Cam Bank": "Zmień bank kamer",
      "Find Bin": "Znajdź Bin'a",
      "Gang": "Banda",
      "Step backward 10 frames": "Krok wstecz o 10 klatek",
      "Step forward 10 frames": "Krok do przodu o 10 klatek",
      "Go to IN": "Przejdź do IN",
      "Play Length Toggle": "Przełącznik długości odtwarzania",
      "Go to OUT": "Przejdź do OUT",
      "Match Frame": "Dopasuj ramkę",
      "Reverse Match Frame": "Odwrócona ramka dopasowania",
      "Quad Split": "Poczwórny podział",
      "Nine Split": "Dziewięć Splitów",
      "Step Backward 1 Frame": "Krok wstecz o 1 klatkę",
      "Step Forward 1 Frame": "Krok do przodu o 1 klatkę",
      "Add Edit": "Dodaj Edytuj",
      "Lift": "Winda",
      "Extract": "Wyciąg",
      "Quick Transition": "Szybkie przejście",
      "Remove Effect": "Usuń efekt",
      "Collapse": "Zawalić się",
      "Video Quality Menu": "Menu jakości wideo",
      "Monitor Volume": "Monitoruj głośność",
      "Meter Menu": "Menu miernika",
      "Effect Mode": "Tryb efektu",
      "Trim Mode": "Tryb przycinania",
      "Step In": "Wejść",
      "Step Out": "Wyjdź",
      "Render Effect": "Efekt renderowania",
      "Tool Palette": "Paleta narzędzi",
      "Head Fade": "Zanik Głowy",
      "Tall Fade": "Wysoki Fade",
      "To the Left": "W lewo",
      "To the Right": "W prawo",
      "Select In/Out": "Wybierz Wejście/Wyjście",
      "Link Selection Toggle": "Przełącznik wyboru łącza",
      "HW/SW": "sprzęt/oprogramowanie",
      "Titler Pro": "Tytułownik Pro",
      "Default Setup": "Konfiguracja domyślna",
      "View Type": "Typ widoku",
      "Track Panel": "Panel torów",
      "Effect Icons": "Ikony efektów",
      "Render Rangers": "Renderuj Rangersów",
      "Dissolve Icons": "Rozpuść ikony",
      "Clip Frames": "Ramki klipsowe",
      "Clip Text": "Tekst klipu",
      "Sync Breaks": "Przerwy w synchronizacji",
      "Dupe Detection": "Wykrywanie duplikatów",
      "Color Correction": "Korekta koloru",
      "Audio Data": "Dane dźwiękowe",
      "Clip Color...": "Kolor klipsa...",
      "Segment Selection": "Wybór segmentu",
      "Mark I/O Selection": "Zaznacz wybór we/wy",
      "Track Color": "Kolor ścieżki",
      "Show markers": "Pokaż znaczniki",
      "Show adapters": "Pokaż adaptery",
      "Show Track": "Pokaż utwór",
      "Track Control Panel": "Panel sterowania ścieżki",
      "Zoom": "Powiększenie",
      "Open Comments": "Otwórz komentarze",
      "Call us at": "Zadzwoń do nas o",
      "Office": "Biuro",
      "Tracks Info": "Informacje o utworach",
      "Edit Marker": "Edytuj znacznik",
      "Add": "Dodać",
      "Marker Name": "Nazwa znacznika",
      "Color": "Kolor",
      "Frame": "Rama",
      "Track": "Ścieżka",
      "Clear Monitor": "Wyczyść monitor",
      "Clear Menu": "Wyczyść menu",
      "Show Markers": "Pokaż znaczniki",
      "Show Adapters": "Pokaż adaptery",
      "Toggle Fullscreen": "Przełącz tryb pełnoekranowy",
      "Highlighter": "Zakreślacz",
      "Logger": "Rejestrator",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Nieprawidłowy plik DOC. Zamiast tego użyj DOCX. Możesz wypróbować to narzędzie innej firmy",
      "Open DOC to DOCX converter": "Otwórz konwerter DOC na DOCX",
      "File is missing any cuts, try to click": "W pliku brakuje żadnych cięć, spróbuj kliknąć",
      "SET ALL ROWS": "USTAW WSZYSTKIE WIERSZE",
      "OFF: Timecodes have start and end": "WYŁ.: Kody czasowe mają początek i koniec",
      "ON: No end timecode and just add 30 seconds from start timecode": "WŁ.: Brak kodu czasowego zakończenia, wystarczy dodać 30 sekund od kodu czasowego rozpoczęcia",
      "NO END TIMECODE USE 30 SECONDS": "BRAK KOŃCA KODU CZASOWEGO UŻYJ 30 SEKUND",
      "HAS END TIMECODE": "MA KOŃCOWY KOD CZASOWY",
      "OFF: Create cuts using highlighted text": "WYŁ.: Twórz wycięcia przy użyciu podświetlonego tekstu",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "WŁ.: Twórz cięcia dla wszystkich wierszy w dokumencie. Ignorowanie najważniejszych wydarzeń",
      "ALL ROWS": "WSZYSTKIE RZĘDY",
      "ONLY HIGHLIGHTED": "TYLKO PODŚWIETLONE",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "WYŁ.: Ignoruj ​​nagrania źródłowe. Spowoduje to utworzenie cięć tylko zgodnie ze źródłowym kodem czasowym.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "WŁ.: Użyj źródłowych kamer camroll. Spowoduje to utworzenie cięć zgodnie ze źródłowym kodem czasowym i klipem źródłowym",
      "USE CAMROLL IN DOCX": "UŻYJ CAMROLL W DOCX",
      "IGNORE CAMROLL IN DOCX": "ZIGNORUJ CAMROLL W DOCX",
      "OFF: AAF downloaded will not be renamed": "WYŁ.: Nazwa pobranego pliku AAF nie zostanie zmieniona",
      "ON: Rename AAF to DOCX file name": "WŁ.: Zmień nazwę pliku AAF na DOCX",
      "RENAME AAF": "ZMIEŃ NAZWĘ AAF",
      "NO RENAME": "BEZ ZMIANY NAZWY",
      "Click to Upload DOCX": "Kliknij, aby przesłać plik DOCX",
      "MULTI": "WIELO",
      "CLASSIC": "KLASYCZNY",
      "RENAME ON": "ZMIEŃ NAZWĘ WŁ",
      "RENAME OFF": "ZMIEŃ NAZWĘ WYŁ",
      "KEEP AUDIO ON": "WŁĄCZ DŹWIĘK",
      "KEEP AUDIO OFF": "WYŁĄCZ DŹWIĘK",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GRUPUJ AUDIO W STANIE WŁĄCZONYM: Tworzy grupy ze ścieżkami audio poprowadzonymi dokładnie tak, jak są umieszczone w zsynchronizowanej sekwencji. Użyj tej opcji, jeśli chcesz, aby dźwięk grup był kierowany dokładnie tak, jak sekwencja",
      "GROUP AUDIO AS IS ON": "GRUPUJ AUDIO W STANIE WŁĄCZONYM",
      "GROUP AUDIO AS IS OFF": "GRUPA AUDIO JEST WYŁĄCZONA",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Tworzy grupy z kamerami znajdującymi się na tej samej ścieżce, w jakiej są one umieszczone w zsynchronizowanej sekwencji. Użyj tej opcji, jeśli chcesz, aby kamery pozostawały w tym samym rozmieszczeniu grupowym przez cały czas trwania sesji",
      "KEEP CAM ON TRACK ON": "Utrzymuj kamerę na torze",
      "KEEP CAM ON TRACK OFF": "TRZYMAJ KAMĘ NA TORIE WYŁĄCZONYM",
      "ROUTE AUDIO ON": "WŁĄCZONE DŹWIĘK TRASY",
      "ROUTE AUDIO OFF": "WYŁĄCZONE DŹWIĘK TRASY",
      "Toolium Editor": "Edytor Toolium",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Prześlij plik AAF, a my udostępnimy Ci zdalny edytor kompatybilny z Avid Media Composer, a następnie wygenerujemy pogrupowany plik AAF dla Avid Media Composer",
      "Free forever": "Na zawsze wolny",
      "Collaborate with ease with all the team": "Z łatwością współpracuj z całym zespołem",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Zadbaj o to, aby wszyscy byli na bieżąco, pisząc opinię bezpośrednio w ramce. Lub narysuj to",
      "Look and feel you are use to in Avid Media Composer": "Wygląd i wrażenia, do których przywykłeś w programie Avid Media Composer",
      "Keyboard shortcuts you are use to": "Skróty klawiaturowe, z których korzystasz",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium to centralne centrum, które pozwala efektywnie grupować, dzielić i usprawniać przepływ pracy, dzięki czemu Twój zespół może przyspieszyć Twoją pracę i skrócić czas dostawy o 4 godziny!",
      "A-Side Trim Counter": "Licznik wykończenia strony A",
      "B-Side Trim Counter": "Licznik wykończenia strony B",
      "Insert Track": "Wstaw utwór",
      "OK": "OK",
      "Track Type": "Typ ścieżki",
      "Track Num": "Numer utworu",
      "Mas TC1": "Masa TC1",
      "Dur TC1": "Czas trwania TC1",
      "I/O TC1": "We/Wy TC1",
      "Abs TC1": "ABS TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Masa 24",
      "Dur 24": "Czas 24",
      "I/O 24": "We/Wy 24",
      "Abs 24": "Brzuch 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Masa 25",
      "Dur 25": "Czas 25",
      "I/O 25": "We/Wy 25",
      "Abs 25": "Brzuch 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "We/Wy Fr",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Sekwencja",
      "Timecode": "Kod czasowy",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "We/Wy",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Materiał filmowy",
      "Frames": "Ramki",
      "Source": "Źródło",
      "None": "Nic",
      "Master": "Gospodarz",
      "Duration": "Czas trwania",
      "In/Out": "Wejście/Wyjście",
      "Absolute": "Absolutny",
      "Remain": "Pozostać",
      "Audio Mono": "Dźwięk monofoniczny",
      "Audio Stereo": "Dźwięk stereo",
      "5.1 Surround": "Dźwięk przestrzenny 5.1",
      "7.1 Surround": "Dźwięk przestrzenny 7.1",
      "Data": "Dane",
      "Video": "Wideo",
      "Leave your comment here...": "Zostaw tutaj swój komentarz...",
      "Send": "Wysłać",
      "Workspace": "Obszar roboczy",
      "Project Settings": "Ustawienia projektu",
      "Leave Project": "Opuść projekt",
      "Delete Project": "Usuń projekt",
      "New Project": "Nowy projekt",
      "Name": "Nazwa",
      "Please, input the name": "Proszę wpisać nazwę",
      "Edit Project": "Edytuj projekt",
      "Save Settings": "Zapisz ustawienia",
      "Jump to project of file...": "Skocz do projektu pliku...",
      "Status": "Status",
      "Comments": "Uwagi",
      "Size": "Rozmiar",
      "Date uploaded": "Data przesłania",
      "Uploader": "Przesyłający",
      "Action": "Działanie",
      "File upload": "Udostępnianie pliku",
      "Folder upload": "Przesyłanie folderu",
      "Folder upload as proxy": "Przesyłanie folderu jako serwer proxy",
      "New folder": "Nowy folder",
      "New private folder": "Nowy folder prywatny",
      "Download all": "Ściągnij wszystko",
      "Recently deleted": "Niedawno usunięte",
      "Project settings": "Ustawienia projektu",
      "Rename file": "Zmień nazwę pliku",
      "Move": "Przenosić",
      "Download": "Pobierać",
      "Make private": "Uczynić prywatnym",
      "Reveal in project": "Pokaż w projekcie",
      "Delete": "Usuwać",
      "File Information": "Informacje o pliku",
      "Copy Comments": "Kopiuj komentarze",
      "Paste Comments": "Wklej komentarze",
      "Print": "Wydrukować",
      "Download as File...": "Pobierz jako plik...",
      "CSV": "CSV",
      "XML": "XML-a",
      "Plain Text": "Zwykły tekst",
      "Download for Final Cut Pro X": "Pobierz dla Final Cut Pro X",
      "Download for Media Composer": "Pobierz dla Media Composer",
      "Download for Resolve": "Pobierz dla rozwiązania",
      "No comments": "Bez komentarza",
      "This is premium feature, contact us at support@toolium.org": "To jest funkcja premium. Skontaktuj się z nami pod adresem support@toolium.org",
      "Automated test to speech": "Zautomatyzowany test na mowę",
      "Import from uploads": "Importuj z przesłanych plików",
      "Upload your VO file": "Prześlij swój plik VO",
      "Record voice over": "Nagraj głos",
      "Crop": "Przyciąć",
      "Voiceover": "lektor",
      "Adjust colors": "Dostosuj kolory",
      "Trim": "Przycinać",
      "Speed": "Prędkość",
      "Loop": "Pętla",
      "Flip": "Trzepnięcie",
      "Effects": "Efekty",
      "Animation": "Animacja",
      "Add title": "Dodaj tytuł",
      "Export": "Eksport",
      "Grid": "Siatka",
      "Split": "Podział",
      "Split View": "Podzielony widok",
      "Zoom to fit": "Powiększ, aby dopasować",
      "Audio meter": "Miernik dźwięku",
      "TC": "współwłaściciel",
      "Comment": "Komentarz",
      "Jump to Marker": "Skocz do Markera",
      "Change Color": "Zmień kolor",
      "Change Track": "Zmień utwór",
      "Import Markers": "Importuj znaczniki",
      "Export Markers": "Markery eksportu",
      "Choose Columns": "Wybierz Kolumny",
      "Cameras": "Kamery",
      "Desktop is required": "Wymagany jest komputer stacjonarny",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Zauważyliśmy, że używasz urządzenia z mniejszym ekranem. Aby uzyskać najlepszą jakość, użyj komputera stacjonarnego.",
      "Go back": "Wróć",
      "OFF: Use 0 seconds for handles": "WYŁ.: Użyj 0 sekund dla uchwytów",
      "ON: Use 5 seconds for handles": "WŁ.: Użyj 5 sekund na uchwyty",
      "HANDLES OFF": "UCHWYTY WYŁĄCZONE",
      "HANDLES ON": "UCHWYTY WŁĄCZONE",
      "OFF: word-per-second will be 0.37": "WYŁ.: słowo na sekundę będzie wynosić 0,37",
      "ON: word-per-second will be detected": "WŁ.: wykrywane będzie słowo na sekundę",
      "DETECT WPS": "WYKRYJ WPS",
      "WPS IS 0.37": "WPS wynosi 0,37",
      "OFF: don't change order or merge cuts": "WYŁĄCZONE: nie zmieniaj kolejności ani nie łącz cięć",
      "ON: reduce cuts by merging and sorting may change order": "WŁ.: redukcja cięć poprzez łączenie i sortowanie może zmienić kolejność",
      "USE REDUCE": "UŻYJ REDUKUJ",
      "KEEP ORDER AND CUTS": "ZACHOWAJ PORZĄDEK I CIĘCIA",
      "Timecode Errors": "Błędy kodu czasowego",
      "Timecodes": "Kody czasowe",
      "Source ID": "Identyfikator źródła",
      "Error": "Błąd",
      "Log Out": "Wyloguj",
      "Sign Up": "Zapisać się",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Asystenci redaktorów tych produkcji są już szczęśliwymi Tooliumerami, bardziej produktywnymi i oszczędzającymi czas",
      "Redo": "Przerobić",
      "Undo": "Cofnij",
      "You have no credits left": "Nie masz już żadnych kredytów",
      "You need to buy more credits in order to keep using our platform": "Aby móc nadal korzystać z naszej platformy, musisz kupić więcej kredytów",
      "Note": "Notatka",
      "You will still get free daily credits even if you don't make a purchase": "Nadal będziesz otrzymywać darmowe kredyty dziennie, nawet jeśli nie dokonasz zakupu",
      "Benefits of buying credits": "Korzyści z zakupu kredytów",
      "Bulk discount (up to 50%)": "Rabat hurtowy (do 50%)",
      "Get to request features to fit your worksflow": "Możesz poprosić o funkcje pasujące do Twojego przepływu pracy",
      "You only pay for what you use": "Płacisz tylko za to, z czego korzystasz",
      "No monthly membership and no automatic recurring payments": "Brak miesięcznego członkostwa i automatycznych płatności cyklicznych",
      "You get to support our site": "Możesz wesprzeć naszą stronę",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Jeśli naprawdę potrzebujesz więcej kredytów, a nie masz pieniędzy, wyślij nam esej wyjaśniający, dlaczego powinniśmy dać Ci darmowe kredyty",
      "Buy credits": "Kup kredyty",
      "Ask for free credits, I don't have any money": "Zapytaj o darmowe kredyty, nie mam pieniędzy",
      "Signup with Google to get 500 free credits": "Zarejestruj się w Google, aby otrzymać 500 darmowych kredytów",
      "You will also get 150 additional free credits every day": "Codziennie otrzymasz także 150 dodatkowych darmowych kredytów",
      "Select": "Wybierać",
      "Credits": "Kredyty",
      "1 cut with Cutter = 20 credits": "1 cięcie nożem = 20 kredytów",
      "Discount": "Rabat",
      "Diamond": "Diament",
      "Enterprise": "Przedsiębiorstwo",
      "7 days money back guarantee": "7-dniowa gwarancja zwrotu pieniędzy",
      "For any questions or issues": "W przypadku jakichkolwiek pytań lub problemów",
      "Free": "Bezpłatny",
      "Gold": "Złoto",
      "1 group with Grouper": "1 grupa z Grouperem",
      "1 cut with Cutter": "1 cięcie nożem"
    }
  },
  "it": {
    "translation": {
      "Home": "Casa",
      "Grouper": "Cernia",
      "GROUPER": "CERNIA",
      "Logout": "Disconnettersi",
      "Profile": "Profilo",
      "Onboard": "A bordo",
      "Cutter": "Taglierina",
      "Blog": "Blog",
      "Contact Us": "Contattaci",
      "Login": "Login",
      "Create group clips": "Crea clip di gruppo",
      "made easy": "reso facile",
      "Free Get started": "Inizia",
      "14 days free. No risk.": "14 giorni gratuiti. Nessun rischio.",
      "Signup Free": "Iscriviti gratis",
      "Contact us with any question at": "Contattaci per qualsiasi domanda a",
      "Just one more step before you start Grouping": "Solo un altro passaggio prima di iniziare il raggruppamento",
      "First name": "Nome di battesimo",
      "What is your first name": "Qual è il tuo nome",
      "Please input your first name!": "Per favore inserisci il tuo nome!",
      "Last/family name": "Cognome/cognome",
      "What is your last/family name": "Qual è il tuo cognome/cognome?",
      "Please input your last/family name!": "Per favore inserisci il tuo cognome/cognome!",
      "Nickname": "Soprannome",
      "What do you want others to call you?": "Come vuoi che gli altri ti chiamino?",
      "Please input your nickname!": "Per favore inserisci il tuo nickname!",
      "Phone Number": "Numero di telefono",
      "What is your phone number?": "Qual è il tuo numero di telefono?",
      "Please input your phone number! Example +18777755232": "Per favore inserisci il tuo numero di telefono! Esempio +18777755232",
      "I agree to the": "sono d'accordo con",
      "privacy policy": "politica sulla riservatezza",
      "terms of service": "Termini di servizio",
      "disclaimer": "disclaimer",
      "Privacy policy": "Politica sulla riservatezza",
      "Terms of service": "Termini di servizio",
      "Disclaimer": "Disclaimer",
      "Submit": "Invia",
      "Contact your Toolium representative to buy more credits": "Contatta il tuo rappresentante Toolium per acquistare più crediti",
      "See your credits in profile": "Vedi i tuoi crediti nel profilo",
      "Contact your Toolium representative to activate your account": "Contatta il tuo rappresentante Toolium per attivare il tuo account",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "carica un file AAF della tua sequenza sincronizzata e ti produrremo un file AAF raggruppato per Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Fare clic per caricare o trascinare e rilasciare un AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Vai e verifica la tua email in modo da poter continuare a utilizzare il sito web. Ti abbiamo inviato un'e-mail con un collegamento su cui fare clic.",
      "Resend verification email": "Invia nuovamente email di verifica",
      "Call us at +1-877-775-5232": "Chiamaci al +1-877-775-5232",
      "Plan": "Piano",
      "PLANS": "PIANI",
      "Choose a plan that works for you": "Scegli un piano che funzioni per te",
      "Basic": "Di base",
      "credit": "credito",
      "year": "anno",
      "Billed annually.": "Fatturati ogni anno.",
      "Contact us": "Contattaci",
      "Recommended": "Consigliato",
      "Standard": "Standard",
      "credits": "crediti",
      "Billed annually. Save": "Fatturati ogni anno. Salva",
      "Premium": "Premio",
      "Toolium Cutter": "Taglierina per strumenti",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Taglia e modifica tutte le tue testimonianze o documenti direttamente dal tuo documento Microsoft Word o Google Docs semplicemente evidenziando il testo e caricando il file utilizzando il nostro processo",
      "Automate uncreative tasks": "Automatizza le attività non creative",
      "Save money processing media": "Risparmia denaro nell'elaborazione dei media",
      "Save 4 hours every shoot day": "Risparmia 4 ore ogni giorno di riprese",
      "The next generation of post production workflow tools": "La prossima generazione di strumenti per il flusso di lavoro di postproduzione",
      "14 days free.": "14 giorni gratuiti.",
      "Plans": "Piani",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium è un hub centrale che ti consente di raggruppare, ridurre e semplificare in modo efficiente il flusso di lavoro in modo che il tuo team possa accelerare il lavoro e ridurre la consegna di 4 ore!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Carica un file AAF della tua sequenza sincronizzata e ti produrremo un file AAF raggruppato per Avid Media Composer",
      "Toolium Grouper": "Cernia Toolium",
      "Used in": "Usato in",
      "Groups are created with the full master clips": "I gruppi vengono creati con le clip master complete",
      "Reference sub-clips for shortened clips": "Sottoclip di riferimento per clip abbreviati",
      "New avid multigroup type groups": "Nuovi gruppi di tipo multigruppo accaniti",
      "Track renaming to group clip names": "Rinomina delle tracce in nomi di clip di gruppo",
      "Features": "Caratteristiche",
      "AAF uploads": "Caricamenti AAF",
      "AAF upload": "Caricamento AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: i gruppi vengono creati come i nuovi gruppi Avid con spazi neri tra i clip sulla stessa traccia. Un nuovo gruppo viene creato dopo uno spazio sulla timeline.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: rinomina le tracce audio all'interno dei gruppi in base ai nomi delle tracce della sequenza. Puoi aprire tutte le tracce di cui hai bisogno, i gruppi verranno creati in base alla traccia audio sorgente.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: unisce i gruppi includendo l'audio finale nella sequenza finale.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: indirizza tutte le tracce audio del gruppo alla sequenza in ordine consecutivo. Utilizzalo se disponi di audio da più di un dispositivo.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: raggruppamento AVID classico",
      "Process type unknown": "Tipo di processo sconosciuto",
      "No clips found on sequence": "Nessun clip trovato nella sequenza",
      "No clips for writing sequence": "Nessuna clip per la scrittura della sequenza",
      "No sequence found in AAF file": "Nessuna sequenza trovata nel file AAF",
      "File is to big reduce AAF file": "Il file è troppo ridotto per ridurre il file AAF",
      "Sequence has length of 0": "La sequenza ha lunghezza pari a 0",
      "Group clip found on sequence": "Clip di gruppo trovata nella sequenza",
      "Group clip found on sequence 2": "Clip di gruppo trovata nella sequenza 2",
      "Unknown selector type found in sequence": "Tipo di selettore sconosciuto trovato in sequenza",
      "Clip framerate does not match sequence framerate": "La frequenza fotogrammi della clip non corrisponde alla frequenza fotogrammi della sequenza",
      "Subclips with motion effects are not supported": "Le clip secondarie con effetti di movimento non sono supportate",
      "Join the best": "Unisciti ai migliori",
      "You are in good hands.": "Sei in buone mani.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Gli assistenti al montaggio di queste produzioni sono già Tooliumer felici, più produttivi e che risparmiano tempo",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Taglia e modifica tutte le tue testimonianze o documenti direttamente dal tuo documento Microsoft Word o Google Docs semplicemente evidenziando il tuo testo e caricando il tuo file utilizzando il nostro processo",
      "Renames audio tracks within the group clips": "Rinomina le tracce audio all'interno dei clip del gruppo",
      "Creates the new avid multigroup type groups": "Crea i nuovi gruppi di tipo multigroup Avid",
      "Creates avid sub-clips for shortened clips": "Crea sub-clip accattivanti per clip abbreviati",
      "Editor": "Editore",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Vuoi modificare nel tuo browser utilizzando il nostro editor compatibile con Avid Media Composer?",
      "Yes, join pre launch": "Sì, iscriviti prima del lancio",
      "No I don't want": "No, non voglio",
      "Click to Upload AAF": "Fare clic per caricare AAF",
      "Bin panel": "Pannello del cestino",
      "Go to Previous Event": "Vai all'evento precedente",
      "Go to Next Event": "Vai all'evento successivo",
      "Step Backward 1 Fram": "Passo indietro di 1 fram",
      "Step Forward 1 Fram": "Passo avanti di 1 fram",
      "Mark IN": "Segna IN",
      "Play": "Giocare",
      "Mark OUT": "Segnare",
      "Mark Clip": "Segna clip",
      "Clear Both Marks": "Cancella entrambi i marchi",
      "Add Marker": "Aggiungi marcatore",
      "Swap Cam Bank": "Scambia Cam Bank",
      "Find Bin": "Trova cestino",
      "Gang": "Banda",
      "Step backward 10 frames": "Torna indietro di 10 fotogrammi",
      "Step forward 10 frames": "Fai un passo avanti di 10 fotogrammi",
      "Go to IN": "Vai all'IN",
      "Play Length Toggle": "Attiva/disattiva la durata della riproduzione",
      "Go to OUT": "Vai su OUT",
      "Match Frame": "Corrispondenza cornice",
      "Reverse Match Frame": "Cornice di corrispondenza inversa",
      "Quad Split": "Quadrupla divisione",
      "Nine Split": "Nove Spaccature",
      "Step Backward 1 Frame": "Passo indietro di 1 fotogramma",
      "Step Forward 1 Frame": "Passo avanti di 1 fotogramma",
      "Add Edit": "Aggiungi Modifica",
      "Lift": "Sollevare",
      "Extract": "Estratto",
      "Quick Transition": "Transizione rapida",
      "Remove Effect": "Rimuovi effetto",
      "Collapse": "Crollo",
      "Video Quality Menu": "Menù qualità video",
      "Monitor Volume": "Monitorare il volume",
      "Meter Menu": "Menù misuratore",
      "Effect Mode": "Modalità effetto",
      "Trim Mode": "Modalità di ritaglio",
      "Step In": "Intervenire",
      "Step Out": "Uscire",
      "Render Effect": "Effetto di rendering",
      "Tool Palette": "Tavolozza degli strumenti",
      "Head Fade": "Dissolvenza della testa",
      "Tall Fade": "Dissolvenza alta",
      "To the Left": "A sinistra",
      "To the Right": "A destra",
      "Select In/Out": "Seleziona Entrata/Uscita",
      "Link Selection Toggle": "Attiva/disattiva la selezione del collegamento",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titolatore Pro",
      "Default Setup": "Configurazione predefinita",
      "View Type": "Visualizza tipo",
      "Track Panel": "Pannello Traccia",
      "Effect Icons": "Icone degli effetti",
      "Render Rangers": "Rendering Rangers",
      "Dissolve Icons": "Sciogliere le icone",
      "Clip Frames": "Cornici a clip",
      "Clip Text": "Ritaglia testo",
      "Sync Breaks": "Interruzioni di sincronizzazione",
      "Dupe Detection": "Rilevamento duplicati",
      "Color Correction": "Correzione del colore",
      "Audio Data": "Dati audio",
      "Clip Color...": "Colore clip...",
      "Segment Selection": "Selezione del segmento",
      "Mark I/O Selection": "Contrassegnare la selezione I/O",
      "Track Color": "Traccia colore",
      "Show markers": "Mostra marcatori",
      "Show adapters": "Mostra adattatori",
      "Show Track": "Mostra traccia",
      "Track Control Panel": "Pannello di controllo della traccia",
      "Zoom": "Ingrandisci",
      "Open Comments": "Apri commenti",
      "Call us at": "Chiamaci al",
      "Office": "Ufficio",
      "Tracks Info": "Informazioni sulle tracce",
      "Edit Marker": "Modifica marcatore",
      "Add": "Aggiungere",
      "Marker Name": "Nome del marcatore",
      "Color": "Colore",
      "Frame": "Telaio",
      "Track": "Traccia",
      "Clear Monitor": "Cancella monitor",
      "Clear Menu": "Cancella Menù",
      "Show Markers": "Mostra marcatori",
      "Show Adapters": "Mostra adattatori",
      "Toggle Fullscreen": "Passare a schermo intero",
      "Highlighter": "Evidenziatore",
      "Logger": "Registratore",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "File DOC non valido, usa invece DOCX, puoi provare questo strumento di terze parti",
      "Open DOC to DOCX converter": "Apri il convertitore da DOC a DOCX",
      "File is missing any cuts, try to click": "Nel file mancano dei tagli, prova a fare clic",
      "SET ALL ROWS": "IMPOSTA TUTTE LE RIGHE",
      "OFF: Timecodes have start and end": "OFF: i codici temporali hanno inizio e fine",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON: nessun timecode finale e basta aggiungere 30 secondi dal timecode iniziale",
      "NO END TIMECODE USE 30 SECONDS": "NESSUN TIMECODE FINE USA 30 SECONDI",
      "HAS END TIMECODE": "HA IL TIMECODE FINE",
      "OFF: Create cuts using highlighted text": "OFF: crea tagli utilizzando il testo evidenziato",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON: crea tagli per tutte le righe del documento. Ignorando i punti salienti",
      "ALL ROWS": "TUTTE LE RIGHE",
      "ONLY HIGHLIGHTED": "SOLO EVIDENZIATO",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "OFF: ignora i camroll di origine. Ciò creerà tagli solo in base al timecode sorgente.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: utilizza i camroll di origine. Ciò creerà tagli in base al timecode sorgente e al camroll della clip sorgente",
      "USE CAMROLL IN DOCX": "USA CAMROLL IN DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORA CAMROLL IN DOCX",
      "OFF: AAF downloaded will not be renamed": "OFF: l'AAF scaricato non verrà rinominato",
      "ON: Rename AAF to DOCX file name": "ON: rinomina AAF in nome file DOCX",
      "RENAME AAF": "RINOMINARE AAF",
      "NO RENAME": "NESSUN RINOMINATO",
      "Click to Upload DOCX": "Fare clic per caricare DOCX",
      "MULTI": "MULTI",
      "CLASSIC": "CLASSICO",
      "RENAME ON": "RINOMINARE ON",
      "RENAME OFF": "RINOMINARE DISATTIVATO",
      "KEEP AUDIO ON": "MANTENERE L'AUDIO ATTIVO",
      "KEEP AUDIO OFF": "MANTENERE L'AUDIO DISATTIVATO",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "AUDIO DI GRUPPO COME È ATTIVO: crea gruppi con le tracce audio instradate esattamente come sono posizionate nella sequenza sincronizzata. Usalo se vuoi che l'audio del tuo gruppo venga instradato esattamente come la tua sequenza",
      "GROUP AUDIO AS IS ON": "AUDIO DI GRUPPO COME È ATTIVO",
      "GROUP AUDIO AS IS OFF": "AUDIO DI GRUPPO COME È DISATTIVATO",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: crea gruppi con angolazioni della telecamera sulla stessa traccia in cui sono posizionati nella sequenza sincronizzata. Usalo se vuoi che le tue fotocamere rimangano nello stesso posizionamento di gruppo durante l'intera ripresa",
      "KEEP CAM ON TRACK ON": "MANTENERE LA CAMMA SULLA PISTA",
      "KEEP CAM ON TRACK OFF": "MANTENERE LA CAMMA SULLA PISTA",
      "ROUTE AUDIO ON": "AUDIO DEL PERCORSO ATTIVO",
      "ROUTE AUDIO OFF": "AUDIO DEL PERCORSO DISATTIVATO",
      "Toolium Editor": "Editore di Toolium",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Carica un file AAF e ti forniremo un editor remoto compatibile con Avid Media Composer e successivamente ti stamperemo un file AAF raggruppato per Avid Media Composer",
      "Free forever": "Libero per sempre",
      "Collaborate with ease with all the team": "Collabora con facilità con tutto il team",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Mantieni tutti sulla stessa lunghezza d'onda scrivendo il feedback direttamente su un frame. Oppure disegnalo",
      "Look and feel you are use to in Avid Media Composer": "Aspetto e sensazione a cui sei abituato in Avid Media Composer",
      "Keyboard shortcuts you are use to": "Scorciatoie da tastiera a cui sei abituato",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium è un hub centrale che ti consente di raggruppare, tagliare e semplificare in modo efficiente il tuo flusso di lavoro in modo che il tuo team possa accelerare il lavoro e ridurre la consegna di 4 ore!",
      "A-Side Trim Counter": "Contatore di rifinitura del lato A",
      "B-Side Trim Counter": "Contatore di rifinitura del lato B",
      "Insert Track": "Inserisci traccia",
      "OK": "OK",
      "Track Type": "Tipo di traccia",
      "Track Num": "N. traccia",
      "Mas TC1": "MasTC1",
      "Dur TC1": "DurTC1",
      "I/O TC1": "I/OTC1",
      "Abs TC1": "ABSTC1",
      "Rem TC1": "RemTC1",
      "Mas 24": "Mas 24",
      "Dur 24": "Dur 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Addominali 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Mas 25",
      "Dur 25": "Dur 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Addominali 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Fr",
      "Dur Frm": "Dur Fr",
      "I/O Frm": "I/O da",
      "Abs Frm": "Abs Fr",
      "Rem Frm": "Rem Fr",
      "Sequence": "Sequenza",
      "Timecode": "Codice temporale",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "I/O",
      "Abs": "Addominali",
      "Rem": "REM",
      "Footage": "Filmato",
      "Frames": "Cornici",
      "Source": "Fonte",
      "None": "Nessuno",
      "Master": "Maestro",
      "Duration": "Durata",
      "In/Out": "Dentro fuori",
      "Absolute": "Assoluto",
      "Remain": "Rimani",
      "Audio Mono": "Audio mono",
      "Audio Stereo": "Audio stereo",
      "5.1 Surround": "5.1 Circondare",
      "7.1 Surround": "7.1 Surround",
      "Data": "Dati",
      "Video": "video",
      "Leave your comment here...": "Lascia qui il tuo commento...",
      "Send": "Inviare",
      "Workspace": "Spazio di lavoro",
      "Project Settings": "Impostazioni del progetto",
      "Leave Project": "Abbandona il progetto",
      "Delete Project": "Elimina progetto",
      "New Project": "Nuovo progetto",
      "Name": "Nome",
      "Please, input the name": "Per favore, inserisci il nome",
      "Edit Project": "Modifica progetto",
      "Save Settings": "Salva le impostazioni",
      "Jump to project of file...": "Vai al progetto del file...",
      "Status": "Stato",
      "Comments": "Commenti",
      "Size": "Misurare",
      "Date uploaded": "Data di caricamento",
      "Uploader": "Caricatore",
      "Action": "Azione",
      "File upload": "Upload di file",
      "Folder upload": "Caricamento cartella",
      "Folder upload as proxy": "Caricamento della cartella come proxy",
      "New folder": "Nuova cartella",
      "New private folder": "Nuova cartella privata",
      "Download all": "Scarica tutto",
      "Recently deleted": "Eliminato di recente",
      "Project settings": "Impostazioni del progetto",
      "Rename file": "Rinomina il file",
      "Move": "Mossa",
      "Download": "Scaricamento",
      "Make private": "Rendere privato",
      "Reveal in project": "Rivela nel progetto",
      "Delete": "Eliminare",
      "File Information": "Informazioni sul file",
      "Copy Comments": "Copia commenti",
      "Paste Comments": "Incolla commenti",
      "Print": "Stampa",
      "Download as File...": "Scarica come file...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Testo semplice",
      "Download for Final Cut Pro X": "Scarica per Final Cut Pro X",
      "Download for Media Composer": "Scarica per Media Composer",
      "Download for Resolve": "Scarica per risolvere",
      "No comments": "Non ci sono commenti",
      "This is premium feature, contact us at support@toolium.org": "Questa è una funzionalità premium, contattaci all'indirizzo support@toolium.org",
      "Automated test to speech": "Test automatizzato per parlare",
      "Import from uploads": "Importa dai caricamenti",
      "Upload your VO file": "Carica il tuo file VO",
      "Record voice over": "Registra la voce fuori campo",
      "Crop": "Raccolto",
      "Voiceover": "Voce fuoricampo",
      "Adjust colors": "Regola i colori",
      "Trim": "Ordinare",
      "Speed": "Velocità",
      "Loop": "Ciclo continuo",
      "Flip": "Flip",
      "Effects": "Effetti",
      "Animation": "Animazione",
      "Add title": "Aggiungi titolo",
      "Export": "Esportare",
      "Grid": "Griglia",
      "Split": "Diviso",
      "Split View": "Vista divisa",
      "Zoom to fit": "Zoom per adattare",
      "Audio meter": "Misuratore audio",
      "TC": "TC",
      "Comment": "Commento",
      "Jump to Marker": "Vai al contrassegno",
      "Change Color": "Cambia colore",
      "Change Track": "Cambia traccia",
      "Import Markers": "Importa marcatori",
      "Export Markers": "Indicatori di esportazione",
      "Choose Columns": "Scegli Colonne",
      "Cameras": "Macchine fotografiche",
      "Desktop is required": "È richiesto il desktop",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Abbiamo notato che stai utilizzando un dispositivo con schermo più piccolo. Per un'esperienza ottimale, utilizza un desktop.",
      "Go back": "Torna indietro",
      "OFF: Use 0 seconds for handles": "OFF: utilizzare 0 secondi per le maniglie",
      "ON: Use 5 seconds for handles": "ON: utilizzare 5 secondi per le maniglie",
      "HANDLES OFF": "MANIGLIE FUORI",
      "HANDLES ON": "MANIGLIE SU",
      "OFF: word-per-second will be 0.37": "OFF: la parola al secondo sarà 0,37",
      "ON: word-per-second will be detected": "ON: verrà rilevata la parola al secondo",
      "DETECT WPS": "RILEVA WPS",
      "WPS IS 0.37": "Il WPS È 0,37",
      "OFF: don't change order or merge cuts": "OFF: non cambia l'ordine né unisce i tagli",
      "ON: reduce cuts by merging and sorting may change order": "ON: riduce i tagli unendoli e l'ordinamento può modificare l'ordine",
      "USE REDUCE": "UTILIZZARE RIDURRE",
      "KEEP ORDER AND CUTS": "MANTENERE ORDINE E TAGLI",
      "Timecode Errors": "Errori del codice temporale",
      "Timecodes": "Codici temporali",
      "Source ID": "Identificativo della fonte",
      "Error": "Errore",
      "Log Out": "Disconnettersi",
      "Sign Up": "Iscrizione",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Gli assistenti al montaggio di queste produzioni sono già Tooliumer felici, più produttivi e che risparmiano tempo",
      "Redo": "Rifare",
      "Undo": "Disfare",
      "You have no credits left": "Non hai più crediti",
      "You need to buy more credits in order to keep using our platform": "È necessario acquistare più crediti per continuare a utilizzare la nostra piattaforma",
      "Note": "Nota",
      "You will still get free daily credits even if you don't make a purchase": "Riceverai comunque crediti giornalieri gratuiti anche se non effettui un acquisto",
      "Benefits of buying credits": "Vantaggi dell'acquisto di crediti",
      "Bulk discount (up to 50%)": "Sconto quantità (fino al 50%)",
      "Get to request features to fit your worksflow": "Richiedi funzionalità adatte al tuo flusso di lavoro",
      "You only pay for what you use": "Paghi solo per quello che usi",
      "No monthly membership and no automatic recurring payments": "Nessun abbonamento mensile e nessun pagamento ricorrente automatico",
      "You get to support our site": "Puoi supportare il nostro sito",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Se hai davvero bisogno di più crediti e non hai soldi, inviaci un saggio spiegando perché dovremmo darti crediti gratuiti",
      "Buy credits": "Comprare crediti",
      "Ask for free credits, I don't have any money": "Richiedi crediti gratuiti, non ho soldi",
      "Signup with Google to get 500 free credits": "Iscriviti con Google per ottenere 500 crediti gratuiti",
      "You will also get 150 additional free credits every day": "Riceverai inoltre 150 crediti gratuiti aggiuntivi ogni giorno",
      "Select": "Selezionare",
      "Credits": "Crediti",
      "1 cut with Cutter = 20 credits": "1 taglio con Cutter = 20 crediti",
      "Discount": "Sconto",
      "Diamond": "Diamante",
      "Enterprise": "Impresa",
      "7 days money back guarantee": "Garanzia di rimborso di 7 giorni",
      "For any questions or issues": "Per qualsiasi domanda o problema",
      "Free": "Gratuito",
      "Gold": "Oro",
      "1 group with Grouper": "1 gruppo con Cernia",
      "1 cut with Cutter": "1 taglio con taglierino"
    }
  },
  "pt": {
    "translation": {
      "Home": "Lar",
      "Grouper": "Garoupa",
      "GROUPER": "GAROUPA",
      "Logout": "Sair",
      "Profile": "Perfil",
      "Onboard": "A bordo",
      "Cutter": "Cortador",
      "Blog": "Blogue",
      "Contact Us": "Contate-nos",
      "Login": "Conecte-se",
      "Create group clips": "Crie clipes de grupo",
      "made easy": "faça facil",
      "Free Get started": "Grátis Comece",
      "14 days free. No risk.": "14 dias grátis. Sem risco.",
      "Signup Free": "Inscreva-se gratuitamente",
      "Contact us with any question at": "Contate-nos com qualquer dúvida em",
      "Just one more step before you start Grouping": "Só mais uma etapa antes de começar a agrupar",
      "First name": "Primeiro nome",
      "What is your first name": "Qual é o seu primeiro nome",
      "Please input your first name!": "Por favor insira seu primeiro nome!",
      "Last/family name": "Último nome de família",
      "What is your last/family name": "Qual é o seu sobrenome/nome de família",
      "Please input your last/family name!": "Por favor, insira seu sobrenome/nome de família!",
      "Nickname": "Apelido",
      "What do you want others to call you?": "Como você quer que os outros te chamem?",
      "Please input your nickname!": "Por favor insira seu apelido!",
      "Phone Number": "Número de telefone",
      "What is your phone number?": "Qual é o seu número de telefone?",
      "Please input your phone number! Example +18777755232": "Por favor insira seu número de telefone! Exemplo +18777755232",
      "I agree to the": "Estou de acordo com",
      "privacy policy": "política de Privacidade",
      "terms of service": "termos de serviço",
      "disclaimer": "isenção de responsabilidade",
      "Privacy policy": "Política de Privacidade",
      "Terms of service": "Termos de serviço",
      "Disclaimer": "Isenção de responsabilidade",
      "Submit": "Enviar",
      "Contact your Toolium representative to buy more credits": "Contate seu representante Toolium para comprar mais créditos",
      "See your credits in profile": "Veja seus créditos no perfil",
      "Contact your Toolium representative to activate your account": "Entre em contato com seu representante Toolium para ativar sua conta",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "carregue um arquivo AAF de sua sequência sincronizada e nós lhe enviaremos um arquivo AAF agrupado para o Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Clique para fazer upload ou arraste e solte um AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Por favor, verifique seu e-mail para poder continuar usando o site. Enviamos um e-mail para você com um link para clicar.",
      "Resend verification email": "Reenviar email de verificação",
      "Call us at +1-877-775-5232": "Ligue para +1-877-775-5232",
      "Plan": "Plano",
      "PLANS": "PLANOS",
      "Choose a plan that works for you": "Escolha um plano que funcione para você",
      "Basic": "Básico",
      "credit": "crédito",
      "year": "ano",
      "Billed annually.": "Cobrado anualmente.",
      "Contact us": "Contate-nos",
      "Recommended": "Recomendado",
      "Standard": "Padrão",
      "credits": "créditos",
      "Billed annually. Save": "Cobrado anualmente. Salvar",
      "Premium": "Prêmio",
      "Toolium Cutter": "Cortador de ferramentas",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Corte e edite todos os seus depoimentos ou documentos diretamente do seu documento do Microsoft Word ou Google Docs, simplesmente editando seu texto e enviando seu arquivo usando nosso processo",
      "Automate uncreative tasks": "Automatize tarefas não criativas",
      "Save money processing media": "Economize dinheiro processando mídia",
      "Save 4 hours every shoot day": "Economize 4 horas todos os dias de filmagem",
      "The next generation of post production workflow tools": "A próxima geração de ferramentas de fluxo de trabalho de pós-produção",
      "14 days free.": "14 dias grátis.",
      "Plans": "Planos",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium é um hub central que permite agrupar, cortar e agilizar seu fluxo de trabalho com eficiência para que sua equipe possa agilizar seu trabalho e reduzir a entrega em 4 horas!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Carregue um arquivo AAF de sua sequência sincronizada e geraremos um arquivo AAF agrupado para o Avid Media Composer",
      "Toolium Grouper": "Garoupa Toolium",
      "Used in": "Usado em",
      "Groups are created with the full master clips": "Os grupos são criados com os clipes mestres completos",
      "Reference sub-clips for shortened clips": "Subclipes de referência para clipes encurtados",
      "New avid multigroup type groups": "Novos grupos ávidos do tipo multigrupo",
      "Track renaming to group clip names": "Rastreie a renomeação para nomes de clipes de grupo",
      "Features": "Características",
      "AAF uploads": "Carregamentos AAF",
      "AAF upload": "Carregar AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Os grupos são criados como os novos grupos ávidos com espaços pretos entre os clipes na mesma trilha. Um novo grupo é criado após um espaço na linha do tempo.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Renomeia as trilhas de áudio dentro dos grupos de acordo com os nomes das trilhas em sequência. Você pode abrir quantas trilhas precisar, os grupos serão criados de acordo com a trilha de áudio fonte.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: Une grupos incluindo o áudio final na sequência final.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Roteia todas as faixas de áudio do grupo para a sequência em ordem consecutiva. Use isto se você tiver áudio de mais de um dispositivo.",
      "CLASSIC: Classic AVID grouping": "CLÁSSICO: agrupamento AVID clássico",
      "Process type unknown": "Tipo de processo desconhecido",
      "No clips found on sequence": "Nenhum clipe encontrado na sequência",
      "No clips for writing sequence": "Sem clipes para escrever sequência",
      "No sequence found in AAF file": "Nenhuma sequência encontrada no arquivo AAF",
      "File is to big reduce AAF file": "O arquivo está muito reduzido no arquivo AAF",
      "Sequence has length of 0": "A sequência tem comprimento 0",
      "Group clip found on sequence": "Clipe de grupo encontrado na sequência",
      "Group clip found on sequence 2": "Clipe de grupo encontrado na sequência 2",
      "Unknown selector type found in sequence": "Tipo de seletor desconhecido encontrado em sequência",
      "Clip framerate does not match sequence framerate": "A taxa de quadros do clipe não corresponde à taxa de quadros da sequência",
      "Subclips with motion effects are not supported": "Subclipes com efeitos de movimento não são suportados",
      "Join the best": "Junte-se aos melhores",
      "You are in good hands.": "Você está em boas mãos.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Os editores assistentes dessas produções já são Tooliumers satisfeitos, mais produtivos e economizando tempo",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Corte e edite todos os seus depoimentos ou documentos diretamente do seu documento do Microsoft Word ou Google Docs, simplesmente destacando seu texto e enviando seu arquivo usando nosso processo",
      "Renames audio tracks within the group clips": "Renomeia faixas de áudio nos clipes do grupo",
      "Creates the new avid multigroup type groups": "Cria os novos grupos do tipo multigrupo ávido",
      "Creates avid sub-clips for shortened clips": "Cria subclipes ávidos para clipes encurtados",
      "Editor": "editor",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Quer editar em seu navegador usando nosso editor compatível com Avid Media Composer?",
      "Yes, join pre launch": "Sim, participe do pré-lançamento",
      "No I don't want": "Não, eu não quero",
      "Click to Upload AAF": "Clique para fazer upload do AAF",
      "Bin panel": "Painel do compartimento",
      "Go to Previous Event": "Ir para o evento anterior",
      "Go to Next Event": "Ir para o próximo evento",
      "Step Backward 1 Fram": "Retroceder 1 quadro",
      "Step Forward 1 Fram": "Avançar 1 quadro",
      "Mark IN": "Marca em",
      "Play": "Jogar",
      "Mark OUT": "Marcar FORA",
      "Mark Clip": "Marcar clipe",
      "Clear Both Marks": "Limpar ambas as marcas",
      "Add Marker": "Adicionar marcador",
      "Swap Cam Bank": "Banco de troca de câmeras",
      "Find Bin": "Encontrar lixeira",
      "Gang": "Gangue",
      "Step backward 10 frames": "Retroceder 10 quadros",
      "Step forward 10 frames": "Avance 10 quadros",
      "Go to IN": "Vá para IN",
      "Play Length Toggle": "Alternar duração da reprodução",
      "Go to OUT": "Vá para FORA",
      "Match Frame": "Quadro de correspondência",
      "Reverse Match Frame": "Quadro de correspondência reversa",
      "Quad Split": "Divisão quádrupla",
      "Nine Split": "Nove Divisão",
      "Step Backward 1 Frame": "Retroceder 1 quadro",
      "Step Forward 1 Frame": "Passo à frente 1 quadro",
      "Add Edit": "Adicionar Editar",
      "Lift": "Elevador",
      "Extract": "Extrair",
      "Quick Transition": "Transição Rápida",
      "Remove Effect": "Remover efeito",
      "Collapse": "Colapso",
      "Video Quality Menu": "Menu Qualidade de Vídeo",
      "Monitor Volume": "Monitorar volume",
      "Meter Menu": "Menu Medidor",
      "Effect Mode": "Modo de efeito",
      "Trim Mode": "Modo de corte",
      "Step In": "Entre",
      "Step Out": "Sair",
      "Render Effect": "Efeito de renderização",
      "Tool Palette": "Paleta de ferramentas",
      "Head Fade": "Cabeça desbotada",
      "Tall Fade": "Desvanecimento alto",
      "To the Left": "Para a esquerda",
      "To the Right": "Para a direita",
      "Select In/Out": "Selecione Entrada/Saída",
      "Link Selection Toggle": "Alternar seleção de link",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titulador Pro",
      "Default Setup": "Configuração padrão",
      "View Type": "Tipo de visualização",
      "Track Panel": "Painel de trilha",
      "Effect Icons": "Ícones de efeito",
      "Render Rangers": "Renderizar Rangers",
      "Dissolve Icons": "Dissolver ícones",
      "Clip Frames": "Molduras de clipe",
      "Clip Text": "Clipe de texto",
      "Sync Breaks": "Quebras de sincronização",
      "Dupe Detection": "Detecção de Duplicados",
      "Color Correction": "Correção de cor",
      "Audio Data": "Dados de áudio",
      "Clip Color...": "Cor do clipe...",
      "Segment Selection": "Seleção de segmento",
      "Mark I/O Selection": "Marcar seleção de E/S",
      "Track Color": "Cor da trilha",
      "Show markers": "Mostrar marcadores",
      "Show adapters": "Mostrar adaptadores",
      "Show Track": "Mostrar trilha",
      "Track Control Panel": "Painel de controle de trilha",
      "Zoom": "Ampliação",
      "Open Comments": "Abrir comentários",
      "Call us at": "Ligue para nós em",
      "Office": "Escritório",
      "Tracks Info": "Informações sobre trilhas",
      "Edit Marker": "Editar marcador",
      "Add": "Adicionar",
      "Marker Name": "Nome do marcador",
      "Color": "Cor",
      "Frame": "Quadro",
      "Track": "Acompanhar",
      "Clear Monitor": "Limpar monitor",
      "Clear Menu": "Limpar menu",
      "Show Markers": "Mostrar marcadores",
      "Show Adapters": "Mostrar adaptadores",
      "Toggle Fullscreen": "Alternar para o modo tela cheia",
      "Highlighter": "Marcador",
      "Logger": "Registrador",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Arquivo DOC inválido. Use DOCX. Você pode tentar esta ferramenta de terceiros",
      "Open DOC to DOCX converter": "Abra o conversor DOC para DOCX",
      "File is missing any cuts, try to click": "Falta algum corte no arquivo, tente clicar",
      "SET ALL ROWS": "DEFINIR TODAS AS LINHAS",
      "OFF: Timecodes have start and end": "DESLIGADO: os códigos de tempo têm início e fim",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON: Sem código de tempo final e apenas adicione 30 segundos do código de tempo inicial",
      "NO END TIMECODE USE 30 SECONDS": "SEM END TIMECODE USAR 30 SEGUNDOS",
      "HAS END TIMECODE": "TEM CÓDIGO DE TEMPO DE FIM",
      "OFF: Create cuts using highlighted text": "DESATIVADO: Crie cortes usando texto destacado",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON: Cria cortes para todas as linhas do documento. Ignorando os destaques",
      "ALL ROWS": "TODAS AS LINHAS",
      "ONLY HIGHLIGHTED": "SOMENTE DESTACADO",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "DESLIGADO: Ignora camrolls de origem. Isso criará cortes apenas de acordo com o timecode fonte.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: Use camrolls de origem. Isso criará cortes de acordo com o timecode de origem e o clipe de origem camroll",
      "USE CAMROLL IN DOCX": "USE CAMROLL EM DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORAR CAMROLL EM DOCX",
      "OFF: AAF downloaded will not be renamed": "DESATIVADO: AAF baixado não será renomeado",
      "ON: Rename AAF to DOCX file name": "ON: Renomear AAF para nome de arquivo DOCX",
      "RENAME AAF": "RENOMEAR AAF",
      "NO RENAME": "SEM RENOMEAR",
      "Click to Upload DOCX": "Clique para enviar DOCX",
      "MULTI": "MÚLTIPLO",
      "CLASSIC": "CLÁSSICO",
      "RENAME ON": "RENOMEAR ATIVADO",
      "RENAME OFF": "RENOMEAR DESATIVADO",
      "KEEP AUDIO ON": "MANTENHA O ÁUDIO LIGADO",
      "KEEP AUDIO OFF": "MANTENHA O ÁUDIO DESLIGADO",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO AS IS ON: Cria grupos com as trilhas de áudio roteadas exatamente como foram colocadas em sua sequência sincronizada. Use isto se quiser que o áudio do seu grupo seja roteado exatamente como sua sequência",
      "GROUP AUDIO AS IS ON": "ÁUDIO DE GRUPO COMO ESTÁ LIGADO",
      "GROUP AUDIO AS IS OFF": "ÁUDIO DE GRUPO COMO ESTÁ DESLIGADO",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Cria grupos com ângulos de câmera na mesma trilha em que são colocados em sua sequência sincronizada. Use isto se quiser que suas câmeras permaneçam no mesmo posicionamento de grupo durante toda a filmagem",
      "KEEP CAM ON TRACK ON": "MANTENHA A CAM NO CAMINHO",
      "KEEP CAM ON TRACK OFF": "MANTENHA CAM NA TRILHA DESLIGADA",
      "ROUTE AUDIO ON": "ROTA DE ÁUDIO ATIVADO",
      "ROUTE AUDIO OFF": "ROTA ÁUDIO DESLIGADO",
      "Toolium Editor": "Editor do Toolium",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Carregue um arquivo AAF e forneceremos a você um editor remoto em qualquer lugar compatível com Avid Media Composer e depois geraremos um arquivo AAF agrupado para Avid Media Composer",
      "Free forever": "Grátis para sempre",
      "Collaborate with ease with all the team": "Colabore com facilidade com toda a equipe",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Mantenha todos na mesma página escrevendo comentários diretamente em um quadro. Ou desenhe",
      "Look and feel you are use to in Avid Media Composer": "A aparência que você está acostumado no Avid Media Composer",
      "Keyboard shortcuts you are use to": "Atalhos de teclado que você usa",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium é um hub central que permite agrupar, cortar e agilizar seu fluxo de trabalho com eficiência para que sua equipe possa agilizar seu trabalho e reduzir a entrega em 4 horas!",
      "A-Side Trim Counter": "Contador de acabamento do lado A",
      "B-Side Trim Counter": "Contador de acabamento do lado B",
      "Insert Track": "Inserir faixa",
      "OK": "OK",
      "Track Type": "Tipo de trilha",
      "Track Num": "Número da faixa",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Durante o TC1",
      "I/O TC1": "E/S TC1",
      "Abs TC1": "Abdominais TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "Duração 24",
      "I/O 24": "E/S 24",
      "Abs 24": "Abdominais 24",
      "Rem 24": "Remo 24",
      "Mas 25": "Mas 25",
      "Dur 25": "Duração 25",
      "I/O 25": "E/S 25",
      "Abs 25": "Abdominais 25",
      "Rem 25": "Remo 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "Empresa de E/S",
      "Abs Frm": "Abdominais",
      "Rem Frm": "Rem Frm",
      "Sequence": "Seqüência",
      "Timecode": "Código de tempo",
      "TC1": "TC1",
      "Mas": "mas",
      "Dur": "Dur",
      "I/O": "E/S",
      "Abs": "Abdômen",
      "Rem": "Rem",
      "Footage": "Imagens de vídeo",
      "Frames": "Molduras",
      "Source": "Fonte",
      "None": "Nenhum",
      "Master": "Mestre",
      "Duration": "Duração",
      "In/Out": "Entrada/Saída",
      "Absolute": "Absoluto",
      "Remain": "Permanecer",
      "Audio Mono": "Áudio Mono",
      "Audio Stereo": "Áudio estéreo",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Dados",
      "Video": "Vídeo",
      "Leave your comment here...": "Deixe seu comentário aqui...",
      "Send": "Enviar",
      "Workspace": "Área de trabalho",
      "Project Settings": "Configurações do projeto",
      "Leave Project": "Sair do projeto",
      "Delete Project": "Excluir projeto",
      "New Project": "Novo projeto",
      "Name": "Nome",
      "Please, input the name": "Por favor, insira o nome",
      "Edit Project": "Editar projeto",
      "Save Settings": "Salvar configurações",
      "Jump to project of file...": "Ir para o projeto do arquivo...",
      "Status": "Status",
      "Comments": "Comentários",
      "Size": "Tamanho",
      "Date uploaded": "Data do upload",
      "Uploader": "Carregador",
      "Action": "Ação",
      "File upload": "Carregamento de arquivo",
      "Folder upload": "Carregamento de pasta",
      "Folder upload as proxy": "Upload de pasta como proxy",
      "New folder": "Nova pasta",
      "New private folder": "Nova pasta privada",
      "Download all": "Baixar tudo",
      "Recently deleted": "Excluído recentemente",
      "Project settings": "Configurações do projeto",
      "Rename file": "Renomear arquivo",
      "Move": "Mover",
      "Download": "Download",
      "Make private": "Tornar privado",
      "Reveal in project": "Revelar no projeto",
      "Delete": "Excluir",
      "File Information": "Informações do arquivo",
      "Copy Comments": "Copiar comentários",
      "Paste Comments": "Colar comentários",
      "Print": "Imprimir",
      "Download as File...": "Baixar como arquivo...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Texto simples",
      "Download for Final Cut Pro X": "Baixar para Final Cut Pro X",
      "Download for Media Composer": "Baixar para Media Composer",
      "Download for Resolve": "Baixe para resolver",
      "No comments": "Sem comentários",
      "This is premium feature, contact us at support@toolium.org": "Este é um recurso premium, entre em contato conosco em support@toolium.org",
      "Automated test to speech": "Teste automatizado de fala",
      "Import from uploads": "Importar de uploads",
      "Upload your VO file": "Carregue seu arquivo VO",
      "Record voice over": "Gravar narração",
      "Crop": "Cortar",
      "Voiceover": "Locução",
      "Adjust colors": "Ajustar cores",
      "Trim": "Aparar",
      "Speed": "Velocidade",
      "Loop": "Laço",
      "Flip": "Virar",
      "Effects": "Efeitos",
      "Animation": "Animação",
      "Add title": "Adicionar título",
      "Export": "Exportar",
      "Grid": "Grade",
      "Split": "Dividir",
      "Split View": "Vista dividida",
      "Zoom to fit": "Zoom para ajustar",
      "Audio meter": "Medidor de áudio",
      "TC": "TC",
      "Comment": "Comente",
      "Jump to Marker": "Ir para o marcador",
      "Change Color": "Mudar cor",
      "Change Track": "Alterar faixa",
      "Import Markers": "Importar marcadores",
      "Export Markers": "Exportar marcadores",
      "Choose Columns": "Escolha Colunas",
      "Cameras": "Câmeras",
      "Desktop is required": "A área de trabalho é obrigatória",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Percebemos que você está usando um dispositivo com tela menor. Para a melhor experiência, use um desktop.",
      "Go back": "Volte",
      "OFF: Use 0 seconds for handles": "DESLIGADO: Use 0 segundos para alças",
      "ON: Use 5 seconds for handles": "ON: Use 5 segundos para alças",
      "HANDLES OFF": "ALÇAS DESLIGADAS",
      "HANDLES ON": "ALÇAS LIGADAS",
      "OFF: word-per-second will be 0.37": "DESLIGADO: palavra por segundo será 0,37",
      "ON: word-per-second will be detected": "ON: palavra por segundo será detectada",
      "DETECT WPS": "DETECTAR WPS",
      "WPS IS 0.37": "WPS É 0,37",
      "OFF: don't change order or merge cuts": "DESATIVADO: não altere a ordem nem mescle os cortes",
      "ON: reduce cuts by merging and sorting may change order": "ATIVADO: reduzir cortes mesclando e classificando pode alterar a ordem",
      "USE REDUCE": "USAR REDUZIR",
      "KEEP ORDER AND CUTS": "MANTENHA A ORDEM E OS CORTES",
      "Timecode Errors": "Erros de código de tempo",
      "Timecodes": "Códigos de tempo",
      "Source ID": "ID da fonte",
      "Error": "Erro",
      "Log Out": "Sair",
      "Sign Up": "Inscrever-se",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Os editores assistentes dessas produções já são Tooliumers felizes, mais produtivos e economizando tempo",
      "Redo": "Refazer",
      "Undo": "Desfazer",
      "You have no credits left": "Você não tem mais créditos",
      "You need to buy more credits in order to keep using our platform": "Você precisa comprar mais créditos para continuar usando nossa plataforma",
      "Note": "Observação",
      "You will still get free daily credits even if you don't make a purchase": "Você ainda receberá créditos diários gratuitos mesmo se não fizer uma compra",
      "Benefits of buying credits": "Benefícios de comprar créditos",
      "Bulk discount (up to 50%)": "Desconto em massa (até 50%)",
      "Get to request features to fit your worksflow": "Solicite recursos adequados ao seu fluxo de trabalho",
      "You only pay for what you use": "Você só paga pelo que usa",
      "No monthly membership and no automatic recurring payments": "Sem assinatura mensal e sem pagamentos recorrentes automáticos",
      "You get to support our site": "Você pode apoiar nosso site",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Se você realmente precisa de mais créditos e não tem dinheiro, envie-nos um ensaio explicando por que devemos lhe dar créditos grátis",
      "Buy credits": "Compre créditos",
      "Ask for free credits, I don't have any money": "Peça créditos grátis, não tenho dinheiro",
      "Signup with Google to get 500 free credits": "Inscreva-se no Google para obter 500 créditos grátis",
      "You will also get 150 additional free credits every day": "Você também receberá 150 créditos gratuitos adicionais todos os dias",
      "Select": "Selecione",
      "Credits": "Créditos",
      "1 cut with Cutter = 20 credits": "1 corte com Cutter = 20 créditos",
      "Discount": "Desconto",
      "Diamond": "Diamante",
      "Enterprise": "Empreendimento",
      "7 days money back guarantee": "7 dias de garantia de devolução do dinheiro",
      "For any questions or issues": "Para qualquer dúvida ou problema",
      "Free": "Livre",
      "Gold": "Ouro",
      "1 group with Grouper": "1 grupo com Garoupa",
      "1 cut with Cutter": "1 corte com cortador"
    }
  },
  "nl": {
    "translation": {
      "Home": "Thuis",
      "Grouper": "Tandbaars",
      "GROUPER": "tandbaars",
      "Logout": "Uitloggen",
      "Profile": "Profiel",
      "Onboard": "Aan boord",
      "Cutter": "Snijder",
      "Blog": "Bloggen",
      "Contact Us": "Neem contact met ons op",
      "Login": "Log in",
      "Create group clips": "Groepsclips maken",
      "made easy": "makkelijk gemaakt",
      "Free Get started": "Gratis Aan de slag",
      "14 days free. No risk.": "14 dagen gratis. Geen risico.",
      "Signup Free": "Schrijf je gratis in",
      "Contact us with any question at": "Neem bij vragen contact met ons op via",
      "Just one more step before you start Grouping": "Nog één stap voordat u begint met groeperen",
      "First name": "Voornaam",
      "What is your first name": "Wat is je voornaam",
      "Please input your first name!": "Voer uw voornaam in!",
      "Last/family name": "Achter / familienaam",
      "What is your last/family name": "Wat is uw achternaam/familienaam",
      "Please input your last/family name!": "Voer uw achternaam/achternaam in!",
      "Nickname": "Bijnaam",
      "What do you want others to call you?": "Hoe wil je dat anderen je noemen?",
      "Please input your nickname!": "Voer uw bijnaam in!",
      "Phone Number": "Telefoonnummer",
      "What is your phone number?": "Wat is uw telefoonnummer?",
      "Please input your phone number! Example +18777755232": "Voer uw telefoonnummer in! Voorbeeld +18777755232",
      "I agree to the": "Ik ga akkoord met de",
      "privacy policy": "privacybeleid",
      "terms of service": "servicevoorwaarden",
      "disclaimer": "vrijwaring",
      "Privacy policy": "Privacybeleid",
      "Terms of service": "Servicevoorwaarden",
      "Disclaimer": "Vrijwaring",
      "Submit": "Indienen",
      "Contact your Toolium representative to buy more credits": "Neem contact op met uw Toolium-vertegenwoordiger om meer credits te kopen",
      "See your credits in profile": "Bekijk uw credits in uw profiel",
      "Contact your Toolium representative to activate your account": "Neem contact op met uw Toolium-vertegenwoordiger om uw account te activeren",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "upload een AAF-bestand van uw gesynchroniseerde reeks en wij zullen u een gegroepeerd AAF-bestand voor Avid Media Composer uitvoeren",
      "Click to Upload or Drag and Drop an AAF": "Klik om een ​​AAF te uploaden of te slepen en neer te zetten",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Ga uw e-mailadres verifiëren zodat u de website kunt blijven gebruiken. We hebben u een e-mail gestuurd met een link waarop u kunt klikken.",
      "Resend verification email": "Verificatie-e-mail opnieuw verzenden",
      "Call us at +1-877-775-5232": "Bel ons op +1-877-775-5232",
      "Plan": "Plan",
      "PLANS": "PLANNEN",
      "Choose a plan that works for you": "Kies een plan dat voor u werkt",
      "Basic": "Basis",
      "credit": "credit",
      "year": "jaar",
      "Billed annually.": "Jaarlijks gefactureerd.",
      "Contact us": "Neem contact met ons op",
      "Recommended": "Aanbevolen",
      "Standard": "Standaard",
      "credits": "tegoeden",
      "Billed annually. Save": "Jaarlijks gefactureerd. Redden",
      "Premium": "Premie",
      "Toolium Cutter": "Toolium-snijder",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Knip en bewerk al uw getuigenissen of documentaires rechtstreeks vanuit uw Microsoft Word-document of Google Documenten door simpelweg uw tekst te verhogen en uw bestand te uploaden met behulp van ons proces",
      "Automate uncreative tasks": "Automatiseer niet-creatieve taken",
      "Save money processing media": "Bespaar geldverwerkingsmedia",
      "Save 4 hours every shoot day": "Bespaar elke shootdag 4 uur",
      "The next generation of post production workflow tools": "De volgende generatie postproductieworkflowtools",
      "14 days free.": "14 dagen gratis.",
      "Plans": "Plannen",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium is een centrale hub waarmee u uw workflow efficiënt kunt groeperen, inkorten en stroomlijnen, zodat uw team uw werk kan versnellen en de levering met 4 uur kan verminderen!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Upload een AAF-bestand van uw gesynchroniseerde reeks en wij zullen u een gegroepeerd AAF-bestand voor Avid Media Composer uitvoeren",
      "Toolium Grouper": "Toolium tandbaars",
      "Used in": "Gebruikt in",
      "Groups are created with the full master clips": "Er worden groepen gemaakt met de volledige masterclips",
      "Reference sub-clips for shortened clips": "Referentie-subclips voor verkorte clips",
      "New avid multigroup type groups": "Nieuwe enthousiaste groepen met meerdere groepen",
      "Track renaming to group clip names": "Track hernoemen naar groepsclipnamen",
      "Features": "Functies",
      "AAF uploads": "AAF-uploads",
      "AAF upload": "AAF-upload",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Groepen worden gemaakt als de nieuwe enthousiaste groepen met zwarte ruimtes tussen clips op hetzelfde nummer. Er wordt een nieuwe groep aangemaakt na een spatie op de tijdlijn.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Hernoemt audiotracks binnen de groepen volgens de tracknamen in de volgorde. Je kunt zoveel tracks openen als je nodig hebt, de groepen worden gemaakt op basis van de bronaudiotrack.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: Koppelt groepen inclusief de audio aan de laatste reeks.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Stuurt alle audiotracks in de groep in opeenvolgende volgorde naar de reeks. Gebruik dit als u audio van meer dan één apparaat heeft.",
      "CLASSIC: Classic AVID grouping": "KLASSIEK: Klassieke AVID-groepering",
      "Process type unknown": "Procestype onbekend",
      "No clips found on sequence": "Geen clips gevonden op reeks",
      "No clips for writing sequence": "Geen clips voor schrijfvolgorde",
      "No sequence found in AAF file": "Geen reeks gevonden in AAF-bestand",
      "File is to big reduce AAF file": "Bestand is te groot om het AAF-bestand te verkleinen",
      "Sequence has length of 0": "Reeks heeft een lengte van 0",
      "Group clip found on sequence": "Groepsclip gevonden in reeks",
      "Group clip found on sequence 2": "Groepsclip gevonden in reeks 2",
      "Unknown selector type found in sequence": "Onbekend selectortype in volgorde gevonden",
      "Clip framerate does not match sequence framerate": "De framesnelheid van de clip komt niet overeen met de framesnelheid van de reeks",
      "Subclips with motion effects are not supported": "Subclips met bewegingseffecten worden niet ondersteund",
      "Join the best": "Sluit je aan bij de beste",
      "You are in good hands.": "Je bent in goede handen.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Assistent-redacteuren van deze producties zijn al blije Tooliumers, productiever en tijdbesparend",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Knip en bewerk al uw getuigenissen of documentaires rechtstreeks vanuit uw Microsoft Word-document of Google Docs door simpelweg uw tekst te markeren en uw bestand te uploaden met behulp van ons proces",
      "Renames audio tracks within the group clips": "Hernoemt audiotracks binnen de groepsclips",
      "Creates the new avid multigroup type groups": "Creëert de nieuwe enthousiaste groepen met meerdere groepen",
      "Creates avid sub-clips for shortened clips": "Creëert enthousiaste subclips voor verkorte clips",
      "Editor": "Editor",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Wilt u in uw browser bewerken met onze Avid Media Composer-compatibele editor?",
      "Yes, join pre launch": "Ja, doe mee vóór de lancering",
      "No I don't want": "Nee, ik wil niet",
      "Click to Upload AAF": "Klik om AAF te uploaden",
      "Bin panel": "Bakpaneel",
      "Go to Previous Event": "Ga naar Vorig evenement",
      "Go to Next Event": "Ga naar volgend evenement",
      "Step Backward 1 Fram": "Stap achteruit 1 Fram",
      "Step Forward 1 Fram": "Stap vooruit 1 Fram",
      "Mark IN": "Markeer IN",
      "Play": "Toneelstuk",
      "Mark OUT": "Markeer UIT",
      "Mark Clip": "Markeer clip",
      "Clear Both Marks": "Wis beide markeringen",
      "Add Marker": "Markering toevoegen",
      "Swap Cam Bank": "Swap Cambank",
      "Find Bin": "Zoek Bin",
      "Gang": "Bende",
      "Step backward 10 frames": "Ga 10 frames achteruit",
      "Step forward 10 frames": "Stap 10 frames vooruit",
      "Go to IN": "Ga naar IN",
      "Play Length Toggle": "Speellengte wisselen",
      "Go to OUT": "Ga naar UIT",
      "Match Frame": "Matchframe",
      "Reverse Match Frame": "Omgekeerd matchframe",
      "Quad Split": "Viervoudige splitsing",
      "Nine Split": "Negen splitsingen",
      "Step Backward 1 Frame": "Stap 1 frame achteruit",
      "Step Forward 1 Frame": "Stap 1 frame vooruit",
      "Add Edit": "Bewerken toevoegen",
      "Lift": "Tillen",
      "Extract": "Extract",
      "Quick Transition": "Snelle overgang",
      "Remove Effect": "Effect verwijderen",
      "Collapse": "Instorten",
      "Video Quality Menu": "Menu Videokwaliteit",
      "Monitor Volume": "Monitorvolume",
      "Meter Menu": "Metermenu",
      "Effect Mode": "Effectmodus",
      "Trim Mode": "Trim-modus",
      "Step In": "Stap in",
      "Step Out": "Uitstappen",
      "Render Effect": "Rendereffect",
      "Tool Palette": "Gereedschappalet",
      "Head Fade": "Hoofd vervagen",
      "Tall Fade": "Hoog vervagen",
      "To the Left": "Naar links",
      "To the Right": "Naar rechts",
      "Select In/Out": "Selecteer In/Uit",
      "Link Selection Toggle": "Linkselectieschakelaar",
      "HW/SW": "HW/ZW",
      "Titler Pro": "Titel Pro",
      "Default Setup": "Standaardinstelling",
      "View Type": "Bekijk type",
      "Track Panel": "Trackpaneel",
      "Effect Icons": "Effectpictogrammen",
      "Render Rangers": "Geef Rangers weer",
      "Dissolve Icons": "Pictogrammen oplossen",
      "Clip Frames": "Clipframes",
      "Clip Text": "Knip tekst",
      "Sync Breaks": "Synchronisatiepauzes",
      "Dupe Detection": "Dupe-detectie",
      "Color Correction": "Kleurcorrectie",
      "Audio Data": "Audiogegevens",
      "Clip Color...": "Clipkleur...",
      "Segment Selection": "Segmentselectie",
      "Mark I/O Selection": "Markeer I/O-selectie",
      "Track Color": "Kleur bijhouden",
      "Show markers": "Toon markeringen",
      "Show adapters": "Adapters tonen",
      "Show Track": "Toon spoor",
      "Track Control Panel": "Track-bedieningspaneel",
      "Zoom": "Zoom",
      "Open Comments": "Opmerkingen openen",
      "Call us at": "Bel ons op",
      "Office": "Kantoor",
      "Tracks Info": "Trackinformatie",
      "Edit Marker": "Markering bewerken",
      "Add": "Toevoegen",
      "Marker Name": "Markernaam",
      "Color": "Kleur",
      "Frame": "Kader",
      "Track": "Spoor",
      "Clear Monitor": "Duidelijke monitor",
      "Clear Menu": "Duidelijk menu",
      "Show Markers": "Toon markeringen",
      "Show Adapters": "Adapters tonen",
      "Toggle Fullscreen": "Volledig scherm activeren",
      "Highlighter": "Markeerstift",
      "Logger": "Logger",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Ongeldig bestand DOC, gebruik in plaats daarvan DOCX. U kunt deze tool van derden proberen",
      "Open DOC to DOCX converter": "Open DOC naar DOCX-converter",
      "File is missing any cuts, try to click": "Het bestand bevat geen uitsneden. Probeer te klikken",
      "SET ALL ROWS": "STEL ALLE RIJEN IN",
      "OFF: Timecodes have start and end": "UIT: Tijdcodes hebben een begin en een einde",
      "ON: No end timecode and just add 30 seconds from start timecode": "AAN: Geen eindtijdcode en voeg gewoon 30 seconden toe vanaf de starttijdcode",
      "NO END TIMECODE USE 30 SECONDS": "GEEN EINDTIJDCODE GEBRUIK 30 SECONDEN",
      "HAS END TIMECODE": "HEEFT EINDTIJDCODE",
      "OFF: Create cuts using highlighted text": "UIT: Uitsnijdingen maken met gemarkeerde tekst",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "AAN: Uitsnijdingen maken voor alle rijen in het document. Het negeren van de hoogtepunten",
      "ALL ROWS": "ALLE RIJEN",
      "ONLY HIGHLIGHTED": "ALLEEN GEMARKEERD",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "UIT: Broncamrolls negeren. Hierdoor worden er alleen sneden gemaakt op basis van de brontijdcode.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "AAN: Gebruik broncamrolls. Hierdoor worden sneden gemaakt op basis van de brontijdcode en de camroll van de bronclip",
      "USE CAMROLL IN DOCX": "GEBRUIK CAMROLL IN DOCX",
      "IGNORE CAMROLL IN DOCX": "NEGEER CAMROLL IN DOCX",
      "OFF: AAF downloaded will not be renamed": "UIT: gedownloade AAF wordt niet hernoemd",
      "ON: Rename AAF to DOCX file name": "AAN: Hernoem AAF naar DOCX-bestandsnaam",
      "RENAME AAF": "HERNOEM AAF",
      "NO RENAME": "GEEN HERNAAM",
      "Click to Upload DOCX": "Klik om DOCX te uploaden",
      "MULTI": "MULTI",
      "CLASSIC": "KLASSIEK",
      "RENAME ON": "HERNOEM AAN",
      "RENAME OFF": "HERNOEMEN UIT",
      "KEEP AUDIO ON": "HOUD AUDIO AAN",
      "KEEP AUDIO OFF": "HOUD AUDIO UIT",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO AS IS ON: Creëert groepen waarbij de audiotracks precies worden gerouteerd zoals ze in uw gesynchroniseerde reeks zijn geplaatst. Gebruik dit als u wilt dat de audio van uw groep precies zo wordt gerouteerd als uw reeks",
      "GROUP AUDIO AS IS ON": "GROEP AUDIO ZOALS HET IS AAN",
      "GROUP AUDIO AS IS OFF": "GROEP AUDIO ZOALS HET IS UIT",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Creëert groepen met camerahoeken op hetzelfde spoor als waarop ze op uw gesynchroniseerde reeks zijn geplaatst. Gebruik dit als u wilt dat uw camera's gedurende de hele opname in dezelfde groepspositie blijven",
      "KEEP CAM ON TRACK ON": "HOUD DE CAM OP HET SPOOR",
      "KEEP CAM ON TRACK OFF": "HOUD CAM OP SPOOR UIT",
      "ROUTE AUDIO ON": "ROUTE AUDIO AAN",
      "ROUTE AUDIO OFF": "ROUTE AUDIO UIT",
      "Toolium Editor": "Toolium-editor",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Upload een AAF-bestand en wij zullen u een Avid Media Composer-compatibele externe editor voor overal leveren en daarna een gegroepeerd AAF-bestand voor Avid Media Composer uitvoeren",
      "Free forever": "Voor altijd vrij",
      "Collaborate with ease with all the team": "Werk gemakkelijk samen met het hele team",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Houd iedereen op één lijn door feedback rechtstreeks op een frame te schrijven. Of teken het",
      "Look and feel you are use to in Avid Media Composer": "Kijk en voel zoals u gewend bent in Avid Media Composer",
      "Keyboard shortcuts you are use to": "Sneltoetsen die u gewend bent",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium is een centrale hub waarmee u uw workflow efficiënt kunt groeperen, inkorten en stroomlijnen, zodat uw team uw werk kan versnellen en de levering met 4 uur kan verkorten!",
      "A-Side Trim Counter": "A-zijtrimteller",
      "B-Side Trim Counter": "B-zijtrimteller",
      "Insert Track": "Voeg nummer in",
      "OK": "OK",
      "Track Type": "Tracktype",
      "Track Num": "Tracknummer",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Tijdens TC1",
      "I/O TC1": "I/O-TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "Duur 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Abs 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Mas 25",
      "Dur 25": "Duur 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Buikspieren 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Reeks",
      "Timecode": "Tijdcode",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Duur",
      "I/O": "IO",
      "Abs": "Buikspieren",
      "Rem": "Rem",
      "Footage": "Filmmateriaal",
      "Frames": "Lijsten",
      "Source": "Bron",
      "None": "Geen",
      "Master": "Meester",
      "Duration": "Duur",
      "In/Out": "In uit",
      "Absolute": "Absoluut",
      "Remain": "Blijven",
      "Audio Mono": "Audio-mono",
      "Audio Stereo": "Audio-stereo",
      "5.1 Surround": "5.1 Omringen",
      "7.1 Surround": "7.1 Omringen",
      "Data": "Gegevens",
      "Video": "Video",
      "Leave your comment here...": "Laat hier uw reactie achter...",
      "Send": "Versturen",
      "Workspace": "Werkruimte",
      "Project Settings": "Projectinstellingen",
      "Leave Project": "Project verlaten",
      "Delete Project": "Project verwijderen",
      "New Project": "Nieuw project",
      "Name": "Naam",
      "Please, input the name": "Voer de naam in",
      "Edit Project": "Project bewerken",
      "Save Settings": "Instellingen opslaan",
      "Jump to project of file...": "Ga naar project of bestand...",
      "Status": "Toestand",
      "Comments": "Opmerkingen",
      "Size": "Maat",
      "Date uploaded": "Datum geüpload",
      "Uploader": "Uploader",
      "Action": "Actie",
      "File upload": "Bestand upload",
      "Folder upload": "Map uploaden",
      "Folder upload as proxy": "Map uploaden als proxy",
      "New folder": "Nieuwe map",
      "New private folder": "Nieuwe privémap",
      "Download all": "Download alles",
      "Recently deleted": "Onlangs verwijderd",
      "Project settings": "Projectinstellingen",
      "Rename file": "Hernoem bestand",
      "Move": "Beweging",
      "Download": "Downloaden",
      "Make private": "Maak prive",
      "Reveal in project": "Onthul in project",
      "Delete": "Verwijderen",
      "File Information": "Bestandsinformatie",
      "Copy Comments": "Kopieer opmerkingen",
      "Paste Comments": "Opmerkingen plakken",
      "Print": "Afdrukken",
      "Download as File...": "Downloaden als bestand...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Platte tekst",
      "Download for Final Cut Pro X": "Downloaden voor Final Cut Pro X",
      "Download for Media Composer": "Downloaden voor mediacomponist",
      "Download for Resolve": "Downloaden voor oplossen",
      "No comments": "Geen commentaar",
      "This is premium feature, contact us at support@toolium.org": "Dit is een premiumfunctie. Neem contact met ons op via support@toolium.org",
      "Automated test to speech": "Geautomatiseerde test naar spraak",
      "Import from uploads": "Importeren uit uploads",
      "Upload your VO file": "Upload uw VO-bestand",
      "Record voice over": "Voice-over opnemen",
      "Crop": "Bijsnijden",
      "Voiceover": "Voice over",
      "Adjust colors": "Pas kleuren aan",
      "Trim": "Trimmen",
      "Speed": "Snelheid",
      "Loop": "Lus",
      "Flip": "Omdraaien",
      "Effects": "Effecten",
      "Animation": "Animatie",
      "Add title": "Titel toevoegen",
      "Export": "Exporteren",
      "Grid": "Rooster",
      "Split": "Splitsen",
      "Split View": "Gesplitste weergave",
      "Zoom to fit": "Zoom om te passen",
      "Audio meter": "Audiometer",
      "TC": "TC",
      "Comment": "Opmerking",
      "Jump to Marker": "Ga naar Markering",
      "Change Color": "Verander kleur",
      "Change Track": "Wijzig spoor",
      "Import Markers": "Markeringen importeren",
      "Export Markers": "Markeringen exporteren",
      "Choose Columns": "Kies Kolommen",
      "Cameras": "Camera's",
      "Desktop is required": "Bureaublad is vereist",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "We hebben gemerkt dat u een apparaat met een kleiner scherm gebruikt. Gebruik een desktop voor de beste ervaring.",
      "Go back": "Ga terug",
      "OFF: Use 0 seconds for handles": "UIT: Gebruik 0 seconden voor handgrepen",
      "ON: Use 5 seconds for handles": "AAN: Gebruik 5 seconden voor handgrepen",
      "HANDLES OFF": "HANDVATTEN UIT",
      "HANDLES ON": "HANDGREPEN AAN",
      "OFF: word-per-second will be 0.37": "UIT: woord per seconde is 0,37",
      "ON: word-per-second will be detected": "AAN: woord-per-seconde wordt gedetecteerd",
      "DETECT WPS": "DETECTEER WPS",
      "WPS IS 0.37": "WPS IS 0,37",
      "OFF: don't change order or merge cuts": "UIT: verander de volgorde niet en voeg geen delen samen",
      "ON: reduce cuts by merging and sorting may change order": "AAN: bezuinigingen verminderen door samenvoegen en sorteren kan de volgorde veranderen",
      "USE REDUCE": "GEBRUIK VERMINDEREN",
      "KEEP ORDER AND CUTS": "HOUD ORDE EN BESNIJDING",
      "Timecode Errors": "Tijdcodefouten",
      "Timecodes": "Tijdcodes",
      "Source ID": "Bron-ID",
      "Error": "Fout",
      "Log Out": "Uitloggen",
      "Sign Up": "Aanmelden",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Assistent-redacteuren van deze producties zijn al blije Tooliumers, productiever en tijdbesparend",
      "Redo": "Opnieuw doen",
      "Undo": "Ongedaan maken",
      "You have no credits left": "Je hebt geen tegoeden meer",
      "You need to buy more credits in order to keep using our platform": "U moet meer credits kopen om ons platform te kunnen blijven gebruiken",
      "Note": "Opmerking",
      "You will still get free daily credits even if you don't make a purchase": "U krijgt nog steeds gratis dagelijkse credits, zelfs als u geen aankoop doet",
      "Benefits of buying credits": "Voordelen van het kopen van credits",
      "Bulk discount (up to 50%)": "Bulkkorting (tot 50%)",
      "Get to request features to fit your worksflow": "Vraag functies aan die bij uw workflow passen",
      "You only pay for what you use": "U betaalt alleen voor wat u gebruikt",
      "No monthly membership and no automatic recurring payments": "Geen maandelijks lidmaatschap en geen automatische terugkerende betalingen",
      "You get to support our site": "Je steunt onze site",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Als je echt meer credits nodig hebt en je hebt geen geld, stuur ons dan een essay waarin je uitlegt waarom we je gratis credits moeten geven",
      "Buy credits": "Koop tegoed",
      "Ask for free credits, I don't have any money": "Vraag om gratis credits, ik heb geen geld",
      "Signup with Google to get 500 free credits": "Meld u aan bij Google en ontvang 500 gratis tegoeden",
      "You will also get 150 additional free credits every day": "Je krijgt ook elke dag 150 extra gratis credits",
      "Select": "Selecteer",
      "Credits": "Kredieten",
      "1 cut with Cutter = 20 credits": "1 snede met Cutter = 20 credits",
      "Discount": "Korting",
      "Diamond": "Diamant",
      "Enterprise": "Onderneming",
      "7 days money back guarantee": "7 dagen geld-terug-garantie",
      "For any questions or issues": "Voor eventuele vragen of problemen",
      "Free": "Vrij",
      "Gold": "Goud",
      "1 group with Grouper": "1 groep met tandbaars",
      "1 cut with Cutter": "1 snede met Cutter"
    }
  },
  "sv": {
    "translation": {
      "Home": "Hem",
      "Grouper": "Grupperare",
      "GROUPER": "GRUPPERARE",
      "Logout": "Logga ut",
      "Profile": "Profil",
      "Onboard": "Ombord",
      "Cutter": "Fräs",
      "Blog": "Blogg",
      "Contact Us": "Kontakta oss",
      "Login": "Logga in",
      "Create group clips": "Skapa gruppklipp",
      "made easy": "gjort lätt",
      "Free Get started": "Gratis Kom igång",
      "14 days free. No risk.": "14 dagar gratis. Ingen risk.",
      "Signup Free": "Registrera dig gratis",
      "Contact us with any question at": "Kontakta oss med alla frågor på",
      "Just one more step before you start Grouping": "Bara ett steg till innan du börjar gruppera",
      "First name": "Förnamn",
      "What is your first name": "Vad är ditt förnamn",
      "Please input your first name!": "Vänligen ange ditt förnamn!",
      "Last/family name": "Efter-/familjenamn",
      "What is your last/family name": "Vad är ditt efternamn/släktnamn",
      "Please input your last/family name!": "Ange ditt efter-/familjenamn!",
      "Nickname": "Smeknamn",
      "What do you want others to call you?": "Vad vill du att andra ska kalla dig?",
      "Please input your nickname!": "Vänligen ange ditt smeknamn!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Vad är ditt telefonnummer?",
      "Please input your phone number! Example +18777755232": "Vänligen ange ditt telefonnummer! Exempel +18777755232",
      "I agree to the": "Jag godkänner",
      "privacy policy": "integritetspolicy",
      "terms of service": "användarvillkor",
      "disclaimer": "varning",
      "Privacy policy": "Integritetspolicy",
      "Terms of service": "Användarvillkor",
      "Disclaimer": "varning",
      "Submit": "Skicka in",
      "Contact your Toolium representative to buy more credits": "Kontakta din Toolium-representant för att köpa fler krediter",
      "See your credits in profile": "Se dina krediter i profilen",
      "Contact your Toolium representative to activate your account": "Kontakta din Toolium-representant för att aktivera ditt konto",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "ladda upp en AAF-fil av din synkroniserade sekvens så skickar vi ut en grupperad AAF-fil för Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Klicka för att ladda upp eller dra och släpp en AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Gå och verifiera din e-post så att du kan fortsätta använda webbplatsen. Vi har skickat ett mail till dig med en länk att klicka på.",
      "Resend verification email": "Skicka verifieringsemail igen",
      "Call us at +1-877-775-5232": "Ring oss på +1-877-775-5232",
      "Plan": "Planen",
      "PLANS": "PLANER",
      "Choose a plan that works for you": "Välj en plan som fungerar för dig",
      "Basic": "Grundläggande",
      "credit": "kreditera",
      "year": "år",
      "Billed annually.": "Faktureras årligen.",
      "Contact us": "Kontakta oss",
      "Recommended": "Rekommenderad",
      "Standard": "Standard",
      "credits": "krediter",
      "Billed annually. Save": "Faktureras årligen. Spara",
      "Premium": "Premie",
      "Toolium Cutter": "Toolium Cutter",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Klipp ut och redigera alla dina vittnesmål eller dokument direkt från ditt Microsoft Word-dokument eller Google Docs genom att helt enkelt höja din text och ladda upp din fil med vår process",
      "Automate uncreative tasks": "Automatisera okreativa uppgifter",
      "Save money processing media": "Spara pengar för att bearbeta media",
      "Save 4 hours every shoot day": "Spara 4 timmar varje inspelningsdag",
      "The next generation of post production workflow tools": "Nästa generations arbetsflödesverktyg efter produktion",
      "14 days free.": "14 dagar gratis.",
      "Plans": "Planer",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium är ett centralt nav som låter dig gruppera, skära och effektivisera ditt arbetsflöde så att ditt team kan påskynda ditt arbete och minska leveransen med 4 timmar!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Ladda upp en AAF-fil med din synkroniserade sekvens så skickar vi ut en grupperad AAF-fil för Avid Media Composer",
      "Toolium Grouper": "Toolium Grouper",
      "Used in": "Använd i",
      "Groups are created with the full master clips": "Grupper skapas med de fullständiga huvudklippen",
      "Reference sub-clips for shortened clips": "Referensunderklipp för förkortade klipp",
      "New avid multigroup type groups": "Nya ivrig multigrupp typ grupper",
      "Track renaming to group clip names": "Spårbyte till gruppklippnamn",
      "Features": "Funktioner",
      "AAF uploads": "AAF laddar upp",
      "AAF upload": "AAF uppladdning",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Grupper skapas som de nya ivriga grupperna med svarta mellanslag mellan klipp på samma spår. En ny grupp skapas efter ett mellanslag på tidslinjen.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Byter namn på ljudspår i grupperna enligt sekvensens spårnamn. Du kan öppna så många spår som du behöver, grupperna kommer att skapas enligt källljudspåret.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "HÅLL LJUD PÅ: Splitsar grupper inklusive det efterföljande ljudet till den slutliga sekvensen.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Leder alla ljudspår i gruppen till sekvensen i följd. Använd detta om du har ljud från mer än en enhet.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: Klassisk AVID-gruppering",
      "Process type unknown": "Processtyp okänd",
      "No clips found on sequence": "Inga klipp hittades i sekvensen",
      "No clips for writing sequence": "Inga klipp för skrivsekvens",
      "No sequence found in AAF file": "Ingen sekvens hittades i AAF-fil",
      "File is to big reduce AAF file": "Filen är för stor reducerad AAF-fil",
      "Sequence has length of 0": "Sekvensen har längden 0",
      "Group clip found on sequence": "Gruppklipp hittat på sekvens",
      "Group clip found on sequence 2": "Gruppklipp hittat på sekvens 2",
      "Unknown selector type found in sequence": "Okänd väljartyp hittades i sekvens",
      "Clip framerate does not match sequence framerate": "Klippets framerate matchar inte sekvensens framerate",
      "Subclips with motion effects are not supported": "Underklipp med rörelseeffekter stöds inte",
      "Join the best": "Gå med de bästa",
      "You are in good hands.": "Du är i goda händer.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Assisterande redaktörer från dessa produktioner är redan glada Tooliumers, mer produktiva och sparar tid",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Klipp ut och redigera alla dina vittnesmål eller dokument direkt från ditt Microsoft Word-dokument eller Google Docs genom att helt enkelt markera din text och ladda upp din fil med vår process",
      "Renames audio tracks within the group clips": "Byter namn på ljudspår i gruppklippen",
      "Creates the new avid multigroup type groups": "Skapar de nya ivrig flergruppstyperna",
      "Creates avid sub-clips for shortened clips": "Skapar ivrig underklipp för förkortade klipp",
      "Editor": "Redaktör",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Vill du redigera i din webbläsare med vår Avid Media Composer-kompatibla redigerare?",
      "Yes, join pre launch": "Ja, gå med i förlansering",
      "No I don't want": "Nej jag vill inte",
      "Click to Upload AAF": "Klicka för att ladda upp AAF",
      "Bin panel": "Bin panel",
      "Go to Previous Event": "Gå till Föregående evenemang",
      "Go to Next Event": "Gå till nästa evenemang",
      "Step Backward 1 Fram": "Steg bakåt 1 Fram",
      "Step Forward 1 Fram": "Steg fram 1 Fram",
      "Mark IN": "Markera IN",
      "Play": "Spela",
      "Mark OUT": "Markera ut",
      "Mark Clip": "Mark Clip",
      "Clear Both Marks": "Rensa båda märkena",
      "Add Marker": "Lägg till markör",
      "Swap Cam Bank": "Byt Cam Bank",
      "Find Bin": "Hitta Bin",
      "Gang": "Gäng",
      "Step backward 10 frames": "Stega bakåt 10 ramar",
      "Step forward 10 frames": "Stega fram 10 ramar",
      "Go to IN": "Gå till IN",
      "Play Length Toggle": "Spela Längd Växla",
      "Go to OUT": "Gå till OUT",
      "Match Frame": "Matchram",
      "Reverse Match Frame": "Omvänd matchram",
      "Quad Split": "Quad Split",
      "Nine Split": "Nio Split",
      "Step Backward 1 Frame": "Steg bakåt 1 ram",
      "Step Forward 1 Frame": "Steg framåt 1 ram",
      "Add Edit": "Lägg till Redigera",
      "Lift": "Hiss",
      "Extract": "Extrahera",
      "Quick Transition": "Snabb övergång",
      "Remove Effect": "Ta bort effekt",
      "Collapse": "Kollaps",
      "Video Quality Menu": "Videokvalitetsmeny",
      "Monitor Volume": "Monitorvolym",
      "Meter Menu": "Mätarmeny",
      "Effect Mode": "Effektläge",
      "Trim Mode": "Trimläge",
      "Step In": "Gå in",
      "Step Out": "Gå ut",
      "Render Effect": "Render effekt",
      "Tool Palette": "Verktygspalett",
      "Head Fade": "Head Tona",
      "Tall Fade": "Tall Fade",
      "To the Left": "Till vänster",
      "To the Right": "Till höger",
      "Select In/Out": "Välj In/Ut",
      "Link Selection Toggle": "Växla länkval",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "Standardinställning",
      "View Type": "Visa typ",
      "Track Panel": "Spårpanel",
      "Effect Icons": "Effekt ikoner",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Lös upp ikoner",
      "Clip Frames": "Klippramar",
      "Clip Text": "Klipptext",
      "Sync Breaks": "Synkroniseringsavbrott",
      "Dupe Detection": "Dupedetektering",
      "Color Correction": "Färgkorrigering",
      "Audio Data": "Ljuddata",
      "Clip Color...": "Klippfärg...",
      "Segment Selection": "Segmentval",
      "Mark I/O Selection": "Markera I/O-val",
      "Track Color": "Spårfärg",
      "Show markers": "Visa markörer",
      "Show adapters": "Visa adaptrar",
      "Show Track": "Visa spår",
      "Track Control Panel": "Spår kontrollpanel",
      "Zoom": "Zoom",
      "Open Comments": "Öppna kommentarer",
      "Call us at": "Ring oss på",
      "Office": "Kontor",
      "Tracks Info": "Spår info",
      "Edit Marker": "Redigera markör",
      "Add": "Lägg till",
      "Marker Name": "Markörens namn",
      "Color": "Färg",
      "Frame": "Ram",
      "Track": "Spår",
      "Clear Monitor": "Rensa bildskärm",
      "Clear Menu": "Rensa menyn",
      "Show Markers": "Visa markörer",
      "Show Adapters": "Visa adaptrar",
      "Toggle Fullscreen": "Växla helskärm",
      "Highlighter": "Highlighter",
      "Logger": "Logger",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Ogiltig fil DOC, använd DOCX istället, du kan prova detta tredjepartsverktyg",
      "Open DOC to DOCX converter": "Öppna DOC till DOCX-omvandlaren",
      "File is missing any cuts, try to click": "Filen saknar några klipp, försök att klicka",
      "SET ALL ROWS": "SÄTT ALLA RADER",
      "OFF: Timecodes have start and end": "AV: Tidskoder har start och slut",
      "ON: No end timecode and just add 30 seconds from start timecode": "PÅ: Ingen sluttidskod och lägg bara till 30 sekunder från starttidskoden",
      "NO END TIMECODE USE 30 SECONDS": "INGEN SLUTTIDSKOD ANVÄND 30 SEKUNDER",
      "HAS END TIMECODE": "HAR SLUTTIDSKOD",
      "OFF: Create cuts using highlighted text": "AV: Skapa klipp med markerad text",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "PÅ: Skapa klipp för alla rader i dokumentet. Ignorera höjdpunkterna",
      "ALL ROWS": "ALLA RADER",
      "ONLY HIGHLIGHTED": "ENDAST MARKERADE",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "AV: Ignorera källan camrolls. Detta kommer att skapa nedskärningar endast enligt källtidskoden.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "PÅ: Använd källcamroller. Detta kommer att skapa klipp enligt källtidskod och källklippscamroll",
      "USE CAMROLL IN DOCX": "ANVÄND CAMROLL I DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORERA CAMROLL I DOCX",
      "OFF: AAF downloaded will not be renamed": "AV: AAF som laddats ner kommer inte att byta namn",
      "ON: Rename AAF to DOCX file name": "PÅ: Byt namn på AAF till DOCX-filnamn",
      "RENAME AAF": "BYT DAMN AAF",
      "NO RENAME": "INGET BYT NAMN",
      "Click to Upload DOCX": "Klicka för att ladda upp DOCX",
      "MULTI": "MÅNG",
      "CLASSIC": "KLASSISK",
      "RENAME ON": "BYT NAMN PÅ",
      "RENAME OFF": "BYT NAMN AV",
      "KEEP AUDIO ON": "HÅLL LJUD PÅ",
      "KEEP AUDIO OFF": "HÅLL LJUD AV",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GRUPPLJUD SOM ÄR PÅ: Skapar grupper med ljudspåren dirigerade exakt som de är placerade i din synkroniserade sekvens. Använd detta om du vill att ditt gruppljud ska dirigeras exakt som din sekvens",
      "GROUP AUDIO AS IS ON": "GRUPP LJUD SOM ÄR PÅ",
      "GROUP AUDIO AS IS OFF": "GRUPP LJUD SOM ÄR AV",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Skapar grupper med kameravinklar på samma spår som de placeras på din synkroniserade sekvens. Använd detta om du vill att dina kameror ska stanna i samma gruppplacering under hela fotograferingen",
      "KEEP CAM ON TRACK ON": "HÅLL KAMMEN PÅ SPÅR",
      "KEEP CAM ON TRACK OFF": "HÅLL CAM PÅ SPÅR AV",
      "ROUTE AUDIO ON": "ROUTE LJUD PÅ",
      "ROUTE AUDIO OFF": "RUTA LJUD AV",
      "Toolium Editor": "Toolium Editor",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Ladda upp en AAF-fil och vi kommer att ge dig en Avid Media Composer-kompatibel fjärrkontroll var som helst editor och sedan mata ut en grupperad AAF-fil för Avid Media Composer",
      "Free forever": "Gratis för alltid",
      "Collaborate with ease with all the team": "Samarbeta med lätthet med hela teamet",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Håll alla på samma sida genom att skriva feedback direkt på en ram. Eller rita det",
      "Look and feel you are use to in Avid Media Composer": "Utseende och känsla du är van vid i Avid Media Composer",
      "Keyboard shortcuts you are use to": "Kortkommandon du är van vid",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium är ett centralt nav som låter dig gruppera, skära och effektivisera ditt arbetsflöde så att ditt team kan påskynda ditt arbete och minska leveransen med 4 timmar!",
      "A-Side Trim Counter": "A-Sid Trim Counter",
      "B-Side Trim Counter": "B-Sid Trim Counter",
      "Insert Track": "Infoga spår",
      "OK": "OK",
      "Track Type": "Spårtyp",
      "Track Num": "Spårnummer",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Under TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "Under 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Abs 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Mas 25",
      "Dur 25": "Under 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Abs 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Sekvens",
      "Timecode": "Tidskod",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "I/O",
      "Abs": "Magmuskler",
      "Rem": "Rem",
      "Footage": "Antal fot",
      "Frames": "Ramar",
      "Source": "Källa",
      "None": "Ingen",
      "Master": "Bemästra",
      "Duration": "Varaktighet",
      "In/Out": "In ut",
      "Absolute": "Absolut",
      "Remain": "Förbli",
      "Audio Mono": "Ljud mono",
      "Audio Stereo": "Audio Stereo",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Data",
      "Video": "Video",
      "Leave your comment here...": "Lämna din kommentar här...",
      "Send": "Skicka",
      "Workspace": "Arbetsyta",
      "Project Settings": "Projektinställningar",
      "Leave Project": "Lämna Project",
      "Delete Project": "Ta bort projekt",
      "New Project": "Nytt projekt",
      "Name": "namn",
      "Please, input the name": "Vänligen ange namnet",
      "Edit Project": "Redigera projekt",
      "Save Settings": "Spara inställningar",
      "Jump to project of file...": "Hoppa till projekt av fil...",
      "Status": "Status",
      "Comments": "Kommentarer",
      "Size": "Storlek",
      "Date uploaded": "Uppladdningsdatum",
      "Uploader": "Uppladdare",
      "Action": "Handling",
      "File upload": "Filuppladdning",
      "Folder upload": "Uppladdning av mapp",
      "Folder upload as proxy": "Mappuppladdning som proxy",
      "New folder": "Ny mapp",
      "New private folder": "Ny privat mapp",
      "Download all": "Ladda ner allt",
      "Recently deleted": "Nyligen raderad",
      "Project settings": "Projektinställningar",
      "Rename file": "Döp om fil",
      "Move": "Flytta",
      "Download": "Ladda ner",
      "Make private": "Göra privat",
      "Reveal in project": "Avslöja i projektet",
      "Delete": "Radera",
      "File Information": "Filinformation",
      "Copy Comments": "Kopiera kommentarer",
      "Paste Comments": "Klistra in kommentarer",
      "Print": "Skriva ut",
      "Download as File...": "Ladda ner som fil...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Oformatterad text",
      "Download for Final Cut Pro X": "Ladda ner för Final Cut Pro X",
      "Download for Media Composer": "Ladda ner för Media Composer",
      "Download for Resolve": "Ladda ner för Resolve",
      "No comments": "Inga kommentarer",
      "This is premium feature, contact us at support@toolium.org": "Detta är en premiumfunktion, kontakta oss på support@toolium.org",
      "Automated test to speech": "Automatiskt test till tal",
      "Import from uploads": "Importera från uppladdningar",
      "Upload your VO file": "Ladda upp din VO-fil",
      "Record voice over": "Spela in voice over",
      "Crop": "Beskära",
      "Voiceover": "Dubbning",
      "Adjust colors": "Justera färger",
      "Trim": "Trim",
      "Speed": "Fart",
      "Loop": "Slinga",
      "Flip": "Flip",
      "Effects": "Effekter",
      "Animation": "Animation",
      "Add title": "Lägg till titel",
      "Export": "Exportera",
      "Grid": "Rutnät",
      "Split": "Dela",
      "Split View": "Delad vy",
      "Zoom to fit": "Zooma för att passa",
      "Audio meter": "Ljudmätare",
      "TC": "TC",
      "Comment": "Kommentar",
      "Jump to Marker": "Hoppa till Marker",
      "Change Color": "Ändra färg",
      "Change Track": "Byt spår",
      "Import Markers": "Importera markörer",
      "Export Markers": "Exportera markörer",
      "Choose Columns": "Välj Kolumner",
      "Cameras": "Kameror",
      "Desktop is required": "Skrivbord krävs",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Vi har märkt att du använder en enhet med mindre skärm. För den bästa upplevelsen, använd ett skrivbord.",
      "Go back": "Gå tillbaka",
      "OFF: Use 0 seconds for handles": "AV: Använd 0 sekunder för handtag",
      "ON: Use 5 seconds for handles": "PÅ: Använd 5 sekunder för handtagen",
      "HANDLES OFF": "HANDTAG AV",
      "HANDLES ON": "HANDTAG PÅ",
      "OFF: word-per-second will be 0.37": "AV: ord per sekund kommer att vara 0,37",
      "ON: word-per-second will be detected": "PÅ: ord per sekund kommer att upptäckas",
      "DETECT WPS": "DETEKTERA WPS",
      "WPS IS 0.37": "WPS ÄR 0,37",
      "OFF: don't change order or merge cuts": "AV: ändra inte ordning eller slå samman snitt",
      "ON: reduce cuts by merging and sorting may change order": "PÅ: minska nedskärningarna genom att slå samman och sortering kan ändra ordning",
      "USE REDUCE": "ANVÄNDNING MINSKA",
      "KEEP ORDER AND CUTS": "HÅLL ORDNING OCH KLIPP",
      "Timecode Errors": "Tidskodsfel",
      "Timecodes": "Tidskoder",
      "Source ID": "Käll-ID",
      "Error": "Fel",
      "Log Out": "Logga ut",
      "Sign Up": "Bli Medlem",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Assisterande redaktörer från dessa produktioner är redan glada Tooliumers, mer produktiva och sparar tid",
      "Redo": "Göra om",
      "Undo": "Ångra",
      "You have no credits left": "Du har inga krediter kvar",
      "You need to buy more credits in order to keep using our platform": "Du måste köpa fler krediter för att fortsätta använda vår plattform",
      "Note": "Notera",
      "You will still get free daily credits even if you don't make a purchase": "Du kommer fortfarande att få gratis dagliga krediter även om du inte gör ett köp",
      "Benefits of buying credits": "Fördelar med att köpa krediter",
      "Bulk discount (up to 50%)": "Massrabatt (upp till 50 %)",
      "Get to request features to fit your worksflow": "Få begära funktioner som passar ditt arbetsflöde",
      "You only pay for what you use": "Du betalar bara för det du använder",
      "No monthly membership and no automatic recurring payments": "Inget månadsmedlemskap och inga automatiska återkommande betalningar",
      "You get to support our site": "Du får stödja vår sida",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Om du verkligen behöver mer krediter och du inte har några pengar, skicka oss en uppsats som förklarar varför vi ska ge dig gratis krediter",
      "Buy credits": "Köp krediter",
      "Ask for free credits, I don't have any money": "Be om gratis krediter, jag har inga pengar",
      "Signup with Google to get 500 free credits": "Registrera dig hos Google för att få 500 gratis krediter",
      "You will also get 150 additional free credits every day": "Du kommer också att få 150 extra gratiskrediter varje dag",
      "Select": "Välj",
      "Credits": "Krediter",
      "1 cut with Cutter = 20 credits": "1 klipp med Cutter = 20 poäng",
      "Discount": "Rabatt",
      "Diamond": "Diamant",
      "Enterprise": "Företag",
      "7 days money back guarantee": "7 dagars pengarna tillbaka-garanti",
      "For any questions or issues": "För eventuella frågor eller problem",
      "Free": "Fri",
      "Gold": "Guld",
      "1 group with Grouper": "1 grupp med Grouper",
      "1 cut with Cutter": "1 skär med Cutter"
    }
  },
  "hu": {
    "translation": {
      "Home": "itthon",
      "Grouper": "Fűrészes sügér",
      "GROUPER": "FŰRÉSZES SÜGÉR",
      "Logout": "Kijelentkezés",
      "Profile": "Profil",
      "Onboard": "A fedélzeten",
      "Cutter": "Vágó",
      "Blog": "Blog",
      "Contact Us": "Lépjen kapcsolatba velünk",
      "Login": "Belépés",
      "Create group clips": "Csoportos klipek létrehozása",
      "made easy": "könnyűvé tette",
      "Free Get started": "Ingyenes Kezdés",
      "14 days free. No risk.": "14 nap ingyenes. Nincs kockázat.",
      "Signup Free": "Regisztrálj ingyen",
      "Contact us with any question at": "Bármilyen kérdéssel forduljon hozzánk a telefonszámon",
      "Just one more step before you start Grouping": "Csak egy lépés a csoportosítás megkezdése előtt",
      "First name": "Keresztnév",
      "What is your first name": "Mi a keresztneve",
      "Please input your first name!": "Kérjük, adja meg a keresztnevét!",
      "Last/family name": "Vezeték-/családnév",
      "What is your last/family name": "Mi a vezeték-/családi neved",
      "Please input your last/family name!": "Kérjük, adja meg vezeték-/családnevét!",
      "Nickname": "Becenév",
      "What do you want others to call you?": "Hogy hívják mások?",
      "Please input your nickname!": "Kérjük, adja meg becenevét!",
      "Phone Number": "Telefonszám",
      "What is your phone number?": "Mi a telefonszáma?",
      "Please input your phone number! Example +18777755232": "Kérjük adja meg telefonszámát! Példa +18777755232",
      "I agree to the": "egyet értek a",
      "privacy policy": "Adatvédelmi irányelvek",
      "terms of service": "szolgáltatás feltételei",
      "disclaimer": "felelősségkizárás",
      "Privacy policy": "Adatvédelmi irányelvek",
      "Terms of service": "Szolgáltatás feltételei",
      "Disclaimer": "Jogi nyilatkozat",
      "Submit": "Beküldés",
      "Contact your Toolium representative to buy more credits": "További kreditek vásárlásához forduljon Toolium képviselőjéhez",
      "See your credits in profile": "Tekintse meg kreditjeit a profilban",
      "Contact your Toolium representative to activate your account": "Fiókja aktiválásához forduljon Toolium képviselőjéhez",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "töltsd fel a szinkronizált sorozatod AAF-fájlját, és mi egy csoportosított AAF-fájlt küldünk ki az Avid Media Composer számára",
      "Click to Upload or Drag and Drop an AAF": "Kattintson az AAF feltöltéséhez vagy húzza át",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Kérjük, menjen, és igazolja e-mail-címét, hogy továbbra is használni tudja a webhelyet. Küldtünk egy e-mailt egy linkkel, amelyre kattinthat.",
      "Resend verification email": "Igazoló e-mail újraküldése",
      "Call us at +1-877-775-5232": "Hívjon minket a +1-877-775-5232 telefonszámon",
      "Plan": "Terv",
      "PLANS": "TERVEK",
      "Choose a plan that works for you": "Válassza ki az Önnek megfelelő tervet",
      "Basic": "Alapvető",
      "credit": "hitel",
      "year": "év",
      "Billed annually.": "Évente számlázva.",
      "Contact us": "Lépjen kapcsolatba velünk",
      "Recommended": "Ajánlott",
      "Standard": "Alapértelmezett",
      "credits": "krediteket",
      "Billed annually. Save": "Évente számlázva. Megment",
      "Premium": "Prémium",
      "Toolium Cutter": "Toolium vágó",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Vágja ki és szerkessze minden ajánlását vagy dokumentumát közvetlenül a Microsoft Word dokumentumból vagy a Google Dokumentumokból, egyszerűen csak kiemeli a szöveget, és feltölti a fájlt a folyamatunk segítségével.",
      "Automate uncreative tasks": "Automatizálja a nem kreatív feladatokat",
      "Save money processing media": "Pénzt takaríthat meg a feldolgozási média",
      "Save 4 hours every shoot day": "Takarítson meg 4 órát minden felvételi napon",
      "The next generation of post production workflow tools": "Az utómunkálati munkafolyamat-eszközök következő generációja",
      "14 days free.": "14 nap ingyenes.",
      "Plans": "Tervek",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "A Toolium egy központi csomópont, amely lehetővé teszi a munkafolyamatok hatékony csoportosítását, megvágását és egyszerűsítését, így csapata felgyorsíthatja munkáját, és 4 órával csökkentheti a kézbesítést!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Töltse fel a szinkronizált sorozatának AAF-fájlját, és mi egy csoportosított AAF-fájlt küldünk ki az Avid Media Composer számára.",
      "Toolium Grouper": "Toolium Grouper",
      "Used in": "Használt",
      "Groups are created with the full master clips": "A csoportok a teljes mesterklipekkel jönnek létre",
      "Reference sub-clips for shortened clips": "Referencia-alklipek a rövidített klipekhez",
      "New avid multigroup type groups": "Új lelkes többcsoportos típusú csoportok",
      "Track renaming to group clip names": "A sáv átnevezése csoportklipnevekre",
      "Features": "Jellemzők",
      "AAF uploads": "AAF feltöltések",
      "AAF upload": "AAF feltöltés",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: A csoportok új lelkes csoportokként jönnek létre, fekete szóközökkel a klipek között ugyanazon a számon. Az idővonalon egy szóköz után új csoport jön létre.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "ÁTNEVEZÉS BE: Átnevezi a hangsávokat a csoportokon belül a sorozatsávok nevének megfelelően. Annyi műsorszámot nyithat meg, amennyire szüksége van, a csoportok a forrás hangsávja szerint jönnek létre.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "HANG BEkapcsolva: A csoportokat, beleértve a záró hangot is, összeilleszti a végső szekvenciával.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: A csoport összes hangsávját egymás utáni sorrendben a sorozatba irányítja. Használja ezt, ha egynél több eszközről van hangja.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: Klasszikus AVID csoportosítás",
      "Process type unknown": "A folyamat típusa ismeretlen",
      "No clips found on sequence": "Nem található klip a sorozatban",
      "No clips for writing sequence": "Nincsenek klipek a sorozat írásához",
      "No sequence found in AAF file": "Nem található sorozat az AAF-fájlban",
      "File is to big reduce AAF file": "A fájl nagyra csökkenti az AAF fájlt",
      "Sequence has length of 0": "A sorozat hossza 0",
      "Group clip found on sequence": "Csoportklip található a sorozaton",
      "Group clip found on sequence 2": "Csoportklip a 2. sorozaton található",
      "Unknown selector type found in sequence": "A sorozatban ismeretlen választótípus található",
      "Clip framerate does not match sequence framerate": "A klip képkockasebessége nem egyezik a sorozat képkockasebességével",
      "Subclips with motion effects are not supported": "A mozgási effektusokkal rendelkező részklipek nem támogatottak",
      "Join the best": "Csatlakozz a legjobbhoz",
      "You are in good hands.": "Jó kezekben vagy.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "A segédszerkesztők ezekből a produkciókból már boldog Tooliumersek, produktívabbak és időt takarítanak meg",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Vágja ki és szerkessze minden ajánlását vagy dokumentumát közvetlenül a Microsoft Word-dokumentumból vagy a Google Dokumentumokból, egyszerűen csak kiemeli a szöveget, és feltölti a fájlt a folyamatunk segítségével.",
      "Renames audio tracks within the group clips": "Átnevezi a hangsávokat a csoportklipeken belül",
      "Creates the new avid multigroup type groups": "Létrehozza az új lelkes többcsoportos típusú csoportokat",
      "Creates avid sub-clips for shortened clips": "Lelkes alklipeket hoz létre a rövidített klipekhez",
      "Editor": "Szerkesztő",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Szeretne szerkeszteni a böngészőjében az Avid Media Composer kompatibilis szerkesztővel?",
      "Yes, join pre launch": "Igen, csatlakozz az indítás előttihez",
      "No I don't want": "Nem, nem akarom",
      "Click to Upload AAF": "Kattintson az AAF feltöltéséhez",
      "Bin panel": "Bin panel",
      "Go to Previous Event": "Ugrás az Előző eseményre",
      "Go to Next Event": "Ugrás a Következő eseményre",
      "Step Backward 1 Fram": "Lépés hátra 1 képkocka",
      "Step Forward 1 Fram": "Lépés előre 1 képkocka",
      "Mark IN": "Jelölje be",
      "Play": "Játék",
      "Mark OUT": "Kijelöl",
      "Mark Clip": "Mark Clip",
      "Clear Both Marks": "Törölje mindkét jelet",
      "Add Marker": "Marker hozzáadása",
      "Swap Cam Bank": "Swap Cam Bank",
      "Find Bin": "Keresse meg Bint",
      "Gang": "Banda",
      "Step backward 10 frames": "Lépjen hátra 10 képkockát",
      "Step forward 10 frames": "Lépj előre 10 képkockát",
      "Go to IN": "Menjen az IN-re",
      "Play Length Toggle": "Lejátszási hossz kapcsoló",
      "Go to OUT": "Lépjen az OUT oldalra",
      "Match Frame": "Match Frame",
      "Reverse Match Frame": "Reverse Match Frame",
      "Quad Split": "Quad Split",
      "Nine Split": "Kilenc Split",
      "Step Backward 1 Frame": "Lépjen hátra 1 képkocka",
      "Step Forward 1 Frame": "Lépjen előre 1 képkocka",
      "Add Edit": "Szerkesztés hozzáadása",
      "Lift": "Emel",
      "Extract": "Kivonat",
      "Quick Transition": "Gyors átállás",
      "Remove Effect": "Hatás eltávolítása",
      "Collapse": "Összeomlás",
      "Video Quality Menu": "Videóminőség menü",
      "Monitor Volume": "Monitor hangerő",
      "Meter Menu": "Mérő menü",
      "Effect Mode": "Effektus mód",
      "Trim Mode": "Vágás mód",
      "Step In": "Közbelép",
      "Step Out": "Kilépni",
      "Render Effect": "Render Effect",
      "Tool Palette": "Eszközpaletta",
      "Head Fade": "Head Fade",
      "Tall Fade": "Tall Fade",
      "To the Left": "Balra",
      "To the Right": "Jobbra",
      "Select In/Out": "Válassza a Be/Ki lehetőséget",
      "Link Selection Toggle": "Linkkiválasztás kapcsoló",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "Alapértelmezett beállítás",
      "View Type": "Nézet típusa",
      "Track Panel": "Nyomvonal Panel",
      "Effect Icons": "Hatás ikonok",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Oldja fel az ikonokat",
      "Clip Frames": "Klip keretek",
      "Clip Text": "Szöveg klip",
      "Sync Breaks": "Szinkronizálási szünetek",
      "Dupe Detection": "Hamis észlelés",
      "Color Correction": "Színkorrekció",
      "Audio Data": "Hangadatok",
      "Clip Color...": "Klip színe...",
      "Segment Selection": "Szegmens kiválasztása",
      "Mark I/O Selection": "Jelölje meg az I/O kijelölést",
      "Track Color": "Pálya színe",
      "Show markers": "Jelölők megjelenítése",
      "Show adapters": "Adapterek megjelenítése",
      "Show Track": "Műsorszám megjelenítése",
      "Track Control Panel": "Nyomvonal vezérlőpult",
      "Zoom": "Zoomolás",
      "Open Comments": "Nyissa meg a Megjegyzéseket",
      "Call us at": "Hívjon minket a számon",
      "Office": "Hivatal",
      "Tracks Info": "Számok Info",
      "Edit Marker": "Marker szerkesztése",
      "Add": "Hozzáadás",
      "Marker Name": "Jelölő neve",
      "Color": "Szín",
      "Frame": "Keret",
      "Track": "Nyomon követni",
      "Clear Monitor": "Tiszta monitor",
      "Clear Menu": "Menü törlése",
      "Show Markers": "Jelölők megjelenítése",
      "Show Adapters": "Adapterek megjelenítése",
      "Toggle Fullscreen": "Teljes képernyőre váltás",
      "Highlighter": "Kiemelő",
      "Logger": "Logger",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Érvénytelen DOC fájl, használja helyette a DOCX-et, kipróbálhatja ezt a harmadik féltől származó eszközt",
      "Open DOC to DOCX converter": "Nyissa meg a DOC-DOCX konvertert",
      "File is missing any cuts, try to click": "A fájlból hiányzik a kivágás, próbáljon meg kattintani",
      "SET ALL ROWS": "MINDEN SOR BEÁLLÍTÁSA",
      "OFF: Timecodes have start and end": "KI: Az időkódoknak van kezdete és vége",
      "ON: No end timecode and just add 30 seconds from start timecode": "BE: Nincs befejezési időkód, és csak adjon hozzá 30 másodpercet a kezdési időkódtól számítva",
      "NO END TIMECODE USE 30 SECONDS": "NINCS VÉGE IDŐKÓD HASZNÁLJON 30 MÁSODPERCET",
      "HAS END TIMECODE": "VÉGE IDŐKÓDJA VAN",
      "OFF: Create cuts using highlighted text": "KI: Kivágások létrehozása kiemelt szöveggel",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "BE: Vágásokat hoz létre a dokumentum összes sorához. A kiemelések figyelmen kívül hagyása",
      "ALL ROWS": "MINDEN SOR",
      "ONLY HIGHLIGHTED": "CSAK KIEMELT",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "KI: A forrás camrollok figyelmen kívül hagyása. Ez csak a forrás időkódja szerint hoz létre vágásokat.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "BE: Forrás camrollok használata. Ez vágásokat hoz létre a forrás időkódja és a forrás klip camroll szerint",
      "USE CAMROLL IN DOCX": "A CAMROLL HASZNÁLATA DOCX-BEN",
      "IGNORE CAMROLL IN DOCX": "A DOCX-BEN ELÉRHETŐ CAMROLL",
      "OFF: AAF downloaded will not be renamed": "KI: A letöltött AAF nem lesz átnevezve",
      "ON: Rename AAF to DOCX file name": "BE: Az AAF átnevezése DOCX fájlnévre",
      "RENAME AAF": "AAF ÁTNEVEZÉSE",
      "NO RENAME": "NINCS ÁTNEVEZÉS",
      "Click to Upload DOCX": "Kattintson a DOCX feltöltéséhez",
      "MULTI": "TÖBB",
      "CLASSIC": "KLASSZIKUS",
      "RENAME ON": "ÁTNEVEZÉS BE",
      "RENAME OFF": "ÁTNEVEZÉS KI",
      "KEEP AUDIO ON": "HANG TARTSA BE",
      "KEEP AUDIO OFF": "TARTSA KIKAPCSOLVA az AUDIÓT",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO ON ON: Csoportokat hoz létre úgy, hogy a hangsávokat pontosan úgy irányítják, ahogyan azok a szinkronizált sorrendben vannak elhelyezve. Használja ezt, ha azt szeretné, hogy a csoportok hangja pontosan úgy legyen irányítva, mint a sorozat",
      "GROUP AUDIO AS IS ON": "A GROUP AUDIO BEkapcsolt állapotában",
      "GROUP AUDIO AS IS OFF": "CSOPORTOS AUDIO KIKAPCSOLVA",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Csoportokat hoz létre a kameraállásokkal ugyanazon a sávon, mint ahogyan a szinkronizált sorozatban vannak elhelyezve. Használja ezt, ha azt szeretné, hogy a kamerák ugyanazon a csoportos elhelyezésen maradjanak a teljes felvétel alatt",
      "KEEP CAM ON TRACK ON": "TARTSA BE KAPCSOLÓT",
      "KEEP CAM ON TRACK OFF": "TARTSA KIKAPCSOLVA a CAM ON TRACK",
      "ROUTE AUDIO ON": "ROUTE AUDIO ON",
      "ROUTE AUDIO OFF": "ROUTE AUDIO OFF",
      "Toolium Editor": "Toolium szerkesztő",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Tölts fel egy AAF-fájlt, és mi biztosítunk egy Avid Media Composer-kompatibilis távoli szerkesztőt bárhol, majd egy csoportosított AAF-fájlt küldünk ki az Avid Media Composer számára.",
      "Free forever": "Örökre ingyen",
      "Collaborate with ease with all the team": "Könnyedén működhet együtt az egész csapattal",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Tartsa mindenki ugyanazon az oldalon, ha visszajelzést ír közvetlenül egy keretre. Vagy rajzold le",
      "Look and feel you are use to in Avid Media Composer": "Az Avid Media Composerben megszokott megjelenés és érzés",
      "Keyboard shortcuts you are use to": "Az Ön által megszokott billentyűparancsok",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "A Toolium egy központi csomópont, amely lehetővé teszi a munkafolyamatok hatékony csoportosítását, vágását és egyszerűsítését, így csapata felgyorsíthatja munkáját, és 4 órával csökkentheti a kézbesítést!",
      "A-Side Trim Counter": "A-oldali trimm számláló",
      "B-Side Trim Counter": "B-oldali trimm számláló",
      "Insert Track": "Nyom beszúrása",
      "OK": "rendben",
      "Track Type": "Nyomvonal típusa",
      "Track Num": "Track Num",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Durva TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "24-ig",
      "I/O 24": "I/O 24",
      "Abs 24": "Has 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Mas 25",
      "Dur 25": "Időtartam 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Abs 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Sorrend",
      "Timecode": "Időkód",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "I/O",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Felvétel",
      "Frames": "Keretek",
      "Source": "Forrás",
      "None": "Egyik sem",
      "Master": "Fő",
      "Duration": "Időtartam",
      "In/Out": "Be ki",
      "Absolute": "Abszolút",
      "Remain": "Marad",
      "Audio Mono": "Audio Mono",
      "Audio Stereo": "Audio sztereó",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Adat",
      "Video": "Videó",
      "Leave your comment here...": "Hagyja itt véleményét...",
      "Send": "Küld",
      "Workspace": "Munkaterület",
      "Project Settings": "Projektbeállítások",
      "Leave Project": "Kilépés a projektből",
      "Delete Project": "Projekt törlése",
      "New Project": "Új projekt",
      "Name": "Név",
      "Please, input the name": "Kérem írja be a nevet",
      "Edit Project": "Projekt szerkesztése",
      "Save Settings": "Beállítások mentése",
      "Jump to project of file...": "Ugrás a fájl projektjére...",
      "Status": "Állapot",
      "Comments": "Hozzászólások",
      "Size": "Méret",
      "Date uploaded": "Feltöltés dátuma",
      "Uploader": "Feltöltő",
      "Action": "Akció",
      "File upload": "Fájlfeltöltés",
      "Folder upload": "Mappa feltöltése",
      "Folder upload as proxy": "Mappa feltöltése proxyként",
      "New folder": "Új mappa",
      "New private folder": "Új privát mappa",
      "Download all": "Mind letöltése",
      "Recently deleted": "Nemrég törölve",
      "Project settings": "Projektbeállítások",
      "Rename file": "Fájl átnevezése",
      "Move": "Mozog",
      "Download": "Letöltés",
      "Make private": "Legyen privát",
      "Reveal in project": "Fedezd fel a projektben",
      "Delete": "Töröl",
      "File Information": "Fájlinformáció",
      "Copy Comments": "Megjegyzések másolása",
      "Paste Comments": "Megjegyzések beillesztése",
      "Print": "Nyomtatás",
      "Download as File...": "Letöltés fájlként...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Egyszerű szöveg",
      "Download for Final Cut Pro X": "Letöltés a Final Cut Pro X-hez",
      "Download for Media Composer": "Letöltés a Media Composerhez",
      "Download for Resolve": "Töltse le a megoldáshoz",
      "No comments": "Nincs hozzászólás",
      "This is premium feature, contact us at support@toolium.org": "Ez prémium szolgáltatás, lépjen kapcsolatba velünk a support@toolium.org címen",
      "Automated test to speech": "Automatizált beszédteszt",
      "Import from uploads": "Importálás a feltöltésekből",
      "Upload your VO file": "Töltse fel a VO fájlt",
      "Record voice over": "Hangfelvétel rögzítése",
      "Crop": "Vág",
      "Voiceover": "Szinkronhang",
      "Adjust colors": "Állítsa be a színeket",
      "Trim": "Vágás",
      "Speed": "Sebesség",
      "Loop": "Hurok",
      "Flip": "Flip",
      "Effects": "Hatások",
      "Animation": "Élénkség",
      "Add title": "Adj címet",
      "Export": "Export",
      "Grid": "Rács",
      "Split": "Hasított",
      "Split View": "Osztott nézet",
      "Zoom to fit": "Nagyítás, hogy illeszkedjen",
      "Audio meter": "Audio mérő",
      "TC": "TC",
      "Comment": "Megjegyzés",
      "Jump to Marker": "Ugrás a Markerre",
      "Change Color": "Színét változtatni",
      "Change Track": "Változás sáv",
      "Import Markers": "Markerek importálása",
      "Export Markers": "Exportjelölők",
      "Choose Columns": "Válassza az Oszlopok lehetőséget",
      "Cameras": "Fényképezőgépek",
      "Desktop is required": "Asztali számítógép szükséges",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Észrevettük, hogy kisebb képernyős eszközt használ. A legjobb élmény érdekében kérjük, használjon asztali számítógépet.",
      "Go back": "Menjen vissza",
      "OFF: Use 0 seconds for handles": "KI: 0 másodpercet használjon a fogantyúkhoz",
      "ON: Use 5 seconds for handles": "BE: Használjon 5 másodpercet a fogantyúkhoz",
      "HANDLES OFF": "KEZELŐK KI",
      "HANDLES ON": "KEZELÉSEK BE",
      "OFF: word-per-second will be 0.37": "KI: szó/másodperc 0,37 lesz",
      "ON: word-per-second will be detected": "BE: A készülék a másodpercenkénti szót érzékeli",
      "DETECT WPS": "WPS ÉSZLELÉSE",
      "WPS IS 0.37": "A WPS 0,37",
      "OFF: don't change order or merge cuts": "KI: ne módosítsa a sorrendet és ne egyesítse a kivágásokat",
      "ON: reduce cuts by merging and sorting may change order": "BE: a vágások csökkentése egyesítéssel és a rendezés megváltoztathatja a sorrendet",
      "USE REDUCE": "HASZNÁLJA CSÖKKENTÉST",
      "KEEP ORDER AND CUTS": "A REND TARTÁSA ÉS VÁGÁSA",
      "Timecode Errors": "Időkód hibák",
      "Timecodes": "Időkódok",
      "Source ID": "Forrásazonosító",
      "Error": "Hiba",
      "Log Out": "Kijelentkezés",
      "Sign Up": "Regisztrálj",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "A szerkesztősegédek ezekből a produkciókból már boldog Toolium-osok, produktívabbak és időt takarítanak meg",
      "Redo": "Újra",
      "Undo": "Visszavonás",
      "You have no credits left": "Nincs több kreditje",
      "You need to buy more credits in order to keep using our platform": "Több kreditet kell vásárolnia ahhoz, hogy továbbra is használja platformunkat",
      "Note": "jegyzet",
      "You will still get free daily credits even if you don't make a purchase": "Még akkor is ingyenes napi kreditet kap, ha nem vásárol",
      "Benefits of buying credits": "A kreditvásárlás előnyei",
      "Bulk discount (up to 50%)": "Tömeges kedvezmény (akár 50%)",
      "Get to request features to fit your worksflow": "Kérjen olyan funkciókat, amelyek illeszkednek a munkafolyamatához",
      "You only pay for what you use": "Csak azért fizet, amit használ",
      "No monthly membership and no automatic recurring payments": "Nincs havi tagság és nincs automatikus visszatérő fizetés",
      "You get to support our site": "Támogathatja oldalunkat",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Ha valóban több kreditre van szüksége, és nincs pénze, küldjön egy esszét, amelyben elmagyarázza, miért adjunk ingyenes kreditet",
      "Buy credits": "Vásároljon krediteket",
      "Ask for free credits, I don't have any money": "Kérj ingyen kreditet, nincs pénzem",
      "Signup with Google to get 500 free credits": "Regisztráljon a Google-lal, és 500 ingyenes kreditet kap",
      "You will also get 150 additional free credits every day": "Ezenkívül minden nap további 150 ingyenes kreditet kap",
      "Select": "Válassza ki",
      "Credits": "Kredit",
      "1 cut with Cutter = 20 credits": "1 vágás Cutterrel = 20 kredit",
      "Discount": "Kedvezmény",
      "Diamond": "gyémánt",
      "Enterprise": "Vállalkozás",
      "7 days money back guarantee": "7 nap pénzvisszafizetési garancia",
      "For any questions or issues": "Bármilyen kérdés vagy probléma esetén",
      "Free": "Ingyenes",
      "Gold": "Arany",
      "1 group with Grouper": "1 csoport csoportosítóval",
      "1 cut with Cutter": "1 vágja Cutterrel"
    }
  },
  "da": {
    "translation": {
      "Home": "Hjem",
      "Grouper": "Grouper",
      "GROUPER": "GRUPPER",
      "Logout": "Log ud",
      "Profile": "Profil",
      "Onboard": "Ombord",
      "Cutter": "Kutter",
      "Blog": "Blog",
      "Contact Us": "Kontakt os",
      "Login": "Log på",
      "Create group clips": "Opret gruppeklip",
      "made easy": "gjort let",
      "Free Get started": "Gratis Kom godt i gang",
      "14 days free. No risk.": "14 dage gratis. Ingen risiko.",
      "Signup Free": "Tilmelding gratis",
      "Contact us with any question at": "Kontakt os med ethvert spørgsmål på",
      "Just one more step before you start Grouping": "Bare et trin mere, før du begynder at gruppere",
      "First name": "Fornavn",
      "What is your first name": "Hvad er dit fornavn",
      "Please input your first name!": "Indtast venligst dit fornavn!",
      "Last/family name": "Efternavn/familienavn",
      "What is your last/family name": "Hvad er dit efternavn/familienavn",
      "Please input your last/family name!": "Indtast venligst dit efternavn/familienavn!",
      "Nickname": "Kaldenavn",
      "What do you want others to call you?": "Hvad vil du have, at andre skal kalde dig?",
      "Please input your nickname!": "Indtast venligst dit kaldenavn!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Hvad er dit telefonnummer?",
      "Please input your phone number! Example +18777755232": "Indtast venligst dit telefonnummer! Eksempel +18777755232",
      "I agree to the": "Jeg er enig i",
      "privacy policy": "Fortrolighedspolitik",
      "terms of service": "servicevilkår",
      "disclaimer": "ansvarsfraskrivelse",
      "Privacy policy": "Fortrolighedspolitik",
      "Terms of service": "Servicevilkår",
      "Disclaimer": "Ansvarsfraskrivelse",
      "Submit": "Indsend",
      "Contact your Toolium representative to buy more credits": "Kontakt din Toolium-repræsentant for at købe flere kreditter",
      "See your credits in profile": "Se dine kreditter i profilen",
      "Contact your Toolium representative to activate your account": "Kontakt din Toolium-repræsentant for at aktivere din konto",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "upload en AAF-fil af din synkroniserede sekvens, og vi sender dig en grupperet AAF-fil til Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Klik for at uploade eller træk og slip en AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Gå og bekræft din e-mail, så du kan fortsætte med at bruge hjemmesiden. Vi har sendt dig en e-mail med et link til at klikke på.",
      "Resend verification email": "Gensend bekræftelses mail",
      "Call us at +1-877-775-5232": "Ring til os på +1-877-775-5232",
      "Plan": "Plan",
      "PLANS": "PLANER",
      "Choose a plan that works for you": "Vælg en plan, der fungerer for dig",
      "Basic": "Grundlæggende",
      "credit": "kredit",
      "year": "år",
      "Billed annually.": "Faktureres årligt.",
      "Contact us": "Kontakt os",
      "Recommended": "Anbefalede",
      "Standard": "Standard",
      "credits": "kreditter",
      "Billed annually. Save": "Faktureres årligt. Gemme",
      "Premium": "Præmie",
      "Toolium Cutter": "Toolium Cutter",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Klip og rediger alle dine udtalelser eller dokumenter direkte fra dit Microsoft Word-dokument eller Google Docs ved blot at højne din tekst og uploade din fil ved hjælp af vores proces",
      "Automate uncreative tasks": "Automatiser ukreative opgaver",
      "Save money processing media": "Spar penge til behandling af medier",
      "Save 4 hours every shoot day": "Spar 4 timer hver skuddag",
      "The next generation of post production workflow tools": "Den næste generation af post-produktion workflow-værktøjer",
      "14 days free.": "14 dage gratis.",
      "Plans": "Planer",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium er en central hub, der giver dig mulighed for effektivt at gruppere, skære og strømline dit workflow, så dit team kan fremskynde dit arbejde og reducere leveringen med 4 timer!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Upload en AAF-fil af din synkroniserede sekvens, og vi sender dig en grupperet AAF-fil til Avid Media Composer",
      "Toolium Grouper": "Toolium Grouper",
      "Used in": "Brugt i",
      "Groups are created with the full master clips": "Grupper oprettes med de fulde masterklip",
      "Reference sub-clips for shortened clips": "Reference underklip til forkortede klip",
      "New avid multigroup type groups": "Nye ivrige grupper af multigruppetype",
      "Track renaming to group clip names": "Sporomdøbning til gruppeklipnavne",
      "Features": "Funktioner",
      "AAF uploads": "AAF uploads",
      "AAF upload": "AAF upload",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Grupper oprettes som de nye ivrige grupper med sorte mellemrum mellem klip på samme spor. En ny gruppe oprettes efter et mellemrum på tidslinjen.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Omdøber lydspor i grupperne i henhold til sekvensspornavnene. Du kan åbne så mange spor, som du har brug for, grupperne oprettes i henhold til kildelydsporet.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "BEHOLD LYD TIL: Splejser grupper inklusive den efterfølgende lyd til den endelige sekvens.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Router alle lydsporene i gruppen til sekvensen i fortløbende rækkefølge. Brug dette, hvis du har lyd fra mere end én enhed.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: Klassisk AVID-gruppering",
      "Process type unknown": "Procestype ukendt",
      "No clips found on sequence": "Ingen klip fundet i sekvensen",
      "No clips for writing sequence": "Ingen klip til skrivesekvens",
      "No sequence found in AAF file": "Ingen sekvens fundet i AAF-fil",
      "File is to big reduce AAF file": "Filen er for stor reduceret AAF-fil",
      "Sequence has length of 0": "Sekvensen har en længde på 0",
      "Group clip found on sequence": "Gruppeklip fundet på sekvens",
      "Group clip found on sequence 2": "Gruppeklip fundet i sekvens 2",
      "Unknown selector type found in sequence": "Ukendt vælgertype fundet i rækkefølge",
      "Clip framerate does not match sequence framerate": "Klip framerate matcher ikke sekvens framerate",
      "Subclips with motion effects are not supported": "Underklip med bevægelseseffekter understøttes ikke",
      "Join the best": "Slut dig til de bedste",
      "You are in good hands.": "Du er i gode hænder.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Assisterende redaktører fra disse produktioner er allerede glade Tooliumers, mere produktive og sparer tid",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Klip og rediger alle dine udtalelser eller dokumenter direkte fra dit Microsoft Word-dokument eller Google Docs ved blot at fremhæve din tekst og uploade din fil ved hjælp af vores proces",
      "Renames audio tracks within the group clips": "Omdøber lydspor i gruppeklippene",
      "Creates the new avid multigroup type groups": "Opretter de nye ivrige multigruppetypegrupper",
      "Creates avid sub-clips for shortened clips": "Opretter ivrige underklip til forkortede klip",
      "Editor": "Redaktør",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Vil du redigere i din browser ved hjælp af vores Avid Media Composer-kompatible editor?",
      "Yes, join pre launch": "Ja, deltag i pre-lanceringen",
      "No I don't want": "Nej, jeg vil ikke",
      "Click to Upload AAF": "Klik for at uploade AAF",
      "Bin panel": "Beholderpanel",
      "Go to Previous Event": "Gå til forrige begivenhed",
      "Go to Next Event": "Gå til næste begivenhed",
      "Step Backward 1 Fram": "Gå tilbage 1 Fram",
      "Step Forward 1 Fram": "Træd frem 1 Fram",
      "Mark IN": "Mærk IN",
      "Play": "Spil",
      "Mark OUT": "Afmærke",
      "Mark Clip": "Mark klip",
      "Clear Both Marks": "Fjern begge mærker",
      "Add Marker": "Tilføj markør",
      "Swap Cam Bank": "Byt kamerabank",
      "Find Bin": "Find bin",
      "Gang": "Bande",
      "Step backward 10 frames": "Gå 10 billeder tilbage",
      "Step forward 10 frames": "Gå 10 billeder frem",
      "Go to IN": "Gå til IN",
      "Play Length Toggle": "Spillængde skifte",
      "Go to OUT": "Gå til OUT",
      "Match Frame": "Matchramme",
      "Reverse Match Frame": "Omvendt matchramme",
      "Quad Split": "Quad Split",
      "Nine Split": "Ni Split",
      "Step Backward 1 Frame": "Gå tilbage 1 ramme",
      "Step Forward 1 Frame": "Træd frem 1 ramme",
      "Add Edit": "Tilføj Rediger",
      "Lift": "Løfte op",
      "Extract": "Uddrag",
      "Quick Transition": "Hurtig overgang",
      "Remove Effect": "Fjern effekt",
      "Collapse": "Bryder sammen",
      "Video Quality Menu": "Videokvalitetsmenu",
      "Monitor Volume": "Overvåg lydstyrke",
      "Meter Menu": "Meter menu",
      "Effect Mode": "Effekttilstand",
      "Trim Mode": "Trimtilstand",
      "Step In": "Træde til",
      "Step Out": "Gå ud",
      "Render Effect": "Render effekt",
      "Tool Palette": "Værktøjspalet",
      "Head Fade": "Hoved fade",
      "Tall Fade": "Høj Fade",
      "To the Left": "Til venstre",
      "To the Right": "Til højre",
      "Select In/Out": "Vælg Ind/Ud",
      "Link Selection Toggle": "Linkvalg til/fra",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "Standardopsætning",
      "View Type": "Visningstype",
      "Track Panel": "Sporpanel",
      "Effect Icons": "Effekt ikoner",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Opløs ikoner",
      "Clip Frames": "Kliprammer",
      "Clip Text": "Klip tekst",
      "Sync Breaks": "Synkroniseringspauser",
      "Dupe Detection": "Dupe Detektion",
      "Color Correction": "Farvekorrektion",
      "Audio Data": "Lyddata",
      "Clip Color...": "Klip farve...",
      "Segment Selection": "Segmentvalg",
      "Mark I/O Selection": "Markér I/O-valg",
      "Track Color": "Sporfarve",
      "Show markers": "Vis markører",
      "Show adapters": "Vis adaptere",
      "Show Track": "Vis spor",
      "Track Control Panel": "Spor kontrolpanel",
      "Zoom": "Zoom",
      "Open Comments": "Åbn kommentarer",
      "Call us at": "Ring til os på",
      "Office": "Kontor",
      "Tracks Info": "Spor info",
      "Edit Marker": "Rediger markør",
      "Add": "Tilføje",
      "Marker Name": "Markør navn",
      "Color": "Farve",
      "Frame": "Ramme",
      "Track": "Spore",
      "Clear Monitor": "Ryd skærm",
      "Clear Menu": "Ryd menu",
      "Show Markers": "Vis markører",
      "Show Adapters": "Vis adaptere",
      "Toggle Fullscreen": "Skift fuldskærm",
      "Highlighter": "Highlighter",
      "Logger": "Logger",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Ugyldig fil DOC, brug DOCX i stedet, du kan prøve dette 3. parts værktøj",
      "Open DOC to DOCX converter": "Åbn DOC til DOCX-konverter",
      "File is missing any cuts, try to click": "Filen mangler nogen klip, prøv at klikke",
      "SET ALL ROWS": "INDSTIL ALLE RÆKKER",
      "OFF: Timecodes have start and end": "FRA: Tidskoder har start og slut",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON: Ingen sluttidskode og tilføj blot 30 sekunder fra starttidskoden",
      "NO END TIMECODE USE 30 SECONDS": "INGEN SLUTTIDSKODE BRUG 30 SEKUNDER",
      "HAS END TIMECODE": "HAR SLUTTIDSKODE",
      "OFF: Create cuts using highlighted text": "FRA: Lav klip ved hjælp af fremhævet tekst",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "TIL: Opret klip for alle rækker i dokumentet. Ignorerer højdepunkterne",
      "ALL ROWS": "ALLE RÆKKER",
      "ONLY HIGHLIGHTED": "KUN MARKEDET",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "FRA: Ignorer kilde-camrolls. Dette vil kun skabe klip i henhold til kildetidskoden.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: Brug source camrolls. Dette vil skabe klip i henhold til kildetidskode og kildeklip-camroll",
      "USE CAMROLL IN DOCX": "BRUG CAMROLL I DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORER CAMROLL I DOCX",
      "OFF: AAF downloaded will not be renamed": "FRA: AAF downloadet vil ikke blive omdøbt",
      "ON: Rename AAF to DOCX file name": "ON: Omdøb AAF til DOCX filnavn",
      "RENAME AAF": "Omdøb AAF",
      "NO RENAME": "INGEN OMNØVN",
      "Click to Upload DOCX": "Klik for at uploade DOCX",
      "MULTI": "MULTI",
      "CLASSIC": "KLASSISK",
      "RENAME ON": "OMNØV TIL",
      "RENAME OFF": "OMNØV FRA",
      "KEEP AUDIO ON": "HOLD LYDEN TIL",
      "KEEP AUDIO OFF": "HOLD LYDEN FRA",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GRUPPELYD SOM ER TIL: Opretter grupper med lydsporene dirigeret nøjagtigt som de er placeret på din synkroniserede sekvens. Brug dette, hvis du ønsker, at din gruppes lyd skal dirigeres nøjagtigt som din sekvens",
      "GROUP AUDIO AS IS ON": "GRUPPELYD SOM ER TIL",
      "GROUP AUDIO AS IS OFF": "GRUPPER LYD SOM ER FRA",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Opretter grupper med kameravinkler på samme spor, som de er placeret på din synkroniserede sekvens. Brug dette, hvis du ønsker, at dine kameraer skal forblive i den samme gruppeplacering under hele optagelsen",
      "KEEP CAM ON TRACK ON": "HOLD CAM PÅ STRØB",
      "KEEP CAM ON TRACK OFF": "HOLD KAMMA PÅ SPORET FRA",
      "ROUTE AUDIO ON": "RUTELYD TIL",
      "ROUTE AUDIO OFF": "RUTE LYD FRA",
      "Toolium Editor": "Toolium Editor",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Upload en AAF-fil, og vi vil give dig en Avid Media Composer-kompatibel fjernbetjening hvor som helst editor og bagefter udlæse dig en grupperet AAF-fil til Avid Media Composer",
      "Free forever": "Gratis for evigt",
      "Collaborate with ease with all the team": "Samarbejd nemt med hele teamet",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Hold alle på samme side ved at skrive feedback direkte på en ramme. Eller tegne det",
      "Look and feel you are use to in Avid Media Composer": "Se og føle, du er vant til i Avid Media Composer",
      "Keyboard shortcuts you are use to": "Tastaturgenveje, du er vant til",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium er en central hub, der giver dig mulighed for effektivt at gruppere, skære og strømline din arbejdsgang, så dit team kan fremskynde dit arbejde og reducere leveringen med 4 timer!",
      "A-Side Trim Counter": "A-Side Trim tæller",
      "B-Side Trim Counter": "B-sidetæller",
      "Insert Track": "Indsæt spor",
      "OK": "Okay",
      "Track Type": "Sportype",
      "Track Num": "Spornummer",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Under TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "I løbet af 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Abs 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Mas 25",
      "Dur 25": "I løbet af 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Abs 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Sekvens",
      "Timecode": "Tidskode",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "I/O",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Optagelser",
      "Frames": "Rammer",
      "Source": "Kilde",
      "None": "Ingen",
      "Master": "Mestre",
      "Duration": "Varighed",
      "In/Out": "Ind ud",
      "Absolute": "Absolut",
      "Remain": "Forblive",
      "Audio Mono": "Lyd mono",
      "Audio Stereo": "Audio Stereo",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Data",
      "Video": "Video",
      "Leave your comment here...": "Skriv din kommentar her...",
      "Send": "Sende",
      "Workspace": "Arbejdsplads",
      "Project Settings": "Projektindstillinger",
      "Leave Project": "Forlad projektet",
      "Delete Project": "Slet projekt",
      "New Project": "Nyt projekt",
      "Name": "Navn",
      "Please, input the name": "Indtast venligst navnet",
      "Edit Project": "Rediger projekt",
      "Save Settings": "Gem indstillinger",
      "Jump to project of file...": "Hop til projekt af fil...",
      "Status": "Status",
      "Comments": "Kommentarer",
      "Size": "Størrelse",
      "Date uploaded": "Dato uploadet",
      "Uploader": "Uploader",
      "Action": "Handling",
      "File upload": "Fil upload",
      "Folder upload": "Upload af mappe",
      "Folder upload as proxy": "Mappe upload som proxy",
      "New folder": "Ny mappe",
      "New private folder": "Ny privat mappe",
      "Download all": "Download alle",
      "Recently deleted": "For nylig slettet",
      "Project settings": "Projektindstillinger",
      "Rename file": "Omdøb fil",
      "Move": "Bevæge sig",
      "Download": "Hent",
      "Make private": "Gør privat",
      "Reveal in project": "Afsløre i projekt",
      "Delete": "Slet",
      "File Information": "Filoplysninger",
      "Copy Comments": "Kopier kommentarer",
      "Paste Comments": "Indsæt kommentarer",
      "Print": "Print",
      "Download as File...": "Download som fil...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Simpel tekst",
      "Download for Final Cut Pro X": "Download til Final Cut Pro X",
      "Download for Media Composer": "Download til Media Composer",
      "Download for Resolve": "Download til løsning",
      "No comments": "Ingen kommentarer",
      "This is premium feature, contact us at support@toolium.org": "Dette er premium-funktion, kontakt os på support@toolium.org",
      "Automated test to speech": "Automatisk test til tale",
      "Import from uploads": "Importer fra uploads",
      "Upload your VO file": "Upload din VO-fil",
      "Record voice over": "Optag voice over",
      "Crop": "Afgrøde",
      "Voiceover": "Voice over",
      "Adjust colors": "Juster farver",
      "Trim": "Trimme",
      "Speed": "Fart",
      "Loop": "Løkke",
      "Flip": "Vend",
      "Effects": "Effekter",
      "Animation": "Animation",
      "Add title": "Tilføj titel",
      "Export": "Eksport",
      "Grid": "Gitter",
      "Split": "Dele",
      "Split View": "Split View",
      "Zoom to fit": "Zoom så den passer",
      "Audio meter": "Lydmåler",
      "TC": "TC",
      "Comment": "Kommentar",
      "Jump to Marker": "Hop til Marker",
      "Change Color": "Skift farve",
      "Change Track": "Skift spor",
      "Import Markers": "Importer markører",
      "Export Markers": "Eksporter markører",
      "Choose Columns": "Vælg kolonner",
      "Cameras": "Kameraer",
      "Desktop is required": "Desktop er påkrævet",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Vi har bemærket, at du bruger en enhed med mindre skærm. For den bedste oplevelse skal du bruge en desktop.",
      "Go back": "Gå tilbage",
      "OFF: Use 0 seconds for handles": "FRA: Brug 0 sekunder til håndtag",
      "ON: Use 5 seconds for handles": "ON: Brug 5 sekunder til håndtagene",
      "HANDLES OFF": "HÅNDTAG AF",
      "HANDLES ON": "HÅNDTAG PÅ",
      "OFF: word-per-second will be 0.37": "FRA: ord-per-sekund vil være 0,37",
      "ON: word-per-second will be detected": "ON: ord-per-sekund vil blive registreret",
      "DETECT WPS": "DETEKTER WPS",
      "WPS IS 0.37": "WPS ER 0,37",
      "OFF: don't change order or merge cuts": "FRA: skift ikke rækkefølge eller flet snit",
      "ON: reduce cuts by merging and sorting may change order": "ON: reducere nedskæringer ved at flette og sortering kan ændre rækkefølge",
      "USE REDUCE": "BRUG REDUCER",
      "KEEP ORDER AND CUTS": "HOLD ORDEN OG KÆR",
      "Timecode Errors": "Tidskodefejl",
      "Timecodes": "Tidskoder",
      "Source ID": "Kilde-id",
      "Error": "Fejl",
      "Log Out": "Log ud",
      "Sign Up": "Tilmelde",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Assisterende redaktører fra disse produktioner er allerede glade Tooliumers, mere produktive og sparer tid",
      "Redo": "Gentag",
      "Undo": "Fortryd",
      "You have no credits left": "Du har ingen kreditter tilbage",
      "You need to buy more credits in order to keep using our platform": "Du skal købe flere kreditter for at fortsætte med at bruge vores platform",
      "Note": "Bemærk",
      "You will still get free daily credits even if you don't make a purchase": "Du vil stadig få gratis daglige kreditter, selvom du ikke foretager et køb",
      "Benefits of buying credits": "Fordele ved at købe kreditter",
      "Bulk discount (up to 50%)": "Masserabat (op til 50 %)",
      "Get to request features to fit your worksflow": "Kom til at anmode om funktioner, der passer til din arbejdsgang",
      "You only pay for what you use": "Du betaler kun for det, du bruger",
      "No monthly membership and no automatic recurring payments": "Intet månedligt medlemskab og ingen automatiske tilbagevendende betalinger",
      "You get to support our site": "Du kan støtte vores side",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Hvis du virkelig har brug for flere kreditter, og du ikke har nogen penge, så send os et essay, der forklarer, hvorfor vi skal give dig gratis kreditter",
      "Buy credits": "Køb kreditter",
      "Ask for free credits, I don't have any money": "Bed om gratis kreditter, jeg har ingen penge",
      "Signup with Google to get 500 free credits": "Tilmeld dig hos Google for at få 500 gratis kreditter",
      "You will also get 150 additional free credits every day": "Du får også 150 ekstra gratis kreditter hver dag",
      "Select": "Vælg",
      "Credits": "Kreditter",
      "1 cut with Cutter = 20 credits": "1 klip med Cutter = 20 credits",
      "Discount": "Rabat",
      "Diamond": "Diamant",
      "Enterprise": "Enterprise",
      "7 days money back guarantee": "7 dages pengene tilbage garanti",
      "For any questions or issues": "For spørgsmål eller problemer",
      "Free": "Gratis",
      "Gold": "Guld",
      "1 group with Grouper": "1 gruppe med Grouper",
      "1 cut with Cutter": "1 klip med Cutter"
    }
  },
  "id": {
    "translation": {
      "Home": "Rumah",
      "Grouper": "Kerapu",
      "GROUPER": "Kerapu",
      "Logout": "Keluar",
      "Profile": "Profil",
      "Onboard": "Di atas kapal",
      "Cutter": "Pemotong",
      "Blog": "Blog",
      "Contact Us": "Hubungi kami",
      "Login": "Gabung",
      "Create group clips": "Buat klip grup",
      "made easy": "menjadi mudah",
      "Free Get started": "Gratis Memulai",
      "14 days free. No risk.": "14 hari gratis. Tidak ada risiko.",
      "Signup Free": "Pendaftaran Gratis",
      "Contact us with any question at": "Hubungi kami jika ada pertanyaan di",
      "Just one more step before you start Grouping": "Tinggal satu langkah lagi sebelum Anda memulai Pengelompokan",
      "First name": "Nama depan",
      "What is your first name": "Apa nama depan Anda",
      "Please input your first name!": "Silakan masukkan nama depan Anda!",
      "Last/family name": "Nama belakang",
      "What is your last/family name": "Apa nama belakang/keluarga Anda",
      "Please input your last/family name!": "Silakan masukkan nama belakang/keluarga Anda!",
      "Nickname": "Nama panggilan",
      "What do you want others to call you?": "Anda ingin orang lain memanggil Anda apa?",
      "Please input your nickname!": "Silakan masukkan nama panggilan Anda!",
      "Phone Number": "Nomor telepon",
      "What is your phone number?": "Berapa nomor telepon anda?",
      "Please input your phone number! Example +18777755232": "Silakan masukkan nomor telepon Anda! Contoh +18777755232",
      "I agree to the": "Saya setuju dengan",
      "privacy policy": "Kebijakan pribadi",
      "terms of service": "ketentuan layanan",
      "disclaimer": "penafian",
      "Privacy policy": "Kebijakan pribadi",
      "Terms of service": "Persyaratan layanan",
      "Disclaimer": "Penafian",
      "Submit": "Kirim",
      "Contact your Toolium representative to buy more credits": "Hubungi perwakilan Toolium Anda untuk membeli lebih banyak kredit",
      "See your credits in profile": "Lihat kredit Anda di profil",
      "Contact your Toolium representative to activate your account": "Hubungi perwakilan Toolium Anda untuk mengaktifkan akun Anda",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "unggah file AAF dari urutan tersinkronisasi Anda dan kami akan mengeluarkan file AAF yang dikelompokkan untuk Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Klik untuk Mengunggah atau Menyeret dan Melepaskan AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Silakan buka dan verifikasi email Anda sehingga Anda dapat terus menggunakan situs web. Kami mengirimi Anda email berisi tautan untuk diklik.",
      "Resend verification email": "Mengirim ulang email verifikasi",
      "Call us at +1-877-775-5232": "Hubungi kami di +1-877-775-5232",
      "Plan": "Rencana",
      "PLANS": "RENCANA",
      "Choose a plan that works for you": "Pilih paket yang sesuai untuk Anda",
      "Basic": "Dasar",
      "credit": "kredit",
      "year": "tahun",
      "Billed annually.": "Ditagih setiap tahun.",
      "Contact us": "Hubungi kami",
      "Recommended": "Direkomendasikan",
      "Standard": "Standar",
      "credits": "kredit",
      "Billed annually. Save": "Ditagih setiap tahun. Menyimpan",
      "Premium": "Premium",
      "Toolium Cutter": "Pemotong Toolium",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Potong dan edit semua testimonial atau dokumentasi Anda langsung dari dokumen Microsoft Word atau Google Docs hanya dengan mengetikkan teks dan mengunggah file Anda menggunakan proses kami",
      "Automate uncreative tasks": "Otomatiskan tugas yang tidak kreatif",
      "Save money processing media": "Menghemat media pemrosesan uang",
      "Save 4 hours every shoot day": "Hemat 4 jam setiap hari pengambilan gambar",
      "The next generation of post production workflow tools": "Alat alur kerja pasca produksi generasi berikutnya",
      "14 days free.": "14 hari gratis.",
      "Plans": "Rencana",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium adalah hub pusat yang memungkinkan Anda mengelompokkan, memotong, dan menyederhanakan alur kerja secara efisien sehingga tim Anda dapat mempercepat pekerjaan dan mengurangi pengiriman hingga 4 jam!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Unggah file AAF dari urutan tersinkronisasi Anda dan kami akan mengeluarkan file AAF yang dikelompokkan untuk Avid Media Composer",
      "Toolium Grouper": "Kerapu Toolium",
      "Used in": "Digunakan dalam",
      "Groups are created with the full master clips": "Grup dibuat dengan klip master lengkap",
      "Reference sub-clips for shortened clips": "Sub-klip referensi untuk klip yang dipersingkat",
      "New avid multigroup type groups": "Grup tipe multigrup baru yang rajin",
      "Track renaming to group clip names": "Lacak penggantian nama menjadi nama klip grup",
      "Features": "Fitur",
      "AAF uploads": "Unggahan AAF",
      "AAF upload": "Unggahan AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Grup dibuat sebagai grup keranjingan baru dengan spasi hitam di antara klip pada trek yang sama. Grup baru dibuat setelah spasi di timeline.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Mengganti nama trek audio di dalam grup sesuai dengan urutan nama trek. Anda dapat membuka trek sebanyak yang Anda perlukan, grup akan dibuat sesuai dengan trek audio sumber.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "TETAPKAN AUDIO AKTIF: Menyambungkan grup termasuk audio tambahan ke urutan terakhir.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Merutekan semua trek audio dalam grup ke urutan secara berurutan. Gunakan ini jika Anda memiliki audio dari lebih dari satu perangkat.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: Pengelompokan AVID klasik",
      "Process type unknown": "Jenis proses tidak diketahui",
      "No clips found on sequence": "Tidak ada klip yang ditemukan pada urutan",
      "No clips for writing sequence": "Tidak ada klip untuk urutan penulisan",
      "No sequence found in AAF file": "Tidak ada urutan yang ditemukan dalam file AAF",
      "File is to big reduce AAF file": "File akan memperkecil file AAF secara besar-besaran",
      "Sequence has length of 0": "Urutan memiliki panjang 0",
      "Group clip found on sequence": "Klip grup ditemukan secara berurutan",
      "Group clip found on sequence 2": "Klip grup ditemukan di urutan 2",
      "Unknown selector type found in sequence": "Jenis pemilih tidak diketahui ditemukan secara berurutan",
      "Clip framerate does not match sequence framerate": "Kecepatan bingkai klip tidak cocok dengan kecepatan bingkai urutan",
      "Subclips with motion effects are not supported": "Subklip dengan efek gerakan tidak didukung",
      "Join the best": "Bergabunglah dengan yang terbaik",
      "You are in good hands.": "Anda berada di tangan yang tepat.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Asisten editor dari produksi ini sudah menjadi Tooliumer yang bahagia, lebih produktif dan menghemat waktu",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Potong dan edit semua testimonial atau dokumentasi Anda langsung dari dokumen Microsoft Word atau Google Docs hanya dengan menyorot teks Anda dan mengunggah file Anda menggunakan proses kami",
      "Renames audio tracks within the group clips": "Mengganti nama trek audio dalam klip grup",
      "Creates the new avid multigroup type groups": "Membuat grup tipe multigrup baru yang rajin",
      "Creates avid sub-clips for shortened clips": "Membuat sub-klip yang rajin untuk klip yang dipersingkat",
      "Editor": "Editor",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Ingin mengedit di browser Anda menggunakan editor kami yang kompatibel dengan Avid Media Composer?",
      "Yes, join pre launch": "Ya, bergabunglah sebelum peluncuran",
      "No I don't want": "Tidak, aku tidak mau",
      "Click to Upload AAF": "Klik untuk Mengunggah AAF",
      "Bin panel": "Panel tempat sampah",
      "Go to Previous Event": "Buka Acara Sebelumnya",
      "Go to Next Event": "Pergi ke Acara Berikutnya",
      "Step Backward 1 Fram": "Langkah Mundur 1 Bingkai",
      "Step Forward 1 Fram": "Langkah Maju 1 Bingkai",
      "Mark IN": "Tandai DI",
      "Play": "Bermain",
      "Mark OUT": "Menandai",
      "Mark Clip": "Tandai Klip",
      "Clear Both Marks": "Hapus Kedua Tanda",
      "Add Marker": "Tambahkan Penanda",
      "Swap Cam Bank": "Tukar Bank Kamera",
      "Find Bin": "Temukan Bin",
      "Gang": "Gang",
      "Step backward 10 frames": "Mundur 10 frame",
      "Step forward 10 frames": "Maju 10 frame",
      "Go to IN": "Pergi ke DALAM",
      "Play Length Toggle": "Putar Panjang Beralih",
      "Go to OUT": "Pergi ke KELUAR",
      "Match Frame": "Bingkai Pertandingan",
      "Reverse Match Frame": "Bingkai Pencocokan Terbalik",
      "Quad Split": "Pemisahan Empat",
      "Nine Split": "Sembilan Berpisah",
      "Step Backward 1 Frame": "Langkah Mundur 1 Bingkai",
      "Step Forward 1 Frame": "Langkah Maju 1 Bingkai",
      "Add Edit": "Tambahkan Sunting",
      "Lift": "Mengangkat",
      "Extract": "Ekstrak",
      "Quick Transition": "Transisi Cepat",
      "Remove Effect": "Hapus Efek",
      "Collapse": "Runtuh",
      "Video Quality Menu": "Menu Kualitas Video",
      "Monitor Volume": "Pantau Volume",
      "Meter Menu": "Menu Meteran",
      "Effect Mode": "Mode Efek",
      "Trim Mode": "Mode Pangkas",
      "Step In": "Masuk",
      "Step Out": "Melangkah keluar",
      "Render Effect": "Efek Render",
      "Tool Palette": "Palet Alat",
      "Head Fade": "Kepala Memudar",
      "Tall Fade": "Pudar Tinggi",
      "To the Left": "Ke kiri",
      "To the Right": "Ke kanan",
      "Select In/Out": "Pilih Masuk/Keluar",
      "Link Selection Toggle": "Tombol Pilihan Tautan",
      "HW/SW": "HW / SW",
      "Titler Pro": "Judul Pro",
      "Default Setup": "Pengaturan Bawaan",
      "View Type": "Jenis Tampilan",
      "Track Panel": "Panel Lacak",
      "Effect Icons": "Ikon Efek",
      "Render Rangers": "Render Ranger",
      "Dissolve Icons": "Larutkan Ikon",
      "Clip Frames": "Bingkai Klip",
      "Clip Text": "Klip Teks",
      "Sync Breaks": "Sinkronisasi Istirahat",
      "Dupe Detection": "Deteksi Penipuan",
      "Color Correction": "Koreksi Warna",
      "Audio Data": "Data Audio",
      "Clip Color...": "Warna Klip...",
      "Segment Selection": "Seleksi Segmen",
      "Mark I/O Selection": "Tandai Pilihan I/O",
      "Track Color": "Warna Lacak",
      "Show markers": "Tunjukkan penanda",
      "Show adapters": "Tampilkan adaptor",
      "Show Track": "Tampilkan Lagu",
      "Track Control Panel": "Panel Kontrol Lacak",
      "Zoom": "Perbesar",
      "Open Comments": "Buka Komentar",
      "Call us at": "Hubungi kami di",
      "Office": "Kantor",
      "Tracks Info": "Info Trek",
      "Edit Marker": "Sunting Penanda",
      "Add": "Menambahkan",
      "Marker Name": "Nama Penanda",
      "Color": "Warna",
      "Frame": "Bingkai",
      "Track": "Melacak",
      "Clear Monitor": "Hapus Monitor",
      "Clear Menu": "Hapus Menu",
      "Show Markers": "Tampilkan Penanda",
      "Show Adapters": "Tampilkan Adaptor",
      "Toggle Fullscreen": "Alihkan Layar Penuh",
      "Highlighter": "Stabilo",
      "Logger": "penebang",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "File DOC tidak valid, gunakan DOCX sebagai gantinya, Anda dapat mencoba alat pihak ketiga ini",
      "Open DOC to DOCX converter": "Buka konverter DOC ke DOCX",
      "File is missing any cuts, try to click": "File tidak ada potongannya, coba klik",
      "SET ALL ROWS": "SETEL SEMUA BARIS",
      "OFF: Timecodes have start and end": "MATI: Kode waktu memiliki awal dan akhir",
      "ON: No end timecode and just add 30 seconds from start timecode": "AKTIF: Tidak ada kode waktu akhir dan cukup tambahkan 30 detik dari kode waktu awal",
      "NO END TIMECODE USE 30 SECONDS": "TANPA GUNAKAN KODE WAKTU AKHIR 30 DETIK",
      "HAS END TIMECODE": "TELAH AKHIR KODE WAKTU",
      "OFF: Create cuts using highlighted text": "MATI: Membuat potongan menggunakan teks yang disorot",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON: Membuat potongan untuk semua baris dalam dokumen. Mengabaikan hal-hal penting",
      "ALL ROWS": "SEMUA BARIS",
      "ONLY HIGHLIGHTED": "HANYA DIPERHATIKAN",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "MATI: Abaikan camroll sumber. Ini akan membuat pemotongan hanya berdasarkan kode waktu sumber.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: Gunakan camroll sumber. Ini akan membuat pemotongan sesuai dengan kode waktu sumber dan klip sumber camroll",
      "USE CAMROLL IN DOCX": "GUNAKAN CAMROLL DI DOCX",
      "IGNORE CAMROLL IN DOCX": "Abaikan CAMROLL DI DOCX",
      "OFF: AAF downloaded will not be renamed": "OFF: AAF yang diunduh tidak akan diganti namanya",
      "ON: Rename AAF to DOCX file name": "AKTIF: Ganti nama AAF menjadi nama file DOCX",
      "RENAME AAF": "GANTI NAMA AAF",
      "NO RENAME": "TIDAK ADA GANTI NAMA",
      "Click to Upload DOCX": "Klik untuk Mengunggah DOCX",
      "MULTI": "MULTI",
      "CLASSIC": "KLASIK",
      "RENAME ON": "GANTI NAMA AKTIF",
      "RENAME OFF": "GANTI NAMA MATI",
      "KEEP AUDIO ON": "TETAPKAN AUDIONYA",
      "KEEP AUDIO OFF": "TETAPKAN AUDIONYA",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO SEBAGAIMANA AKTIF: Membuat grup dengan trek audio yang dirutekan persis seperti yang ditempatkan pada urutan tersinkronisasi Anda. Gunakan ini jika Anda ingin audio grup Anda dirutekan persis seperti urutan Anda",
      "GROUP AUDIO AS IS ON": "AUDIO KELOMPOK SEPERTI AKTIF",
      "GROUP AUDIO AS IS OFF": "AUDIO GRUP SEBAGAI MATI",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "TETAPKAN CAM DI JALUR AKTIF: Membuat grup dengan sudut kamera pada trek yang sama dengan penempatannya pada urutan tersinkronisasi Anda. Gunakan ini jika Anda ingin kamera Anda tetap berada di penempatan grup yang sama selama keseluruhan pengambilan gambar",
      "KEEP CAM ON TRACK ON": "TETAPKAN CAM PADA JALUR",
      "KEEP CAM ON TRACK OFF": "TETAPKAN CAM PADA JALUR",
      "ROUTE AUDIO ON": "AUDIO RUTE AKTIF",
      "ROUTE AUDIO OFF": "AUDIO RUTE MATI",
      "Toolium Editor": "Editor Alatium",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Unggah file AAF dan kami akan memberi Anda editor jarak jauh yang kompatibel dengan Avid Media Composer dan setelah itu menghasilkan file AAF yang dikelompokkan untuk Avid Media Composer",
      "Free forever": "Bebas selamanya",
      "Collaborate with ease with all the team": "Berkolaborasi dengan mudah dengan seluruh tim",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Jaga agar semua orang mempunyai pemahaman yang sama dengan menulis masukan tepat di bingkai. Atau menggambarnya",
      "Look and feel you are use to in Avid Media Composer": "Tampilan dan nuansa yang biasa Anda gunakan di Avid Media Composer",
      "Keyboard shortcuts you are use to": "Pintasan keyboard yang biasa Anda gunakan",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium adalah hub pusat yang memungkinkan Anda mengelompokkan, memotong, dan menyederhanakan alur kerja secara efisien sehingga tim Anda dapat mempercepat pekerjaan dan mengurangi pengiriman hingga 4 jam!",
      "A-Side Trim Counter": "Penghitung Trim Sisi A",
      "B-Side Trim Counter": "Penghitung Trim Sisi B",
      "Insert Track": "Sisipkan Trek",
      "OK": "OKE",
      "Track Type": "Tipe Lintasan",
      "Track Num": "Nomor Lacak",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Selama TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "24 Mei",
      "Dur 24": "Selama 24",
      "I/O 24": "masukan/keluaran 24",
      "Abs 24": "perut 24",
      "Rem 24": "Rem 24",
      "Mas 25": "tanggal 25",
      "Dur 25": "Selama 25",
      "I/O 25": "masukan/keluaran 25",
      "Abs 25": "perut 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Selama Frm",
      "I/O Frm": "I/O Dari",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Urutan",
      "Timecode": "Kode waktu",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "masukan/keluaran",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Rekaman",
      "Frames": "Bingkai",
      "Source": "Sumber",
      "None": "Tidak ada",
      "Master": "Menguasai",
      "Duration": "Durasi",
      "In/Out": "Masuk/Keluar",
      "Absolute": "Mutlak",
      "Remain": "Tetap",
      "Audio Mono": "Audio Mono",
      "Audio Stereo": "Audio Stereo",
      "5.1 Surround": "5.1 Keliling",
      "7.1 Surround": "7.1 Keliling",
      "Data": "Data",
      "Video": "Video",
      "Leave your comment here...": "Tinggalkan komentar Anda di sini...",
      "Send": "Mengirim",
      "Workspace": "Ruang kerja",
      "Project Settings": "Pengaturan Proyek",
      "Leave Project": "Tinggalkan Proyek",
      "Delete Project": "Hapus Proyek",
      "New Project": "Proyek baru",
      "Name": "Nama",
      "Please, input the name": "Silakan masukkan namanya",
      "Edit Project": "Sunting Proyek",
      "Save Settings": "Simpan Pengaturan",
      "Jump to project of file...": "Lompat ke proyek file...",
      "Status": "Status",
      "Comments": "Komentar",
      "Size": "Ukuran",
      "Date uploaded": "Tanggal diunggah",
      "Uploader": "Pengunggah",
      "Action": "Tindakan",
      "File upload": "Unggah berkas",
      "Folder upload": "Unggah folder",
      "Folder upload as proxy": "Unggah folder sebagai proksi",
      "New folder": "Folder baru",
      "New private folder": "Folder pribadi baru",
      "Download all": "Unduh semuanya",
      "Recently deleted": "Baru-baru ini dihapus",
      "Project settings": "Pengaturan proyek",
      "Rename file": "Ganti nama berkas",
      "Move": "Bergerak",
      "Download": "Unduh",
      "Make private": "Jadikan pribadi",
      "Reveal in project": "Ungkapkan dalam proyek",
      "Delete": "Menghapus",
      "File Information": "Informasi Berkas",
      "Copy Comments": "Salin Komentar",
      "Paste Comments": "Tempel Komentar",
      "Print": "Mencetak",
      "Download as File...": "Unduh sebagai File...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Teks Biasa",
      "Download for Final Cut Pro X": "Unduh untuk Final Cut Pro X",
      "Download for Media Composer": "Unduh untuk Komposer Media",
      "Download for Resolve": "Unduh untuk Resolve",
      "No comments": "Tidak ada komentar",
      "This is premium feature, contact us at support@toolium.org": "Ini adalah fitur premium, hubungi kami di support@toolium.org",
      "Automated test to speech": "Tes otomatis untuk berbicara",
      "Import from uploads": "Impor dari unggahan",
      "Upload your VO file": "Unggah file VO Anda",
      "Record voice over": "Rekam suara",
      "Crop": "Tanaman",
      "Voiceover": "Pengisi suara",
      "Adjust colors": "Sesuaikan warna",
      "Trim": "Memangkas",
      "Speed": "Kecepatan",
      "Loop": "Lingkaran",
      "Flip": "Balik",
      "Effects": "Efek",
      "Animation": "Animasi",
      "Add title": "Tambahkan judul",
      "Export": "Ekspor",
      "Grid": "jaringan",
      "Split": "Membelah",
      "Split View": "Tampilan Terpisah",
      "Zoom to fit": "Perbesar agar pas",
      "Audio meter": "Pengukur audio",
      "TC": "karena",
      "Comment": "Komentar",
      "Jump to Marker": "Lompat ke Penanda",
      "Change Color": "Ganti warna",
      "Change Track": "Ganti Jalur",
      "Import Markers": "Impor Penanda",
      "Export Markers": "Ekspor Penanda",
      "Choose Columns": "Pilih Kolom",
      "Cameras": "Kamera",
      "Desktop is required": "Desktop diperlukan",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Kami memperhatikan bahwa Anda menggunakan perangkat dengan layar lebih kecil. Untuk pengalaman terbaik, silakan gunakan desktop.",
      "Go back": "Kembali",
      "OFF: Use 0 seconds for handles": "MATI: Gunakan 0 detik untuk pegangan",
      "ON: Use 5 seconds for handles": "ON: Gunakan 5 detik untuk pegangan",
      "HANDLES OFF": "TANGAN MATI",
      "HANDLES ON": "MENANGANI",
      "OFF: word-per-second will be 0.37": "MATI: kata per detik akan menjadi 0,37",
      "ON: word-per-second will be detected": "ON: kata per detik akan terdeteksi",
      "DETECT WPS": "DETEKSI WPS",
      "WPS IS 0.37": "WPS ADALAH 0,37",
      "OFF: don't change order or merge cuts": "OFF: tidak mengubah urutan atau menggabungkan potongan",
      "ON: reduce cuts by merging and sorting may change order": "ON: mengurangi pemotongan dengan menggabungkan dan menyortir dapat mengubah urutan",
      "USE REDUCE": "GUNAKAN KURANGI",
      "KEEP ORDER AND CUTS": "TETAPKAN PESANAN DAN POTONGAN",
      "Timecode Errors": "Kesalahan Kode Waktu",
      "Timecodes": "Kode waktu",
      "Source ID": "ID Sumber",
      "Error": "Kesalahan",
      "Log Out": "Keluar",
      "Sign Up": "Mendaftar",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Asisten editor dari produksi ini sudah menjadi Tooliumer yang bahagia, lebih produktif dan menghemat waktu",
      "Redo": "Mengulangi",
      "Undo": "Membuka",
      "You have no credits left": "Anda tidak memiliki kredit tersisa",
      "You need to buy more credits in order to keep using our platform": "Anda perlu membeli lebih banyak kredit untuk terus menggunakan platform kami",
      "Note": "Catatan",
      "You will still get free daily credits even if you don't make a purchase": "Anda tetap akan mendapatkan kredit harian gratis meskipun Anda tidak melakukan pembelian",
      "Benefits of buying credits": "Keuntungan membeli pulsa",
      "Bulk discount (up to 50%)": "Diskon massal (hingga 50%)",
      "Get to request features to fit your worksflow": "Dapatkan untuk meminta fitur agar sesuai dengan alur kerja Anda",
      "You only pay for what you use": "Anda hanya membayar untuk apa yang Anda gunakan",
      "No monthly membership and no automatic recurring payments": "Tidak ada keanggotaan bulanan dan tidak ada pembayaran berulang otomatis",
      "You get to support our site": "Anda dapat mendukung situs kami",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Jika Anda benar-benar membutuhkan lebih banyak kredit dan Anda tidak punya uang, kirimkan esai yang menjelaskan mengapa kami harus memberi Anda kredit gratis",
      "Buy credits": "Membeli kredit",
      "Ask for free credits, I don't have any money": "Minta kredit gratis, saya tidak punya uang",
      "Signup with Google to get 500 free credits": "Daftar ke Google untuk mendapatkan 500 kredit gratis",
      "You will also get 150 additional free credits every day": "Anda juga akan mendapatkan 150 kredit gratis tambahan setiap hari",
      "Select": "Pilih",
      "Credits": "Kredit",
      "1 cut with Cutter = 20 credits": "1 potong dengan Cutter = 20 SKS",
      "Discount": "Diskon",
      "Diamond": "berlian",
      "Enterprise": "Perusahaan",
      "7 days money back guarantee": "Jaminan uang kembali 7 hari",
      "For any questions or issues": "Untuk pertanyaan atau masalah apa pun",
      "Free": "Bebas",
      "Gold": "Emas",
      "1 group with Grouper": "1 kelompok dengan Kerapu",
      "1 cut with Cutter": "1 potong dengan Cutter"
    }
  },
  "ru": {
    "translation": {
      "Home": "Дом",
      "Grouper": "Групер",
      "GROUPER": "ГРУППИРОВКА",
      "Logout": "Выйти",
      "Profile": "Профиль",
      "Onboard": "На борту",
      "Cutter": "Резак",
      "Blog": "Блог",
      "Contact Us": "Связаться с нами",
      "Login": "Авторизоваться",
      "Create group clips": "Создание групповых клипов",
      "made easy": "стало проще",
      "Free Get started": "Бесплатно Начало работы",
      "14 days free. No risk.": "14 дней бесплатно. Без риска.",
      "Signup Free": "Бесплатная регистрация",
      "Contact us with any question at": "Свяжитесь с нами по любому вопросу по адресу",
      "Just one more step before you start Grouping": "Еще один шаг, прежде чем вы начнете группировать",
      "First name": "Имя",
      "What is your first name": "Как тебя зовут",
      "Please input your first name!": "Пожалуйста, введите свое имя!",
      "Last/family name": "Фамилия/фамилия",
      "What is your last/family name": "Какова ваша фамилия/фамилия?",
      "Please input your last/family name!": "Пожалуйста, введите свою фамилию/фамилию!",
      "Nickname": "Псевдоним",
      "What do you want others to call you?": "Как вы хотите, чтобы вас называли другие?",
      "Please input your nickname!": "Пожалуйста, введите свой никнейм!",
      "Phone Number": "Номер телефона",
      "What is your phone number?": "Какой у тебя номер телефона?",
      "Please input your phone number! Example +18777755232": "Пожалуйста, введите свой номер телефона! Пример +18777755232",
      "I agree to the": "я согласен",
      "privacy policy": "политика конфиденциальности",
      "terms of service": "Условия использования",
      "disclaimer": "отказ от ответственности",
      "Privacy policy": "Политика конфиденциальности",
      "Terms of service": "Условия использования",
      "Disclaimer": "Отказ от ответственности",
      "Submit": "Представлять на рассмотрение",
      "Contact your Toolium representative to buy more credits": "Свяжитесь с представителем Toolium, чтобы купить больше кредитов.",
      "See your credits in profile": "Смотрите свои кредиты в профиле",
      "Contact your Toolium representative to activate your account": "Свяжитесь с представителем Toolium, чтобы активировать вашу учетную запись.",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "загрузите файл AAF вашей синхронизированной последовательности, и мы выведем вам сгруппированный файл AAF для Avid Media Composer.",
      "Click to Upload or Drag and Drop an AAF": "Нажмите, чтобы загрузить или перетащить AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Пожалуйста, зайдите и подтвердите свой адрес электронной почты, чтобы продолжить использование веб-сайта. Мы отправили вам электронное письмо со ссылкой, по которой можно перейти.",
      "Resend verification email": "Выслать повторно письмо для подтверждения",
      "Call us at +1-877-775-5232": "Позвоните нам по телефону +1-877-775-5232.",
      "Plan": "План",
      "PLANS": "ПЛАНЫ",
      "Choose a plan that works for you": "Выберите план, который подходит вам",
      "Basic": "Базовый",
      "credit": "кредит",
      "year": "год",
      "Billed annually.": "Счет ежегодно.",
      "Contact us": "Связаться с нами",
      "Recommended": "рекомендуемые",
      "Standard": "Стандартный",
      "credits": "кредиты",
      "Billed annually. Save": "Счет ежегодно. Сохранять",
      "Premium": "Премиум",
      "Toolium Cutter": "Инструментальный резак",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Вырезайте и редактируйте все свои отзывы или документальные материалы прямо из документа Microsoft Word или Google Docs, просто выделив текст и загрузив файл с помощью нашего процесса.",
      "Automate uncreative tasks": "Автоматизируйте нетворческие задачи",
      "Save money processing media": "Экономьте деньги на обработке средств массовой информации",
      "Save 4 hours every shoot day": "Экономьте 4 часа каждый съемочный день",
      "The next generation of post production workflow tools": "Следующее поколение инструментов постобработки",
      "14 days free.": "14 дней бесплатно.",
      "Plans": "Планы",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium — это центральный хаб, который позволяет вам эффективно группировать, сокращать и оптимизировать рабочий процесс, чтобы ваша команда могла ускорить вашу работу и сократить время выполнения на 4 часа!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Загрузите файл AAF вашей синхронизированной последовательности, и мы выведем вам сгруппированный файл AAF для Avid Media Composer.",
      "Toolium Grouper": "Инструментальный группер",
      "Used in": "Используется в",
      "Groups are created with the full master clips": "Группы создаются с полными мастер-клипами.",
      "Reference sub-clips for shortened clips": "Справочные субклипы для сокращенных клипов",
      "New avid multigroup type groups": "Новые заядлые группы мультигруппового типа",
      "Track renaming to group clip names": "Переименование трека в группу названий клипов",
      "Features": "Функции",
      "AAF uploads": "загрузки AAF",
      "AAF upload": "загрузка AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "МУЛЬТИ: Группы создаются как новые группы с черными пробелами между клипами на одной и той же дорожке. Новая группа создается после пробела на временной шкале.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: переименовывает аудиодорожки внутри групп в соответствии с названиями дорожек последовательности. Вы можете открыть столько треков, сколько вам нужно, группы будут созданы в соответствии с исходной аудиодорожкой.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: объединяет группы, включая завершающий звук, в финальную последовательность.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: направляет все аудиодорожки в группе в последовательность в последовательном порядке. Используйте это, если у вас есть звук с нескольких устройств.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: классическая группировка AVID.",
      "Process type unknown": "Тип процесса неизвестен",
      "No clips found on sequence": "В эпизоде ​​не найдено ни одного клипа",
      "No clips for writing sequence": "Нет клипов для написания последовательности",
      "No sequence found in AAF file": "В файле AAF последовательность не найдена",
      "File is to big reduce AAF file": "Файл слишком большой, уменьшите файл AAF.",
      "Sequence has length of 0": "Последовательность имеет длину 0",
      "Group clip found on sequence": "Групповой клип найден в эпизоде",
      "Group clip found on sequence 2": "Групповой клип найден в эпизоде ​​2.",
      "Unknown selector type found in sequence": "Неизвестный тип селектора найден в последовательности",
      "Clip framerate does not match sequence framerate": "Частота кадров клипа не соответствует частоте кадров эпизода",
      "Subclips with motion effects are not supported": "Подклипы с эффектами движения не поддерживаются.",
      "Join the best": "Присоединяйтесь к лучшим",
      "You are in good hands.": "Вы в надежных руках.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Помощники редакторов этих проектов уже довольны инструментами, более продуктивными и экономящими время.",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Вырезайте и редактируйте все свои отзывы или документальные материалы прямо из документа Microsoft Word или Google Docs, просто выделив текст и загрузив файл с помощью нашего процесса.",
      "Renames audio tracks within the group clips": "Переименовывает аудиодорожки в групповых клипах.",
      "Creates the new avid multigroup type groups": "Создает новые заядлые группы мультигруппового типа.",
      "Creates avid sub-clips for shortened clips": "Создает дополнительные фрагменты для сокращенных клипов.",
      "Editor": "редактор",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Хотите редактировать в своем браузере с помощью нашего редактора, совместимого с Avid Media Composer?",
      "Yes, join pre launch": "Да, присоединиться к предварительному запуску",
      "No I don't want": "Нет, я не хочу",
      "Click to Upload AAF": "Нажмите, чтобы загрузить AAF",
      "Bin panel": "Панель бункера",
      "Go to Previous Event": "Перейти к предыдущему событию",
      "Go to Next Event": "Перейти к следующему событию",
      "Step Backward 1 Fram": "Шаг назад на 1 кадр",
      "Step Forward 1 Fram": "Шаг вперед на 1 кадр",
      "Mark IN": "Отметить IN",
      "Play": "Играть",
      "Mark OUT": "Выделить",
      "Mark Clip": "Марк Клип",
      "Clear Both Marks": "Очистить обе отметки",
      "Add Marker": "Добавить маркер",
      "Swap Cam Bank": "Обмен банка камер",
      "Find Bin": "Найти Бин",
      "Gang": "Банда",
      "Step backward 10 frames": "Шаг назад на 10 кадров.",
      "Step forward 10 frames": "Шаг вперед на 10 кадров.",
      "Go to IN": "Перейти в ИН",
      "Play Length Toggle": "Переключатель продолжительности воспроизведения",
      "Go to OUT": "Перейти к ВЫХОД",
      "Match Frame": "Матч кадра",
      "Reverse Match Frame": "Обратный кадр совпадения",
      "Quad Split": "Четырехместный сплит",
      "Nine Split": "Девять Сплит",
      "Step Backward 1 Frame": "Шаг назад на 1 кадр",
      "Step Forward 1 Frame": "Шаг вперед на 1 кадр",
      "Add Edit": "Добавить Редактировать",
      "Lift": "Поднимать",
      "Extract": "Извлекать",
      "Quick Transition": "Быстрый переход",
      "Remove Effect": "Удалить эффект",
      "Collapse": "Крах",
      "Video Quality Menu": "Меню качества видео",
      "Monitor Volume": "Громкость монитора",
      "Meter Menu": "Меню счетчика",
      "Effect Mode": "Режим эффектов",
      "Trim Mode": "Режим обрезки",
      "Step In": "Шаг вперед",
      "Step Out": "Выйти",
      "Render Effect": "Эффект рендеринга",
      "Tool Palette": "Палитра инструментов",
      "Head Fade": "Голова исчезает",
      "Tall Fade": "Высокий Фейд",
      "To the Left": "Слева",
      "To the Right": "Направо",
      "Select In/Out": "Выберите вход/выход",
      "Link Selection Toggle": "Переключатель выбора ссылки",
      "HW/SW": "Аппаратное обеспечение/ПО",
      "Titler Pro": "Титлер Про",
      "Default Setup": "Настройка по умолчанию",
      "View Type": "Тип просмотра",
      "Track Panel": "Трековая панель",
      "Effect Icons": "Значки эффектов",
      "Render Rangers": "Рендер Рейнджерс",
      "Dissolve Icons": "Растворить значки",
      "Clip Frames": "Клип-кадры",
      "Clip Text": "Вырезать текст",
      "Sync Breaks": "Синхронизация перерывов",
      "Dupe Detection": "Обнаружение обмана",
      "Color Correction": "Коррекция цвета",
      "Audio Data": "Аудиоданные",
      "Clip Color...": "Цвет клипа...",
      "Segment Selection": "Выбор сегмента",
      "Mark I/O Selection": "Отметить выбор ввода/вывода",
      "Track Color": "Цвет трека",
      "Show markers": "Показать маркеры",
      "Show adapters": "Показать адаптеры",
      "Show Track": "Показать трек",
      "Track Control Panel": "Панель управления треком",
      "Zoom": "Увеличить",
      "Open Comments": "Открыть комментарии",
      "Call us at": "Позвоните нам по",
      "Office": "Офис",
      "Tracks Info": "Информация о треках",
      "Edit Marker": "Редактировать маркер",
      "Add": "Добавлять",
      "Marker Name": "Имя маркера",
      "Color": "Цвет",
      "Frame": "Рамка",
      "Track": "Отслеживать",
      "Clear Monitor": "Очистить монитор",
      "Clear Menu": "Очистить меню",
      "Show Markers": "Показать маркеры",
      "Show Adapters": "Показать адаптеры",
      "Toggle Fullscreen": "Включить полноэкранный режим",
      "Highlighter": "Хайлайтер",
      "Logger": "Регистратор",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Неверный файл DOC. Вместо этого используйте DOCX. Вы можете попробовать этот сторонний инструмент.",
      "Open DOC to DOCX converter": "Открыть конвертер DOC в DOCX",
      "File is missing any cuts, try to click": "В файле отсутствуют какие-либо фрагменты, попробуйте нажать",
      "SET ALL ROWS": "УСТАНОВИТЬ ВСЕ СТРОКИ",
      "OFF: Timecodes have start and end": "ВЫКЛ: таймкоды имеют начало и конец.",
      "ON: No end timecode and just add 30 seconds from start timecode": "ВКЛ: Нет конечного тайм-кода, просто добавьте 30 секунд от начального тайм-кода.",
      "NO END TIMECODE USE 30 SECONDS": "НЕТ КОНЕЦ ТАЙМКОДА ИСПОЛЬЗОВАТЬ 30 СЕКУНД",
      "HAS END TIMECODE": "ЕСТЬ КОНЕЦ ТАЙМКОДА",
      "OFF: Create cuts using highlighted text": "ВЫКЛ.: создавать вырезы с использованием выделенного текста.",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ВКЛ.: создавать вырезы для всех строк документа. Игнорирование основных моментов",
      "ALL ROWS": "ВСЕ РЯДЫ",
      "ONLY HIGHLIGHTED": "ТОЛЬКО ВЫДЕЛЕНЫ",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "ВЫКЛ: игнорировать исходные видеозаписи. Это приведет к созданию сокращений только в соответствии с исходным тайм-кодом.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ВКЛ.: использовать исходные камеры. Это создаст обрезки в соответствии с исходным тайм-кодом и исходным клипом camroll.",
      "USE CAMROLL IN DOCX": "ИСПОЛЬЗОВАТЬ CAMROLL В DOCX",
      "IGNORE CAMROLL IN DOCX": "ИГНОРИРОВАТЬ CAMROLL В DOCX",
      "OFF: AAF downloaded will not be renamed": "ВЫКЛ: загруженный AAF не будет переименован.",
      "ON: Rename AAF to DOCX file name": "ВКЛ.: переименовать файл AAF в имя файла DOCX.",
      "RENAME AAF": "ПЕРЕИМЕНОВАТЬ ААФ",
      "NO RENAME": "НЕТ ПЕРЕИМЕНОВАНИЯ",
      "Click to Upload DOCX": "Нажмите, чтобы загрузить DOCX",
      "MULTI": "МУЛЬТИ",
      "CLASSIC": "КЛАССИК",
      "RENAME ON": "ПЕРЕИМЕНОВАТЬ ВКЛ.",
      "RENAME OFF": "ПЕРЕИМЕНОВАТЬ ВЫКЛ.",
      "KEEP AUDIO ON": "ОСТАВЛЯТЬ АУДИО ВКЛЮЧЕННЫМ",
      "KEEP AUDIO OFF": "ВЫКЛЮЧИТЬ ЗВУК",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "ГРУППИРОВАТЬ АУДИО КАК ЕСТЬ: Создает группы, в которых звуковые дорожки маршрутизируются точно так, как они расположены в синхронизированном эпизоде. Используйте это, если вы хотите, чтобы звук ваших групп маршрутизировался точно так же, как ваша последовательность.",
      "GROUP AUDIO AS IS ON": "ГРУППОВОЕ АУДИО КАК ЕСТЬ ВКЛ.",
      "GROUP AUDIO AS IS OFF": "ГРУППОВОЕ АУДИО КАК ВЫКЛЮЧЕНО",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Создает группы с ракурсами камеры на той же дорожке, где они размещены в синхронизированном эпизоде. Используйте это, если вы хотите, чтобы ваши камеры оставались в одном и том же групповом расположении на протяжении всей съемки.",
      "KEEP CAM ON TRACK ON": "ДЕРЖИТЕ КАМЕРУ НА СЛЕЖЕНИИ ВКЛЮЧЕННОЙ",
      "KEEP CAM ON TRACK OFF": "ДЕРЖИТЕ КАМЕРУ НА ТРЕКЕ ВЫКЛ.",
      "ROUTE AUDIO ON": "МАРШРУТИРОВАТЬ АУДИО ВКЛ.",
      "ROUTE AUDIO OFF": "МАРШРУТ АУДИО ВЫКЛ.",
      "Toolium Editor": "Редактор инструментов",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Загрузите файл AAF, и мы предоставим вам удаленный редактор, совместимый с Avid Media Composer, а затем выведем вам сгруппированный файл AAF для Avid Media Composer.",
      "Free forever": "Бесплатно навсегда",
      "Collaborate with ease with all the team": "Легко сотрудничайте со всей командой",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Держите всех на одной волне, написав отзыв прямо в рамке. Или нарисуй это",
      "Look and feel you are use to in Avid Media Composer": "Внешний вид, к которому вы привыкли в Avid Media Composer",
      "Keyboard shortcuts you are use to": "Сочетания клавиш, которые вы используете",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium — это центральный хаб, который позволяет эффективно группировать, сокращать и оптимизировать рабочий процесс, чтобы ваша команда могла ускорить вашу работу и сократить время выполнения на 4 часа!",
      "A-Side Trim Counter": "Счетчик обрезки стороны A",
      "B-Side Trim Counter": "Счетчик обрезки стороны B",
      "Insert Track": "Вставить трек",
      "OK": "ХОРОШО",
      "Track Type": "Тип трека",
      "Track Num": "Номер трека",
      "Mas TC1": "Мас TC1",
      "Dur TC1": "Дур TC1",
      "I/O TC1": "Ввод/вывод TC1",
      "Abs TC1": "Абс ТС1",
      "Rem TC1": "Рем TC1",
      "Mas 24": "Мас 24",
      "Dur 24": "Дюр 24",
      "I/O 24": "ввод/вывод 24",
      "Abs 24": "Абс 24",
      "Rem 24": "Рем 24",
      "Mas 25": "Мас 25",
      "Dur 25": "Дюр 25",
      "I/O 25": "Ввод/вывод 25",
      "Abs 25": "Абс 25",
      "Rem 25": "Рем 25",
      "Mas Frm": "Мас Фрм",
      "Dur Frm": "Дур Фрм",
      "I/O Frm": "Формат ввода-вывода",
      "Abs Frm": "Абс Фрм",
      "Rem Frm": "Рем Фрм",
      "Sequence": "Последовательность",
      "Timecode": "Таймкод",
      "TC1": "ТК1",
      "Mas": "Мас",
      "Dur": "Дюр",
      "I/O": "ввод/вывод",
      "Abs": "пресс",
      "Rem": "Рем",
      "Footage": "Отснятый материал",
      "Frames": "Рамки",
      "Source": "Источник",
      "None": "Никто",
      "Master": "Владелец",
      "Duration": "Продолжительность",
      "In/Out": "Вход/Выход",
      "Absolute": "Абсолютный",
      "Remain": "Оставаться",
      "Audio Mono": "Аудио Моно",
      "Audio Stereo": "Аудио Стерео",
      "5.1 Surround": "5.1 Объемное звучание",
      "7.1 Surround": "7.1 Объемное звучание",
      "Data": "Данные",
      "Video": "видео",
      "Leave your comment here...": "Оставьте свой комментарий здесь...",
      "Send": "Отправлять",
      "Workspace": "Рабочая среда",
      "Project Settings": "Настройки проекта",
      "Leave Project": "Покинуть проект",
      "Delete Project": "Удалить проект",
      "New Project": "Новый проект",
      "Name": "Имя",
      "Please, input the name": "Пожалуйста, введите имя",
      "Edit Project": "Редактировать проект",
      "Save Settings": "Сохранить настройки",
      "Jump to project of file...": "Перейти к проекту файла...",
      "Status": "Положение дел",
      "Comments": "Комментарии",
      "Size": "Размер",
      "Date uploaded": "Дата загрузки",
      "Uploader": "Загрузчик",
      "Action": "Действие",
      "File upload": "Файл загружен",
      "Folder upload": "Загрузка папки",
      "Folder upload as proxy": "Загрузка папки в качестве прокси",
      "New folder": "Новая папка",
      "New private folder": "Новая личная папка",
      "Download all": "Скачать все",
      "Recently deleted": "Недавно удалено",
      "Project settings": "Настройки проекта",
      "Rename file": "Переименуйте файл",
      "Move": "Двигаться",
      "Download": "Скачать",
      "Make private": "Сделать личное",
      "Reveal in project": "Раскрыть в проекте",
      "Delete": "Удалить",
      "File Information": "Информация о файле",
      "Copy Comments": "Копировать комментарии",
      "Paste Comments": "Вставить комментарии",
      "Print": "Распечатать",
      "Download as File...": "Скачать как файл...",
      "CSV": "CSV-файл",
      "XML": "XML",
      "Plain Text": "Простой текст",
      "Download for Final Cut Pro X": "Скачать для Final Cut Pro X",
      "Download for Media Composer": "Скачать для Media Composer",
      "Download for Resolve": "Скачать для решения",
      "No comments": "Без комментариев",
      "This is premium feature, contact us at support@toolium.org": "Это премиум-функция, свяжитесь с нами по адресу support@toolium.org.",
      "Automated test to speech": "Автоматизированный тест на речь",
      "Import from uploads": "Импорт из загрузок",
      "Upload your VO file": "Загрузите свой VO-файл",
      "Record voice over": "Записать голос за кадром",
      "Crop": "Обрезать",
      "Voiceover": "Закадровый голос",
      "Adjust colors": "Настройка цветов",
      "Trim": "Подрезать",
      "Speed": "Скорость",
      "Loop": "Петля",
      "Flip": "Подбросить",
      "Effects": "Последствия",
      "Animation": "Анимация",
      "Add title": "Добавить заголовок",
      "Export": "Экспорт",
      "Grid": "Сетка",
      "Split": "Расколоть",
      "Split View": "Разделенный вид",
      "Zoom to fit": "Масштабировать по размеру",
      "Audio meter": "Аудиометр",
      "TC": "ТК",
      "Comment": "Комментарий",
      "Jump to Marker": "Перейти к маркеру",
      "Change Color": "Изменить цвет",
      "Change Track": "Изменить трек",
      "Import Markers": "Импортировать маркеры",
      "Export Markers": "Экспортировать маркеры",
      "Choose Columns": "Выберите столбцы",
      "Cameras": "Камеры",
      "Desktop is required": "Требуется рабочий стол",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Мы заметили, что вы используете устройство с меньшим экраном. Для получения наилучших результатов используйте рабочий стол.",
      "Go back": "Возвращаться",
      "OFF: Use 0 seconds for handles": "ВЫКЛ: используйте 0 секунд для дескрипторов.",
      "ON: Use 5 seconds for handles": "ВКЛ.: использовать 5 секунд для дескрипторов.",
      "HANDLES OFF": "РУЧКИ ВЫКЛ.",
      "HANDLES ON": "РУЧКИ НА",
      "OFF: word-per-second will be 0.37": "ВЫКЛ: слово в секунду будет 0,37.",
      "ON: word-per-second will be detected": "ВКЛ: будет определяться слово в секунду",
      "DETECT WPS": "ОБНАРУЖИТЬ WPS",
      "WPS IS 0.37": "WPS 0,37",
      "OFF: don't change order or merge cuts": "ВЫКЛ: не менять порядок и не объединять фрагменты.",
      "ON: reduce cuts by merging and sorting may change order": "ВКЛ: уменьшить сокращение путем слияния и сортировки, может измениться порядок",
      "USE REDUCE": "ИСПОЛЬЗОВАТЬ УМЕНЬШИТЬ",
      "KEEP ORDER AND CUTS": "СОХРАНЯЙТЕ ПОРЯДОК И РАЗРЕЗЫ",
      "Timecode Errors": "Ошибки временного кода",
      "Timecodes": "Таймкоды",
      "Source ID": "Идентификатор источника",
      "Error": "Ошибка",
      "Log Out": "Выйти",
      "Sign Up": "Зарегистрироваться",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Помощники редакторов этих проектов уже довольны инструментами, более продуктивными и экономящими время.",
      "Redo": "Повторить",
      "Undo": "Отменить",
      "You have no credits left": "У вас не осталось кредитов",
      "You need to buy more credits in order to keep using our platform": "Вам необходимо купить больше кредитов, чтобы продолжать использовать нашу платформу.",
      "Note": "Примечание",
      "You will still get free daily credits even if you don't make a purchase": "Вы по-прежнему будете получать бесплатные ежедневные кредиты, даже если не совершите покупку.",
      "Benefits of buying credits": "Преимущества покупки кредитов",
      "Bulk discount (up to 50%)": "Оптовая скидка (до 50%)",
      "Get to request features to fit your worksflow": "Получите возможность запросить функции, соответствующие вашему рабочему процессу",
      "You only pay for what you use": "Вы платите только за то, чем пользуетесь",
      "No monthly membership and no automatic recurring payments": "Никакого ежемесячного членства и никаких автоматических регулярных платежей.",
      "You get to support our site": "Вы можете поддержать наш сайт",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Если вам действительно нужно больше кредитов, а у вас нет денег, отправьте нам эссе, объясняющее, почему мы должны предоставить вам бесплатные кредиты.",
      "Buy credits": "Купить кредиты",
      "Ask for free credits, I don't have any money": "Попросите бесплатные кредиты, у меня нет денег",
      "Signup with Google to get 500 free credits": "Зарегистрируйтесь в Google, чтобы получить 500 бесплатных кредитов.",
      "You will also get 150 additional free credits every day": "Вы также будете получать 150 дополнительных бесплатных кредитов каждый день.",
      "Select": "Выбирать",
      "Credits": "Кредиты",
      "1 cut with Cutter = 20 credits": "1 разрез резаком = 20 кредитов.",
      "Discount": "Скидка",
      "Diamond": "Алмаз",
      "Enterprise": "Предприятие",
      "7 days money back guarantee": "7 дней гарантия возврата денег",
      "For any questions or issues": "По любым вопросам или проблемам",
      "Free": "Бесплатно",
      "Gold": "Золото",
      "1 group with Grouper": "1 группа с группером",
      "1 cut with Cutter": "1 разрез резаком"
    }
  },
  "tr": {
    "translation": {
      "Home": "Ev",
      "Grouper": "Orfoz",
      "GROUPER": "GRUPÇU",
      "Logout": "Çıkış Yap",
      "Profile": "Profil",
      "Onboard": "Gemide",
      "Cutter": "Kesici",
      "Blog": "Blog",
      "Contact Us": "Bize Ulaşın",
      "Login": "Giriş yapmak",
      "Create group clips": "Grup klipleri oluşturun",
      "made easy": "kolaylaştı",
      "Free Get started": "Ücretsiz Başlayın",
      "14 days free. No risk.": "14 gün ücretsiz. Risk yok.",
      "Signup Free": "Ücretsiz kaydol",
      "Contact us with any question at": "Herhangi bir sorunuz için bizimle iletişime geçin:",
      "Just one more step before you start Grouping": "Gruplandırmaya başlamadan önce yalnızca bir adım daha",
      "First name": "İlk adı",
      "What is your first name": "İlk adın ne",
      "Please input your first name!": "Lütfen adınızı girin!",
      "Last/family name": "Soyadı/soyadı",
      "What is your last/family name": "Soyadınız/aile adınız nedir",
      "Please input your last/family name!": "Lütfen soyadınızı/aile adınızı girin!",
      "Nickname": "Takma ad",
      "What do you want others to call you?": "Başkalarının sana ne demesini istiyorsun?",
      "Please input your nickname!": "Lütfen takma adınızı giriniz!",
      "Phone Number": "Telefon numarası",
      "What is your phone number?": "Telefon numaran nedir?",
      "Please input your phone number! Example +18777755232": "Lütfen telefon numaranızı giriniz! Örnek +18777755232",
      "I agree to the": "Katılıyorum",
      "privacy policy": "Gizlilik Politikası",
      "terms of service": "kullanım Şartları",
      "disclaimer": "sorumluluk reddi beyanı",
      "Privacy policy": "Gizlilik Politikası",
      "Terms of service": "Kullanım Şartları",
      "Disclaimer": "Sorumluluk reddi beyanı",
      "Submit": "Göndermek",
      "Contact your Toolium representative to buy more credits": "Daha fazla kredi satın almak için Toolium temsilcinizle iletişime geçin",
      "See your credits in profile": "Kredilerinizi profilde görün",
      "Contact your Toolium representative to activate your account": "Hesabınızı etkinleştirmek için Toolium temsilcinizle iletişime geçin",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "senkronize dizinizin bir AAF dosyasını yükleyin; size Avid Media Composer için gruplandırılmış bir AAF dosyası çıktısı verelim",
      "Click to Upload or Drag and Drop an AAF": "Bir AAF'yi Yüklemek veya Sürükleyip Bırakmak için Tıklayın",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Lütfen gidin ve e-posta adresinizi doğrulayın, böylece web sitesini kullanmaya devam edebilirsiniz. Size tıklayabileceğiniz bir bağlantı içeren bir e-posta gönderdik.",
      "Resend verification email": "Doğrulama e-postasını tekrar gönder",
      "Call us at +1-877-775-5232": "Bizi +1-877-775-5232 numaralı telefondan arayın",
      "Plan": "Plan",
      "PLANS": "PLANLAR",
      "Choose a plan that works for you": "Size uygun bir plan seçin",
      "Basic": "Temel",
      "credit": "kredi",
      "year": "yıl",
      "Billed annually.": "Yıllık olarak faturalandırılır.",
      "Contact us": "Bize Ulaşın",
      "Recommended": "Tavsiye edilen",
      "Standard": "Standart",
      "credits": "kredi",
      "Billed annually. Save": "Yıllık olarak faturalandırılır. Kaydetmek",
      "Premium": "Ödül",
      "Toolium Cutter": "Takım Kesici",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Yalnızca metninizi yükselterek ve sürecimizi kullanarak dosyanızı yükleyerek tüm referanslarınızı veya belgelerinizi doğrudan Microsoft Word belgenizden veya Google Dokümanlar'dan kesin ve düzenleyin",
      "Automate uncreative tasks": "Yaratıcı olmayan görevleri otomatikleştirin",
      "Save money processing media": "Para işleme ortamından tasarruf edin",
      "Save 4 hours every shoot day": "Her çekim gününde 4 saat tasarruf edin",
      "The next generation of post production workflow tools": "Yeni nesil post prodüksiyon iş akışı araçları",
      "14 days free.": "14 gün ücretsiz.",
      "Plans": "Planlar",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium, ekibinizin işinizi hızlandırabilmesi ve teslimatı 4 saat azaltabilmesi için iş akışınızı verimli bir şekilde gruplamanıza, kesmenize ve kolaylaştırmanıza olanak tanıyan merkezi bir merkezdir!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Senkronize dizinizin bir AAF dosyasını yükleyin; size Avid Media Composer için gruplandırılmış bir AAF dosyası çıktısı verelim",
      "Toolium Grouper": "Alet Gruplayıcı",
      "Used in": "Kullanılan",
      "Groups are created with the full master clips": "Gruplar tam ana kliplerle oluşturulur",
      "Reference sub-clips for shortened clips": "Kısaltılmış klipler için referans alt klipleri",
      "New avid multigroup type groups": "Yeni hevesli çok gruplu tip gruplar",
      "Track renaming to group clip names": "Parçanın yeniden adlandırılmasını grup klip adlarına göre değiştirme",
      "Features": "Özellikler",
      "AAF uploads": "AAF yüklemeleri",
      "AAF upload": "AAF yüklemesi",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Gruplar, aynı parçadaki klipler arasında siyah boşluklar bulunan yeni hevesli gruplar olarak oluşturulur. Zaman çizelgesindeki bir boşluktan sonra yeni bir grup oluşturulur.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "YENİDEN ADLANDIRMA AÇIK: Grupların içindeki ses parçalarını sıralı parça adlarına göre yeniden adlandırır. İhtiyacınız kadar parça açabilirsiniz, gruplar kaynak ses parçasına göre oluşturulacaktır.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "SESİ AÇIK TUT: Sondaki ses de dahil olmak üzere grupları son sekansa ekler.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Gruptaki tüm ses parçalarını ardışık sırayla sıraya yönlendirir. Birden fazla cihazdan ses alıyorsanız bunu kullanın.",
      "CLASSIC: Classic AVID grouping": "KLASİK: Klasik AVID gruplaması",
      "Process type unknown": "İşlem türü bilinmiyor",
      "No clips found on sequence": "Dizide klip bulunamadı",
      "No clips for writing sequence": "Sırayı yazmak için klip yok",
      "No sequence found in AAF file": "AAF dosyasında dizi bulunamadı",
      "File is to big reduce AAF file": "Dosya AAF dosyasını büyük ölçüde küçültecek",
      "Sequence has length of 0": "Dizinin uzunluğu 0'dır",
      "Group clip found on sequence": "Dizide grup klibi bulundu",
      "Group clip found on sequence 2": "Sekans 2'de grup klibi bulundu",
      "Unknown selector type found in sequence": "Bilinmeyen seçici türü sırayla bulundu",
      "Clip framerate does not match sequence framerate": "Klip kare hızı, dizi kare hızıyla eşleşmiyor",
      "Subclips with motion effects are not supported": "Hareket efektli alt klipler desteklenmez",
      "Join the best": "En iyilere katılın",
      "You are in good hands.": "Emin ellerdesin.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Bu prodüksiyonlardaki yardımcı editörler zaten mutlu Tooliumer'lardır, daha üretkendirler ve zaman tasarrufu sağlarlar",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Yalnızca metninizi vurgulayarak ve sürecimizi kullanarak dosyanızı yükleyerek tüm referanslarınızı veya belgelerinizi doğrudan Microsoft Word belgenizden veya Google Dokümanlar'dan kesip düzenleyin",
      "Renames audio tracks within the group clips": "Grup kliplerindeki ses parçalarını yeniden adlandırır",
      "Creates the new avid multigroup type groups": "Yeni hevesli çoklu grup tipi grupları oluşturur",
      "Creates avid sub-clips for shortened clips": "Kısaltılmış klipler için hevesli alt klipler oluşturur",
      "Editor": "Editör",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Avid Media Composer uyumlu düzenleyicimizi kullanarak tarayıcınızda düzenleme yapmak ister misiniz?",
      "Yes, join pre launch": "Evet, lansman öncesi katılın",
      "No I don't want": "Hayır istemiyorum",
      "Click to Upload AAF": "AAF'yi Yüklemek İçin Tıklayın",
      "Bin panel": "Çöp kutusu paneli",
      "Go to Previous Event": "Önceki Etkinliğe Git",
      "Go to Next Event": "Sonraki Etkinliğe Git",
      "Step Backward 1 Fram": "1 Kare Geriye Adım",
      "Step Forward 1 Fram": "1 Fram İleri Adım",
      "Mark IN": "IN olarak işaretle",
      "Play": "Oynamak",
      "Mark OUT": "Ayırmak",
      "Mark Clip": "Klipi İşaretle",
      "Clear Both Marks": "Her İki İşareti de Temizle",
      "Add Marker": "İşaretçi Ekle",
      "Swap Cam Bank": "Kam Bankasını Değiştir",
      "Find Bin": "Çöp Kutusunu Bul",
      "Gang": "Çete",
      "Step backward 10 frames": "10 kare geri adım at",
      "Step forward 10 frames": "10 kare ileri git",
      "Go to IN": "IN'e git",
      "Play Length Toggle": "Oynatma Uzunluğu Geçişi",
      "Go to OUT": "OUT'a git",
      "Match Frame": "Maç Çerçevesi",
      "Reverse Match Frame": "Ters Eşleştirme Çerçevesi",
      "Quad Split": "Dörtlü Bölünmüş",
      "Nine Split": "Dokuz Bölünmüş",
      "Step Backward 1 Frame": "1 Kare Geriye Adım",
      "Step Forward 1 Frame": "1 Kare İleri Adım",
      "Add Edit": "Ekle Düzenle",
      "Lift": "Kaldırmak",
      "Extract": "Çıkarmak",
      "Quick Transition": "Hızlı Geçiş",
      "Remove Effect": "Efekti Kaldır",
      "Collapse": "Yıkılmak",
      "Video Quality Menu": "Video Kalitesi Menüsü",
      "Monitor Volume": "Monitör Sesi",
      "Meter Menu": "Sayaç Menüsü",
      "Effect Mode": "Efekt Modu",
      "Trim Mode": "Kırpma Modu",
      "Step In": "Adım Atın",
      "Step Out": "Dışarı çıkmak",
      "Render Effect": "İşleme Etkisi",
      "Tool Palette": "Araç Paleti",
      "Head Fade": "Kafa Solma",
      "Tall Fade": "Uzun Solma",
      "To the Left": "Sola",
      "To the Right": "Sağa",
      "Select In/Out": "Giriş/Çıkış'ı seçin",
      "Link Selection Toggle": "Bağlantı Seçimi Geçişi",
      "HW/SW": "Donanım/Yazılım",
      "Titler Pro": "Başlık Pro",
      "Default Setup": "Varsayılan Kurulum",
      "View Type": "Görünüm Türü",
      "Track Panel": "Parça Paneli",
      "Effect Icons": "Efekt Simgeleri",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Simgeleri Çözün",
      "Clip Frames": "Klip Çerçeveleri",
      "Clip Text": "Klip Metni",
      "Sync Breaks": "Senkronizasyon Araları",
      "Dupe Detection": "Kopya Tespiti",
      "Color Correction": "Renk düzeltmesi",
      "Audio Data": "Ses Verileri",
      "Clip Color...": "Klip Rengi...",
      "Segment Selection": "Segment Seçimi",
      "Mark I/O Selection": "G/Ç Seçimini İşaretle",
      "Track Color": "Parça Rengi",
      "Show markers": "İşaretçileri göster",
      "Show adapters": "Bağdaştırıcıları göster",
      "Show Track": "Parçayı Göster",
      "Track Control Panel": "Parça Kontrol Paneli",
      "Zoom": "Yakınlaştır",
      "Open Comments": "Yorumları Aç",
      "Call us at": "Bizi şu numaradan arayın:",
      "Office": "Ofis",
      "Tracks Info": "Parça Bilgisi",
      "Edit Marker": "İşaretçiyi Düzenle",
      "Add": "Eklemek",
      "Marker Name": "İşaretçi Adı",
      "Color": "Renk",
      "Frame": "Çerçeve",
      "Track": "İzlemek",
      "Clear Monitor": "Monitörü Temizle",
      "Clear Menu": "Menüyü Temizle",
      "Show Markers": "İşaretçileri Göster",
      "Show Adapters": "Adaptörleri Göster",
      "Toggle Fullscreen": "Tam ekrana geç",
      "Highlighter": "Vurgulayıcı",
      "Logger": "Ağaç kesicisi",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Geçersiz DOC dosyası, bunun yerine DOCX kullanın, bu 3. taraf aracını deneyebilirsiniz",
      "Open DOC to DOCX converter": "DOC'dan DOCX'e dönüştürücüyü açın",
      "File is missing any cuts, try to click": "Dosyada herhangi bir kesim eksik, tıklamayı deneyin",
      "SET ALL ROWS": "TÜM SATIRLARI AYARLA",
      "OFF: Timecodes have start and end": "KAPALI: Zaman kodlarının başlangıç ​​ve bitişi vardır",
      "ON: No end timecode and just add 30 seconds from start timecode": "AÇIK: Bitiş zaman kodu yok ve yalnızca başlangıç ​​zaman kodundan 30 saniye ekleyin",
      "NO END TIMECODE USE 30 SECONDS": "BİTİŞ ZAMAN KODU YOK 30 SANİYE KULLANIN",
      "HAS END TIMECODE": "BİTİŞ ZAMAN KODU VAR",
      "OFF: Create cuts using highlighted text": "KAPALI: Vurgulanan metni kullanarak kesmeler oluşturun",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "AÇIK: Belgedeki tüm satırlar için kesimler oluşturun. Öne çıkanları görmezden gelmek",
      "ALL ROWS": "TÜM SIRALAR",
      "ONLY HIGHLIGHTED": "SADECE VURGULANMIŞ",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "KAPALI: Kaynak kamera kayıtlarını yoksay. Bu, yalnızca kaynak zaman koduna göre kesintiler yaratacaktır.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "AÇIK: Kaynak kamera kayıtlarını kullanın. Bu, kaynak zaman koduna ve kaynak klip kamera kaydına göre kesimler oluşturacaktır",
      "USE CAMROLL IN DOCX": "DOCX'TE CAMROLL'U KULLANIN",
      "IGNORE CAMROLL IN DOCX": "DOCX'TE CAMROLL'U GÖZ ÖNÜNDE BULUNDURUN",
      "OFF: AAF downloaded will not be renamed": "KAPALI: İndirilen AAF yeniden adlandırılmayacak",
      "ON: Rename AAF to DOCX file name": "AÇIK: AAF'yi DOCX dosya adı olarak yeniden adlandırın",
      "RENAME AAF": "AAF'yi YENİDEN ADLANDIRIN",
      "NO RENAME": "YENİDEN ADLANDIRMA YOK",
      "Click to Upload DOCX": "DOCX'i Yüklemek İçin Tıklayın",
      "MULTI": "ÇOK",
      "CLASSIC": "KLASİK",
      "RENAME ON": "YENİDEN ADLANDIRMA AÇIK",
      "RENAME OFF": "YENİDEN ADLANDIRMA KAPALI",
      "KEEP AUDIO ON": "SESİ AÇIK TUTUN",
      "KEEP AUDIO OFF": "SESİ KAPALI TUTUN",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO AS IS ON: Ses parçalarının tam olarak senkronize sıranıza yerleştirildikleri şekilde yönlendirildiği gruplar oluşturur. Gruplarınızın sesinin tam olarak diziniz gibi yönlendirilmesini istiyorsanız bunu kullanın",
      "GROUP AUDIO AS IS ON": "GRUP SESİ AÇIK OLDUĞU GİBİ",
      "GROUP AUDIO AS IS OFF": "GRUP SES KAPALI OLARAK",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "CAM ON TRACK ON'da Tutun: Senkronize sekansınıza yerleştirildikleri şekilde aynı iz üzerinde kamera açılarına sahip gruplar oluşturur. Kameralarınızın tüm çekim boyunca aynı grup yerleşiminde kalmasını istiyorsanız bunu kullanın.",
      "KEEP CAM ON TRACK ON": "KAM'I TAKİPTE TUTUN",
      "KEEP CAM ON TRACK OFF": "KAM'I TAKİPTE KAPALI TUTUN",
      "ROUTE AUDIO ON": "ROTA SESİ AÇIK",
      "ROUTE AUDIO OFF": "ROTA SESİ KAPALI",
      "Toolium Editor": "Araç Düzenleyici",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Bir AAF dosyası yükleyin ve size Avid Media Composer uyumlu, uzaktan her yerde düzenleyici sunalım ve ardından size Avid Media Composer için gruplandırılmış bir AAF dosyası çıktısı verelim",
      "Free forever": "Sonsuza kadar özgür",
      "Collaborate with ease with all the team": "Tüm ekiple kolaylıkla işbirliği yapın",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Geri bildirimlerinizi doğrudan bir çerçeveye yazarak herkesin aynı fikirde olmasını sağlayın. Veya çizin",
      "Look and feel you are use to in Avid Media Composer": "Avid Media Composer'a alışık olduğunuz görünüm ve his",
      "Keyboard shortcuts you are use to": "Kullandığınız klavye kısayolları",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium, ekibinizin işinizi hızlandırabilmesi ve teslimatı 4 saat azaltabilmesi için iş akışınızı verimli bir şekilde gruplamanıza, kesmenize ve kolaylaştırmanıza olanak tanıyan merkezi bir merkezdir!",
      "A-Side Trim Counter": "A Tarafı Trim Sayacı",
      "B-Side Trim Counter": "B Tarafı Trim Sayacı",
      "Insert Track": "Parça Ekle",
      "OK": "TAMAM",
      "Track Type": "Parça Türü",
      "Track Num": "Parça Numarası",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "TC1 sırasında",
      "I/O TC1": "G/Ç TC1",
      "Abs TC1": "Karın kasları TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "24'te",
      "I/O 24": "G/Ç 24",
      "Abs 24": "Karın kasları 24",
      "Rem 24": "24.hafta",
      "Mas 25": "Mas 25",
      "Dur 25": "25'ten itibaren",
      "I/O 25": "G/Ç 25",
      "Abs 25": "Karın kasları 25",
      "Rem 25": "25 dakika",
      "Mas Frm": "Mas Firması",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "G/Ç Firması",
      "Abs Frm": "Karın kasları Firması",
      "Rem Frm": "Rem Frm",
      "Sequence": "Sekans",
      "Timecode": "Zaman kodu",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "süre",
      "I/O": "G/Ç",
      "Abs": "Karın kasları",
      "Rem": "Rem",
      "Footage": "Kamera görüntüsü",
      "Frames": "Çerçeveler",
      "Source": "Kaynak",
      "None": "Hiçbiri",
      "Master": "Usta",
      "Duration": "Süre",
      "In/Out": "Giriş/Çıkış",
      "Absolute": "Mutlak",
      "Remain": "Geriye kalmak",
      "Audio Mono": "Ses Mono",
      "Audio Stereo": "Ses Stereosu",
      "5.1 Surround": "5.1 Çevreleme",
      "7.1 Surround": "7.1 Çevreleme",
      "Data": "Veri",
      "Video": "Video",
      "Leave your comment here...": "Yorumunuzu buraya bırakın...",
      "Send": "Göndermek",
      "Workspace": "Çalışma alanı",
      "Project Settings": "Proje ayarları",
      "Leave Project": "Projeden Ayrıl",
      "Delete Project": "Projeyi Sil",
      "New Project": "Yeni proje",
      "Name": "İsim",
      "Please, input the name": "Lütfen adı girin",
      "Edit Project": "Projeyi Düzenle",
      "Save Settings": "Ayarları kaydet",
      "Jump to project of file...": "Dosya projesine atla...",
      "Status": "Durum",
      "Comments": "Yorumlar",
      "Size": "Boyut",
      "Date uploaded": "Yüklenme tarihi",
      "Uploader": "Yükleyici",
      "Action": "Aksiyon",
      "File upload": "Dosya yükleme",
      "Folder upload": "Klasör yükleme",
      "Folder upload as proxy": "Proxy olarak klasör yükleme",
      "New folder": "Yeni dosya",
      "New private folder": "Yeni özel klasör",
      "Download all": "Hepsini indir",
      "Recently deleted": "Yakın zamanda silindi",
      "Project settings": "Proje ayarları",
      "Rename file": "Dosyayı yeniden adlandır",
      "Move": "Taşınmak",
      "Download": "İndirmek",
      "Make private": "Özel yap",
      "Reveal in project": "Projede ortaya çıkar",
      "Delete": "Silmek",
      "File Information": "Dosya bilgisi",
      "Copy Comments": "Yorumları Kopyala",
      "Paste Comments": "Yorumları Yapıştır",
      "Print": "Yazdır",
      "Download as File...": "Dosya Olarak İndir...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Düz Metin",
      "Download for Final Cut Pro X": "Final Cut Pro X'i indirin",
      "Download for Media Composer": "Media Composer'ı indirin",
      "Download for Resolve": "Çözüm için İndirin",
      "No comments": "Yorum yok",
      "This is premium feature, contact us at support@toolium.org": "Bu premium bir özelliktir; support@toolium.org adresinden bizimle iletişime geçin.",
      "Automated test to speech": "Otomatik konuşma testi",
      "Import from uploads": "Yüklemelerden içe aktar",
      "Upload your VO file": "VO dosyanızı yükleyin",
      "Record voice over": "Ses kaydı yap",
      "Crop": "Mahsul",
      "Voiceover": "Seslendirme",
      "Adjust colors": "Renkleri ayarlama",
      "Trim": "Kırpma",
      "Speed": "Hız",
      "Loop": "Döngü",
      "Flip": "Çevir",
      "Effects": "Etkileri",
      "Animation": "Animasyon",
      "Add title": "Başlık ekle",
      "Export": "İhracat",
      "Grid": "Kafes",
      "Split": "Bölmek",
      "Split View": "Bölünmüş Görünüm",
      "Zoom to fit": "Sığacak şekilde yakınlaştırın",
      "Audio meter": "Ses ölçer",
      "TC": "TC",
      "Comment": "Yorum",
      "Jump to Marker": "İşaretçiye Git",
      "Change Color": "Rengi değiştir",
      "Change Track": "Parçayı Değiştir",
      "Import Markers": "İşaretleyicileri İçe Aktar",
      "Export Markers": "İşaretleyicileri Dışa Aktar",
      "Choose Columns": "Sütunları seç",
      "Cameras": "Kameralar",
      "Desktop is required": "Masaüstü gereklidir",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Daha küçük ekranlı bir cihaz kullandığınızı fark ettik. En iyi deneyim için lütfen bir masaüstü kullanın.",
      "Go back": "Geri gitmek",
      "OFF: Use 0 seconds for handles": "KAPALI: Tutamaçlar için 0 saniye kullanın",
      "ON: Use 5 seconds for handles": "AÇIK: Tutamaçlar için 5 saniye kullanın",
      "HANDLES OFF": "KOLLAR KAPALI",
      "HANDLES ON": "KOLLAR AÇIK",
      "OFF: word-per-second will be 0.37": "KAPALI: saniye başına kelime 0,37 olacaktır",
      "ON: word-per-second will be detected": "AÇIK: saniyedeki kelime algılanacak",
      "DETECT WPS": "WPS'İ ALGILA",
      "WPS IS 0.37": "WPS 0,37'dir",
      "OFF: don't change order or merge cuts": "KAPALI: sırayı değiştirmeyin veya kesimleri birleştirmeyin",
      "ON: reduce cuts by merging and sorting may change order": "AÇIK: birleştirme ve sıralama yoluyla kesintileri azaltın, sırayı değiştirebilir",
      "USE REDUCE": "KULLANIN AZALTIN",
      "KEEP ORDER AND CUTS": "DÜZENİ VE KESİMLERİ TUTUN",
      "Timecode Errors": "Zaman Kodu Hataları",
      "Timecodes": "Zaman Kodları",
      "Source ID": "Kaynak kimliği",
      "Error": "Hata",
      "Log Out": "Çıkış Yap",
      "Sign Up": "Üye olmak",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Bu yapımlardaki yardımcı editörler zaten mutlu Tooliumer'lardır, daha üretkendirler ve zaman tasarrufu sağlarlar",
      "Redo": "Yinele",
      "Undo": "Geri alma",
      "You have no credits left": "Krediniz kalmadı",
      "You need to buy more credits in order to keep using our platform": "Platformumuzu kullanmaya devam etmek için daha fazla kredi satın almanız gerekiyor",
      "Note": "Not",
      "You will still get free daily credits even if you don't make a purchase": "Satın alma yapmasanız bile ücretsiz günlük kredi almaya devam edeceksiniz",
      "Benefits of buying credits": "Kredi satın almanın faydaları",
      "Bulk discount (up to 50%)": "Toplu indirim (%50'ye kadar)",
      "Get to request features to fit your worksflow": "İş akışınıza uyacak özellikler talep edin",
      "You only pay for what you use": "Yalnızca kullandığınız kadar ödersiniz",
      "No monthly membership and no automatic recurring payments": "Aylık üyelik yok ve otomatik yinelenen ödeme yok",
      "You get to support our site": "Sitemize destek olabilirsiniz",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Gerçekten daha fazla krediye ihtiyacınız varsa ve hiç paranız yoksa, size neden ücretsiz kredi vermemiz gerektiğini açıklayan bir makale gönderin.",
      "Buy credits": "Kredi satın Al",
      "Ask for free credits, I don't have any money": "Ücretsiz kredi isteyin, hiç param yok",
      "Signup with Google to get 500 free credits": "500 ücretsiz kredi almak için Google'a kaydolun",
      "You will also get 150 additional free credits every day": "Ayrıca her gün 150 ek ücretsiz kredi alacaksınız",
      "Select": "Seçme",
      "Credits": "Kredi",
      "1 cut with Cutter = 20 credits": "Kesici ile 1 kesim = 20 kredi",
      "Discount": "İndirim",
      "Diamond": "Elmas",
      "Enterprise": "Girişim",
      "7 days money back guarantee": "7 gün para iade garantisi",
      "For any questions or issues": "Her türlü soru veya sorun için",
      "Free": "Özgür",
      "Gold": "Altın",
      "1 group with Grouper": "Orfozlu 1 grup",
      "1 cut with Cutter": "Kesici ile 1 kesim"
    }
  },
  "no": {
    "translation": {
      "Home": "Hjem",
      "Grouper": "Grouper",
      "GROUPER": "GRUPPER",
      "Logout": "Logg ut",
      "Profile": "Profil",
      "Onboard": "Om bord",
      "Cutter": "Kutter",
      "Blog": "Blogg",
      "Contact Us": "Kontakt oss",
      "Login": "Logg Inn",
      "Create group clips": "Lag gruppeklipp",
      "made easy": "gjort enkelt",
      "Free Get started": "Gratis Kom i gang",
      "14 days free. No risk.": "14 dager gratis. Ingen risiko.",
      "Signup Free": "Registrer deg gratis",
      "Contact us with any question at": "Kontakt oss med spørsmål på",
      "Just one more step before you start Grouping": "Bare ett trinn til før du begynner å gruppere",
      "First name": "Fornavn",
      "What is your first name": "Hva er ditt fornavn",
      "Please input your first name!": "Skriv inn fornavnet ditt!",
      "Last/family name": "Etter-/familienavn",
      "What is your last/family name": "Hva er ditt etternavn/familienavn",
      "Please input your last/family name!": "Skriv inn etter-/familienavnet ditt!",
      "Nickname": "Kallenavn",
      "What do you want others to call you?": "Hva vil du at andre skal kalle deg?",
      "Please input your nickname!": "Vennligst skriv inn kallenavnet ditt!",
      "Phone Number": "Telefonnummer",
      "What is your phone number?": "Hva er telefonnummeret ditt?",
      "Please input your phone number! Example +18777755232": "Vennligst skriv inn telefonnummeret ditt! Eksempel +18777755232",
      "I agree to the": "Jeg er enig i",
      "privacy policy": "personvernerklæring",
      "terms of service": "vilkår for bruk",
      "disclaimer": "ansvarsfraskrivelse",
      "Privacy policy": "Personvernerklæring",
      "Terms of service": "Vilkår for bruk",
      "Disclaimer": "Ansvarsfraskrivelse",
      "Submit": "Sende inn",
      "Contact your Toolium representative to buy more credits": "Kontakt Toolium-representanten din for å kjøpe flere kreditter",
      "See your credits in profile": "Se kredittene dine i profilen",
      "Contact your Toolium representative to activate your account": "Kontakt Toolium-representanten din for å aktivere kontoen din",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "last opp en AAF-fil av den synkroniserte sekvensen din, og vi sender deg en gruppert AAF-fil for Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Klikk for å laste opp eller dra og slipp en AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Gå og bekreft e-posten din slik at du kan fortsette å bruke nettstedet. Vi har sendt deg en e-post med en lenke du kan klikke på.",
      "Resend verification email": "Send bekreftelses-e-post på nytt",
      "Call us at +1-877-775-5232": "Ring oss på +1-877-775-5232",
      "Plan": "Plan",
      "PLANS": "PLANER",
      "Choose a plan that works for you": "Velg en plan som fungerer for deg",
      "Basic": "Grunnleggende",
      "credit": "kreditt",
      "year": "år",
      "Billed annually.": "Faktureres årlig.",
      "Contact us": "Kontakt oss",
      "Recommended": "Anbefalt",
      "Standard": "Standard",
      "credits": "studiepoeng",
      "Billed annually. Save": "Faktureres årlig. Lagre",
      "Premium": "Premium",
      "Toolium Cutter": "Toolium Cutter",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Klipp ut og rediger alle anbefalingene eller dokumentene dine direkte fra Microsoft Word-dokumentet eller Google Dokumenter ved å bare høyne teksten og laste opp filen ved hjelp av prosessen vår",
      "Automate uncreative tasks": "Automatiser ukreative oppgaver",
      "Save money processing media": "Spar penger ved å behandle media",
      "Save 4 hours every shoot day": "Spar 4 timer hver opptaksdag",
      "The next generation of post production workflow tools": "Den neste generasjonen av arbeidsflytverktøy etter produksjon",
      "14 days free.": "14 dager gratis.",
      "Plans": "Planer",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium er et sentralt knutepunkt som lar deg gruppere, kutte og effektivisere arbeidsflyten din, slik at teamet ditt kan fremskynde arbeidet ditt og redusere leveringen med 4 timer!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Last opp en AAF-fil av den synkroniserte sekvensen din, og vi sender deg en gruppert AAF-fil for Avid Media Composer",
      "Toolium Grouper": "Toolium Grouper",
      "Used in": "Brukt i",
      "Groups are created with the full master clips": "Grupper opprettes med de fullstendige hovedklippene",
      "Reference sub-clips for shortened clips": "Referanse underklipp for forkortede klipp",
      "New avid multigroup type groups": "Nye ivrige grupper av flere grupper",
      "Track renaming to group clip names": "Spor endre navn til gruppe klippnavn",
      "Features": "Egenskaper",
      "AAF uploads": "AAF laster opp",
      "AAF upload": "AAF opplasting",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Grupper opprettes som de nye ivrige gruppene med svarte mellomrom mellom klipp på samme spor. En ny gruppe opprettes etter et mellomrom på tidslinjen.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Gi nytt navn til lydspor i gruppene i henhold til sekvensspornavnene. Du kan åpne så mange spor du trenger, gruppene vil bli opprettet i henhold til kildelydsporet.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "BEHOLD LYD PÅ: Spleiser grupper inkludert den etterfølgende lyden til den endelige sekvensen.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Ruter alle lydsporene i gruppen til sekvensen i fortløpende rekkefølge. Bruk denne hvis du har lyd fra mer enn én enhet.",
      "CLASSIC: Classic AVID grouping": "KLASSISK: Klassisk AVID-gruppering",
      "Process type unknown": "Prosesstype ukjent",
      "No clips found on sequence": "Ingen klipp funnet i sekvensen",
      "No clips for writing sequence": "Ingen klipp for skrivesekvens",
      "No sequence found in AAF file": "Ingen sekvens funnet i AAF-fil",
      "File is to big reduce AAF file": "Filen er for stor redusere AAF-filen",
      "Sequence has length of 0": "Sekvens har lengde 0",
      "Group clip found on sequence": "Gruppeklipp funnet på sekvens",
      "Group clip found on sequence 2": "Gruppeklipp funnet i sekvens 2",
      "Unknown selector type found in sequence": "Ukjent velgertype funnet i rekkefølge",
      "Clip framerate does not match sequence framerate": "Klippets framerate samsvarer ikke med sekvensens framerate",
      "Subclips with motion effects are not supported": "Underklipp med bevegelseseffekter støttes ikke",
      "Join the best": "Bli med de beste",
      "You are in good hands.": "Du er i gode hender.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Assisterende redaktører fra disse produksjonene er allerede glade Tooliumers, mer produktive og sparer tid",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Klipp ut og rediger alle attester eller dokumenter direkte fra Microsoft Word-dokumentet eller Google Docs ved å bare fremheve teksten og laste opp filen ved å bruke prosessen vår",
      "Renames audio tracks within the group clips": "Gi nytt navn til lydspor i gruppeklippene",
      "Creates the new avid multigroup type groups": "Oppretter de nye ivrige multigruppetypegruppene",
      "Creates avid sub-clips for shortened clips": "Lager ivrige underklipp for forkortede klipp",
      "Editor": "Redaktør",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Vil du redigere i nettleseren din ved å bruke vår Avid Media Composer-kompatible editor?",
      "Yes, join pre launch": "Ja, bli med på forhåndslansering",
      "No I don't want": "Nei, jeg vil ikke",
      "Click to Upload AAF": "Klikk for å laste opp AAF",
      "Bin panel": "Beholderpanel",
      "Go to Previous Event": "Gå til forrige arrangement",
      "Go to Next Event": "Gå til neste arrangement",
      "Step Backward 1 Fram": "Gå tilbake 1 Fram",
      "Step Forward 1 Fram": "Gå frem 1 Fram",
      "Mark IN": "Merk INN",
      "Play": "Spille",
      "Mark OUT": "Markere",
      "Mark Clip": "Mark Clip",
      "Clear Both Marks": "Fjern begge merkene",
      "Add Marker": "Legg til markør",
      "Swap Cam Bank": "Bytt kamerabank",
      "Find Bin": "Finn bin",
      "Gang": "Gjeng",
      "Step backward 10 frames": "Gå 10 bilder bakover",
      "Step forward 10 frames": "Gå frem 10 rammer",
      "Go to IN": "Gå til IN",
      "Play Length Toggle": "Spill Lengde Toggle",
      "Go to OUT": "Gå til UT",
      "Match Frame": "Matchramme",
      "Reverse Match Frame": "Omvendt matchramme",
      "Quad Split": "Quad Split",
      "Nine Split": "Ni Split",
      "Step Backward 1 Frame": "Gå tilbake 1 ramme",
      "Step Forward 1 Frame": "Gå frem 1 ramme",
      "Add Edit": "Legg til Rediger",
      "Lift": "Løfte",
      "Extract": "Ekstrakt",
      "Quick Transition": "Rask overgang",
      "Remove Effect": "Fjern effekt",
      "Collapse": "Kollapse",
      "Video Quality Menu": "Videokvalitetsmeny",
      "Monitor Volume": "Monitorvolum",
      "Meter Menu": "Målermeny",
      "Effect Mode": "Effektmodus",
      "Trim Mode": "Trimmodus",
      "Step In": "Gå inn",
      "Step Out": "Gå ut",
      "Render Effect": "Render effekt",
      "Tool Palette": "Verktøypalett",
      "Head Fade": "Hodet blekner",
      "Tall Fade": "Tall Fade",
      "To the Left": "Til venstre",
      "To the Right": "Til høyre",
      "Select In/Out": "Velg Inn/Ut",
      "Link Selection Toggle": "Link valg Veksle",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "Standard oppsett",
      "View Type": "Visningstype",
      "Track Panel": "Sporpanel",
      "Effect Icons": "Effekt ikoner",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Løs opp ikoner",
      "Clip Frames": "Klipprammer",
      "Clip Text": "Klipp tekst",
      "Sync Breaks": "Synkroniseringsbrudd",
      "Dupe Detection": "Dupe Deteksjon",
      "Color Correction": "Fargekorreksjon",
      "Audio Data": "Lyddata",
      "Clip Color...": "Klippfarge...",
      "Segment Selection": "Segmentvalg",
      "Mark I/O Selection": "Merk I/O-valg",
      "Track Color": "Sporfarge",
      "Show markers": "Vis markører",
      "Show adapters": "Vis adaptere",
      "Show Track": "Vis spor",
      "Track Control Panel": "Spor kontrollpanel",
      "Zoom": "Zoom",
      "Open Comments": "Åpne kommentarer",
      "Call us at": "Ring oss på",
      "Office": "Kontor",
      "Tracks Info": "Spor info",
      "Edit Marker": "Rediger markør",
      "Add": "Legg til",
      "Marker Name": "Navn på markør",
      "Color": "Farge",
      "Frame": "Ramme",
      "Track": "Spor",
      "Clear Monitor": "Tøm skjerm",
      "Clear Menu": "Tøm menyen",
      "Show Markers": "Vis markører",
      "Show Adapters": "Vis adaptere",
      "Toggle Fullscreen": "Slå på fullskjerm",
      "Highlighter": "Highlighter",
      "Logger": "Logger",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Ugyldig fil DOC, bruk DOCX i stedet, du kan prøve dette tredjepartsverktøyet",
      "Open DOC to DOCX converter": "Åpne DOC til DOCX-konverterer",
      "File is missing any cuts, try to click": "Filen mangler noen kutt, prøv å klikke",
      "SET ALL ROWS": "SETT ALLE RADER",
      "OFF: Timecodes have start and end": "AV: Tidskoder har start og slutt",
      "ON: No end timecode and just add 30 seconds from start timecode": "PÅ: Ingen slutttidskode og bare legg til 30 sekunder fra starttidskoden",
      "NO END TIMECODE USE 30 SECONDS": "INGEN SLUTTIDSKODE BRUK 30 SEKUNDER",
      "HAS END TIMECODE": "HAR SLUTTIDSKODE",
      "OFF: Create cuts using highlighted text": "AV: Lag kutt med uthevet tekst",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "PÅ: Lag kutt for alle rader i dokumentet. Ignorerer høydepunktene",
      "ALL ROWS": "ALLE RADER",
      "ONLY HIGHLIGHTED": "BARE UTHEFTET",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "AV: Ignorer kilde-camrolls. Dette vil lage kutt kun i henhold til kildetidskoden.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "PÅ: Bruk kildekameraer. Dette vil lage kutt i henhold til kildetidskoden og kildeklippets camroll",
      "USE CAMROLL IN DOCX": "BRUK CAMROLL I DOCX",
      "IGNORE CAMROLL IN DOCX": "IGNORER CAMROLL I DOCX",
      "OFF: AAF downloaded will not be renamed": "AV: AAF lastet ned vil ikke få nytt navn",
      "ON: Rename AAF to DOCX file name": "PÅ: Gi nytt navn til AAF til DOCX-filnavn",
      "RENAME AAF": "Gi nytt navn til AAF",
      "NO RENAME": "INGEN OMNØVN",
      "Click to Upload DOCX": "Klikk for å laste opp DOCX",
      "MULTI": "MULTI",
      "CLASSIC": "KLASSISK",
      "RENAME ON": "GJENNOMFØR PÅ",
      "RENAME OFF": "RENAME AV",
      "KEEP AUDIO ON": "HOLD LYDEN PÅ",
      "KEEP AUDIO OFF": "HOLD LYDEN AV",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GRUPPELYD SOM ER PÅ: Oppretter grupper med lydsporene rutet nøyaktig slik de er plassert på den synkroniserte sekvensen. Bruk dette hvis du vil at gruppelyden skal rutes nøyaktig som sekvensen",
      "GROUP AUDIO AS IS ON": "GRUPPELYD SOM ER PÅ",
      "GROUP AUDIO AS IS OFF": "GRUPPELYD AS ER AV",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Oppretter grupper med kameravinkler på samme spor som de er plassert på din synkroniserte sekvens. Bruk denne hvis du vil at kameraene skal forbli i samme gruppeplassering under hele fotograferingen",
      "KEEP CAM ON TRACK ON": "HOLD CAM PÅ SPORET",
      "KEEP CAM ON TRACK OFF": "HOLD CAM PÅ SPOR AV",
      "ROUTE AUDIO ON": "RUTELYD PÅ",
      "ROUTE AUDIO OFF": "RUTE LYD AV",
      "Toolium Editor": "Toolium Editor",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Last opp en AAF-fil, og vi vil gi deg en Avid Media Composer-kompatibel fjernkontroll hvor som helst editor og deretter sende ut en gruppert AAF-fil for Avid Media Composer",
      "Free forever": "Gratis for alltid",
      "Collaborate with ease with all the team": "Samarbeid enkelt med hele teamet",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Hold alle på samme side ved å skrive tilbakemelding rett på en ramme. Eller tegne det",
      "Look and feel you are use to in Avid Media Composer": "Utseende og følelse du er vant til i Avid Media Composer",
      "Keyboard shortcuts you are use to": "Hurtigtaster du er vant til",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium er et sentralt knutepunkt som lar deg gruppere, kutte og effektivisere arbeidsflyten din, slik at teamet ditt kan fremskynde arbeidet ditt og redusere leveringen med 4 timer!",
      "A-Side Trim Counter": "A-Side Trim Counter",
      "B-Side Trim Counter": "B-sideteller",
      "Insert Track": "Sett inn spor",
      "OK": "OK",
      "Track Type": "Sportype",
      "Track Num": "Spornummer",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Dur TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Mas 24",
      "Dur 24": "Dur 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Abs 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Mas 25",
      "Dur 25": "Dur 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Abs 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Sekvens",
      "Timecode": "Tidskode",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "I/O",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Opptakene",
      "Frames": "Rammer",
      "Source": "Kilde",
      "None": "Ingen",
      "Master": "Herre",
      "Duration": "Varighet",
      "In/Out": "Inn/Ut",
      "Absolute": "Absolutt",
      "Remain": "Forbli",
      "Audio Mono": "Lyd mono",
      "Audio Stereo": "Lyd stereo",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Data",
      "Video": "Video",
      "Leave your comment here...": "Legg igjen din kommentar her...",
      "Send": "Sende",
      "Workspace": "Arbeidsområde",
      "Project Settings": "Prosjektinnstillinger",
      "Leave Project": "Forlat prosjektet",
      "Delete Project": "Slett prosjekt",
      "New Project": "Nytt prosjekt",
      "Name": "Navn",
      "Please, input the name": "Vennligst skriv inn navnet",
      "Edit Project": "Rediger prosjekt",
      "Save Settings": "Lagre innstillinger",
      "Jump to project of file...": "Hopp til prosjektet av filen...",
      "Status": "Status",
      "Comments": "Kommentarer",
      "Size": "Størrelse",
      "Date uploaded": "Dato lastet opp",
      "Uploader": "Laster opp",
      "Action": "Handling",
      "File upload": "Filopplasting",
      "Folder upload": "Opplasting av mappe",
      "Folder upload as proxy": "Mappeopplasting som proxy",
      "New folder": "Ny mappe",
      "New private folder": "Ny privat mappe",
      "Download all": "Last ned alle",
      "Recently deleted": "Nylig slettet",
      "Project settings": "Prosjektinnstillinger",
      "Rename file": "Endre navn på fil",
      "Move": "Bevege seg",
      "Download": "nedlasting",
      "Make private": "Gjør privat",
      "Reveal in project": "Avsløre i prosjektet",
      "Delete": "Slett",
      "File Information": "Filinformasjon",
      "Copy Comments": "Kopier kommentarer",
      "Paste Comments": "Lim inn kommentarer",
      "Print": "Skrive ut",
      "Download as File...": "Last ned som fil...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Ren tekst",
      "Download for Final Cut Pro X": "Last ned for Final Cut Pro X",
      "Download for Media Composer": "Last ned for Media Composer",
      "Download for Resolve": "Last ned for Resolve",
      "No comments": "Ingen kommentarer",
      "This is premium feature, contact us at support@toolium.org": "Dette er førsteklasses funksjon, kontakt oss på support@toolium.org",
      "Automated test to speech": "Automatisk test til tale",
      "Import from uploads": "Importer fra opplastinger",
      "Upload your VO file": "Last opp VO-filen din",
      "Record voice over": "Ta opp voice over",
      "Crop": "Avling",
      "Voiceover": "Voiceover",
      "Adjust colors": "Juster farger",
      "Trim": "Listverk",
      "Speed": "Hastighet",
      "Loop": "Løkke",
      "Flip": "Vend",
      "Effects": "Effekter",
      "Animation": "Animasjon",
      "Add title": "Legg til tittel",
      "Export": "Eksport",
      "Grid": "Nett",
      "Split": "Dele",
      "Split View": "Delt visning",
      "Zoom to fit": "Zoom for å passe",
      "Audio meter": "Lydmåler",
      "TC": "TC",
      "Comment": "Kommentar",
      "Jump to Marker": "Hopp til Marker",
      "Change Color": "Endre farge",
      "Change Track": "Bytt spor",
      "Import Markers": "Importer markører",
      "Export Markers": "Eksporter markører",
      "Choose Columns": "Velg kolonner",
      "Cameras": "Kameraer",
      "Desktop is required": "Skrivebord er påkrevd",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Vi har lagt merke til at du bruker en enhet med mindre skjerm. For den beste opplevelsen, bruk et skrivebord.",
      "Go back": "Gå tilbake",
      "OFF: Use 0 seconds for handles": "AV: Bruk 0 sekunder for håndtak",
      "ON: Use 5 seconds for handles": "PÅ: Bruk 5 sekunder for håndtak",
      "HANDLES OFF": "HÅNDER AV",
      "HANDLES ON": "HÅNDTAK PÅ",
      "OFF: word-per-second will be 0.37": "AV: ord-per-sekund vil være 0,37",
      "ON: word-per-second will be detected": "PÅ: ord-per-sekund vil bli oppdaget",
      "DETECT WPS": "OPPDATER WPS",
      "WPS IS 0.37": "WPS ER 0,37",
      "OFF: don't change order or merge cuts": "AV: ikke endre rekkefølge eller slå sammen kutt",
      "ON: reduce cuts by merging and sorting may change order": "PÅ: redusere kutt ved å slå sammen og sortering kan endre rekkefølge",
      "USE REDUCE": "BRUK REDUSER",
      "KEEP ORDER AND CUTS": "HOLD ORDEN OG KUTTER",
      "Timecode Errors": "Tidskodefeil",
      "Timecodes": "Tidskoder",
      "Source ID": "Kilde-ID",
      "Error": "Feil",
      "Log Out": "Logg ut",
      "Sign Up": "Melde deg på",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Assisterende redaktører fra disse produksjonene er allerede glade Tooliumers, mer produktive og sparer tid",
      "Redo": "Gjøre om",
      "Undo": "Angre",
      "You have no credits left": "Du har ingen kreditter igjen",
      "You need to buy more credits in order to keep using our platform": "Du må kjøpe flere kreditter for å fortsette å bruke plattformen vår",
      "Note": "Merk",
      "You will still get free daily credits even if you don't make a purchase": "Du vil fortsatt få gratis daglige kreditter selv om du ikke kjøper noe",
      "Benefits of buying credits": "Fordeler med å kjøpe kreditter",
      "Bulk discount (up to 50%)": "Masserabatt (opptil 50 %)",
      "Get to request features to fit your worksflow": "Få til å be om funksjoner som passer til arbeidsflyten din",
      "You only pay for what you use": "Du betaler kun for det du bruker",
      "No monthly membership and no automatic recurring payments": "Ingen månedlig medlemskap og ingen automatiske gjentakende betalinger",
      "You get to support our site": "Du får støtte siden vår",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Hvis du virkelig trenger flere studiepoeng og du ikke har penger, send oss ​​et essay som forklarer hvorfor vi bør gi deg gratis studiepoeng",
      "Buy credits": "Kjøp kreditter",
      "Ask for free credits, I don't have any money": "Be om gratis kreditter, jeg har ingen penger",
      "Signup with Google to get 500 free credits": "Registrer deg hos Google for å få 500 gratis kreditter",
      "You will also get 150 additional free credits every day": "Du vil også få 150 ekstra gratis kreditter hver dag",
      "Select": "Plukke ut",
      "Credits": "Studiepoeng",
      "1 cut with Cutter = 20 credits": "1 kutt med Cutter = 20 studiepoeng",
      "Discount": "Rabatt",
      "Diamond": "Diamant",
      "Enterprise": "Bedriften",
      "7 days money back guarantee": "7 dager pengene tilbake-garanti",
      "For any questions or issues": "For spørsmål eller problemer",
      "Free": "Gratis",
      "Gold": "Gull",
      "1 group with Grouper": "1 gruppe med Grouper",
      "1 cut with Cutter": "1 kutt med Cutter"
    }
  },
  "el": {
    "translation": {
      "Home": "Σπίτι",
      "Grouper": "Σφυρίδα",
      "GROUPER": "ΣΦΥΡΙΔΑ",
      "Logout": "Αποσύνδεση",
      "Profile": "Προφίλ",
      "Onboard": "Εν πλω",
      "Cutter": "Κόπτης",
      "Blog": "Ιστολόγιο",
      "Contact Us": "Επικοινωνήστε μαζί μας",
      "Login": "Σύνδεση",
      "Create group clips": "Δημιουργήστε ομαδικά κλιπ",
      "made easy": "έγινε εύκολο",
      "Free Get started": "Δωρεάν Ξεκινήστε",
      "14 days free. No risk.": "14 ημέρες δωρεάν. Χωρίς κίνδυνο.",
      "Signup Free": "Εγγράψου δωρεάν",
      "Contact us with any question at": "Επικοινωνήστε μαζί μας για οποιαδήποτε ερώτηση στο",
      "Just one more step before you start Grouping": "Ένα ακόμη βήμα πριν ξεκινήσετε την Ομαδοποίηση",
      "First name": "Ονομα",
      "What is your first name": "Ποιο είναι το μικρό σου όνομα",
      "Please input your first name!": "Παρακαλώ εισάγετε το όνομά σας!",
      "Last/family name": "Επώνυμο/επώνυμο",
      "What is your last/family name": "Ποιο είναι το επίθετό/το οικογενειακό σας όνομα",
      "Please input your last/family name!": "Παρακαλώ εισάγετε το επώνυμό σας!",
      "Nickname": "Παρατσούκλι",
      "What do you want others to call you?": "Πώς θέλεις να σε λένε οι άλλοι;",
      "Please input your nickname!": "Παρακαλώ εισάγετε το ψευδώνυμό σας!",
      "Phone Number": "Τηλεφωνικό νούμερο",
      "What is your phone number?": "Ποιος είναι ο τηλεφωνικός αριθμός σας;",
      "Please input your phone number! Example +18777755232": "Εισαγάγετε τον αριθμό τηλεφώνου σας! Παράδειγμα +18777755232",
      "I agree to the": "συμφωνώ στο",
      "privacy policy": "πολιτική απορρήτου",
      "terms of service": "Όροι χρήσης",
      "disclaimer": "αποποίηση ευθυνών",
      "Privacy policy": "Πολιτική απορρήτου",
      "Terms of service": "Οροι χρήσης",
      "Disclaimer": "Αποποίηση ευθυνών",
      "Submit": "υποβάλλουν",
      "Contact your Toolium representative to buy more credits": "Επικοινωνήστε με τον αντιπρόσωπο της Toolium για να αγοράσετε περισσότερες πιστώσεις",
      "See your credits in profile": "Δείτε τις πιστώσεις σας στο προφίλ",
      "Contact your Toolium representative to activate your account": "Επικοινωνήστε με τον αντιπρόσωπο του Toolium για να ενεργοποιήσετε τον λογαριασμό σας",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "μεταφορτώστε ένα αρχείο AAF της συγχρονισμένης ακολουθίας σας και θα σας εξάγουμε ένα ομαδοποιημένο αρχείο AAF για το Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Κάντε κλικ για μεταφόρτωση ή μεταφορά και απόθεση ενός AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Πηγαίνετε και επαληθεύστε το email σας για να συνεχίσετε να χρησιμοποιείτε τον ιστότοπο. Σας στείλαμε ένα email με έναν σύνδεσμο για να κάνετε κλικ.",
      "Resend verification email": "Επαναποστολή μηνύματος επιβεβαίωσης",
      "Call us at +1-877-775-5232": "Καλέστε μας στο +1-877-775-5232",
      "Plan": "Σχέδιο",
      "PLANS": "ΣΧΕΔΙΑ",
      "Choose a plan that works for you": "Επιλέξτε ένα σχέδιο που λειτουργεί για εσάς",
      "Basic": "Βασικός",
      "credit": "πίστωση",
      "year": "έτος",
      "Billed annually.": "Τιμολογείται ετησίως.",
      "Contact us": "Επικοινωνήστε μαζί μας",
      "Recommended": "Συνιστάται",
      "Standard": "Πρότυπο",
      "credits": "πιστώσεις",
      "Billed annually. Save": "Τιμολογείται ετησίως. Αποθηκεύσετε",
      "Premium": "Ασφάλιστρο",
      "Toolium Cutter": "Κόφτης Εργαλείων",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Κόψτε και επεξεργαστείτε όλες τις μαρτυρίες ή τα ντοκιμαντέρ σας απευθείας από το έγγραφο του Microsoft Word ή τα Έγγραφα Google, απλώς επισημαίνοντας το κείμενό σας και ανεβάζοντας το αρχείο σας χρησιμοποιώντας τη διαδικασία μας",
      "Automate uncreative tasks": "Αυτοματοποιήστε μη δημιουργικές εργασίες",
      "Save money processing media": "Εξοικονομήστε χρήματα μέσα επεξεργασίας",
      "Save 4 hours every shoot day": "Εξοικονομήστε 4 ώρες κάθε μέρα λήψης",
      "The next generation of post production workflow tools": "Η επόμενη γενιά εργαλείων ροής εργασίας μετά την παραγωγή",
      "14 days free.": "14 ημέρες δωρεάν.",
      "Plans": "Σχέδια",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Το Toolium είναι ένας κεντρικός κόμβος που σας επιτρέπει να ομαδοποιείτε αποτελεσματικά, να κόβετε και να εξορθολογίζετε τη ροή εργασίας σας, ώστε η ομάδα σας να μπορεί να επισπεύσει την εργασία σας και να μειώσει την παράδοση κατά 4 ώρες!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Ανεβάστε ένα αρχείο AAF της συγχρονισμένης ακολουθίας σας και θα σας εξάγουμε ένα ομαδοποιημένο αρχείο AAF για το Avid Media Composer",
      "Toolium Grouper": "Toolium Grouper",
      "Used in": "Χρησιμοποιείται σε",
      "Groups are created with the full master clips": "Οι ομάδες δημιουργούνται με τα πλήρη κύρια κλιπ",
      "Reference sub-clips for shortened clips": "Αναφορά υπο-κλιπ για συντομευμένα κλιπ",
      "New avid multigroup type groups": "Νέες μανιώδεις ομάδες τύπου πολλαπλών ομάδων",
      "Track renaming to group clip names": "Μετονομασία κομματιού σε ονόματα κλιπ ομάδας",
      "Features": "Χαρακτηριστικά",
      "AAF uploads": "Μεταφορτώσεις AAF",
      "AAF upload": "Μεταφόρτωση AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Οι ομάδες δημιουργούνται ως οι νέες μανιώδεις ομάδες με μαύρα κενά μεταξύ των κλιπ στο ίδιο κομμάτι. Μια νέα ομάδα δημιουργείται μετά από ένα διάστημα στη γραμμή χρόνου.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Μετονομάζει τα κομμάτια ήχου μέσα στις ομάδες σύμφωνα με τα ονόματα των κομματιών ακολουθίας. Μπορείτε να ανοίξετε όσα κομμάτια χρειάζεστε, οι ομάδες θα δημιουργηθούν σύμφωνα με το ηχητικό κομμάτι της πηγής.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: Ενώνει τις ομάδες συμπεριλαμβανομένου του τελευταίου ήχου στην τελική ακολουθία.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Δρομολογεί όλα τα κομμάτια ήχου στην ομάδα στη σειρά με διαδοχική σειρά. Χρησιμοποιήστε το εάν έχετε ήχο από περισσότερες από μία συσκευές.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: Κλασική ομαδοποίηση AVID",
      "Process type unknown": "Άγνωστος τύπος διαδικασίας",
      "No clips found on sequence": "Δεν βρέθηκαν κλιπ στην ακολουθία",
      "No clips for writing sequence": "Δεν υπάρχουν κλιπ για τη σειρά γραφής",
      "No sequence found in AAF file": "Δεν βρέθηκε ακολουθία στο αρχείο AAF",
      "File is to big reduce AAF file": "Το αρχείο είναι η μεγάλη μείωση του αρχείου AAF",
      "Sequence has length of 0": "Η ακολουθία έχει μήκος 0",
      "Group clip found on sequence": "Ομαδικό κλιπ που βρέθηκε στην ακολουθία",
      "Group clip found on sequence 2": "Ομαδικό απόσπασμα που βρέθηκε στην ακολουθία 2",
      "Unknown selector type found in sequence": "Βρέθηκε άγνωστος τύπος επιλογέα στη σειρά",
      "Clip framerate does not match sequence framerate": "Ο ρυθμός καρέ κλιπ δεν ταιριάζει με τον ρυθμό καρέ ακολουθίας",
      "Subclips with motion effects are not supported": "Τα δευτερεύοντα κλιπ με εφέ κίνησης δεν υποστηρίζονται",
      "Join the best": "Εγγραφείτε στους καλύτερους",
      "You are in good hands.": "Είστε σε καλά χέρια.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Οι βοηθοί συντάκτες από αυτές τις παραγωγές είναι ήδη χαρούμενοι Tooliumers, πιο παραγωγικοί και εξοικονομούν χρόνο",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Κόψτε και επεξεργαστείτε όλες τις μαρτυρίες ή τα ντοκιμαντέρ σας απευθείας από το έγγραφο του Microsoft Word ή τα Έγγραφα Google απλά επισημαίνοντας το κείμενό σας και ανεβάζοντας το αρχείο σας χρησιμοποιώντας τη διαδικασία μας",
      "Renames audio tracks within the group clips": "Μετονομάζει τα ηχητικά κομμάτια στα κλιπ της ομάδας",
      "Creates the new avid multigroup type groups": "Δημιουργεί τις νέες μανιώδεις ομάδες τύπου πολλαπλών ομάδων",
      "Creates avid sub-clips for shortened clips": "Δημιουργεί μανιώδεις υποκλιπ για συντομευμένα κλιπ",
      "Editor": "Συντάκτης",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Θέλετε να κάνετε επεξεργασία στο πρόγραμμα περιήγησής σας χρησιμοποιώντας το συμβατό πρόγραμμα επεξεργασίας Avid Media Composer;",
      "Yes, join pre launch": "Ναι, εγγραφείτε πριν από την κυκλοφορία",
      "No I don't want": "Όχι δεν θέλω",
      "Click to Upload AAF": "Κάντε κλικ για να ανεβάσετε AAF",
      "Bin panel": "Πίνακας κάδου",
      "Go to Previous Event": "Μεταβείτε στην Προηγούμενη εκδήλωση",
      "Go to Next Event": "Μεταβείτε στο επόμενο συμβάν",
      "Step Backward 1 Fram": "Βήμα προς τα πίσω 1 καρέ",
      "Step Forward 1 Fram": "Βήμα εμπρός 1 καρέ",
      "Mark IN": "Σημειώστε ΕΙΣ",
      "Play": "Παίζω",
      "Mark OUT": "Μαρκάρω",
      "Mark Clip": "Mark Clip",
      "Clear Both Marks": "Καθαρίστε και τα δύο σημάδια",
      "Add Marker": "Προσθήκη δείκτη",
      "Swap Cam Bank": "Swap Cam Bank",
      "Find Bin": "Βρείτε τον κάδο",
      "Gang": "Συμμορία",
      "Step backward 10 frames": "Βήμα προς τα πίσω 10 καρέ",
      "Step forward 10 frames": "Βήμα μπροστά 10 καρέ",
      "Go to IN": "Μεταβείτε στο IN",
      "Play Length Toggle": "Εναλλαγή μήκους αναπαραγωγής",
      "Go to OUT": "Πηγαίνετε στο OUT",
      "Match Frame": "Πλαίσιο αγώνα",
      "Reverse Match Frame": "Πλαίσιο αντίστροφης αντιστοίχισης",
      "Quad Split": "Quad Split",
      "Nine Split": "Nine Split",
      "Step Backward 1 Frame": "Βήμα προς τα πίσω 1 Πλαίσιο",
      "Step Forward 1 Frame": "Βήμα εμπρός 1 Πλαίσιο",
      "Add Edit": "Προσθήκη Επεξεργασίας",
      "Lift": "Ανελκυστήρας",
      "Extract": "Εκχύλισμα",
      "Quick Transition": "Γρήγορη μετάβαση",
      "Remove Effect": "Κατάργηση εφέ",
      "Collapse": "Κατάρρευση",
      "Video Quality Menu": "Μενού ποιότητας βίντεο",
      "Monitor Volume": "Ένταση έντασης οθόνης",
      "Meter Menu": "Μενού μετρητή",
      "Effect Mode": "Λειτουργία εφέ",
      "Trim Mode": "Λειτουργία περικοπής",
      "Step In": "Βήμα μέσα",
      "Step Out": "Βγες έξω",
      "Render Effect": "Εφέ απόδοσης",
      "Tool Palette": "Παλέτα εργαλείων",
      "Head Fade": "Ξεθώριασμα κεφαλιού",
      "Tall Fade": "Ψηλός Fade",
      "To the Left": "Αριστερά",
      "To the Right": "Δεξιά",
      "Select In/Out": "Επιλέξτε In/Out",
      "Link Selection Toggle": "Εναλλαγή επιλογής συνδέσμου",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "Προεπιλεγμένη ρύθμιση",
      "View Type": "Τύπος προβολής",
      "Track Panel": "Πίνακας διαδρομής",
      "Effect Icons": "Εικονίδια εφέ",
      "Render Rangers": "Render Rangers",
      "Dissolve Icons": "Διάλυση εικονιδίων",
      "Clip Frames": "Κλιπ Κορνίζες",
      "Clip Text": "Κείμενο κλιπ",
      "Sync Breaks": "Διακοπές συγχρονισμού",
      "Dupe Detection": "Ανίχνευση απάτης",
      "Color Correction": "Διόρθωση χρώματος",
      "Audio Data": "Ηχητικά δεδομένα",
      "Clip Color...": "Χρώμα κλιπ...",
      "Segment Selection": "Επιλογή τμήματος",
      "Mark I/O Selection": "Επιλογή εισόδου/εξόδου",
      "Track Color": "Χρώμα κομματιού",
      "Show markers": "Εμφάνιση δεικτών",
      "Show adapters": "Εμφάνιση προσαρμογέων",
      "Show Track": "Εμφάνιση κομματιού",
      "Track Control Panel": "Παρακολούθηση Πίνακα Ελέγχου",
      "Zoom": "Ανίπταμαι διαγωνίως",
      "Open Comments": "Ανοίξτε τα σχόλια",
      "Call us at": "Καλέστε μας στο",
      "Office": "Γραφείο",
      "Tracks Info": "Πληροφορίες κομματιών",
      "Edit Marker": "Επεξεργασία δείκτη",
      "Add": "Προσθήκη",
      "Marker Name": "Όνομα δείκτη",
      "Color": "Χρώμα",
      "Frame": "Πλαίσιο",
      "Track": "Πίστα",
      "Clear Monitor": "Εκκαθάριση οθόνης",
      "Clear Menu": "Εκκαθάριση μενού",
      "Show Markers": "Εμφάνιση δεικτών",
      "Show Adapters": "Εμφάνιση προσαρμογέων",
      "Toggle Fullscreen": "Λειτουργεία πλήρους οθόνης",
      "Highlighter": "Highlighter",
      "Logger": "Κόπτων δέντρα διά ξυλείαν",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Μη έγκυρο αρχείο DOC, χρησιμοποιήστε το DOCX, μπορείτε να δοκιμάσετε αυτό το εργαλείο τρίτου κατασκευαστή",
      "Open DOC to DOCX converter": "Ανοίξτε τον μετατροπέα DOC σε DOCX",
      "File is missing any cuts, try to click": "Δεν υπάρχουν περικοπές από το αρχείο, δοκιμάστε να κάνετε κλικ",
      "SET ALL ROWS": "ΡΥΘΜΙΣΕ ΟΛΕΣ ΤΙΣ ΣΕΙΡΕΣ",
      "OFF: Timecodes have start and end": "ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ: Οι χρονικοί κωδικοί έχουν αρχή και τέλος",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON: Δεν υπάρχει κωδικός ώρας λήξης και απλώς προσθέστε 30 δευτερόλεπτα από τον κωδικό ώρας έναρξης",
      "NO END TIMECODE USE 30 SECONDS": "ΟΧΙ ΧΡΗΣΗ ΚΩΔΙΚΟΣ ΧΡΟΝΟΥ ΤΕΛΟΣ 30 ΔΕΥΤΕΡΟΛΕΠΤΑ",
      "HAS END TIMECODE": "ΕΧΕΙ ΚΩΔΙΚΟ ΛΗΞΗΣ",
      "OFF: Create cuts using highlighted text": "OFF: Δημιουργήστε περικοπές χρησιμοποιώντας επισημασμένο κείμενο",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON: Δημιουργήστε περικοπές για όλες τις σειρές του εγγράφου. Αγνοώντας τα κυριότερα σημεία",
      "ALL ROWS": "ΟΛΕΣ ΟΙ ΣΕΙΡΕΣ",
      "ONLY HIGHLIGHTED": "ΜΟΝΟ ΤΟΝΙΣΜΕΝΟ",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ: Αγνοήστε τα camrolls της πηγής. Αυτό θα δημιουργήσει περικοπές μόνο σύμφωνα με τον κώδικα χρόνου προέλευσης.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: Χρησιμοποιήστε camrolls πηγής. Αυτό θα δημιουργήσει περικοπές σύμφωνα με τον κώδικα χρόνου προέλευσης και το camroll του κλιπ πηγής",
      "USE CAMROLL IN DOCX": "ΧΡΗΣΗ CAMROLL ΣΤΟ DOCX",
      "IGNORE CAMROLL IN DOCX": "ΑΓΝΟΗ CAMROLL ΣΤΟ DOCX",
      "OFF: AAF downloaded will not be renamed": "OFF: Το AAF που έχει ληφθεί δεν θα μετονομαστεί",
      "ON: Rename AAF to DOCX file name": "ON: Μετονομάστε το όνομα αρχείου AAF σε DOCX",
      "RENAME AAF": "ΜΕΤΟΝΟΜΑΣΙΑ AAF",
      "NO RENAME": "ΟΧΙ ΜΕΤΟΝΟΜΑΣΙΑ",
      "Click to Upload DOCX": "Κάντε κλικ για μεταφόρτωση DOCX",
      "MULTI": "ΠΟΛΥ",
      "CLASSIC": "ΚΛΑΣΣΙΚΟΣ",
      "RENAME ON": "ΜΕΤΟΝΟΜΑΣΙΑ ΕΝΕΡΓΟΠΟΙΗΣΗ",
      "RENAME OFF": "ΜΕΤΟΝΟΜΑΣΙΑ ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ",
      "KEEP AUDIO ON": "ΔΙΑΤΗΡΗΣΤΕ ΕΝΕΡΓΟ ΤΟΝ ΗΧΟ",
      "KEEP AUDIO OFF": "ΔΙΑΤΗΡΗΣΤΕ ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ ΤΟΥ ΗΧΟΥ",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "ΟΜΑΔΙΚΟΣ ΗΧΟΣ ΩΣ ΕΝΕΡΓΟΠΟΙΗΜΕΝΟΣ: Δημιουργεί ομάδες με τα κομμάτια ήχου να δρομολογούνται ακριβώς όπως τοποθετούνται στη συγχρονισμένη ακολουθία σας. Χρησιμοποιήστε το εάν θέλετε ο ήχος των ομάδων σας να δρομολογείται ακριβώς όπως η ακολουθία σας",
      "GROUP AUDIO AS IS ON": "ΟΜΑΔΙΚΟΣ ΗΧΟΣ ΩΣ ΕΧΕΙ",
      "GROUP AUDIO AS IS OFF": "Ο ΟΜΑΔΙΚΟΣ ΗΧΟΣ ΩΣ ΕΧΕΙ ΑΠΕΝΕΡΓΟΠΟΙΗΜΕΝΟ",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: Δημιουργεί ομάδες με γωνίες κάμερας στο ίδιο κομμάτι που τοποθετούνται στη συγχρονισμένη ακολουθία σας. Χρησιμοποιήστε το εάν θέλετε οι κάμερές σας να παραμείνουν στην ίδια ομαδική τοποθέτηση καθ' όλη τη διάρκεια της λήψης",
      "KEEP CAM ON TRACK ON": "KEEP ON TRACK ON",
      "KEEP CAM ON TRACK OFF": "KEEP CAM ON TRACK OFF",
      "ROUTE AUDIO ON": "ΔΙΑΔΡΟΜΗ ΕΝΕΡΓΟ ΗΧΟΥ",
      "ROUTE AUDIO OFF": "ΔΙΑΔΡΟΜΗ ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ ΗΧΟΥ",
      "Toolium Editor": "Toolium Editor",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Ανεβάστε ένα αρχείο AAF και θα σας παρέχουμε ένα πρόγραμμα επεξεργασίας τηλεχειριστηρίου συμβατό με Avid Media Composer και στη συνέχεια θα σας εξάγουμε ένα ομαδοποιημένο αρχείο AAF για το Avid Media Composer",
      "Free forever": "Δωρεάν για πάντα",
      "Collaborate with ease with all the team": "Συνεργαστείτε με ευκολία με όλη την ομάδα",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Κρατήστε όλους στην ίδια σελίδα γράφοντας σχόλια απευθείας σε ένα πλαίσιο. Ή ζωγραφίστε το",
      "Look and feel you are use to in Avid Media Composer": "Εμφάνιση και αίσθηση ότι είστε συνηθισμένοι στο Avid Media Composer",
      "Keyboard shortcuts you are use to": "Συντομεύσεις πληκτρολογίου που χρησιμοποιείτε",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Το Toolium είναι ένας κεντρικός κόμβος που σας επιτρέπει να ομαδοποιείτε αποτελεσματικά, να κόβετε και να εξορθολογίζετε τη ροή εργασίας σας, ώστε η ομάδα σας να μπορεί να επισπεύσει την εργασία σας και να μειώσει την παράδοση κατά 4 ώρες!",
      "A-Side Trim Counter": "A-Side Trim Counter",
      "B-Side Trim Counter": "Τριμόμετρο B-Side",
      "Insert Track": "Εισαγωγή κομματιού",
      "OK": "Εντάξει",
      "Track Type": "Τύπος κομματιού",
      "Track Num": "Αριθμός κομματιού",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Dur TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Μάιος 24",
      "Dur 24": "Διάρκεια 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Κοιλιακοί 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Μάιος 25",
      "Dur 25": "Διάρκεια 25",
      "I/O 25": "I/O 25",
      "Abs 25": "Κοιλιακοί 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "Rem Frm",
      "Sequence": "Αλληλουχία",
      "Timecode": "Κωδικός χρόνου",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "I/O",
      "Abs": "Abs",
      "Rem": "Rem",
      "Footage": "Πλάνο",
      "Frames": "Κορνίζες",
      "Source": "Πηγή",
      "None": "Κανένας",
      "Master": "Κύριος",
      "Duration": "Διάρκεια",
      "In/Out": "Μέσα έξω",
      "Absolute": "Απόλυτος",
      "Remain": "Παραμένει",
      "Audio Mono": "Μονοφωνικό ήχο",
      "Audio Stereo": "Στερεοφωνικό ήχου",
      "5.1 Surround": "5.1 Surround",
      "7.1 Surround": "7.1 Surround",
      "Data": "Δεδομένα",
      "Video": "βίντεο",
      "Leave your comment here...": "Αφήστε το σχόλιό σας εδώ...",
      "Send": "Στείλετε",
      "Workspace": "Χώρος εργασίας",
      "Project Settings": "Ρυθμίσεις έργου",
      "Leave Project": "Αποχώρηση από το έργο",
      "Delete Project": "Διαγραφή έργου",
      "New Project": "Νέο Έργο",
      "Name": "Ονομα",
      "Please, input the name": "Παρακαλώ εισάγετε το όνομα",
      "Edit Project": "Επεξεργασία έργου",
      "Save Settings": "Αποθήκευση ρυθμίσεων",
      "Jump to project of file...": "Μετάβαση στο έργο του αρχείου...",
      "Status": "Κατάσταση",
      "Comments": "Σχόλια",
      "Size": "Μέγεθος",
      "Date uploaded": "Ημερομηνία μεταφόρτωσης",
      "Uploader": "Αποστολέας",
      "Action": "Δράση",
      "File upload": "Ανέβασμα αρχείου",
      "Folder upload": "Μεταφόρτωση φακέλου",
      "Folder upload as proxy": "Μεταφόρτωση φακέλου ως διακομιστή μεσολάβησης",
      "New folder": "Νέος φάκελος",
      "New private folder": "Νέος ιδιωτικός φάκελος",
      "Download all": "Κατέβασέ τα όλα",
      "Recently deleted": "Διαγράφηκε πρόσφατα",
      "Project settings": "Ρυθμίσεις έργου",
      "Rename file": "Μετονόμασε το αρχείο",
      "Move": "Κίνηση",
      "Download": "Κατεβάστε",
      "Make private": "Κάντε ιδιωτικό",
      "Reveal in project": "Αποκάλυψη στο έργο",
      "Delete": "Διαγράφω",
      "File Information": "Πληροφορίες αρχείου",
      "Copy Comments": "Αντιγραφή σχολίων",
      "Paste Comments": "Επικόλληση σχολίων",
      "Print": "Τυπώνω",
      "Download as File...": "Λήψη ως αρχείο...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Απλό κείμενο",
      "Download for Final Cut Pro X": "Λήψη για το Final Cut Pro X",
      "Download for Media Composer": "Λήψη για Media Composer",
      "Download for Resolve": "Λήψη για επίλυση",
      "No comments": "Χωρίς σχόλια",
      "This is premium feature, contact us at support@toolium.org": "Αυτή είναι η δυνατότητα premium, επικοινωνήστε μαζί μας στο support@toolium.org",
      "Automated test to speech": "Αυτοματοποιημένη δοκιμή ομιλίας",
      "Import from uploads": "Εισαγωγή από μεταφορτώσεις",
      "Upload your VO file": "Ανεβάστε το αρχείο VO σας",
      "Record voice over": "Ηχογράφηση φωνής",
      "Crop": "Καλλιέργεια",
      "Voiceover": "Φωνή",
      "Adjust colors": "Ρυθμίστε τα χρώματα",
      "Trim": "Τακτοποίηση",
      "Speed": "Ταχύτητα",
      "Loop": "Βρόχος",
      "Flip": "Αναρρίπτω",
      "Effects": "Υπάρχοντα",
      "Animation": "Κινουμένων σχεδίων",
      "Add title": "Προσθέστε τίτλο",
      "Export": "Εξαγωγή",
      "Grid": "Πλέγμα",
      "Split": "Διαίρεση",
      "Split View": "Split View",
      "Zoom to fit": "Ζουμ για να ταιριάζει",
      "Audio meter": "Μετρητής ήχου",
      "TC": "TC",
      "Comment": "Σχόλιο",
      "Jump to Marker": "Μετάβαση στο Marker",
      "Change Color": "Αλλαξε χρώμα",
      "Change Track": "Αλλαγή κομματιού",
      "Import Markers": "Εισαγωγή δεικτών",
      "Export Markers": "Εξαγωγή δεικτών",
      "Choose Columns": "Επιλέξτε Στήλες",
      "Cameras": "Κάμερες",
      "Desktop is required": "Απαιτείται επιφάνεια εργασίας",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Παρατηρήσαμε ότι χρησιμοποιείτε συσκευή με μικρότερη οθόνη. Για την καλύτερη εμπειρία, χρησιμοποιήστε επιτραπέζιο υπολογιστή.",
      "Go back": "Πήγαινε πίσω",
      "OFF: Use 0 seconds for handles": "OFF: Χρησιμοποιήστε 0 δευτερόλεπτα για λαβές",
      "ON: Use 5 seconds for handles": "ON: Χρησιμοποιήστε 5 δευτερόλεπτα για λαβές",
      "HANDLES OFF": "ΛΑΒΕΙ ΚΕΝΤΑ",
      "HANDLES ON": "ΛΑΒΗ ΑΝΟΙΚΤΑ",
      "OFF: word-per-second will be 0.37": "OFF: λέξη ανά δευτερόλεπτο θα είναι 0,37",
      "ON: word-per-second will be detected": "ΕΝΕΡΓΟΠΟΙΗΣΗ: Θα εντοπιστεί λέξη ανά δευτερόλεπτο",
      "DETECT WPS": "ΑΝΙΧΝΕΥΣΗ WPS",
      "WPS IS 0.37": "Το WPS είναι 0,37",
      "OFF: don't change order or merge cuts": "ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ: μην αλλάξετε σειρά ή συγχώνευση περικοπών",
      "ON: reduce cuts by merging and sorting may change order": "ON: μειώστε τις περικοπές με συγχώνευση και η ταξινόμηση μπορεί να αλλάξει σειρά",
      "USE REDUCE": "ΧΡΗΣΗ ΜΕΙΩΣΗ",
      "KEEP ORDER AND CUTS": "ΚΡΑΤΗΣΤΕ ΠΑΡΑΓΓΕΛΙΑ ΚΑΙ ΚΟΨΕΤΕ",
      "Timecode Errors": "Σφάλματα χρονικού κωδικού",
      "Timecodes": "Κωδικοί χρόνου",
      "Source ID": "Αναγνωριστικό πηγής",
      "Error": "Λάθος",
      "Log Out": "Αποσύνδεση",
      "Sign Up": "Εγγραφείτε",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Οι βοηθοί συντάκτες από αυτές τις παραγωγές είναι ήδη χαρούμενοι Tooliumers, πιο παραγωγικοί και εξοικονομούν χρόνο",
      "Redo": "Ξανακάνω",
      "Undo": "Ξεκάνω",
      "You have no credits left": "Δεν σας έχουν απομείνει πιστώσεις",
      "You need to buy more credits in order to keep using our platform": "Πρέπει να αγοράσετε περισσότερες πιστώσεις για να συνεχίσετε να χρησιμοποιείτε την πλατφόρμα μας",
      "Note": "Σημείωση",
      "You will still get free daily credits even if you don't make a purchase": "Θα εξακολουθείτε να λαμβάνετε δωρεάν ημερήσιες πιστώσεις ακόμα κι αν δεν κάνετε μια αγορά",
      "Benefits of buying credits": "Οφέλη από την αγορά πιστώσεων",
      "Bulk discount (up to 50%)": "Μαζική έκπτωση (έως 50%)",
      "Get to request features to fit your worksflow": "Ζητήστε λειτουργίες που ταιριάζουν στη ροή εργασίας σας",
      "You only pay for what you use": "Πληρώνετε μόνο για ό,τι χρησιμοποιείτε",
      "No monthly membership and no automatic recurring payments": "Χωρίς μηνιαία συνδρομή και χωρίς αυτόματες επαναλαμβανόμενες πληρωμές",
      "You get to support our site": "Μπορείτε να υποστηρίξετε τον ιστότοπό μας",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Εάν χρειάζεστε πραγματικά περισσότερες πιστώσεις και δεν έχετε χρήματα, στείλτε μας ένα δοκίμιο εξηγώντας γιατί πρέπει να σας δώσουμε δωρεάν πιστώσεις",
      "Buy credits": "Αγοράστε πιστώσεις",
      "Ask for free credits, I don't have any money": "Ζητήστε δωρεάν πιστώσεις, δεν έχω χρήματα",
      "Signup with Google to get 500 free credits": "Εγγραφείτε στην Google για να λάβετε 500 δωρεάν πιστώσεις",
      "You will also get 150 additional free credits every day": "Θα λαμβάνετε επίσης 150 επιπλέον δωρεάν πιστώσεις κάθε μέρα",
      "Select": "Επιλέγω",
      "Credits": "Πιστώσεις",
      "1 cut with Cutter = 20 credits": "1 κόψιμο με Κόφτη = 20 μονάδες",
      "Discount": "Εκπτωση",
      "Diamond": "Διαμάντι",
      "Enterprise": "Επιχείρηση",
      "7 days money back guarantee": "7 ημέρες εγγύηση επιστροφής χρημάτων",
      "For any questions or issues": "Για οποιεσδήποτε ερωτήσεις ή προβλήματα",
      "Free": "Ελεύθερος",
      "Gold": "Χρυσός",
      "1 group with Grouper": "1 ομάδα με Σφυρίδα",
      "1 cut with Cutter": "1 κόψιμο με κόφτη"
    }
  },
  "ar": {
    "translation": {
      "Home": "بيت",
      "Grouper": "الهامور",
      "GROUPER": "جروبر",
      "Logout": "تسجيل خروج",
      "Profile": "حساب تعريفي",
      "Onboard": "صعد على متنها",
      "Cutter": "القاطع",
      "Blog": "مدونة",
      "Contact Us": "اتصل بنا",
      "Login": "تسجيل الدخول",
      "Create group clips": "إنشاء مقاطع جماعية",
      "made easy": "يجلعه سهلا",
      "Free Get started": "ابدأ",
      "14 days free. No risk.": "14 يومًا مجانًا. لا يوجد خطر.",
      "Signup Free": "الاشتراك مجانا",
      "Contact us with any question at": "اتصل بنا بخصوص أي سؤال على",
      "Just one more step before you start Grouping": "ما عليك سوى خطوة واحدة أخرى قبل بدء التجميع",
      "First name": "الاسم الأول",
      "What is your first name": "ما هو اسمك الأول",
      "Please input your first name!": "الرجاء إدخال اسمك الأول!",
      "Last/family name": "اسم العائلة/العائلة",
      "What is your last/family name": "ما هو اسم عائلتك/عائلتك",
      "Please input your last/family name!": "الرجاء إدخال اسم العائلة/الاسم الأخير!",
      "Nickname": "كنية",
      "What do you want others to call you?": "ماذا تريد أن يناديك الآخرون؟",
      "Please input your nickname!": "الرجاء إدخال اللقب الخاص بك!",
      "Phone Number": "رقم التليفون",
      "What is your phone number?": "ما هو رقم هاتفك؟",
      "Please input your phone number! Example +18777755232": "الرجاء إدخال رقم هاتفك! مثال +18777755232",
      "I agree to the": "أنا أوافق على",
      "privacy policy": "سياسة الخصوصية",
      "terms of service": "شروط الخدمة",
      "disclaimer": "تنصل",
      "Privacy policy": "سياسة الخصوصية",
      "Terms of service": "شروط الخدمة",
      "Disclaimer": "تنصل",
      "Submit": "يُقدِّم",
      "Contact your Toolium representative to buy more credits": "اتصل بممثل Toolium الخاص بك لشراء المزيد من الاعتمادات",
      "See your credits in profile": "انظر الاعتمادات الخاصة بك في الملف الشخصي",
      "Contact your Toolium representative to activate your account": "اتصل بممثل Toolium الخاص بك لتنشيط حسابك",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "قم بتحميل ملف AAF للتسلسل المتزامن الخاص بك وسنقوم بإخراج ملف AAF مجمع لـ Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "انقر لتحميل أو سحب وإسقاط AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "يرجى الذهاب والتحقق من بريدك الإلكتروني حتى تتمكن من الاستمرار في استخدام الموقع. لقد أرسلنا لك بريدًا إلكترونيًا يحتوي على رابط للنقر عليه.",
      "Resend verification email": "إعادة ارسال بريد التحقق",
      "Call us at +1-877-775-5232": "اتصل بنا على +1-877-775-5232",
      "Plan": "يخطط",
      "PLANS": "خطط",
      "Choose a plan that works for you": "اختر الخطة التي تناسبك",
      "Basic": "أساسي",
      "credit": "ائتمان",
      "year": "سنة",
      "Billed annually.": "دفع سنوى.",
      "Contact us": "اتصل بنا",
      "Recommended": "مُستَحسَن",
      "Standard": "معيار",
      "credits": "الاعتمادات",
      "Billed annually. Save": "دفع سنوى. يحفظ",
      "Premium": "غالي",
      "Toolium Cutter": "قاطعة الأدوات",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "قم بقص جميع شهاداتك أو مستنداتك وتحريرها مباشرةً من مستند Microsoft Word أو مستندات Google بمجرد رفع النص وتحميل ملفك باستخدام عمليتنا",
      "Automate uncreative tasks": "أتمتة المهام غير الإبداعية",
      "Save money processing media": "توفير وسائل معالجة المال",
      "Save 4 hours every shoot day": "وفر 4 ساعات كل يوم تصوير",
      "The next generation of post production workflow tools": "الجيل القادم من أدوات سير عمل ما بعد الإنتاج",
      "14 days free.": "14 يومًا مجانًا.",
      "Plans": "الخطط",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium هو مركز مركزي يتيح لك تجميع سير العمل الخاص بك وقصه وتبسيطه بكفاءة حتى يتمكن فريقك من تسريع عملك وتقليل التسليم بمقدار 4 ساعات!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "قم بتحميل ملف AAF للتسلسل المتزامن الخاص بك وسنقوم بإخراج ملف AAF مجمع لـ Avid Media Composer",
      "Toolium Grouper": "الهامور توليوم",
      "Used in": "مستعمل في",
      "Groups are created with the full master clips": "يتم إنشاء المجموعات باستخدام المقاطع الرئيسية الكاملة",
      "Reference sub-clips for shortened clips": "مقاطع فرعية مرجعية للمقاطع المختصرة",
      "New avid multigroup type groups": "مجموعات جديدة متعطشة متعددة المجموعات",
      "Track renaming to group clip names": "إعادة تسمية المسار إلى أسماء مقاطع المجموعة",
      "Features": "سمات",
      "AAF uploads": "تحميلات AAF",
      "AAF upload": "تحميل AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "متعدد: يتم إنشاء المجموعات كمجموعات متعطشة جديدة بمسافات سوداء بين المقاطع الموجودة على نفس المسار. يتم إنشاء مجموعة جديدة بعد مسافة على المخطط الزمني.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: إعادة تسمية المسارات الصوتية داخل المجموعات وفقًا لأسماء المسارات التسلسلية. يمكنك فتح أي عدد تريده من المسارات، وسيتم إنشاء المجموعات وفقًا للمسار الصوتي المصدر.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "إبقاء الصوت قيد التشغيل: مجموعات التوصيلات بما في ذلك الصوت اللاحق في التسلسل النهائي.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: لتوجيه كافة المسارات الصوتية في المجموعة إلى التسلسل بترتيب متتابع. استخدم هذا إذا كان لديك صوت من أكثر من جهاز واحد.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: مجموعة AVID الكلاسيكية",
      "Process type unknown": "نوع العملية غير معروف",
      "No clips found on sequence": "لم يتم العثور على مقاطع في التسلسل",
      "No clips for writing sequence": "لا توجد مقاطع لكتابة التسلسل",
      "No sequence found in AAF file": "لم يتم العثور على تسلسل في ملف AAF",
      "File is to big reduce AAF file": "الملف هو تقليل ملف AAF بشكل كبير",
      "Sequence has length of 0": "طول التسلسل هو 0",
      "Group clip found on sequence": "تم العثور على مقطع المجموعة في التسلسل",
      "Group clip found on sequence 2": "تم العثور على مقطع المجموعة في التسلسل 2",
      "Unknown selector type found in sequence": "تم العثور على نوع محدد غير معروف في التسلسل",
      "Clip framerate does not match sequence framerate": "معدل إطارات المقطع لا يتطابق مع معدل إطارات التسلسل",
      "Subclips with motion effects are not supported": "لا يتم دعم المقاطع الفرعية ذات تأثيرات الحركة",
      "Join the best": "انضم إلى الأفضل",
      "You are in good hands.": "أنت في أيد أمينة.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "المحررون المساعدون من هذه المنتجات هم بالفعل من مستخدمي الأدوات السعداء، وأكثر إنتاجية وتوفيرًا للوقت",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "قم بقص وتحرير جميع الشهادات أو المستندات الخاصة بك مباشرةً من مستند Microsoft Word أو مستندات Google بمجرد تمييز النص الخاص بك وتحميل ملفك باستخدام عمليتنا",
      "Renames audio tracks within the group clips": "إعادة تسمية المسارات الصوتية داخل مقاطع المجموعة",
      "Creates the new avid multigroup type groups": "يقوم بإنشاء مجموعات جديدة من النوع متعدد المجموعات",
      "Creates avid sub-clips for shortened clips": "ينشئ مقاطع فرعية متعطشة للمقاطع المختصرة",
      "Editor": "محرر",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "هل تريد التحرير في متصفحك باستخدام المحرر المتوافق مع Avid Media Composer؟",
      "Yes, join pre launch": "نعم، انضم إلى الإطلاق المسبق",
      "No I don't want": "لا لا أريد",
      "Click to Upload AAF": "انقر لتحميل AAF",
      "Bin panel": "لوحة بن",
      "Go to Previous Event": "انتقل إلى الحدث السابق",
      "Go to Next Event": "انتقل إلى الحدث التالي",
      "Step Backward 1 Fram": "خطوة إلى الوراء 1 فرام",
      "Step Forward 1 Fram": "خطوة للأمام 1 فرام",
      "Mark IN": "علامة في",
      "Play": "يلعب",
      "Mark OUT": "اشارة",
      "Mark Clip": "مارك كليب",
      "Clear Both Marks": "مسح كلتا العلامتين",
      "Add Marker": "أضف علامة",
      "Swap Cam Bank": "مبادلة بنك كام",
      "Find Bin": "ابحث عن بن",
      "Gang": "عصابة",
      "Step backward 10 frames": "خطوة إلى الوراء 10 إطارات",
      "Step forward 10 frames": "خطوة إلى الأمام 10 إطارات",
      "Go to IN": "اذهب إلى إن",
      "Play Length Toggle": "تبديل طول اللعب",
      "Go to OUT": "الذهاب إلى خارج",
      "Match Frame": "إطار المباراة",
      "Reverse Match Frame": "عكس إطار المباراة",
      "Quad Split": "رباعية سبليت",
      "Nine Split": "تسعة سبليت",
      "Step Backward 1 Frame": "خطوة إلى الوراء 1 الإطار",
      "Step Forward 1 Frame": "خطوة للأمام 1 الإطار",
      "Add Edit": "إضافة تحرير",
      "Lift": "يرفع",
      "Extract": "يستخرج",
      "Quick Transition": "الانتقال السريع",
      "Remove Effect": "إزالة التأثير",
      "Collapse": "ينهار",
      "Video Quality Menu": "قائمة جودة الفيديو",
      "Monitor Volume": "حجم الشاشة",
      "Meter Menu": "قائمة العداد",
      "Effect Mode": "وضع التأثير",
      "Trim Mode": "وضع القطع",
      "Step In": "ادخل",
      "Step Out": "يخرج",
      "Render Effect": "تقديم تأثير",
      "Tool Palette": "لوحة الأدوات",
      "Head Fade": "تتلاشى الرأس",
      "Tall Fade": "طويل القامة تتلاشى",
      "To the Left": "إلى اليسار",
      "To the Right": "إلى اليمين",
      "Select In/Out": "حدد داخل/خارج",
      "Link Selection Toggle": "تبديل اختيار الارتباط",
      "HW/SW": "الأب/سو",
      "Titler Pro": "تايلتر برو",
      "Default Setup": "الإعداد الافتراضي",
      "View Type": "نوع العرض",
      "Track Panel": "لوحة المسار",
      "Effect Icons": "أيقونات التأثير",
      "Render Rangers": "تقديم رينجرز",
      "Dissolve Icons": "حل الأيقونات",
      "Clip Frames": "إطارات كليب",
      "Clip Text": "مقطع النص",
      "Sync Breaks": "فواصل المزامنة",
      "Dupe Detection": "كشف الخداع",
      "Color Correction": "تصحيح الألوان",
      "Audio Data": "بيانات الصوت",
      "Clip Color...": "لون المقطع...",
      "Segment Selection": "اختيار القطاع",
      "Mark I/O Selection": "وضع علامة على تحديد الإدخال/الإخراج",
      "Track Color": "لون المسار",
      "Show markers": "إظهار العلامات",
      "Show adapters": "إظهار المحولات",
      "Show Track": "إظهار المسار",
      "Track Control Panel": "لوحة تحكم المسار",
      "Zoom": "تكبير",
      "Open Comments": "افتح التعليقات",
      "Call us at": "اتصل بنا على",
      "Office": "مكتب",
      "Tracks Info": "معلومات المسارات",
      "Edit Marker": "تحرير العلامة",
      "Add": "يضيف",
      "Marker Name": "اسم العلامة",
      "Color": "لون",
      "Frame": "إطار",
      "Track": "مسار",
      "Clear Monitor": "شاشة واضحة",
      "Clear Menu": "مسح القائمة",
      "Show Markers": "إظهار العلامات",
      "Show Adapters": "إظهار المحولات",
      "Toggle Fullscreen": "ملء الشاشة تبديل",
      "Highlighter": "قلم تمييز",
      "Logger": "المسجل",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "ملف DOC غير صالح، استخدم DOCX بدلاً من ذلك، يمكنك تجربة أداة الطرف الثالث هذه",
      "Open DOC to DOCX converter": "افتح محول DOC إلى DOCX",
      "File is missing any cuts, try to click": "الملف يفتقد أي قطع، حاول النقر",
      "SET ALL ROWS": "ضبط كافة الصفوف",
      "OFF: Timecodes have start and end": "OFF: الرموز الزمنية لها بداية ونهاية",
      "ON: No end timecode and just add 30 seconds from start timecode": "تشغيل: لا يوجد رمز زمني للانتهاء وأضف فقط 30 ثانية من رمز الوقت للبدء",
      "NO END TIMECODE USE 30 SECONDS": "لا يستخدم رمز الوقت النهائي 30 ثانية",
      "HAS END TIMECODE": "لديه رمز زمني للانتهاء",
      "OFF: Create cuts using highlighted text": "OFF: إنشاء قطع باستخدام النص المميز",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "تشغيل: إنشاء قطع لجميع الصفوف في المستند. تجاهل النقاط البارزة",
      "ALL ROWS": "جميع الصفوف",
      "ONLY HIGHLIGHTED": "أبرزت فقط",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "OFF: تجاهل كاميرات المصدر. سيؤدي هذا إلى إنشاء تخفيضات فقط وفقًا للرمز الزمني المصدر.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "تشغيل: استخدم كاميرات المصدر. سيؤدي هذا إلى إنشاء تخفيضات وفقًا للرمز الزمني المصدر وكامترول مقطع المصدر",
      "USE CAMROLL IN DOCX": "استخدم CAMROLL في DOCX",
      "IGNORE CAMROLL IN DOCX": "تجاهل CAMROLL في DOCX",
      "OFF: AAF downloaded will not be renamed": "OFF: لن تتم إعادة تسمية AAF الذي تم تنزيله",
      "ON: Rename AAF to DOCX file name": "تشغيل: إعادة تسمية AAF إلى اسم ملف DOCX",
      "RENAME AAF": "إعادة تسمية AAF",
      "NO RENAME": "لا إعادة تسمية",
      "Click to Upload DOCX": "انقر لتحميل DOCX",
      "MULTI": "متعدد",
      "CLASSIC": "كلاسيكي",
      "RENAME ON": "إعادة التسمية قيد التشغيل",
      "RENAME OFF": "إعادة تسمية قبالة",
      "KEEP AUDIO ON": "احتفظ بالصوت قيد التشغيل",
      "KEEP AUDIO OFF": "أبقِ الصوت مغلقًا",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "صوت المجموعة كما هو قيد التشغيل: لإنشاء مجموعات تحتوي على مسارات صوتية يتم توجيهها تمامًا كما تم وضعها في التسلسل المتزامن. استخدم هذا إذا كنت تريد أن يتم توجيه صوت مجموعتك تمامًا مثل التسلسل الخاص بك",
      "GROUP AUDIO AS IS ON": "صوت المجموعة كما هو قيد التشغيل",
      "GROUP AUDIO AS IS OFF": "صوت المجموعة كما هو متوقف",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "إبقاء الكاميرا على المسار الصحيح: إنشاء مجموعات بزوايا الكاميرا على نفس المسار حيث يتم وضعها على التسلسل المتزامن. استخدم هذا إذا كنت تريد أن تظل الكاميرات الخاصة بك في نفس موضع المجموعة أثناء التصوير بالكامل",
      "KEEP CAM ON TRACK ON": "أبقِ الكاميرا على المسار الصحيح",
      "KEEP CAM ON TRACK OFF": "أبقِ الكاميرا على المسار الصحيح",
      "ROUTE AUDIO ON": "تشغيل الصوت",
      "ROUTE AUDIO OFF": "إيقاف تشغيل الصوت",
      "Toolium Editor": "محرر الأدوات",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "قم بتحميل ملف AAF وسنزودك بمحرر عن بعد متوافق مع Avid Media Composer في أي مكان وبعد ذلك نخرج لك ملف AAF مجمعًا لـ Avid Media Composer",
      "Free forever": "حر للابد",
      "Collaborate with ease with all the team": "التعاون بسهولة مع كل الفريق",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "اجعل الجميع على علم بنفس الصفحة من خلال كتابة التعليقات مباشرة على الإطار. أو ارسمه",
      "Look and feel you are use to in Avid Media Composer": "الشكل والمظهر الذي اعتدت عليه في Avid Media Composer",
      "Keyboard shortcuts you are use to": "اختصارات لوحة المفاتيح التي تستخدمها",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium هو مركز مركزي يتيح لك تجميع سير العمل الخاص بك وقصه وتبسيطه بكفاءة حتى يتمكن فريقك من تسريع عملك وتقليل التسليم بمقدار 4 ساعات!",
      "A-Side Trim Counter": "عداد القطع الجانبي",
      "B-Side Trim Counter": "عداد تقليم الجانب B",
      "Insert Track": "أدخل المسار",
      "OK": "نعم",
      "Track Type": "نوع المسار",
      "Track Num": "رقم المسار",
      "Mas TC1": "ماس TC1",
      "Dur TC1": "دور TC1",
      "I/O TC1": "الإدخال/الإخراج TC1",
      "Abs TC1": "عبس TC1",
      "Rem TC1": "ريم TC1",
      "Mas 24": "ماس 24",
      "Dur 24": "دور 24",
      "I/O 24": "الإدخال/الإخراج 24",
      "Abs 24": "عبس 24",
      "Rem 24": "ريم 24",
      "Mas 25": "ماس 25",
      "Dur 25": "دور 25",
      "I/O 25": "الإدخال/الإخراج 25",
      "Abs 25": "عبس 25",
      "Rem 25": "ريم 25",
      "Mas Frm": "ماس فرم",
      "Dur Frm": "دور فرم",
      "I/O Frm": "الإدخال/الإخراج فرم",
      "Abs Frm": "عبس فرم",
      "Rem Frm": "ريم فرم",
      "Sequence": "تسلسل",
      "Timecode": "الرمز الزمني",
      "TC1": "TC1",
      "Mas": "ماس",
      "Dur": "دور",
      "I/O": "الإدخال/الإخراج",
      "Abs": "عضلات المعدة",
      "Rem": "ريم",
      "Footage": "لقطات",
      "Frames": "إطارات",
      "Source": "مصدر",
      "None": "لا أحد",
      "Master": "يتقن",
      "Duration": "مدة",
      "In/Out": "في خارج",
      "Absolute": "مطلق",
      "Remain": "يبقى",
      "Audio Mono": "الصوت الأحادي",
      "Audio Stereo": "ستيريو الصوت",
      "5.1 Surround": "5.1 المحيطي",
      "7.1 Surround": "7.1 المحيطي",
      "Data": "بيانات",
      "Video": "فيديو",
      "Leave your comment here...": "اترك تعليقك هنا...",
      "Send": "يرسل",
      "Workspace": "مساحة العمل",
      "Project Settings": "إعدادات المشروع",
      "Leave Project": "ترك المشروع",
      "Delete Project": "حذف المشروع",
      "New Project": "مشروع جديد",
      "Name": "اسم",
      "Please, input the name": "من فضلك أدخل الاسم",
      "Edit Project": "تحرير المشروع",
      "Save Settings": "احفظ التغييرات",
      "Jump to project of file...": "انتقل إلى مشروع الملف...",
      "Status": "حالة",
      "Comments": "تعليقات",
      "Size": "مقاس",
      "Date uploaded": "تاريخ الرفع",
      "Uploader": "الرافع",
      "Action": "فعل",
      "File upload": "تحميل الملف",
      "Folder upload": "تحميل المجلد",
      "Folder upload as proxy": "تحميل المجلد كوكيل",
      "New folder": "ملف جديد",
      "New private folder": "مجلد خاص جديد",
      "Download all": "تحميل الكل",
      "Recently deleted": "تم حذفه مؤخرًا",
      "Project settings": "إعدادات المشروع",
      "Rename file": "إعادة تسمية الملف",
      "Move": "يتحرك",
      "Download": "تحميل",
      "Make private": "اجعلها شخصيه",
      "Reveal in project": "تكشف في المشروع",
      "Delete": "يمسح",
      "File Information": "معلومات الملف",
      "Copy Comments": "نسخ التعليقات",
      "Paste Comments": "لصق التعليقات",
      "Print": "مطبعة",
      "Download as File...": "تحميل كملف...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "نص عادي",
      "Download for Final Cut Pro X": "تحميل فاينل كت برو اكس",
      "Download for Media Composer": "تنزيل لمؤلف الوسائط",
      "Download for Resolve": "تحميل للحل",
      "No comments": "بدون تعليقات",
      "This is premium feature, contact us at support@toolium.org": "هذه ميزة متميزة، اتصل بنا على support@toolium.org",
      "Automated test to speech": "اختبار الكلام الآلي",
      "Import from uploads": "الاستيراد من التحميلات",
      "Upload your VO file": "قم بتحميل ملف VO الخاص بك",
      "Record voice over": "سجل الصوت",
      "Crop": "اقتصاص",
      "Voiceover": "التعليق الصوتي",
      "Adjust colors": "ضبط الألوان",
      "Trim": "تقليم",
      "Speed": "سرعة",
      "Loop": "حلقة",
      "Flip": "يواجه",
      "Effects": "تأثيرات",
      "Animation": "الرسوم المتحركة",
      "Add title": "أضف عنوانا",
      "Export": "يصدّر",
      "Grid": "شبكة",
      "Split": "ينقسم",
      "Split View": "انقسام الرأي",
      "Zoom to fit": "تكبير لتناسب",
      "Audio meter": "مقياس الصوت",
      "TC": "ح",
      "Comment": "تعليق",
      "Jump to Marker": "انتقل إلى العلامة",
      "Change Color": "غير اللون",
      "Change Track": "تغيير المسار",
      "Import Markers": "علامات الاستيراد",
      "Export Markers": "علامات التصدير",
      "Choose Columns": "اختر الأعمدة",
      "Cameras": "الكاميرات",
      "Desktop is required": "سطح المكتب مطلوب",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "لقد لاحظنا أنك تستخدم جهازًا بشاشة أصغر. للحصول على أفضل تجربة، يرجى استخدام سطح المكتب.",
      "Go back": "عُد",
      "OFF: Use 0 seconds for handles": "OFF: استخدم 0 ثانية للمقابض",
      "ON: Use 5 seconds for handles": "تشغيل: استخدم 5 ثوانٍ للمقابض",
      "HANDLES OFF": "مقابض قبالة",
      "HANDLES ON": "مقابض على",
      "OFF: word-per-second will be 0.37": "إيقاف: ستكون كلمة في الثانية 0.37",
      "ON: word-per-second will be detected": "تشغيل: سيتم اكتشاف كلمة في الثانية",
      "DETECT WPS": "كشف WPS",
      "WPS IS 0.37": "WPS هو 0.37",
      "OFF: don't change order or merge cuts": "OFF: لا تغير الترتيب أو تدمج القطع",
      "ON: reduce cuts by merging and sorting may change order": "تشغيل: تقليل التخفيضات عن طريق الدمج والفرز قد يغير الترتيب",
      "USE REDUCE": "استخدم تقليل",
      "KEEP ORDER AND CUTS": "حافظ على النظام والتخفيضات",
      "Timecode Errors": "أخطاء في الرمز الزمني",
      "Timecodes": "الرموز الزمنية",
      "Source ID": "معرف المصدر",
      "Error": "خطأ",
      "Log Out": "تسجيل خروج",
      "Sign Up": "اشتراك",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "المحررون المساعدون من هذه المنتجات هم بالفعل من مستخدمي الأدوات السعداء، وأكثر إنتاجية وتوفيرًا للوقت",
      "Redo": "إعادة",
      "Undo": "الغاء التحميل",
      "You have no credits left": "ليس لديك أي اعتمادات اليسار",
      "You need to buy more credits in order to keep using our platform": "تحتاج إلى شراء المزيد من الاعتمادات لمواصلة استخدام منصتنا",
      "Note": "ملحوظة",
      "You will still get free daily credits even if you don't make a purchase": "ستظل تحصل على أرصدة يومية مجانية حتى إذا لم تقم بإجراء عملية شراء",
      "Benefits of buying credits": "فوائد شراء الاعتمادات",
      "Bulk discount (up to 50%)": "خصم بالجملة (يصل إلى 50%)",
      "Get to request features to fit your worksflow": "احصل على ميزات لطلب تناسب سير العمل الخاص بك",
      "You only pay for what you use": "انت لا تدفع فقط لما تستخدمه",
      "No monthly membership and no automatic recurring payments": "لا توجد عضوية شهرية ولا توجد دفعات متكررة تلقائية",
      "You get to support our site": "تحصل على دعم موقعنا",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "إذا كنت حقًا بحاجة إلى المزيد من الاعتمادات وليس لديك أي أموال، فأرسل إلينا مقالًا يشرح لماذا يجب أن نمنحك أرصدة مجانية",
      "Buy credits": "شراء إئتمانات",
      "Ask for free credits, I don't have any money": "أطلب رصيدا مجانيا، ليس لدي أي أموال",
      "Signup with Google to get 500 free credits": "سجل في جوجل لتحصل على 500 رصيد مجاني",
      "You will also get 150 additional free credits every day": "ستحصل أيضًا على 150 رصيدًا مجانيًا إضافيًا كل يوم",
      "Select": "يختار",
      "Credits": "الاعتمادات",
      "1 cut with Cutter = 20 credits": "1 قطع مع القاطع = 20 ساعة معتمدة",
      "Discount": "تخفيض",
      "Diamond": "الماس",
      "Enterprise": "مَشرُوع",
      "7 days money back guarantee": "ضمان استعادة الأموال لمدة 7 أيام",
      "For any questions or issues": "لأية أسئلة أو قضايا",
      "Free": "حر",
      "Gold": "ذهب",
      "1 group with Grouper": "1 مجموعة مع الهامور",
      "1 cut with Cutter": "1 قطع مع القاطع"
    }
  },
  "ko": {
    "translation": {
      "Home": "집",
      "Grouper": "그루퍼",
      "GROUPER": "그루퍼",
      "Logout": "로그 아웃",
      "Profile": "프로필",
      "Onboard": "온보드",
      "Cutter": "커터",
      "Blog": "블로그",
      "Contact Us": "문의하기",
      "Login": "로그인",
      "Create group clips": "그룹 클립 만들기",
      "made easy": "쉬워졌다",
      "Free Get started": "무료 시작하기",
      "14 days free. No risk.": "14일 무료. 위험 없음.",
      "Signup Free": "가입 무료",
      "Contact us with any question at": "질문이 있으시면 다음 주소로 문의해 주세요.",
      "Just one more step before you start Grouping": "그룹화를 시작하기 전에 한 단계만 더 진행하세요.",
      "First name": "이름",
      "What is your first name": "당신의 이름은 무엇인가",
      "Please input your first name!": "이름을 입력해주세요!",
      "Last/family name": "성/성",
      "What is your last/family name": "당신의 성/성은 무엇입니까",
      "Please input your last/family name!": "성/성을 입력해주세요!",
      "Nickname": "별명",
      "What do you want others to call you?": "다른 사람들이 당신을 뭐라고 부르길 바라나요?",
      "Please input your nickname!": "닉네임을 입력해주세요!",
      "Phone Number": "전화 번호",
      "What is your phone number?": "전화 번호는 뭐 지요?",
      "Please input your phone number! Example +18777755232": "전화번호를 입력해주세요! 예 +18777755232",
      "I agree to the": "나는 동의 함",
      "privacy policy": "개인 정보 정책",
      "terms of service": "서비스 약관",
      "disclaimer": "부인 성명",
      "Privacy policy": "개인 정보 정책",
      "Terms of service": "서비스 약관",
      "Disclaimer": "부인 성명",
      "Submit": "제출하다",
      "Contact your Toolium representative to buy more credits": "추가 크레딧을 구매하려면 Toolium 담당자에게 문의하세요.",
      "See your credits in profile": "프로필에서 크레딧을 확인하세요",
      "Contact your Toolium representative to activate your account": "계정을 활성화하려면 Toolium 담당자에게 문의하세요.",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "동기화된 시퀀스의 AAF 파일을 업로드하면 Avid Media Composer용 그룹화된 AAF 파일이 출력됩니다.",
      "Click to Upload or Drag and Drop an AAF": "AAF를 업로드하거나 드래그 앤 드롭하려면 클릭하세요.",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "웹사이트를 계속 사용할 수 있도록 이메일을 확인해 주세요. 클릭할 수 있는 링크가 포함된 이메일을 보내드렸습니다.",
      "Resend verification email": "확인 이메일을 다시 보내",
      "Call us at +1-877-775-5232": "+1-877-775-5232로 전화하세요.",
      "Plan": "계획",
      "PLANS": "계획",
      "Choose a plan that works for you": "귀하에게 적합한 플랜을 선택하세요",
      "Basic": "기초적인",
      "credit": "신용 거래",
      "year": "년도",
      "Billed annually.": "매년 청구됩니다.",
      "Contact us": "문의하기",
      "Recommended": "추천",
      "Standard": "기준",
      "credits": "크레딧",
      "Billed annually. Save": "매년 청구됩니다. 구하다",
      "Premium": "프리미엄",
      "Toolium Cutter": "툴륨 커터",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Microsoft Word 문서나 Google Docs에서 직접 모든 추천서나 문서를 잘라내고 편집하세요. 간단히 텍스트에 강조 표시를 하고 당사의 프로세스를 사용하여 파일을 업로드하기만 하면 됩니다.",
      "Automate uncreative tasks": "창의적이지 않은 작업을 자동화하세요",
      "Save money processing media": "비용 절감 미디어 처리",
      "Save 4 hours every shoot day": "촬영일마다 4시간 절약",
      "The next generation of post production workflow tools": "차세대 포스트 프로덕션 워크플로우 도구",
      "14 days free.": "14일 무료.",
      "Plans": "계획",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium은 작업 흐름을 효율적으로 그룹화하고, 잘라내고, 간소화하여 팀이 작업을 신속하게 처리하고 배송 시간을 4시간 단축할 수 있게 해주는 중앙 허브입니다!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "동기화된 시퀀스의 AAF 파일을 업로드하면 Avid Media Composer용 그룹화된 AAF 파일이 출력됩니다.",
      "Toolium Grouper": "툴륨 그루퍼",
      "Used in": "다음에서 사용됨",
      "Groups are created with the full master clips": "그룹은 전체 마스터 클립으로 생성됩니다.",
      "Reference sub-clips for shortened clips": "단축된 클립에 대한 참조 하위 클립",
      "New avid multigroup type groups": "새로운 Avid 다중 그룹 유형 그룹",
      "Track renaming to group clip names": "그룹 클립 이름으로 이름 변경을 추적합니다.",
      "Features": "특징",
      "AAF uploads": "AAF 업로드",
      "AAF upload": "AAF 업로드",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: 그룹은 동일한 트랙의 클립 사이에 검은색 공백이 있는 새로운 Avid 그룹으로 생성됩니다. 타임라인의 공백 뒤에 새 그룹이 생성됩니다.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: 시퀀스 트랙 이름에 따라 그룹 내의 오디오 트랙 이름을 바꿉니다. 필요한 만큼 트랙을 열 수 있으며, 소스 오디오 트랙에 따라 그룹이 생성됩니다.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "KEEP AUDIO ON: 후행 오디오를 포함한 그룹을 최종 시퀀스에 연결합니다.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: 그룹의 모든 오디오 트랙을 연속 순서로 시퀀스로 라우팅합니다. 둘 이상의 장치에서 오디오가 나오는 경우 이 기능을 사용하세요.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: 클래식 AVID 그룹화",
      "Process type unknown": "프로세스 유형을 알 수 없음",
      "No clips found on sequence": "시퀀스에 클립이 없습니다.",
      "No clips for writing sequence": "쓰기 시퀀스용 클립이 없습니다.",
      "No sequence found in AAF file": "AAF 파일에서 시퀀스를 찾을 수 없습니다.",
      "File is to big reduce AAF file": "파일은 AAF 파일을 크게 줄입니다.",
      "Sequence has length of 0": "시퀀스의 길이는 0입니다.",
      "Group clip found on sequence": "시퀀스에서 그룹 클립이 발견되었습니다.",
      "Group clip found on sequence 2": "시퀀스 2에서 그룹 클립이 발견되었습니다.",
      "Unknown selector type found in sequence": "알 수 없는 선택기 유형이 시퀀스에서 발견되었습니다.",
      "Clip framerate does not match sequence framerate": "클립 프레임 속도가 시퀀스 프레임 속도와 일치하지 않습니다.",
      "Subclips with motion effects are not supported": "모션 효과가 있는 하위 클립은 지원되지 않습니다.",
      "Join the best": "최고의 자리에 합류하세요",
      "You are in good hands.": "당신은 좋은 손에 있습니다.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "이러한 프로덕션의 보조 편집자들은 이미 행복한 Tooliumer로서 생산성을 높이고 시간을 절약하고 있습니다.",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Microsoft Word 문서 또는 Google Docs에서 직접 모든 추천서나 문서를 잘라내고 편집하세요. 간단히 텍스트를 강조 표시하고 당사의 프로세스를 사용하여 파일을 업로드하면 됩니다.",
      "Renames audio tracks within the group clips": "그룹 클립 내의 오디오 트랙 이름을 바꿉니다.",
      "Creates the new avid multigroup type groups": "새로운 Avid 다중 그룹 유형 그룹을 생성합니다.",
      "Creates avid sub-clips for shortened clips": "단축된 클립을 위한 열렬한 하위 클립 생성",
      "Editor": "편집자",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Avid Media Composer 호환 편집기를 사용하여 브라우저에서 편집하고 싶으십니까?",
      "Yes, join pre launch": "예, 출시 전 참여",
      "No I don't want": "아니, 난 원하지 않아",
      "Click to Upload AAF": "AAF를 업로드하려면 클릭하세요.",
      "Bin panel": "빈 패널",
      "Go to Previous Event": "이전 이벤트로 이동",
      "Go to Next Event": "다음 이벤트로 이동",
      "Step Backward 1 Fram": "1프레임 뒤로 이동",
      "Step Forward 1 Fram": "1프레임 앞으로 이동",
      "Mark IN": "마크인",
      "Play": "놀다",
      "Mark OUT": "아웃으로 표시",
      "Mark Clip": "마크 클립",
      "Clear Both Marks": "두 표시 모두 지우기",
      "Add Marker": "마커 추가",
      "Swap Cam Bank": "스왑 캠 뱅크",
      "Find Bin": "빈 찾기",
      "Gang": "가다",
      "Step backward 10 frames": "10프레임 뒤로 이동",
      "Step forward 10 frames": "10프레임 앞으로 이동",
      "Go to IN": "IN으로 이동",
      "Play Length Toggle": "재생 길이 전환",
      "Go to OUT": "아웃으로 이동",
      "Match Frame": "매치 프레임",
      "Reverse Match Frame": "역방향 일치 프레임",
      "Quad Split": "쿼드 분할",
      "Nine Split": "나인 스플릿",
      "Step Backward 1 Frame": "1프레임 뒤로 이동",
      "Step Forward 1 Frame": "1프레임 앞으로 이동",
      "Add Edit": "편집 추가",
      "Lift": "승강기",
      "Extract": "발췌",
      "Quick Transition": "빠른 전환",
      "Remove Effect": "효과 제거",
      "Collapse": "무너지다",
      "Video Quality Menu": "비디오 품질 메뉴",
      "Monitor Volume": "모니터 볼륨",
      "Meter Menu": "미터 메뉴",
      "Effect Mode": "효과 모드",
      "Trim Mode": "트림 모드",
      "Step In": "들르다",
      "Step Out": "밖으로 나가다",
      "Render Effect": "렌더 효과",
      "Tool Palette": "도구 팔레트",
      "Head Fade": "헤드 페이드",
      "Tall Fade": "톨 페이드",
      "To the Left": "왼쪽으로",
      "To the Right": "오른쪽으로",
      "Select In/Out": "인/아웃 선택",
      "Link Selection Toggle": "링크 선택 토글",
      "HW/SW": "하드웨어/소프트웨어",
      "Titler Pro": "타이틀러 프로",
      "Default Setup": "기본 설정",
      "View Type": "보기 유형",
      "Track Panel": "트랙 패널",
      "Effect Icons": "효과 아이콘",
      "Render Rangers": "렌더 레인저스",
      "Dissolve Icons": "디졸브 아이콘",
      "Clip Frames": "클립 프레임",
      "Clip Text": "클립 텍스트",
      "Sync Breaks": "동기화 중단",
      "Dupe Detection": "중복 탐지",
      "Color Correction": "색상보정",
      "Audio Data": "오디오 데이터",
      "Clip Color...": "클립 색상...",
      "Segment Selection": "세그먼트 선택",
      "Mark I/O Selection": "I/O 선택 표시",
      "Track Color": "트랙 색상",
      "Show markers": "마커 표시",
      "Show adapters": "어댑터 표시",
      "Show Track": "트랙 표시",
      "Track Control Panel": "트랙 제어판",
      "Zoom": "줌",
      "Open Comments": "댓글 열기",
      "Call us at": "우리에게 전화하세요",
      "Office": "사무실",
      "Tracks Info": "트랙 정보",
      "Edit Marker": "마커 편집",
      "Add": "추가하다",
      "Marker Name": "마커 이름",
      "Color": "색상",
      "Frame": "액자",
      "Track": "길",
      "Clear Monitor": "모니터 지우기",
      "Clear Menu": "메뉴 지우기",
      "Show Markers": "마커 표시",
      "Show Adapters": "어댑터 표시",
      "Toggle Fullscreen": "전체 화면 전환",
      "Highlighter": "형광펜",
      "Logger": "나무꾼",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "파일 DOC가 잘못되었습니다. 대신 DOCX를 사용하세요. 이 타사 도구를 사용해 보세요.",
      "Open DOC to DOCX converter": "DOC에서 DOCX로 변환기 열기",
      "File is missing any cuts, try to click": "파일에 잘라낸 부분이 없습니다. 클릭해 보세요.",
      "SET ALL ROWS": "모든 행 설정",
      "OFF: Timecodes have start and end": "OFF: 타임코드에 시작과 끝이 있습니다.",
      "ON: No end timecode and just add 30 seconds from start timecode": "ON: 종료 타임코드가 없으며 시작 타임코드에서 30초만 추가합니다.",
      "NO END TIMECODE USE 30 SECONDS": "종료 타임코드 없음 30초 사용",
      "HAS END TIMECODE": "종료 시간 코드가 있음",
      "OFF: Create cuts using highlighted text": "OFF: 강조 표시된 텍스트를 사용하여 컷 만들기",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "ON: 문서의 모든 행에 대한 컷을 만듭니다. 하이라이트 무시",
      "ALL ROWS": "모든 행",
      "ONLY HIGHLIGHTED": "강조된 부분만",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "OFF: 소스 캠롤을 무시합니다. 소스 타임코드에 따라서만 컷이 생성됩니다.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: 소스 캠롤을 사용합니다. 소스 타임코드와 소스 클립 캠롤에 따라 컷이 생성됩니다.",
      "USE CAMROLL IN DOCX": "DOCX에서 CAMROLL 사용",
      "IGNORE CAMROLL IN DOCX": "DOCX에서 CAMROLL을 무시합니다.",
      "OFF: AAF downloaded will not be renamed": "OFF: 다운로드된 AAF의 이름이 변경되지 않습니다.",
      "ON: Rename AAF to DOCX file name": "ON: AAF의 이름을 DOCX 파일 이름으로 바꿉니다.",
      "RENAME AAF": "AAF 이름 바꾸기",
      "NO RENAME": "이름 바꾸기 없음",
      "Click to Upload DOCX": "DOCX를 업로드하려면 클릭하세요.",
      "MULTI": "다중",
      "CLASSIC": "권위 있는",
      "RENAME ON": "이름 바꾸기 켜기",
      "RENAME OFF": "이름 바꾸기 끄기",
      "KEEP AUDIO ON": "오디오를 계속 켜두세요",
      "KEEP AUDIO OFF": "오디오를 꺼두세요",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "GROUP AUDIO AS IS ON: 동기화된 시퀀스에 배치된 대로 정확하게 라우팅된 오디오 트랙으로 그룹을 생성합니다. 그룹 오디오를 시퀀스와 똑같이 라우팅하려면 이 옵션을 사용하세요.",
      "GROUP AUDIO AS IS ON": "현재 상태 그대로 그룹 오디오",
      "GROUP AUDIO AS IS OFF": "현재 상태 그대로 그룹 오디오",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: 동기화된 시퀀스에 배치된 것과 동일한 트랙에 카메라 각도가 있는 그룹을 만듭니다. 전체 촬영 중에 카메라가 동일한 그룹 배치에 유지되도록 하려면 이것을 사용하십시오.",
      "KEEP CAM ON TRACK ON": "캠을 계속 추적하세요",
      "KEEP CAM ON TRACK OFF": "캠을 계속 추적하세요",
      "ROUTE AUDIO ON": "오디오 라우팅 켜기",
      "ROUTE AUDIO OFF": "오디오 라우팅 끄기",
      "Toolium Editor": "툴리움 편집자",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "AAF 파일을 업로드하면 Avid Media Composer와 호환되는 원격 편집기를 제공하고 이후 Avid Media Composer용 그룹화된 AAF 파일을 출력해 드립니다.",
      "Free forever": "영원히 무료",
      "Collaborate with ease with all the team": "모든 팀과 쉽게 협업하세요",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "프레임에 바로 피드백을 작성하여 모든 사람이 동일한 내용을 공유할 수 있도록 하세요. 아니면 그려보세요",
      "Look and feel you are use to in Avid Media Composer": "Avid Media Composer에서 익숙한 모양과 느낌",
      "Keyboard shortcuts you are use to": "자주 사용하는 단축키",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium은 작업 흐름을 효율적으로 그룹화, 잘라내기, 간소화하여 팀이 작업을 신속하게 처리하고 배송 시간을 4시간 단축할 수 있게 해주는 중앙 허브입니다!",
      "A-Side Trim Counter": "A면 트림 카운터",
      "B-Side Trim Counter": "B면 트림 카운터",
      "Insert Track": "트랙 삽입",
      "OK": "좋아요",
      "Track Type": "트랙 유형",
      "Track Num": "트랙 번호",
      "Mas TC1": "마스 TC1",
      "Dur TC1": "Dur TC1",
      "I/O TC1": "입출력 TC1",
      "Abs TC1": "복근 TC1",
      "Rem TC1": "렘 TC1",
      "Mas 24": "마 24",
      "Dur 24": "기간 24",
      "I/O 24": "입출력 24",
      "Abs 24": "복근 24",
      "Rem 24": "렘 24",
      "Mas 25": "마스 25",
      "Dur 25": "기간 25",
      "I/O 25": "입출력 25",
      "Abs 25": "복근 25",
      "Rem 25": "렘 25",
      "Mas Frm": "마스 프름",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O 펌웨어",
      "Abs Frm": "절대 FRM",
      "Rem Frm": "렘 프름",
      "Sequence": "순서",
      "Timecode": "시간 코드",
      "TC1": "TC1",
      "Mas": "마스",
      "Dur": "두르",
      "I/O": "입출력",
      "Abs": "복근",
      "Rem": "렘",
      "Footage": "피트 길이",
      "Frames": "프레임",
      "Source": "원천",
      "None": "없음",
      "Master": "주인",
      "Duration": "지속",
      "In/Out": "인/아웃",
      "Absolute": "순수한",
      "Remain": "유지하다",
      "Audio Mono": "오디오 모노",
      "Audio Stereo": "오디오 스테레오",
      "5.1 Surround": "5.1 서라운드",
      "7.1 Surround": "7.1 서라운드",
      "Data": "데이터",
      "Video": "동영상",
      "Leave your comment here...": "여기에 의견을 남겨주세요...",
      "Send": "보내다",
      "Workspace": "작업공간",
      "Project Settings": "프로젝트 설정",
      "Leave Project": "프로젝트 나가기",
      "Delete Project": "프로젝트 삭제",
      "New Project": "새 프로젝트",
      "Name": "이름",
      "Please, input the name": "이름을 입력해주세요",
      "Edit Project": "프로젝트 편집",
      "Save Settings": "설정 저장",
      "Jump to project of file...": "파일의 프로젝트로 이동...",
      "Status": "상태",
      "Comments": "코멘트",
      "Size": "크기",
      "Date uploaded": "업로드된 날짜",
      "Uploader": "업로더",
      "Action": "행동",
      "File upload": "파일 업로드",
      "Folder upload": "폴더 업로드",
      "Folder upload as proxy": "프록시로 폴더 업로드",
      "New folder": "새 폴더",
      "New private folder": "새 개인 폴더",
      "Download all": "모두 다운로드",
      "Recently deleted": "최근 삭제됨",
      "Project settings": "프로젝트 설정",
      "Rename file": "파일명 변경",
      "Move": "이동하다",
      "Download": "다운로드",
      "Make private": "비공개로 설정",
      "Reveal in project": "프로젝트에 공개",
      "Delete": "삭제",
      "File Information": "파일 정보",
      "Copy Comments": "댓글 복사",
      "Paste Comments": "댓글 붙여넣기",
      "Print": "인쇄",
      "Download as File...": "파일로 다운로드...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "일반 텍스트",
      "Download for Final Cut Pro X": "Final Cut Pro X용 다운로드",
      "Download for Media Composer": "Media Composer용 다운로드",
      "Download for Resolve": "해결용 다운로드",
      "No comments": "댓글 없음",
      "This is premium feature, contact us at support@toolium.org": "이것은 프리미엄 기능입니다. support@toolium.org로 문의해 주세요.",
      "Automated test to speech": "음성 테스트 자동화",
      "Import from uploads": "업로드에서 가져오기",
      "Upload your VO file": "VO 파일 업로드",
      "Record voice over": "음성 해설 녹음",
      "Crop": "수확고",
      "Voiceover": "음성 해설",
      "Adjust colors": "색상 조정",
      "Trim": "손질",
      "Speed": "속도",
      "Loop": "고리",
      "Flip": "튀기다",
      "Effects": "효과",
      "Animation": "생기",
      "Add title": "제목 추가",
      "Export": "내보내다",
      "Grid": "그리드",
      "Split": "나뉘다",
      "Split View": "분할 보기",
      "Zoom to fit": "맞게 확대/축소",
      "Audio meter": "오디오 미터",
      "TC": "TC",
      "Comment": "논평",
      "Jump to Marker": "마커로 이동",
      "Change Color": "색상 변경",
      "Change Track": "변경 추적",
      "Import Markers": "마커 가져오기",
      "Export Markers": "마커 내보내기",
      "Choose Columns": "열 선택",
      "Cameras": "카메라",
      "Desktop is required": "데스크톱이 필요합니다",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "귀하께서 더 작은 화면의 장치를 사용하고 계신 것으로 확인되었습니다. 최상의 경험을 위해서는 데스크톱을 사용하시기 바랍니다.",
      "Go back": "돌아가기",
      "OFF: Use 0 seconds for handles": "OFF: 핸들에 0초 사용",
      "ON: Use 5 seconds for handles": "ON: 핸들에 5초 사용",
      "HANDLES OFF": "핸들 꺼짐",
      "HANDLES ON": "핸들 ON",
      "OFF: word-per-second will be 0.37": "끄기: 초당 단어 수는 0.37입니다.",
      "ON: word-per-second will be detected": "ON: 초당 단어가 감지됩니다.",
      "DETECT WPS": "WPS 감지",
      "WPS IS 0.37": "WPS는 0.37입니다.",
      "OFF: don't change order or merge cuts": "OFF: 순서를 변경하거나 컷을 병합하지 않습니다.",
      "ON: reduce cuts by merging and sorting may change order": "켜기: 병합 및 정렬을 통해 절단량을 줄이면 순서가 변경될 수 있습니다.",
      "USE REDUCE": "사용 감소",
      "KEEP ORDER AND CUTS": "질서 유지 및 삭감",
      "Timecode Errors": "타임코드 오류",
      "Timecodes": "타임코드",
      "Source ID": "소스 ID",
      "Error": "오류",
      "Log Out": "로그 아웃",
      "Sign Up": "가입하기",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "이러한 프로덕션의 보조 편집자들은 이미 행복한 Tooliumer로서 생산성을 높이고 시간을 절약하고 있습니다.",
      "Redo": "다시 하다",
      "Undo": "실행 취소",
      "You have no credits left": "남은 크레딧이 없습니다.",
      "You need to buy more credits in order to keep using our platform": "우리 플랫폼을 계속 사용하려면 더 많은 크레딧을 구매해야 합니다.",
      "Note": "메모",
      "You will still get free daily credits even if you don't make a purchase": "구매하지 않아도 매일 무료 크레딧을 받을 수 있습니다.",
      "Benefits of buying credits": "크레딧 구매의 이점",
      "Bulk discount (up to 50%)": "대량할인(최대 50%)",
      "Get to request features to fit your worksflow": "귀하의 워크플로우에 맞는 기능을 요청하세요",
      "You only pay for what you use": "사용한 만큼만 비용을 지불하면 됩니다.",
      "No monthly membership and no automatic recurring payments": "월간 멤버십 및 자동 반복 결제가 없습니다.",
      "You get to support our site": "당신은 우리 사이트를 지원하게됩니다",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "정말로 더 많은 크레딧이 필요하고 돈이 없다면 무료 크레딧을 제공해야 하는 이유를 설명하는 에세이를 보내주세요.",
      "Buy credits": "크레딧 구매",
      "Ask for free credits, I don't have any money": "무료 크레딧을 요청하세요. 돈이 없습니다.",
      "Signup with Google to get 500 free credits": "500 무료 크레딧을 받으려면 Google에 가입하세요.",
      "You will also get 150 additional free credits every day": "또한 매일 150개의 추가 무료 크레딧을 받으실 수 있습니다.",
      "Select": "선택하다",
      "Credits": "크레딧",
      "1 cut with Cutter = 20 credits": "커터로 1컷 = 20크레딧",
      "Discount": "할인",
      "Diamond": "다이아몬드",
      "Enterprise": "기업",
      "7 days money back guarantee": "7일 환불 보장",
      "For any questions or issues": "질문이나 문제가 있는 경우",
      "Free": "무료",
      "Gold": "금",
      "1 group with Grouper": "그루퍼가 있는 그룹 1개",
      "1 cut with Cutter": "커터로 1컷"
    }
  },
  "vi": {
    "translation": {
      "Home": "Trang chủ",
      "Grouper": "cá mú",
      "GROUPER": "NHÓM",
      "Logout": "Đăng xuất",
      "Profile": "Hồ sơ",
      "Onboard": "Trên tàu",
      "Cutter": "Máy cắt",
      "Blog": "Blog",
      "Contact Us": "Liên hệ chúng tôi",
      "Login": "Đăng nhập",
      "Create group clips": "Tạo clip nhóm",
      "made easy": "thực hiện dễ dàng",
      "Free Get started": "Bắt đầu miễn phí",
      "14 days free. No risk.": "14 ngày miễn phí. Không mạo hiểm.",
      "Signup Free": "Đăng kí miễn phí",
      "Contact us with any question at": "Liên hệ với chúng tôi với bất kỳ câu hỏi tại",
      "Just one more step before you start Grouping": "Chỉ một bước nữa trước khi bạn bắt đầu Nhóm",
      "First name": "Tên đầu tiên",
      "What is your first name": "Tên bạn là gì",
      "Please input your first name!": "Vui lòng nhập tên của bạn!",
      "Last/family name": "Họ/họ",
      "What is your last/family name": "Họ/họ của bạn là gì",
      "Please input your last/family name!": "Vui lòng nhập họ/họ của bạn!",
      "Nickname": "Tên nick",
      "What do you want others to call you?": "Bạn muốn người khác gọi bạn là gì?",
      "Please input your nickname!": "Vui lòng nhập biệt danh của bạn!",
      "Phone Number": "Số điện thoại",
      "What is your phone number?": "Số điện thoại của bạn là gì?",
      "Please input your phone number! Example +18777755232": "Vui lòng nhập số điện thoại của bạn! Ví dụ +18777755232",
      "I agree to the": "tôi đồng ý với",
      "privacy policy": "Chính sách bảo mật",
      "terms of service": "điều khoản dịch vụ",
      "disclaimer": "từ chối trách nhiệm",
      "Privacy policy": "Chính sách bảo mật",
      "Terms of service": "Điều khoản dịch vụ",
      "Disclaimer": "Tuyên bố miễn trừ trách nhiệm",
      "Submit": "Nộp",
      "Contact your Toolium representative to buy more credits": "Liên hệ với đại diện Toolium của bạn để mua thêm tín dụng",
      "See your credits in profile": "Xem các khoản tín dụng của bạn trong hồ sơ",
      "Contact your Toolium representative to activate your account": "Liên hệ với đại diện Toolium của bạn để kích hoạt tài khoản của bạn",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "tải lên tệp AAF của trình tự đã đồng bộ hóa của bạn và chúng tôi sẽ xuất cho bạn tệp AAF được nhóm cho Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "Nhấp để tải lên hoặc kéo và thả AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "Vui lòng đi và xác minh email của bạn để bạn có thể tiếp tục sử dụng trang web. Chúng tôi đã gửi cho bạn một email có liên kết để nhấp vào.",
      "Resend verification email": "Gửi lại email xác minh",
      "Call us at +1-877-775-5232": "Gọi cho chúng tôi theo số +1-877-775-5232",
      "Plan": "Kế hoạch",
      "PLANS": "CÁC KẾ HOẠCH",
      "Choose a plan that works for you": "Chọn một kế hoạch phù hợp với bạn",
      "Basic": "Nền tảng",
      "credit": "tín dụng",
      "year": "năm",
      "Billed annually.": "Thanh toán hàng năm.",
      "Contact us": "Liên hệ chúng tôi",
      "Recommended": "Khuyến khích",
      "Standard": "Tiêu chuẩn",
      "credits": "tín dụng",
      "Billed annually. Save": "Thanh toán hàng năm. Cứu",
      "Premium": "Phần thưởng",
      "Toolium Cutter": "Máy cắt công cụ",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "Cắt và chỉnh sửa tất cả lời chứng thực hoặc tài liệu của bạn trực tiếp từ tài liệu Microsoft Word hoặc Google Docs bằng cách chỉ cần đánh dấu văn bản của bạn và tải tệp của bạn lên bằng quy trình của chúng tôi",
      "Automate uncreative tasks": "Tự động hóa các nhiệm vụ không sáng tạo",
      "Save money processing media": "Tiết kiệm tiền xử lý phương tiện truyền thông",
      "Save 4 hours every shoot day": "Tiết kiệm 4 giờ mỗi ngày chụp",
      "The next generation of post production workflow tools": "Thế hệ tiếp theo của các công cụ xử lý công việc hậu sản xuất",
      "14 days free.": "14 ngày miễn phí.",
      "Plans": "Các kế hoạch",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium là một trung tâm trung tâm cho phép bạn nhóm, cắt giảm và hợp lý hóa quy trình làm việc một cách hiệu quả để nhóm của bạn có thể đẩy nhanh công việc và giảm thời gian giao hàng xuống 4 giờ!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "Tải lên tệp AAF của trình tự đã đồng bộ hóa của bạn và chúng tôi sẽ xuất cho bạn tệp AAF được nhóm cho Avid Media Composer",
      "Toolium Grouper": "Cá mú Toolium",
      "Used in": "Được dùng trong",
      "Groups are created with the full master clips": "Các nhóm được tạo với các clip chính đầy đủ",
      "Reference sub-clips for shortened clips": "Các clip phụ tham khảo cho các clip rút gọn",
      "New avid multigroup type groups": "Nhóm loại nhiều nhóm khao khát mới",
      "Track renaming to group clip names": "Theo dõi đổi tên thành tên clip nhóm",
      "Features": "Đặc trưng",
      "AAF uploads": "Tải lên AAF",
      "AAF upload": "tải lên AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: Các nhóm được tạo dưới dạng nhóm đam mê mới với khoảng trắng giữa các clip trên cùng một bản nhạc. Một nhóm mới được tạo sau một khoảng trống trên dòng thời gian.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: Đổi tên các bản âm thanh trong nhóm theo tên bản nhạc trong chuỗi. Bạn có thể mở bao nhiêu bản nhạc tùy thích, các nhóm sẽ được tạo theo bản âm thanh nguồn.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "GIỮ ÂM THANH BẬT: Ghép các nhóm bao gồm cả âm thanh cuối vào chuỗi cuối cùng.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: Định tuyến tất cả các bản âm thanh trong nhóm theo trình tự theo thứ tự liên tiếp. Sử dụng tính năng này nếu bạn có âm thanh từ nhiều thiết bị.",
      "CLASSIC: Classic AVID grouping": "CỔ ĐIỂN: Nhóm AVID cổ điển",
      "Process type unknown": "Loại quy trình không xác định",
      "No clips found on sequence": "Không tìm thấy clip nào theo trình tự",
      "No clips for writing sequence": "Không có clip để viết trình tự",
      "No sequence found in AAF file": "Không tìm thấy trình tự trong tệp AAF",
      "File is to big reduce AAF file": "Tệp là tệp AAF giảm lớn",
      "Sequence has length of 0": "Chuỗi có độ dài bằng 0",
      "Group clip found on sequence": "Đã tìm thấy clip nhóm theo trình tự",
      "Group clip found on sequence 2": "Clip nhóm được tìm thấy ở chuỗi 2",
      "Unknown selector type found in sequence": "Đã tìm thấy loại bộ chọn không xác định theo trình tự",
      "Clip framerate does not match sequence framerate": "Tốc độ khung hình của clip không khớp với tốc độ khung hình của chuỗi",
      "Subclips with motion effects are not supported": "Subclips có hiệu ứng chuyển động không được hỗ trợ",
      "Join the best": "Tham gia tốt nhất",
      "You are in good hands.": "Thật khéo tay.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "Các biên tập viên trợ lý từ những sản phẩm này đã là những Tooliumers hài lòng, làm việc hiệu quả hơn và tiết kiệm thời gian hơn",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "Cắt và chỉnh sửa tất cả lời chứng thực hoặc tài liệu của bạn trực tiếp từ tài liệu Microsoft Word hoặc Google Docs chỉ bằng cách đánh dấu văn bản của bạn và tải tệp của bạn lên bằng quy trình của chúng tôi",
      "Renames audio tracks within the group clips": "Đổi tên các bản âm thanh trong clip nhóm",
      "Creates the new avid multigroup type groups": "Tạo các nhóm loại nhiều nhóm khao khát mới",
      "Creates avid sub-clips for shortened clips": "Tạo các clip phụ hấp dẫn cho các clip rút gọn",
      "Editor": "Biên tập viên",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "Bạn muốn chỉnh sửa trong trình duyệt của mình bằng trình chỉnh sửa tương thích Avid Media Composer của chúng tôi?",
      "Yes, join pre launch": "Có, tham gia trước khi ra mắt",
      "No I don't want": "Không, tôi không muốn",
      "Click to Upload AAF": "Bấm để tải lên AAF",
      "Bin panel": "Bảng thùng",
      "Go to Previous Event": "Đi tới sự kiện trước đó",
      "Go to Next Event": "Đi tới sự kiện tiếp theo",
      "Step Backward 1 Fram": "Bước lùi 1 khung hình",
      "Step Forward 1 Fram": "Bước tới 1 khung hình",
      "Mark IN": "Đánh dấu VÀO",
      "Play": "Chơi",
      "Mark OUT": "Đánh dấu",
      "Mark Clip": "Đánh dấu clip",
      "Clear Both Marks": "Xóa cả hai dấu",
      "Add Marker": "Thêm điểm đánh dấu",
      "Swap Cam Bank": "Trao đổi ngân hàng Cam",
      "Find Bin": "Tìm thùng",
      "Gang": "Băng nhóm",
      "Step backward 10 frames": "Bước lùi 10 khung hình",
      "Step forward 10 frames": "Bước tới 10 khung hình",
      "Go to IN": "Đi tới VÀO",
      "Play Length Toggle": "Chuyển đổi độ dài phát",
      "Go to OUT": "Đi tới RA",
      "Match Frame": "Khung trận đấu",
      "Reverse Match Frame": "Khung đối sánh ngược",
      "Quad Split": "Chia bốn",
      "Nine Split": "chín chia",
      "Step Backward 1 Frame": "Bước lùi 1 khung hình",
      "Step Forward 1 Frame": "Bước tới 1 khung hình",
      "Add Edit": "Thêm Chỉnh sửa",
      "Lift": "Thang máy",
      "Extract": "Trích xuất",
      "Quick Transition": "Chuyển đổi nhanh",
      "Remove Effect": "Xóa hiệu ứng",
      "Collapse": "Sụp đổ",
      "Video Quality Menu": "Trình đơn chất lượng video",
      "Monitor Volume": "Giám sát âm lượng",
      "Meter Menu": "Menu đồng hồ đo",
      "Effect Mode": "Chế độ hiệu ứng",
      "Trim Mode": "Chế độ cắt",
      "Step In": "Bước vào",
      "Step Out": "Bước ra khỏi",
      "Render Effect": "Hiệu ứng kết xuất",
      "Tool Palette": "Bảng công cụ",
      "Head Fade": "đầu mờ dần",
      "Tall Fade": "cao phai",
      "To the Left": "Qua bên trái",
      "To the Right": "Rẽ phải",
      "Select In/Out": "Chọn vào/ra",
      "Link Selection Toggle": "Chuyển đổi lựa chọn liên kết",
      "HW/SW": "CT/SW",
      "Titler Pro": "Tiêu đề Pro",
      "Default Setup": "Cài đặt mặc định",
      "View Type": "Loại xem",
      "Track Panel": "Bảng theo dõi",
      "Effect Icons": "Biểu tượng hiệu ứng",
      "Render Rangers": "Kết xuất Rangers",
      "Dissolve Icons": "Hòa tan các biểu tượng",
      "Clip Frames": "Khung kẹp",
      "Clip Text": "Đoạn văn bản",
      "Sync Breaks": "Đồng bộ hóa ngắt",
      "Dupe Detection": "Phát hiện lừa đảo",
      "Color Correction": "Chỉnh màu",
      "Audio Data": "Dữ liệu âm thanh",
      "Clip Color...": "Kẹp màu...",
      "Segment Selection": "Lựa chọn phân khúc",
      "Mark I/O Selection": "Đánh dấu lựa chọn I/O",
      "Track Color": "Theo dõi màu",
      "Show markers": "Hiển thị điểm đánh dấu",
      "Show adapters": "Hiển thị bộ điều hợp",
      "Show Track": "Hiển thị bản nhạc",
      "Track Control Panel": "Bảng điều khiển theo dõi",
      "Zoom": "Phóng",
      "Open Comments": "Mở bình luận",
      "Call us at": "Gọi cho chúng tôi tại",
      "Office": "Văn phòng",
      "Tracks Info": "Thông tin bài hát",
      "Edit Marker": "Chỉnh sửa điểm đánh dấu",
      "Add": "Thêm vào",
      "Marker Name": "Tên điểm đánh dấu",
      "Color": "Màu sắc",
      "Frame": "Khung",
      "Track": "Theo dõi",
      "Clear Monitor": "Xóa màn hình",
      "Clear Menu": "Xóa menu",
      "Show Markers": "Hiển thị điểm đánh dấu",
      "Show Adapters": "Hiển thị bộ điều hợp",
      "Toggle Fullscreen": "Bật chế độ toàn màn hình",
      "Highlighter": "Bút đánh dấu",
      "Logger": "Tiều phu",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "Tệp DOC không hợp lệ, thay vào đó hãy sử dụng DOCX, bạn có thể thử công cụ của bên thứ 3 này",
      "Open DOC to DOCX converter": "Mở trình chuyển đổi DOC sang DOCX",
      "File is missing any cuts, try to click": "Tệp bị thiếu bất kỳ vết cắt nào, hãy thử nhấp vào",
      "SET ALL ROWS": "THIẾT LẬP TẤT CẢ CÁC HÀNG",
      "OFF: Timecodes have start and end": "TẮT: Mã thời gian có điểm bắt đầu và kết thúc",
      "ON: No end timecode and just add 30 seconds from start timecode": "BẬT: Không có mã thời gian kết thúc và chỉ thêm 30 giây kể từ mã thời gian bắt đầu",
      "NO END TIMECODE USE 30 SECONDS": "KHÔNG SỬ DỤNG MÃ THỜI GIAN KẾT THÚC 30 GIÂY",
      "HAS END TIMECODE": "CÓ MÃ THỜI GIAN KẾT THÚC",
      "OFF: Create cuts using highlighted text": "TẮT: Tạo vết cắt bằng văn bản được đánh dấu",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "BẬT: Tạo vết cắt cho tất cả các hàng trong tài liệu. Bỏ qua những điểm nổi bật",
      "ALL ROWS": "TẤT CẢ CÁC HÀNG",
      "ONLY HIGHLIGHTED": "CHỈ ĐÁNH DẤU",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "TẮT: Bỏ qua camroll nguồn. Điều này sẽ chỉ tạo ra các vết cắt theo mã thời gian nguồn.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "BẬT: Sử dụng camroll nguồn. Điều này sẽ tạo các đoạn cắt theo mã thời gian nguồn và camroll clip nguồn",
      "USE CAMROLL IN DOCX": "SỬ DỤNG CAMROLL TRONG DOCX",
      "IGNORE CAMROLL IN DOCX": "BỎ QUA CAMROLL TRONG DOCX",
      "OFF: AAF downloaded will not be renamed": "TẮT: AAF đã tải xuống sẽ không được đổi tên",
      "ON: Rename AAF to DOCX file name": "BẬT: Đổi tên tệp AAF thành tên tệp DOCX",
      "RENAME AAF": "ĐỔI TÊN AAF",
      "NO RENAME": "KHÔNG ĐỔI TÊN",
      "Click to Upload DOCX": "Bấm để tải lên DOCX",
      "MULTI": "ĐA",
      "CLASSIC": "CỔ ĐIỂN",
      "RENAME ON": "BẬT ĐỔI TÊN",
      "RENAME OFF": "TẮT ĐỔI TẮT",
      "KEEP AUDIO ON": "BẬT ÂM THANH",
      "KEEP AUDIO OFF": "TẮT ÂM THANH",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "NHÓM ÂM THANH NHƯ VẬY: Tạo các nhóm với các bản âm thanh được định tuyến chính xác như chúng được đặt trên chuỗi đã đồng bộ hóa của bạn. Sử dụng tính năng này nếu bạn muốn âm thanh nhóm của mình được định tuyến chính xác như trình tự của bạn",
      "GROUP AUDIO AS IS ON": "ÂM THANH NHÓM NHƯ TRÊN",
      "GROUP AUDIO AS IS OFF": "ÂM THANH NHÓM NHƯ TẮT",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "GIỮ CAM TRÊN THEO DÕI: Tạo các nhóm có góc camera trên cùng một rãnh khi chúng được đặt trên chuỗi đã đồng bộ hóa của bạn. Sử dụng tính năng này nếu bạn muốn máy ảnh của mình ở cùng một vị trí nhóm trong toàn bộ buổi chụp",
      "KEEP CAM ON TRACK ON": "GIỮ CAM ĐANG THEO DÕI",
      "KEEP CAM ON TRACK OFF": "GIỮ CAM TRÊN THEO DÕI TẮT",
      "ROUTE AUDIO ON": "TUYẾN ÂM THANH BẬT",
      "ROUTE AUDIO OFF": "TẮT ÂM THANH TẮT",
      "Toolium Editor": "Trình chỉnh sửa công cụ",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "Tải lên tệp AAF và chúng tôi sẽ cung cấp cho bạn trình chỉnh sửa từ xa tương thích với Avid Media Composer ở mọi nơi và sau đó xuất cho bạn tệp AAF được nhóm cho Avid Media Composer",
      "Free forever": "Miễn phí mãi mãi",
      "Collaborate with ease with all the team": "Cộng tác dễ dàng với tất cả nhóm",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "Giữ mọi người cùng thống nhất bằng cách viết phản hồi ngay trên khung. Hoặc vẽ nó",
      "Look and feel you are use to in Avid Media Composer": "Nhìn và cảm nhận bạn đã quen với Avid Media Composer",
      "Keyboard shortcuts you are use to": "Các phím tắt bạn đang sử dụng",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium là một trung tâm trung tâm cho phép bạn nhóm, cắt giảm và hợp lý hóa quy trình làm việc một cách hiệu quả để nhóm của bạn có thể đẩy nhanh công việc và giảm thời gian giao hàng xuống 4 giờ!",
      "A-Side Trim Counter": "Bộ đếm trang trí A-Side",
      "B-Side Trim Counter": "Bộ đếm trang trí bên B",
      "Insert Track": "Chèn bản nhạc",
      "OK": "ĐƯỢC RỒI",
      "Track Type": "Loại bản nhạc",
      "Track Num": "Theo dõi số",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "Dur TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "cơ bụng TC1",
      "Rem TC1": "Rem TC1",
      "Mas 24": "Thứ 24",
      "Dur 24": "Suốt 24 giờ",
      "I/O 24": "Vào/ra 24",
      "Abs 24": "Cơ bụng 24",
      "Rem 24": "Rem 24",
      "Mas 25": "Thứ 25",
      "Dur 25": "trong 25",
      "I/O 25": "Vào/ra 25",
      "Abs 25": "Cơ bụng 25",
      "Rem 25": "Rem 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "Dur Frm",
      "I/O Frm": "I/O từ",
      "Abs Frm": "Cơ bụng",
      "Rem Frm": "Rem Từ",
      "Sequence": "Sự liên tiếp",
      "Timecode": "Mã thời gian",
      "TC1": "TC1",
      "Mas": "Mas",
      "Dur": "Dur",
      "I/O": "Vào/ra",
      "Abs": "cơ bụng",
      "Rem": "Rem",
      "Footage": "Đoạn phim",
      "Frames": "Khung",
      "Source": "Nguồn",
      "None": "Không có",
      "Master": "Bậc thầy",
      "Duration": "Khoảng thời gian",
      "In/Out": "Vào/Ra",
      "Absolute": "tuyệt đối",
      "Remain": "Duy trì",
      "Audio Mono": "Âm thanh đơn âm",
      "Audio Stereo": "Âm thanh nổi",
      "5.1 Surround": "Âm thanh vòm 5.1",
      "7.1 Surround": "Âm thanh vòm 7.1",
      "Data": "Dữ liệu",
      "Video": "Băng hình",
      "Leave your comment here...": "Để lại bình luận của bạn ở đây...",
      "Send": "Gửi",
      "Workspace": "Không gian làm việc",
      "Project Settings": "Thiết lập dự án",
      "Leave Project": "Rời khỏi dự án",
      "Delete Project": "Xóa dự án",
      "New Project": "Dự án mới",
      "Name": "Tên",
      "Please, input the name": "Vui lòng nhập tên",
      "Edit Project": "Chỉnh sửa dự án",
      "Save Settings": "Lưu các thiết lập",
      "Jump to project of file...": "Chuyển tới dự án của tập tin...",
      "Status": "Trạng thái",
      "Comments": "Bình luận",
      "Size": "Kích cỡ",
      "Date uploaded": "Ngày tải lên",
      "Uploader": "Người tải lên",
      "Action": "Hoạt động",
      "File upload": "Tải tập tin lên",
      "Folder upload": "Tải lên thư mục",
      "Folder upload as proxy": "Tải lên thư mục dưới dạng proxy",
      "New folder": "Thư mục mới",
      "New private folder": "Thư mục riêng mới",
      "Download all": "Tải xuống tất cả",
      "Recently deleted": "Đã xóa gần đây",
      "Project settings": "Thiết lập dự án",
      "Rename file": "Đổi tên tập tin",
      "Move": "Di chuyển",
      "Download": "Tải xuống",
      "Make private": "Đặt ở chế độ riêng tư",
      "Reveal in project": "Tiết lộ trong dự án",
      "Delete": "Xóa bỏ",
      "File Information": "Thông tin tập tin",
      "Copy Comments": "Sao chép bình luận",
      "Paste Comments": "Dán bình luận",
      "Print": "In",
      "Download as File...": "Tải xuống dưới dạng tệp...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "Văn bản thô",
      "Download for Final Cut Pro X": "Tải xuống cho Final Cut Pro X",
      "Download for Media Composer": "Tải xuống cho Trình soạn thảo phương tiện",
      "Download for Resolve": "Tải xuống cho Giải quyết",
      "No comments": "Miễn bình luận",
      "This is premium feature, contact us at support@toolium.org": "Đây là tính năng cao cấp, hãy liên hệ với chúng tôi tại support@toolium.org",
      "Automated test to speech": "Kiểm tra giọng nói tự động",
      "Import from uploads": "Nhập từ video tải lên",
      "Upload your VO file": "Tải lên tệp VO của bạn",
      "Record voice over": "Ghi âm giọng nói",
      "Crop": "Mùa vụ",
      "Voiceover": "Âm thanh vượt mức",
      "Adjust colors": "Điều chỉnh màu sắc",
      "Trim": "Cắt tỉa",
      "Speed": "Tốc độ",
      "Loop": "Vòng",
      "Flip": "Lật",
      "Effects": "Các hiệu ứng",
      "Animation": "Hoạt hình",
      "Add title": "Thêm tiêu đề",
      "Export": "Xuất khẩu",
      "Grid": "Lưới",
      "Split": "Tách ra",
      "Split View": "Chế độ xem tách",
      "Zoom to fit": "Thu phóng để phù hợp",
      "Audio meter": "Máy đo âm thanh",
      "TC": "TC",
      "Comment": "Bình luận",
      "Jump to Marker": "Chuyển đến điểm đánh dấu",
      "Change Color": "Thay đổi màu",
      "Change Track": "Thay đổi bản nhạc",
      "Import Markers": "Điểm đánh dấu nhập",
      "Export Markers": "Điểm đánh dấu xuất khẩu",
      "Choose Columns": "Chọn cột",
      "Cameras": "Máy ảnh",
      "Desktop is required": "Máy tính để bàn là bắt buộc",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "Chúng tôi nhận thấy rằng bạn đang sử dụng thiết bị có màn hình nhỏ hơn. Để có trải nghiệm tốt nhất, vui lòng sử dụng máy tính để bàn.",
      "Go back": "Quay lại",
      "OFF: Use 0 seconds for handles": "TẮT: Sử dụng 0 giây cho tay cầm",
      "ON: Use 5 seconds for handles": "BẬT: Sử dụng 5 giây cho tay cầm",
      "HANDLES OFF": "TẮT TAY",
      "HANDLES ON": "TAY TAY BẬT",
      "OFF: word-per-second will be 0.37": "TẮT: từ trên giây sẽ là 0,37",
      "ON: word-per-second will be detected": "BẬT: từ mỗi giây sẽ được phát hiện",
      "DETECT WPS": "PHÁT HIỆN WPS",
      "WPS IS 0.37": "WPS LÀ 0,37",
      "OFF: don't change order or merge cuts": "TẮT: không thay đổi thứ tự hoặc hợp nhất các vết cắt",
      "ON: reduce cuts by merging and sorting may change order": "BẬT: giảm các vết cắt bằng cách hợp nhất và sắp xếp có thể thay đổi thứ tự",
      "USE REDUCE": "SỬ DỤNG GIẢM GIÁ",
      "KEEP ORDER AND CUTS": "GIỮ ĐẶT HÀNG VÀ CẮT",
      "Timecode Errors": "Lỗi mã thời gian",
      "Timecodes": "Mã thời gian",
      "Source ID": "Mã nguồn",
      "Error": "Lỗi",
      "Log Out": "Đăng xuất",
      "Sign Up": "Đăng ký",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "Trợ lý biên tập viên của những sản phẩm này đã là những Tooliumers hạnh phúc, làm việc hiệu quả hơn và tiết kiệm thời gian hơn",
      "Redo": "Làm lại",
      "Undo": "Hoàn tác",
      "You have no credits left": "Bạn không còn tín dụng nào",
      "You need to buy more credits in order to keep using our platform": "Bạn cần mua thêm tín dụng để tiếp tục sử dụng nền tảng của chúng tôi",
      "Note": "Ghi chú",
      "You will still get free daily credits even if you don't make a purchase": "Bạn vẫn sẽ nhận được tín dụng miễn phí hàng ngày ngay cả khi bạn không mua hàng",
      "Benefits of buying credits": "Lợi ích của việc mua tín dụng",
      "Bulk discount (up to 50%)": "Giảm giá hàng loạt (lên tới 50%)",
      "Get to request features to fit your worksflow": "Nhận yêu cầu các tính năng phù hợp với quy trình làm việc của bạn",
      "You only pay for what you use": "Bạn chỉ trả tiền cho những gì bạn sử dụng",
      "No monthly membership and no automatic recurring payments": "Không có thành viên hàng tháng và không có thanh toán định kỳ tự động",
      "You get to support our site": "Bạn có thể hỗ trợ trang web của chúng tôi",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "Nếu bạn thực sự cần thêm tín dụng và bạn không có tiền, hãy gửi cho chúng tôi một bài luận giải thích lý do tại sao chúng tôi nên cung cấp cho bạn tín dụng miễn phí",
      "Buy credits": "Mua thẻ tín dụng",
      "Ask for free credits, I don't have any money": "Yêu cầu tín dụng miễn phí, tôi không có tiền",
      "Signup with Google to get 500 free credits": "Đăng ký với Google để nhận 500 tín dụng miễn phí",
      "You will also get 150 additional free credits every day": "Bạn cũng sẽ nhận được thêm 150 tín dụng miễn phí mỗi ngày",
      "Select": "Lựa chọn",
      "Credits": "Tín dụng",
      "1 cut with Cutter = 20 credits": "1 lần cắt bằng Máy cắt = 20 tín chỉ",
      "Discount": "Giảm giá",
      "Diamond": "Kim cương",
      "Enterprise": "Doanh nghiệp",
      "7 days money back guarantee": "Đảm bảo hoàn tiền trong 7 ngày",
      "For any questions or issues": "Đối với bất kỳ câu hỏi hoặc vấn đề",
      "Free": "Miễn phí",
      "Gold": "Vàng",
      "1 group with Grouper": "1 nhóm có cá mú",
      "1 cut with Cutter": "1 lần cắt bằng Máy cắt"
    }
  },
  "he": {
    "translation": {
      "Home": "בית",
      "Grouper": "דַקָר",
      "GROUPER": "דַקָר",
      "Logout": "להתנתק",
      "Profile": "פּרוֹפִיל",
      "Onboard": "בעניין",
      "Cutter": "מַחתֵך",
      "Blog": "בלוג",
      "Contact Us": "צור קשר",
      "Login": "התחברות",
      "Create group clips": "צור קליפים קבוצתיים",
      "made easy": "נעשה בקלות",
      "Free Get started": "חינם התחל",
      "14 days free. No risk.": "14 יום חינם. אין סיכון.",
      "Signup Free": "הירשם בחינם",
      "Contact us with any question at": "פנה אלינו בכל שאלה ב",
      "Just one more step before you start Grouping": "רק עוד שלב אחד לפני שתתחיל בקיבוץ",
      "First name": "שם פרטי",
      "What is your first name": "מה השם הפרטי שלך",
      "Please input your first name!": "אנא הזן את שמך הפרטי!",
      "Last/family name": "שם משפחה אחרון",
      "What is your last/family name": "מה שם המשפחה/משפחה שלך",
      "Please input your last/family name!": "אנא הזן את שם המשפחה/משפחה שלך!",
      "Nickname": "כינוי",
      "What do you want others to call you?": "איך אתה רוצה שאחרים יקראו לך?",
      "Please input your nickname!": "אנא הזן את הכינוי שלך!",
      "Phone Number": "מספר טלפון",
      "What is your phone number?": "מה מספר הטלפון שלך?",
      "Please input your phone number! Example +18777755232": "אנא הזן את מספר הטלפון שלך! דוגמה +18777755232",
      "I agree to the": "אני מסכים ל",
      "privacy policy": "מדיניות הפרטיות",
      "terms of service": "תנאי השירות",
      "disclaimer": "כתב ויתור",
      "Privacy policy": "מדיניות הפרטיות",
      "Terms of service": "תנאי השירות",
      "Disclaimer": "כתב ויתור",
      "Submit": "שלח",
      "Contact your Toolium representative to buy more credits": "צור קשר עם נציג Toolium שלך כדי לקנות זיכויים נוספים",
      "See your credits in profile": "ראה את הקרדיטים שלך בפרופיל",
      "Contact your Toolium representative to activate your account": "צור קשר עם נציג Toolium שלך כדי להפעיל את חשבונך",
      "upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "העלה קובץ AAF של הרצף המסונכרן שלך ואנו נוציא לך קובץ AAF מקובץ עבור Avid Media Composer",
      "Click to Upload or Drag and Drop an AAF": "לחץ כדי להעלות או לגרור ושחרר AAF",
      "Please go and verify your email so you can continue to use the website. We sent you an email with a link to click on.": "נא לאמת את הדוא\"ל שלך כדי שתוכל להמשיך להשתמש באתר. שלחנו לך מייל עם קישור ללחוץ עליו.",
      "Resend verification email": "לשלוח דוא\"ל לאימות",
      "Call us at +1-877-775-5232": "התקשר אלינו למספר 1-877-775-5232",
      "Plan": "לְתַכְנֵן",
      "PLANS": "תוכניות",
      "Choose a plan that works for you": "בחר תוכנית שמתאימה לך",
      "Basic": "בסיסי",
      "credit": "אַשׁרַאי",
      "year": "שָׁנָה",
      "Billed annually.": "מחויב מדי שנה.",
      "Contact us": "צור קשר",
      "Recommended": "מוּמלָץ",
      "Standard": "תֶקֶן",
      "credits": "נקודות זכות",
      "Billed annually. Save": "מחויב מדי שנה. להציל",
      "Premium": "פּרֶמיָה",
      "Toolium Cutter": "חותך Toolium",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highting your text and uploading your file using our process": "גזור וערוך את כל העדויות או המסמכים שלך ישירות ממסמך Microsoft Word או Google Docs שלך פשוט על ידי העלאת הטקסט שלך והעלאת הקובץ שלך באמצעות התהליך שלנו",
      "Automate uncreative tasks": "הפוך משימות לא יצירתיות לאוטומטיות",
      "Save money processing media": "חסוך כסף בעיבוד מדיה",
      "Save 4 hours every shoot day": "חסוך 4 שעות בכל יום צילום",
      "The next generation of post production workflow tools": "הדור הבא של כלי זרימת עבודה לאחר הייצור",
      "14 days free.": "14 יום חינם.",
      "Plans": "תוכניות",
      "Toolium is a central hub that lets you efficiently group, cut and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium הוא מרכז מרכזי המאפשר לך לקבץ, לחתוך ולייעל ביעילות את זרימת העבודה שלך כך שהצוות שלך יוכל לזרז את העבודה שלך ולהפחית את האספקה ​​ב-4 שעות!",
      "Upload an AAF file of your synchronised sequence and we will output you a grouped AAF file for Avid Media Composer": "העלה קובץ AAF של הרצף המסונכרן שלך ואנו נוציא לך קובץ AAF מקובץ עבור Avid Media Composer",
      "Toolium Grouper": "Toolium Grouper",
      "Used in": "בשימוש ב",
      "Groups are created with the full master clips": "קבוצות נוצרות עם קטעי המאסטר המלאים",
      "Reference sub-clips for shortened clips": "קטעי משנה עזר לקליפים מקוצרים",
      "New avid multigroup type groups": "קבוצות חדשות נלהבות מסוג מרובות קבוצות",
      "Track renaming to group clip names": "שינוי שם הרצועה לשמות קליפים קבוצתיים",
      "Features": "מאפיינים",
      "AAF uploads": "העלאות של AAF",
      "AAF upload": "העלאת AAF",
      "MULTI: Groups are created as the new avid groups with black spaces between clips on the same track. A new group is created after a space on the timeline.": "MULTI: קבוצות נוצרות כקבוצות נלהבות חדשות עם רווחים שחורים בין קליפים באותו רצועה. קבוצה חדשה נוצרת לאחר רווח על ציר הזמן.",
      "RENAME ON: Renames audio tracks inside the groups according to the sequence track names. You can open as many tracks as you need, the groups will be created according to the source audio track.": "RENAME ON: שינוי שמות של רצועות אודיו בתוך הקבוצות לפי שמות רצועות הרצף. אתה יכול לפתוח כמה רצועות שאתה צריך, הקבוצות ייווצרו לפי רצועת האודיו המקור.",
      "KEEP AUDIO ON: Splices groups including the trailing audio onto the final sequence.": "שמור על אודיו פועל: מחבר קבוצות כולל האודיו הנגרר לרצף הסופי.",
      "ROUTE AUDIO ON: Routes all the audio tracks in the group to the sequence in consecutive order. Use this if you have audio from more than one device.": "ROUTE AUDIO ON: מנתב את כל רצועות האודיו בקבוצה לרצף בסדר רצוף. השתמש בזה אם יש לך אודיו מיותר ממכשיר אחד.",
      "CLASSIC: Classic AVID grouping": "CLASSIC: קיבוץ AVID קלאסי",
      "Process type unknown": "סוג תהליך לא ידוע",
      "No clips found on sequence": "לא נמצאו קליפים ברצף",
      "No clips for writing sequence": "אין קליפים לרצף כתיבה",
      "No sequence found in AAF file": "לא נמצא רצף בקובץ AAF",
      "File is to big reduce AAF file": "הקובץ הוא קובץ AAF להקטין בגדול",
      "Sequence has length of 0": "לרצף יש אורך של 0",
      "Group clip found on sequence": "קליפ קבוצתי נמצא ברצף",
      "Group clip found on sequence 2": "קליפ קבוצתי נמצא ברצף 2",
      "Unknown selector type found in sequence": "סוג בורר לא ידוע נמצא ברצף",
      "Clip framerate does not match sequence framerate": "קצב המסגרת של הקליפ אינו תואם לקצב המסגרת של הרצף",
      "Subclips with motion effects are not supported": "קטעי משנה עם אפקטי תנועה אינם נתמכים",
      "Join the best": "הצטרפו לטובים ביותר",
      "You are in good hands.": "אתה בידיים טובות.",
      "Assitent editors from these productions are already happy Tooliumers, more productive and saving time": "עורכים עוזרים מהפקות אלה כבר מאושרים Tooliumers, פרודוקטיביים יותר וחוסכים זמן",
      "Cut and edit all your testimonials or documentries directly from your Microsoft Word document or Google Docs by just simply highlighting your text and uploading your file using our process": "גזור וערוך את כל העדויות או המסמכים שלך ישירות ממסמך Microsoft Word או Google Docs שלך פשוט על ידי הדגשת הטקסט שלך והעלאת הקובץ שלך באמצעות התהליך שלנו",
      "Renames audio tracks within the group clips": "משנה שמות של רצועות אודיו בתוך הקליפים הקבוצתיים",
      "Creates the new avid multigroup type groups": "יוצר את הקבוצות החדשות מסוג מרובות קבוצות נלהבות",
      "Creates avid sub-clips for shortened clips": "יוצר קטעי משנה נלהבים עבור קטעים מקוצרים",
      "Editor": "עוֹרֵך",
      "Want to edit in your browser using our Avid Media Composer compatible editor?": "רוצה לערוך בדפדפן שלך באמצעות העורך התואם Avid Media Composer שלנו?",
      "Yes, join pre launch": "כן, הצטרף להשקה מוקדמת",
      "No I don't want": "לא אני לא רוצה",
      "Click to Upload AAF": "לחץ כדי להעלות AAF",
      "Bin panel": "פאנל פח",
      "Go to Previous Event": "עבור לאירוע הקודם",
      "Go to Next Event": "עבור לאירוע הבא",
      "Step Backward 1 Fram": "צעד אחורה 1 Fram",
      "Step Forward 1 Fram": "צעד קדימה 1 Fram",
      "Mark IN": "סמן IN",
      "Play": "לְשַׂחֵק",
      "Mark OUT": "תדגיש",
      "Mark Clip": "מארק קליפ",
      "Clear Both Marks": "נקה את שני הסימנים",
      "Add Marker": "הוסף מרקר",
      "Swap Cam Bank": "החלפת בנק מצלמות",
      "Find Bin": "מצא את Bin",
      "Gang": "כְּנוּפִיָה",
      "Step backward 10 frames": "צעד אחורה 10 פריימים",
      "Step forward 10 frames": "צעד קדימה 10 פריימים",
      "Go to IN": "עבור אל IN",
      "Play Length Toggle": "החלפת אורך משחק",
      "Go to OUT": "עבור אל OUT",
      "Match Frame": "Match Frame",
      "Reverse Match Frame": "מסגרת התאמה הפוכה",
      "Quad Split": "Quad Split",
      "Nine Split": "פיצול תשע",
      "Step Backward 1 Frame": "צעד אחורה מסגרת אחת",
      "Step Forward 1 Frame": "צעד קדימה מסגרת אחת",
      "Add Edit": "הוסף עריכה",
      "Lift": "מעלית",
      "Extract": "לחלץ",
      "Quick Transition": "מעבר מהיר",
      "Remove Effect": "הסר אפקט",
      "Collapse": "הִתמוֹטְטוּת",
      "Video Quality Menu": "תפריט איכות וידאו",
      "Monitor Volume": "עוצמת הקול של צג",
      "Meter Menu": "תפריט מטר",
      "Effect Mode": "מצב אפקט",
      "Trim Mode": "מצב חיתוך",
      "Step In": "כנס",
      "Step Out": "צא החוצה",
      "Render Effect": "אפקט עיבוד",
      "Tool Palette": "לוח כלים",
      "Head Fade": "ראש דעיכה",
      "Tall Fade": "Tall Fade",
      "To the Left": "לשמאל",
      "To the Right": "לימין",
      "Select In/Out": "בחר ב-In/Out",
      "Link Selection Toggle": "החלפת מצב בחירת קישור",
      "HW/SW": "HW/SW",
      "Titler Pro": "Titler Pro",
      "Default Setup": "הגדרת ברירת מחדל",
      "View Type": "סוג תצוגה",
      "Track Panel": "לוח מסלול",
      "Effect Icons": "אייקוני אפקט",
      "Render Rangers": "רנדר ריינג'רס",
      "Dissolve Icons": "פירוק אייקונים",
      "Clip Frames": "מסגרות קליפ",
      "Clip Text": "טקסט קליפ",
      "Sync Breaks": "הפסקות סנכרון",
      "Dupe Detection": "איתור שולל",
      "Color Correction": "תיקון צבע",
      "Audio Data": "נתוני אודיו",
      "Clip Color...": "צבע קליפ...",
      "Segment Selection": "בחירת פלחים",
      "Mark I/O Selection": "סמן את בחירת I/O",
      "Track Color": "צבע מסלול",
      "Show markers": "הצג סמנים",
      "Show adapters": "הצג מתאמים",
      "Show Track": "הצג מסלול",
      "Track Control Panel": "מסלול לוח הבקרה",
      "Zoom": "תקריב",
      "Open Comments": "פתח תגובות",
      "Call us at": "תתקשר אלינו ב",
      "Office": "מִשׂרָד",
      "Tracks Info": "מידע על רצועות",
      "Edit Marker": "ערוך סמן",
      "Add": "לְהוֹסִיף",
      "Marker Name": "שם סמן",
      "Color": "צֶבַע",
      "Frame": "מִסגֶרֶת",
      "Track": "מַסלוּל",
      "Clear Monitor": "צג נקה",
      "Clear Menu": "נקה תפריט",
      "Show Markers": "הצג סמנים",
      "Show Adapters": "הצג מתאמים",
      "Toggle Fullscreen": "החלף מסך מלא",
      "Highlighter": "מרקר מדגיש",
      "Logger": "כּוֹרֵת עֵצִים",
      "Invalid file DOC, use DOCX instead, you can try this 3rd party tool": "קובץ DOC לא חוקי, השתמש ב-DOCX במקום זאת, אתה יכול לנסות את הכלי הזה של צד שלישי",
      "Open DOC to DOCX converter": "פתח את ממיר DOC ל-DOCX",
      "File is missing any cuts, try to click": "בקובץ חסרים כל חתכים, נסה ללחוץ",
      "SET ALL ROWS": "הגדר את כל השורות",
      "OFF: Timecodes have start and end": "כבוי: לקודי זמן יש התחלה וסיום",
      "ON: No end timecode and just add 30 seconds from start timecode": "מופעל: אין קוד זמן סיום ורק הוסף 30 שניות מקוד זמן ההתחלה",
      "NO END TIMECODE USE 30 SECONDS": "אין שימוש בקוד זמן סיום 30 שניות",
      "HAS END TIMECODE": "יש קוד סיום",
      "OFF: Create cuts using highlighted text": "כבוי: צור חיתוכים באמצעות טקסט מודגש",
      "ON: Create cuts for all rows in the document. Ignoring the highlights": "מופעל: צור חיתוכים עבור כל השורות במסמך. מתעלמים מהדגשים",
      "ALL ROWS": "כל השורות",
      "ONLY HIGHLIGHTED": "רק מודגש",
      "OFF: Ignore source camrolls. This will create cuts only according to source timecode.": "כבוי: התעלם ממראות מקור. זה ייצור חיתוכים רק לפי קוד זמן המקור.",
      "ON: Use source camrolls. This will create cuts according to source timecode and source clip camroll": "ON: השתמש ב-camrolls מקור. זה ייצור חיתוכים בהתאם לקוד זמן המקור ו-Camroll של המקור",
      "USE CAMROLL IN DOCX": "השתמש ב-CAMROLL ב-DOCX",
      "IGNORE CAMROLL IN DOCX": "התעלם מ-CAMROLL ב-DOCX",
      "OFF: AAF downloaded will not be renamed": "כבוי: AAF שהורד לא ישונה",
      "ON: Rename AAF to DOCX file name": "מופעל: שנה את שם הקובץ של AAF ל-DOCX",
      "RENAME AAF": "שנה את שם AAF",
      "NO RENAME": "אין שינוי שם",
      "Click to Upload DOCX": "לחץ כדי להעלות DOCX",
      "MULTI": "רַב",
      "CLASSIC": "קלַאסִי",
      "RENAME ON": "שנה שם מופעל",
      "RENAME OFF": "שנה שם כבוי",
      "KEEP AUDIO ON": "השאר אודיו מופעל",
      "KEEP AUDIO OFF": "השאר את האודיו כבוי",
      "GROUP AUDIO AS IS ON: Creates groups with the audio tracks routed exactly as they are placed on your synchronized sequence. Use this if you want your groups audio to be routed exactly like your sequence": "קבוצת אודיו כפי שהיא פועלת: יוצר קבוצות עם רצועות האודיו מנותבות בדיוק כפי שהן ממוקמות ברצף המסונכרן שלך. השתמש בזה אם אתה רוצה ששמע הקבוצות שלך ינותב בדיוק כמו הרצף שלך",
      "GROUP AUDIO AS IS ON": "אודיו קבוצתי כפי שהוא מופעל",
      "GROUP AUDIO AS IS OFF": "קבוצת אודיו כבויה",
      "KEEP CAM ON TRACK ON: Creates groups with camera angles on the same track as they are placed on your synchronized sequence. Use this if you want your cameras to stay in the same group placement during the whole shoot": "KEEP CAM ON TRACK ON: יוצר קבוצות עם זוויות מצלמה באותו המסלול כפי שהן ממוקמות ברצף המסונכרן שלך. השתמש בזה אם אתה רוצה שהמצלמות שלך יישארו באותו מיקום קבוצתי במהלך כל הצילום",
      "KEEP CAM ON TRACK ON": "השאר את המצלמה על המסלול",
      "KEEP CAM ON TRACK OFF": "השאר את המצלמה במצב כבוי",
      "ROUTE AUDIO ON": "מסלול אודיו מופעל",
      "ROUTE AUDIO OFF": "מסלול אודיו כבוי",
      "Toolium Editor": "עורך Toolium",
      "Upload an AAF file and we will provide you a Avid Media Composer compatible remote anywhere editor and afterwards output you a grouped AAF file for Avid Media Composer": "העלה קובץ AAF ואנו נספק לך שלט תואם Avid Media Composer בכל מקום עורך ולאחר מכן פלט לך קובץ AAF מקובץ עבור Avid Media Composer",
      "Free forever": "חופשי לנצח",
      "Collaborate with ease with all the team": "שתף פעולה בקלות עם כל הצוות",
      "Keep everyone on the same page by writing feedback right on a frame. Or draw it": "השאר את כולם באותו עמוד על ידי כתיבת משוב ישירות על מסגרת. או לצייר את זה",
      "Look and feel you are use to in Avid Media Composer": "תראה והרגש שאתה רגיל אליהם ב-Avid Media Composer",
      "Keyboard shortcuts you are use to": "קיצורי מקשים שאתה רגיל אליהם",
      "Toolium is a central hub that lets you efficiently group, cut, and streamline your workflow so your team can expedite your work and reduce delivery by 4 hours!": "Toolium הוא מרכז מרכזי המאפשר לך לקבץ, לחתוך ולייעל ביעילות את זרימת העבודה שלך כך שהצוות שלך יכול לזרז את העבודה שלך ולהפחית את האספקה ​​ב-4 שעות!",
      "A-Side Trim Counter": "דלפק חיזור A-Side",
      "B-Side Trim Counter": "B-Side Trim Counter",
      "Insert Track": "הכנס מסלול",
      "OK": "בסדר",
      "Track Type": "סוג מסלול",
      "Track Num": "מספר רצועה",
      "Mas TC1": "Mas TC1",
      "Dur TC1": "דור TC1",
      "I/O TC1": "I/O TC1",
      "Abs TC1": "Abs TC1",
      "Rem TC1": "רם TC1",
      "Mas 24": "מס 24",
      "Dur 24": "משך 24",
      "I/O 24": "I/O 24",
      "Abs 24": "Abs 24",
      "Rem 24": "רם 24",
      "Mas 25": "מס 25",
      "Dur 25": "משך 25",
      "I/O 25": "I/O 25",
      "Abs 25": "בטן 25",
      "Rem 25": "רם 25",
      "Mas Frm": "Mas Frm",
      "Dur Frm": "דור פרם",
      "I/O Frm": "I/O Frm",
      "Abs Frm": "Abs Frm",
      "Rem Frm": "רם פרם",
      "Sequence": "סדר פעולות",
      "Timecode": "קוד זמן",
      "TC1": "TC1",
      "Mas": "מאס",
      "Dur": "דור",
      "I/O": "I/O",
      "Abs": "שרירי בטן",
      "Rem": "רמ",
      "Footage": "מִדָה",
      "Frames": "מסגרות",
      "Source": "מָקוֹר",
      "None": "אף אחד",
      "Master": "לִשְׁלוֹט",
      "Duration": "מֶשֶׁך",
      "In/Out": "בפנים בחוץ",
      "Absolute": "מוּחלָט",
      "Remain": "לְהִשָׁאֵר",
      "Audio Mono": "אודיו מונו",
      "Audio Stereo": "אודיו סטריאו",
      "5.1 Surround": "5.1 סראונד",
      "7.1 Surround": "7.1 סראונד",
      "Data": "נתונים",
      "Video": "וִידֵאוֹ",
      "Leave your comment here...": "השאר את תגובתך כאן...",
      "Send": "לִשְׁלוֹחַ",
      "Workspace": "סביבת עבודה",
      "Project Settings": "הגדרות פרויקט",
      "Leave Project": "עזוב את הפרויקט",
      "Delete Project": "מחק את הפרויקט",
      "New Project": "פרוייקט חדש",
      "Name": "שֵׁם",
      "Please, input the name": "בבקשה, הזן את השם",
      "Edit Project": "ערוך פרויקט",
      "Save Settings": "שמור הגדרות",
      "Jump to project of file...": "עבור לפרויקט של קובץ...",
      "Status": "סטָטוּס",
      "Comments": "הערות",
      "Size": "גודל",
      "Date uploaded": "תאריך העלאה",
      "Uploader": "מעלה",
      "Action": "פעולה",
      "File upload": "העלאת קובץ",
      "Folder upload": "העלאת תיקיה",
      "Folder upload as proxy": "העלאת תיקיה כפרוקסי",
      "New folder": "תיקייה חדשה",
      "New private folder": "תיקיה פרטית חדשה",
      "Download all": "הורד הכל",
      "Recently deleted": "נמחק לאחרונה",
      "Project settings": "הגדרות הפרויקט",
      "Rename file": "שנה את שם הקובץ",
      "Move": "מהלך \\ לזוז \\ לעבור",
      "Download": "הורד",
      "Make private": "להפוך לפרטי",
      "Reveal in project": "לחשוף בפרויקט",
      "Delete": "לִמְחוֹק",
      "File Information": "מידע על הקובץ",
      "Copy Comments": "העתק הערות",
      "Paste Comments": "הדבק הערות",
      "Print": "הדפס",
      "Download as File...": "הורד כקובץ...",
      "CSV": "CSV",
      "XML": "XML",
      "Plain Text": "טקסט רגיל",
      "Download for Final Cut Pro X": "הורד עבור Final Cut Pro X",
      "Download for Media Composer": "הורד עבור Media Composer",
      "Download for Resolve": "הורד ל-Resolve",
      "No comments": "אין תגובה",
      "This is premium feature, contact us at support@toolium.org": "זוהי תכונת פרימיום, פנה אלינו בכתובת support@toolium.org",
      "Automated test to speech": "בדיקה אוטומטית לדיבור",
      "Import from uploads": "ייבוא ​​מהעלאות",
      "Upload your VO file": "העלה את קובץ ה-VO שלך",
      "Record voice over": "הקלט קול אובר",
      "Crop": "יְבוּל",
      "Voiceover": "קריינות",
      "Adjust colors": "התאם צבעים",
      "Trim": "מְטוּפָּח",
      "Speed": "מְהִירוּת",
      "Loop": "לוּלָאָה",
      "Flip": "לְהַעִיף",
      "Effects": "אפקטים",
      "Animation": "אנימציה",
      "Add title": "הוסף כותרת",
      "Export": "יְצוּא",
      "Grid": "רֶשֶׁת",
      "Split": "לְפַצֵל",
      "Split View": "תצוגה מפוצלת",
      "Zoom to fit": "זום כדי להתאים",
      "Audio meter": "מד אודיו",
      "TC": "TC",
      "Comment": "תגובה",
      "Jump to Marker": "קפוץ ל-Marker",
      "Change Color": "שנה צבע",
      "Change Track": "שנה מסלול",
      "Import Markers": "סמני ייבוא",
      "Export Markers": "סמני ייצוא",
      "Choose Columns": "בחר עמודות",
      "Cameras": "מצלמות",
      "Desktop is required": "נדרש שולחן עבודה",
      "We've noticed that you're using a smaller screen device. For the best experience, please use a desktop.": "שמנו לב שאתה משתמש במכשיר מסך קטן יותר. לחוויה הטובה ביותר, השתמש בשולחן עבודה.",
      "Go back": "תחזור",
      "OFF: Use 0 seconds for handles": "כבוי: השתמש ב-0 שניות עבור ידיות",
      "ON: Use 5 seconds for handles": "ON: השתמש ב-5 שניות עבור ידיות",
      "HANDLES OFF": "ידיות כבוי",
      "HANDLES ON": "ידיות על",
      "OFF: word-per-second will be 0.37": "כבוי: מילה לשנייה תהיה 0.37",
      "ON: word-per-second will be detected": "פועל: מילה לשנייה תזוהה",
      "DETECT WPS": "זיהוי WPS",
      "WPS IS 0.37": "WPS הוא 0.37",
      "OFF: don't change order or merge cuts": "כבוי: אין לשנות סדר או למזג חתכים",
      "ON: reduce cuts by merging and sorting may change order": "מופעל: צמצם קיצוצים על ידי מיזוג ומיון עשוי לשנות סדר",
      "USE REDUCE": "שימוש הפחת",
      "KEEP ORDER AND CUTS": "שמור על הסדר וחתכים",
      "Timecode Errors": "שגיאות קוד זמן",
      "Timecodes": "קודי זמן",
      "Source ID": "מזהה מקור",
      "Error": "שְׁגִיאָה",
      "Log Out": "להתנתק",
      "Sign Up": "הירשם",
      "Assistant editors from these productions are already happy Tooliumers, more productive and saving time": "עוזרי עורכים מהפקות אלה כבר מאושרים ב-Tooliumers, פרודוקטיביים יותר וחוסכים זמן",
      "Redo": "לַעֲשׂוֹת שׁוּב",
      "Undo": "לבטל",
      "You have no credits left": "לא נשארו לך קרדיטים",
      "You need to buy more credits in order to keep using our platform": "אתה צריך לקנות זיכויים נוספים כדי להמשיך להשתמש בפלטפורמה שלנו",
      "Note": "הערה",
      "You will still get free daily credits even if you don't make a purchase": "עדיין תקבל זיכוי יומי חינם גם אם לא תבצע רכישה",
      "Benefits of buying credits": "יתרונות בקניית זיכויים",
      "Bulk discount (up to 50%)": "הנחה בכמות גדולה (עד 50%)",
      "Get to request features to fit your worksflow": "קבל לבקש תכונות שיתאימו לזרימת העבודה שלך",
      "You only pay for what you use": "אתה משלם רק על מה שאתה משתמש",
      "No monthly membership and no automatic recurring payments": "ללא חברות חודשית וללא תשלומים חוזרים אוטומטיים",
      "You get to support our site": "אתה יכול לתמוך באתר שלנו",
      "If you really need more credits and you don't have any money, send us an essay explaining why we should give you free credits": "אם אתה באמת צריך יותר זיכויים ואין לך כסף, שלח לנו חיבור המסביר מדוע עלינו לתת לך קרדיטים בחינם",
      "Buy credits": "קנה קרדיטים",
      "Ask for free credits, I don't have any money": "בקש זיכויים בחינם, אין לי כסף",
      "Signup with Google to get 500 free credits": "הירשם ל-Google כדי לקבל 500 זיכויים בחינם",
      "You will also get 150 additional free credits every day": "תקבל גם 150 זיכויים נוספים בחינם בכל יום",
      "Select": "בחר",
      "Credits": "נקודות זכות",
      "1 cut with Cutter = 20 credits": "חתך אחד עם קאטר = 20 קרדיטים",
      "Discount": "הנחה",
      "Diamond": "יהלום",
      "Enterprise": "מִפְעָל",
      "7 days money back guarantee": "אחריות להחזר כספי של 7 ימים",
      "For any questions or issues": "לכל שאלה או בעיה",
      "Free": "חינם",
      "Gold": "זהב",
      "1 group with Grouper": "קבוצה אחת עם Grouper",
      "1 cut with Cutter": "1 חתך עם קאטר"
    }
  }
};
/* eslint-enable quote-props,quotes,max-len */

export default E;
