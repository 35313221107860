// LICENSE_CODE MIT
import fs from 'fs';
import path from 'path';
import assert from 'assert';
import eserf from './eserf.js';
import xurl from './xurl.js';
import str from './str.js';
import proc from './proc.js';
import conv from './conv.js';

// XXX colin: change to xfs.js
let E = {};
export default E;
let is_verbose;

E.BYTE = 1;
E.KB = E.BYTE*1000;
E.MB = E.KB*1000;
E.GB = E.MB*1000;

E.read = f=>{
  let ret;
  try {
    ret = fs.readFileSync(f, 'utf8');
  } catch(e) {}
  return ret;
};

E.extname = (file, opt)=>{
  if (opt?.is_no_period)
    return path.extname(file).toLowerCase().slice(1);
  return path.extname(file).toLowerCase();
};

E.eread = (f, encoding='utf8')=>eserf(function* xfs_eread(){
  fs.readFile(f, encoding, (err, data)=>{
    if (err)
      return void this.continue({err});
    this.continue({data});
  });
  let res = yield this.wait();
  if (res.err)
    return res;
  return res.data;
});

E.eread_e = (f, encoding)=>eserf(function* xfs_eread_e(){
  let ret = yield E.eread(f, encoding);
  if (ret.err)
    assert(0, 'eread_failed '+f+'\n'+ret.err);
  return ret;
});

E.eread_json = f=>eserf(function* xfs_eread_json(){
  return xurl.json_parse(yield E.eread_e(f));
});

E.ewrite = (f, s, opt)=>eserf(function* xfs_ewrite(){
  let func = opt?.append ? fs.appendFile : fs.writeFile;
  func(f, s, undefined, (err, data)=>{
    if (err)
      return void this.continue({err});
    this.continue({});
  });
  let res = yield this.wait();
  return res;
});

E.ewrite_e = (f, s, opt)=>eserf(function* xfs_ewrite_e(){
  let res = yield E.ewrite(f, s, opt);
  if (res.err)
    assert(0, 'ewrite_e_err '+res.err);
  return res;
});

E.ewrite_json = (f, json)=>eserf(function* xfs_ewrite_json(){
  return yield E.ewrite_e(f, str.j2s(json));
});

E.write_json = (f, json)=>{
  return E.write(f, str.j2s(json));
};

export let ewrite_bin = E.ewrite_bin = (f, bytes, offset)=>eserf(function*
xfs_ewrite_bin(){
  if (!(bytes instanceof Array))
    bytes = [bytes];
  fs.open(f, 'r+', (err, fd)=>{
    if (err)
      return void this.continue({err});
    this.finally(()=>fs.close(fd));
    fs.write(fd, new Uint8Array(bytes), 0, bytes.length, offset,
      (_err, bw, buf)=>{
        if (_err)
          return void this.continue({err: _err});
        this.continue({bw, buf});
      });
  });
  let res = yield this.wait();
  return res;
});

E.read_e = (f, encoding='utf8')=>fs.readFileSync(f, encoding);
E.exists = E.exist = f=>fs.existsSync(f);
E.touch = f=>fs.writeFileSync(f, '');
E.is_file = f=>fs.lstatSync(f).isFile();
E.is_dir = f=>fs.lstatSync(f).isDirectory();
E.eis_dir = (f, is_not_recur)=>eserf(function* eis_dir(){
  let func = is_not_recur ? fs.lstat : fs.stat;
  func(f, (err, stats)=>{
    if (err)
      return void this.continue({err});
    this.continue(stats);
  });
  let _stats = yield this.wait();
  if (_stats.err)
    return false;
  return _stats.isDirectory();
});

E.write = (f, s, opt={})=>{
  if (opt.append)
    return fs.appendFileSync(f, s);
  return fs.writeFileSync(f, s);
};
E.copy = (src, dst)=>fs.copyFileSync(src, dst);
E.ecopy = (src, dst)=>eserf(function* ecopy(){
  src = path.resolve(src);
  fs.copyFile(src, dst, (err, res)=>{
    if (err)
      return void this.continue({err});
    this.continue(res);
  });
  let res = yield this.wait();
  if (res)
    assert(0, `res_not_null_err ${str.j2s(res)}`);
  return {};
});
E.erename = (src, dst)=>eserf(function* erename(){
  src = path.resolve(src);
  fs.rename(src, dst, (err, res)=>{
    if (err)
      return void this.continue({err});
    this.continue({});
  });
  return yield this.wait();
});
E.mkdir = dir=>{
  if (E.exist(dir))
    return;
  fs.mkdirSync(dir, '0777');
};
E.mkdir_p = dir=>{
  if (E.exist(dir))
    return;
  fs.mkdirSync(dir, {recursive: true}, '0777');
};
E.path_win2posix = _path=>_path.replace(/\\/ug, '/');
// XXX colin: merge with jake/util.js
E.basename = (_path, opt)=>{
  let _parse;
  if (opt?.is_win)
    _parse = path.win32.parse(_path);
  else
    _parse = path.parse(_path);
  if (opt?.is_no_suffix)
    return _parse.name;
  return _parse.base;
};

E.dirname = (_path, opt)=>{
  let _parse;
  if (opt?.is_win)
    _parse = path.win32.parse(_path);
  else
    _parse = path.parse(_path);
  return _parse.dir;
};

E.remove = f=>{
  if (!E.exist(f))
    return;
  fs.rmSync(f);
};

export let rm = E.rm = (file, opt)=>eserf(function* _rm(){
  file = path.resolve(file);
  fs.rm(file, {recursive: !!opt?.recursive, force: !!opt?.force}, (err, res)=>{
    if (err)
      return void this.continue({err});
    this.continue(res);
  });
  return yield this.wait();
});

E.rm_rf = file=>E.rm(file, {recursive: true, force: true});
export let ls = E.ls = E.readdir = (dir, opt={})=>eserf(function* readdir(){
  // XXX colin: remove leading edge and change to absolute dir path
  // dir == '' == './' == '.'
  dir = path.resolve(dir);
  fs.readdir(dir, (err, _files)=>{
    if (err)
      return void this.continue({err});
    this.continue(_files);
  });
  let files = yield this.wait();
  if (files.err)
    assert(0, 'readdir_err '+files.err);
  if (!opt.is_no_add_dir)
  {
    for (let i=0; i<files.length; i++)
      files[i] = dir+'/'+files[i];
  }
  return files;
});

// XXX colin: once moving to node20 move to use core recursive
E.readdir_rec = (dir, files, is_stat)=>eserf(function* readdir_rec(){
  dir = path.resolve(dir);
  fs.readdir(dir, (err, _files)=>{
    if (err)
      return void this.continue({err});
    this.continue(_files);
  });
  let _files = yield this.wait();
  // XXX colin: handle error
  if (_files?.err)
  {
    if (is_verbose)
      console.error(_files.err);
    return files;
  }
  files = files||[];
  for (let f of _files)
  {
    let _f = dir+'/'+f;
    let stat = yield E.stat(_f);
    // XXX colin: handle error
    if (stat.err)
    {
      if (is_verbose)
        console.error(stat.err);
      continue;
    }
    if (stat.isDirectory())
    {
      files = yield E.readdir_rec(_f, files, is_stat);
      continue;
    }
    let o = {file: path.join(dir, '/', f), stat};
    files.push(is_stat ? o : o.file);
  }
  return files;
});

E.stat = file=>eserf(function* fs_stat(){
  fs.stat(file, (err, res)=>{
    if (err)
      return void this.continue({err});
    this.continue(res);
  });
  return yield this.wait();
});

E.stat_fmt = file=>eserf(function* fs_stat(){
  fs.stat(file, (err, res)=>{
    if (err)
      return void this.continue({err});
    this.continue(res);
  });
  let {dev, mode, nlink, uid, gid, rdev, blksize, ino, size, blocks, atimeMs,
    mtimeMs, ctimeMs, birthtimeMs} = yield this.wait();
  return {atime: new Date(atimeMs), mtime: new Date(mtimeMs),
    ctime: new Date(ctimeMs), btime: new Date(birthtimeMs)};
});

E.statfs = file=>eserf(function* fs_statfs(){
  fs.statfs(file, (err, stats)=>{
    if (err)
      return void this.continue({err});
    this.continue(stats);
  });
  let stats = yield this.wait();
  if (stats.err)
    return stats;
  return {...stats, free_n: stats.bfree*stats.bsize,
    avail_n: stats.bavail*stats.bsize};
});

E.unlink = file=>eserf(function* fs_unlink(){
  fs.unlink(file, (err, res)=>{
    if (err)
      return void this.continue({err});
    this.continue(res);
  });
  let ret = yield this.wait();
  return ret||{};
});

E.unlink_e = file=>eserf(function* fs_unlink_e(){
  let ret = yield E.unlink(file);
  if (ret.err)
    assert(0, 'eread_failed '+ret.err);
  return ret;
});

E.dir_cond = (dir, cond)=>eserf(function* readdir_rec_unlink_cond(){
  let files = yield E.readdir_rec(dir);
  for (let f of files)
  {
    let stat = yield E.stat(f);
    yield cond(f, stat);
  }
});

E.dir_unlink_mtime_less = (dir, mtime)=>eserf(function* dir_unlink_mtime(){
  yield E.dir_cond(dir, (file, stat)=>eserf(function* _dir_cond_cb(){
    if (stat.mtime<mtime)
    {
      console.log(`unlinked ${file}`);
      yield E.unlink(file);
    }
  }));
});

E.dir_mtime_n = (dir, n, is_new)=>eserf(function* dir_unlink_mtime(){
  let ofiles = yield E.readdir_rec(dir, undefined, true);
  ofiles.sort((a, b)=>b.stat.mtime-a.stat.mtime);
  let _ofiles = ofiles;
  if (n)
  {
    _ofiles = ofiles.slice(is_new ? 0 : ofiles.length-n,
      is_new ? n : ofiles.length);
  }
  let ret = [];
  for (let ofile of _ofiles)
    ret.push(ofile.file);
  return ret;
});

export let stream_open = E.stream_open = (file, encoding='utf8', start, end)=>{
  let ret = fs.createReadStream(file, {encoding, start, end});
  return ret;
};

export let stream_open_write = E.stream_open_write = (file, encoding='utf8',
  opt)=>{
  let flags = opt?.is_append ? 'a' : undefined;
  let ret = fs.createWriteStream(file, {encoding, flags});
  return ret;
};

export let stream_write = E.stream_write = (stream, buf)=>eserf(function*
xfs_stream_write(){
  stream.write(buf, (err, written, s)=>{
    if (err)
      return void this.continue({err});
    this.continue({written, s});
  });
  let res = yield this.wait();
  if (res.err)
    return res;
  return res;
});

E.stream_append = f=>fs.createWriteStream(f, {flags: 'a'});
E.stream_read = f=>fs.createReadStream(f);

export let stream_close = E.stream_close = stream=>{
  stream.close();
};

export let is_stream = E.is_stream = stream=>{
  return stream instanceof fs.ReadStream;
};

export let md5 = E.md5 = file=>eserf(function* xfs_md5(){
  let stream = E.stream_open(file);
  let hash = conv.hash_create('md5');
  this.finally(()=>E.stream_close(stream));
  stream.on('data', chunk=>{
    hash.update(chunk);
  });
  stream.on('close', ()=>{
    this.continue(hash.digest('hex').slice(0, 8));
  });
  return yield this.wait();
});

if (proc.is_main(import.meta.url))
{
  eserf(function* _fs_main(){
    //let files = yield E.ls('/tmp');
    //console.log(files);
    //let res = yield E.eis_dir('/tmp');
    //let res = yield E.stat('/tmp/test_amit_sosana__idx__32__len__69.mp3');
    let res = yield E.stream_open(
      '/tmp/test_amit_sosana__idx__32__len__69.mp3');
    console.log(res);
    yield E.stream_close(res);
  });
}
