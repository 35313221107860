// LICENSE_CODE MIT
import crypto from 'crypto';
import proc from './proc.js';

let E = {};
export default E;

E.hash_create = (type='sha256')=>{
  return crypto.createHash(type);
};
E.hash = (buf, len, encoding='utf8', type='sha256')=>{
  return E.hash_create(type).update(buf, encoding).digest('hex')
    .slice(0, len);
};

E.md5 = (buf, len, encoding)=>E.hash(buf, len, encoding, 'md5');
E.md5_etag = buf=>E.md5(buf, 8);

if (proc.is_main(import.meta.url))
  console.log(E.md5('xxx', 16));
