// LICENSE_CODE MIT
let E = {};
export default E;

E.get_v = (k, is_str, is_bool, is_date)=>{
  if (get_cmd_inited)
  {
    console.error('get_cmd already called must call last');
    E.usage();
  }
  if (typeof k == 'string')
    k = [k];
  let i;
  for (let j = 0; j<k.length; j++)
  {
    i = E.argv.findIndex(ele=>new RegExp(`^${k[j]}(=.*)?$`, 'u').test(ele));
    if (i>-1)
      break;
  }
  if (i<0)
    return;
  let _k = E.argv[i];
  let ret = E.argv[i+1];
  if (/[=]/u.test(_k))
    ret = _k.split('=')[1];
  if (!ret || /^-[a-zA-Z]/u.test(ret))
  {
    console.error('invalid param for', _k);
    E.usage();
  }
  E.argv.splice(i, 2);
  if (!is_str && !isNaN(ret))
    ret = +ret;
  if (is_bool)
  {
    ret = ret.toLowerCase();
    if (ret=='true' || ret=='1')
      ret = true;
    else if (ret=='false' || ret=='0')
      ret = false;
    else
    {
      console.error('invalid param expected bool true|false|1|0', k, ret);
      E.usage();
    }
  }
  if (is_date)
    ret = new Date(ret);
  E.argo[_k] = E.argo[E.trim_minus(_k)] = E.argo[E.trim_minus(k[0])] = ret;
  return ret;
};

E.get_v_str = k=>E.get_v(k, 1);
E.get_v_date = k=>E.get_v(k, 1, 0, 1);
E.get_v_bool = k=>E.get_v(k, 1, 1);

E.trim_minus = v=>v.replaceAll(/^(--|-)/ug, '');
E.get_flag = (k, is_v)=>{
  if (get_cmd_inited)
  {
    console.error('get_cmd already called must call last');
    E.usage();
  }
  if (typeof k == 'string')
    k = [k];
  let i;
  for (let j=0; j<k.length; j++)
  {
    i = E.argv.indexOf(k[j]);
    if (i>-1)
      break;
  }
  if (i<0)
    return;
  let _k = E.argv[i];
  let ret = E.argv[i+1];
  if (is_v && ret && !/^--?[a-z]/u.test(ret))
    return E.get_v(_k);
  ret = true;
  E.argv.splice(i, 1);
  E.argo[_k] = E.argo[E.trim_minus(_k)] = E.argo[E.trim_minus(k[0])] = ret;
  return ret;
};

let get_cmd_inited;
E.get_cmd = (cond_cb, id='cmd', n)=>{
  cond_cb = cond_cb||(()=>({}));
  get_cmd_inited = true;
  if (!E.argv.length)
  {
    console.error('no cmd in argv');
    E.usage();
  }
  if (n>1)
  {
    console.error('get_cmd n>1 unsupported');
    E.usage();
  }
  let cmd = E.argv[0];
  E.argv.splice(0, 1);
  E.argo[id] = cmd;
  let res = cond_cb(cmd);
  if (res.err)
  {
    console.error(res.err);
    E.usage();
  }
};

E.init = (usage, opt)=>{
  let argv = process.argv.slice(2);
  E.argv = [];
  for (let i=0; i<argv.length; i++)
  {
    let _arg = argv[i];
    if (_arg.startsWith('-'))
    {
      _arg = _arg.replaceAll(/\s/gu, ' ');
      let arr = _arg.split(' ');
      let k = arr[0];
      arr.shift();
      E.argv.push(k);
      if (arr.length)
        E.argv.push(arr.join(' '));
      continue;
    }
    E.argv.push(_arg);
  }
  E.argo = {};
  E.is_shell = !!process.stdin.isTTY;
  E.usage = usage;
  if (!opt?.is_no_help && E.argv.includes('--help') || E.argv.includes('-h'))
    E.usage();
};

